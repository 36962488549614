import React, { Component } from 'react';
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Fancybox from '../containers/FancyBox';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';
import PressBox from './PressBox';

// Images 
import ImgRelease from '../assets/img/img-video-press-release-new.png'

import Img8 from '../assets/img/bg-featured-news-sample.jpg'
import Img7 from '../assets/img/img-letter-to-stakeholders-featured.jpg'
import Img1 from '../assets/img/img-press-release-corporate-update-new.jpg'
import Img2 from '../assets/img/img-press-release-ovato-lists-new.jpg'
import Img3 from '../assets/img/img-press-release-instantly-new.jpg'
import Img4 from '../assets/img/img-press-release-ovato-will-be.jpg'
import Img5 from '../assets/img/img-press-release-coin-listed.jpg'
import Img6 from '../assets/img/img-press-release-providing.jpg'

class Press extends Component {
  render() {
    const { t } = this.props;
    const record = [
      {
        id: 8, 
        image: Img8, 
        title: 'Ovato Update', 
        date: 'June 29, 2023', 
        content: 'The second quarter of this year has been very interesting for our industry, to say the least. We believe that the strategy we have chosen, our commitment to real retail utility and mass adoption, is the correct way to navigate this extremely dynamic and fluid industry.',
        link: '/newsletter/ovato-nation-v1-issue-3'
      },
      {
        id: 7, 
        image: Img7, 
        title: t('newPress.title7'), 
        date: t('newPress.date7'), 
        content: t('newPress.content7'),
        link: '/letter-to-stakeholders'
      },
      {
        id: 1, 
        image: Img1, 
        title: t('newPress.title1'), 
        date: t('newPress.march'), 
        content: t('newPress.content1'),
        link: '/year-end-report'
      },
      {
        id: 2, 
        image: Img2, 
        title: t('newPress.title2'), 
        date: t('newPress.date2'), 
        content: t('newPress.content2'),
        link: 'https://www.facebook.com/OvatoCoin'
      },
      {
        id: 3, 
        image: Img3, 
        title: t('newPress.title3'), 
        date: t('newPress.date3'), 
        content: t('newPress.content3'),
        link: 'https://www.facebook.com/OvatoCoin'
      },
      {
        id: 4, 
        image: Img4, 
        title: t('newPress.title4'),
        date: t('newPress.date4'), 
        content: t('newPress.content4'),
        link: 'https://www.facebook.com/OvatoCoin'
      },
      {
        id: 5, 
        image: Img5, 
        title: t('newPress.title5'), 
        date: t('newPress.date5'), 
        content: t('newPress.content5'),
        link: 'https://www.facebook.com/OvatoCoin'
      },
      {
        id: 6, 
        image: Img6, 
        title: t('newPress.title6'), 
        date: t('newPress.date6'), 
        content: t('newPress.content6'),
        link: 'https://www.facebook.com/OvatoCoin'
      },
    ]
    return (
      <div className='blue-nav press-main-new'>
        <TopNav />
        
        {/* BANNER */}
        <div className='new-first-row brand-main-new press-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={5}>
                <h1 className='n-font-20 dark-text-5 mb-2'>{t('newPress.pressNewsletter')}</h1>
                <h2 className='Poppins font-14 medium blue-text-3 press-banner-new-heading mt-0'>{t('newPress.latest')}</h2>
                <h3 className='Poppins font-46 bold dark-text-5 press-banner-new-heading-2'>Announcement</h3>
                <p className='Poppins font-13 medium gray-text-4 press-banner-new-date'>August 10, 2023</p>
                <p className='Poppins font-18 light press-banner-new-content'>To better align with the changing environment and pending new rules and regulations with exchanges, Ovato would like to announce the policy changes effective immediately with a trailing effect for ALL stakeholders...</p>
                <div className='btn-wrap press-banner-new-btn-wrap'>
                  <a href='/announcement' target='_blank' className='custom-new-btn custom-new-btn-transparent custom-new-btn-small press-banner-new-btn'>{t('newPress.read')}</a>
                </div>
              </Col>
              <Col md={7} className='press-banner-new-col-2'>
                <div className='video-wrap press-banner-new-video-wrap'>
                  <Fancybox>
                    <a data-fancybox href='https://www.youtube.com/watch?v=QvFtAtVnSik'>
                      <img src={ImgRelease} />
                    </a>
                  </Fancybox>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <div className='press-section-new white-top-curve-2 custom-gradient-1'>
          <Container>
              <Row>
                  <Col>
                    <div className='press-box-holder'>
                      { record.map((post) =>    
                          <PressBox title={post.title} image={post.image} date={post.date} content={post.content} link={post.link} />
                      )}
                    </div>
                  </Col>
              </Row>
          </Container>
        </div>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Press);
