import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class LeaderboardPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

  render() {
    const { t, data } = this.props;

    return (
        <ul className='leaderboard-pagination'>
            <li><a href='#'>{'<'}</a></li>
            <li><a href='#' className='active'>1</a></li>
            <li><a href='#'>2</a></li>
            <li><a href='#'>3</a></li>
            <li><a href='#'>4</a></li>
            <li><a href='#'>5</a></li>
            <li><a href='#'>{'>'}</a></li>
        </ul>
    );
  }
}
export default withTranslation()(LeaderboardPagination);