import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgUserExperience4 from '../assets/img/img-cwa-user-experience-01.png';
import imgUserExperience5 from '../assets/img/img-cwa-user-experience-02.png';
import imgUserExperience1 from '../assets/img/img-cwa-user-experience-03.png';
import imgUserExperience2 from '../assets/img/img-cwa-user-experience-04.png';
import imgUserExperience3 from '../assets/img/img-cwa-user-experience-05.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
class ConsumerSection5 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
     const { t } = this.props;
    const sliderOpts = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 5,
      slidesToScroll: 5,
      centerMode: true,
      centerPadding: '30px',
      responsive: [
        {
          breakpoint: 811,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            centerPadding: '30px',
          }
        },
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '60px',
          }
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    const sliders = [
      { title: 'Receive OVO', img: imgUserExperience1 },
      { title: 'Settings', img: imgUserExperience2 },
      {
        title: 'Sign Up',
        img: imgUserExperience3,
      },
      {
        title: 'Payment',
        img: imgUserExperience4,
      },
      { title: 'Send OVO', img: imgUserExperience5 },
      { title: 'Receive OVO', img: imgUserExperience1 },
      { title: 'Settings', img: imgUserExperience2 },
      {
        title: 'Sign Up',
        img: imgUserExperience3,
      },
      {
        title: 'Payment',
        img: imgUserExperience4,
      },
      { title: 'Send OVO', img: imgUserExperience5 }
    ];
    return (
      <>
        <section className='cwa-5 desktop'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-50 bold dark-text-2 text-center'>{t('consumer5.easy')}</h2>
                        <p className='poppins font-20 regular gray-text-2 text-center mt-30'>{t('consumer5.quick')} <br /> {t('consumer5.providing')} <br /> {t('consumer5.payment')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='cwa-5-col-2'>
                        <Slider
                            {...sliderOpts}
                            className='cwa-5-slider'
                            >
                            {sliders.map((slider) => (
                                <div className='img-wrap'>
                                    <div className='img-wrap-inner'>
                                        <img src={slider.img} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Mobile */}
        <section className='cwa-5 mobile'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-58 bold dark-text-2 text-center'>{t('consumer5.easy')}</h2>
                        <p className='poppins font-20 regular gray-text-2 text-center mt-30'>{t('consumer5.quick')} <br /> {t('consumer5.providing')} <br /> {t('consumer5.payment')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='cwa-5-col-2'>
                        <Slider
                            {...sliderOpts}
                            className='cwa-5-slider'
                            >
                            {sliders.map((slider) => (
                                <div className='img-wrap'>
                                    <div className='img-wrap-inner'>
                                        <img src={slider.img} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(ConsumerSection5);