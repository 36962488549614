import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/TopNav';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MerchantTable from './MerchantTable';

import ImgYear from '../assets/img/img-yer-ovato.png'
import ImgYearBanner from '../assets/img/img-yer-banner.png'
import ImgOvato from '../assets/img/img-yer-ovato-2.png'
import ImgBitovation from '../assets/img/img-yer-bitovation.png'
import ImgYear4 from '../assets/img/img-yer-4.png'
import ImgLeft3 from '../assets/img/img-yer-4-left-3.png'
class YearEndReport extends Component {
  render() {
    const { t } = this.props;
    return (
        <div className='yer-wrap'>
        <section className='yer-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='img-wrap'>
                            <a href='/'><img src={ImgYear} width='144' alt='Ovato' /></a>
                        </div>
                    </Col>
                    <Col className='yer-col-right'>
                        <Row>
                            <Col >
                                <p className='white-text font-20 bold Poppins'>Seth Kamlet</p>
                                <p className='white-text medium font-17'>CEO </p>
                                <p className='white-text medium font-17'>Ovato Pte Ltd</p>
                            </Col>
                            <Col className='yer-col-inner-right'>
                                <p className='white-text font-20 bold Poppins'>Helgo Eisert</p>
                                <p className='white-text medium font-17'>Investor and Corporate Communications </p>
                                <p className='white-text medium font-17'>Bitovation Corp, Ovato Pte Ltd</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} className='yer-col-left-2'>
                        <h2 className='white-text extra-bold font-45 Poppins m-0'>Corporate Update</h2>
                        <h3 className='white-text extra-bold font-107 Poppins m-0'>2021/2022</h3>
                        <p className='white-text font-18 medium Poppins'>Please find enclosed the company’s year-end milestones along with our forecasts for the upcoming year.</p>
                    </Col>
                    <Col sm={6}>
                        <div className='img-wrap yer-img-wrap'>
                            <img src={ImgYearBanner} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='yer-2'>
            <Container>
                <Row>
                    <Col sm={5} className='yer-2-left'>
                        <div className='img-wrap text-center'>
                            <img width='437' src={ImgOvato} alt='Ovato' />
                        </div>
                        <div className='img-wrap text-center'>
                            <img width='462' src={ImgBitovation} alt='Bitovation' />
                        </div>
                       
                    </Col>
                    <Col sm={7} className='yer-2-right'>
                        <h2 className='font-45 extra-bold Poppins blue-text'>Highlights Of 2021 For <br /> Ovato And Bitovation</h2>
                        <p className='font-20 medium'>It was a very interesting year for both Ovato and Bitovation.
                        Ovato is now listed on 4 international exchanges. As for the aggregators (Coinmarketcap, Nomics, etc.) we are listed with basic disclosures and information. We have refined our product mix to include upgrades to both iOS and Android Apps. We have launched the mining protocol to ensure the network adapts to scale. We have also launched our Multi Sig wallets for high secure transactions as well as on “On- chain” escrow type functions and lastly, an “Off Chain” stacking function that gives participants a 30% reward in either value or coins allowing users to lock in percentage growth gains.</p>
                        <p className='font-20 medium'>Bitovation is now operational with our pilot program fully underway in South Florida. The company has discovered a variety of unique opportunities for our merchant on boarding that has developed in this new Covid era. “Local Business “has dramatically changed and powe believe that this has created some very unique opportunities favorable to our position with respect to the Merchant side of the business. With respect to the onboarding of users we have now a clear pathway to scale the on boarding of users
                        </p>
                    </Col>
                </Row>
            </Container>
            
        </section>
        <section className='yer-3'>
            <Container>
                <Row>
                    <Col>
                        <div className='yer-3-box'>
                            <h2 className='font-45 Poppins extra-bold white-text lh-1-2 text-center'>Ovato And Bitovation <br /> 2022 Milestones</h2>
                            <p className='text-center white-text medium font-20'>This coming calendar year promises to be a very active year operationally for both Ovato and Bitovation with the following milestones:</p>
                        </div>
                    </Col>
                </Row>
                <Row className='yer-3-row-2'>
                    <Col>
                        <h2 className='font-67 Poppins extra-bold blue-text text-center'>Ovato</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className='yer-3-box-2'>
                            <h3><span>Exchange Markets</span></h3>
                            <p>We plan to be fully listed with information aggregators allowing for the exchange to be able to fully view and provide a more transparent ranking for OVO. Once fully reporting, Ovato (OVO) plans for promotional and marketing campaigns highlighting Ovato. Tier 1 Exchange listing's are coming this next year.</p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='yer-3-box-2'>
                            <h3><span>New Products</span></h3>
                            <p>Upgrade to User and Merchant Wallets will include a directory listing of merchants who accept Ovato and this will be done through Geolocation. Online Payment Gateways for online transactions are planned Full integration to SMS registrations will be completed.</p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='yer-3-box-2'>
                            <h3><span>Ovato Meet-Ups</span></h3>
                            <p>We will begin promoting our “Meet-Ups” officially in January and plans call to use the meet-ups as a “Catch All”, a face to face forum, to include everything from merchant relations and on-boarding to technical help. Each meet-up generally has a theme or a topic attached and is about 2 hours in length. The meet ups are administered though Bitovation.  If you would like to participate in holding or hosting one or would like to attend one, please visit:
https://ovato.com/en/meet-ups.</p>
                            <p>We sponsor the events and believe strongly that they provide an excellent forum to build a local organization for on-boarding both users and merchants</p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='yer-3-box-2'>
                            <h3><span>Marketing/Influencers</span></h3>
                            <p>We are currently in discussions to secure very high-level celebrity influencers to promote our message to their friends and followers and plans call for Ovato to begin this in Q1.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='yer-4'>
            <Container>
                <Row>
                    <Col sm={7} className='yer-4-col-left-1'>
                        <h2 className='Poppins font-67 extra-bold blue-text'>Bitovation</h2>
                        <p className='font-20 medium'>The company is now operational with our pilot program fully underway. The pilot program will continue another 60 days. We will then commence with our merchant program nationally (USA)</p>
                    </Col>
                    <Col sm={5}>
                        <div className='img-wrap yer-4-img-wrap'>
                            <img src={ImgYear4} alt='Ovato Mobile' />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>Private Placement <br /> Memorandum (PPM)</span></h3>
                            <p>Bitovation will be conducting a PPM of approximately $3 million (We already have indications of interest for about $1 million).  Once this is complete, management believes that we are in a strong position to be a fully reporting listed company on the USA exchange markets.</p>
                            <p>The private placement is attractively priced at $.40 per share and has a full warrant attached. The pricing of this warrant is as follows</p>
                            <p>A) $.80 within 9 months, <br />
                            B) $1.20 within 1 year <br />
                            C) $2 within 18 months.</p>
                            <p>The complete details of the private placement are in the document to be released on or about January 10, 2022
                            </p>
                        </div>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>Ovato(OVO) Holdings</span></h3>
                            <p>Bitovation has increased its holdings of Ovato to 3 million coins currently trading at around $5 per coin. These coins are placed directly on to our balance sheet and financials can be found in the private placement. Additionally, Bitovation will be publishing reviewed financials on a quarterly bases at the conclusion of the PPM</p>
                        </div>
                        <div className='img-wrap yer-4-left-3'>
                            <img src={ImgLeft3} alt='ATM' />
                        </div>
                    </Col>
                    <Col>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>Exchange Listing</span></h3>
                            <p>Bitovation plans to file a registration statement to go public through an S1 filing after the company has completed the PPM. Bitovation plans to list in both the US market as well as the Frankfurt stock exchange at the completion of the private placement. 
Special Note: Once public, Bitovation may receive an additional $6 million to $15 million in warrant coverage financing</p>
                        </div>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>You Can Help!</span></h3>
                            <p>There are many ways that you can get more involved:  Attend meetings, refer merchants and users or even partner with us. The affiliate programs are launching soon, and you can even refer people to our PPM and even follow and comment on social media. Found something Buggy on the platform? Please let us know - We pay for Bugs!</p>
                        </div>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>Bitovation ATM</span></h3>
                            <p>To round out the ecosystem Bitovation is entering enter the ATM marketplace. We will be doing this though a combination of direct operations, joint venture, and dealership models. To this end, Bitovation will also be filing an MSB registration. Management believes that by using Ovato as an initial gateway for the on- boarding to cryptocurrencies there is a unique competitive advantage while providing a more frictionless method of on-boarding to users. </p>
                        </div>
                        <div className='yer-3-box-2 yer-3-box-2-white'>
                            <h3><span>In Memoriam</span></h3>
                            <p>You may have noticed our social media was a bit anemic in end Q4 as we lost our dear, very special collogue, Catherine Jenson (CJ) (38). 
Honest, reliable, and trustworthy, we were hit hard in many ways by her passing.  CJ was our head of social media among other things since even before the Bitovation and Ovato companies were founded.   Although you may never have heard her name, she was a key member of our team, and she is missed.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='yer-5'>
            <Container>
                <Row>
                    <Col className='yer-5-col'>
                        <p className='font-20 medium'>Thank you for reading and thank you always for believing in us even before we began to see success.  As you can see, a lot has happened as we reflect over the past year but as we pick up steam, even more will happen in 2022.  Thank you for your continued support</p>
                        <h2 className='font-30 extra-bold blue-text'>The Ovato <br /> And Bitovation Family</h2>
                    </Col>
                </Row>
            </Container>
        </section>
        <BottomNav />
        </div>
    );
  }
}
export default withTranslation()(YearEndReport);
