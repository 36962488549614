import React, { Component } from 'react';
import { Row, Col, Container, Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { deviceDetect } from '../constants/defaultValues';
import { Service } from '../providers/Services';
import { toast } from 'react-toastify';
import img16ProvenWays from '../assets/img/img-16-proven-ways-ovato.png';
import imgOvato from '../assets/img/ovato-logo-new.png';
import imgGooglePlay from '../assets/img/img-google-play-landing-new.png';
import imgAppStore from '../assets/img/img-app-store-landing-new.png';
var typearray = [];
const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class LandingBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      fname: '',
      lname: '',
      phone: '',
      type: [],
      country: '',
      check1: false,
      check2: false,
      check3: false,
    };
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleCheck = (val) => {
    switch (val) {
      case 1:
        this.setState({ check1: !this.state.check1 }, () => {
          if (this.state.check1 === true) {
            typearray.push(1);
          } else {
            typearray.splice(typearray.indexOf(1), 1);
          }
        });
        break;
      case 2:
        this.setState({ check2: !this.state.check2 }, () => {
          if (this.state.check2 === true) {
            typearray.push(2);
          } else {
            typearray.splice(typearray.indexOf(2), 1);
          }
        });
        break;
      case 3:
        this.setState({ check3: !this.state.check3 }, () => {
          if (this.state.check3 === true) {
            typearray.push(3);
          } else {
            typearray.splice(typearray.indexOf(3), 1);
          }
        });
        break;
      default:
        this.setState({ check1: false, check2: false, check3: false });
    }
  };
  handleSend = (e) => {
    e.preventDefault()
    if (this.state.email === '') {
      toast.error("Email can't be empty !", toaststyle);
    } else {
      const payload = {
        email: this.state.email,
        fname: this.state.fname,
        lname: this.state.lname,
        phone: this.state.phone,
        country: this.state.country,
        landing_type: typearray,
        type: 'landing',
        os: deviceDetect.os,
        browser: deviceDetect.browsername,
        device: deviceDetect.devicetype,
        plateform: deviceDetect.plateform,
      };
      Service.ebookForm(payload)
        .then((res) => {
          toast.success(
            'Thank you Your Ebook is on its way. Please send the requests to business@ovato.com!',
            toaststyle
          );
        })
        .catch((error) => {
          if (error && error.status === 400) {
            toast.error(
              'Email is invalid, please enter a valid email!',
              toaststyle
            );
          } else {
            toast.error(
              error && error.status==429
                ? error.data
                : 'Some error occurred!',
              toaststyle
            );
          }
        });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='landing-footer-new pt-80 pb-100'>
          <Container>
            <Row>
              <Col>
                <h2 className='font-42 extra-bold white-text text-center lh-1-1 landing-footer-h2'>
                {t('landingbottom.get_our_e_book')} <br /> {t('landingbottom.proven_ways')}
                </h2>
              </Col>
            </Row>
            <Row className='align-center mt-60 landing-footer-row-2'>
              <Col md={5}>
                <ul className='font-23 medium white-text blue-check-ribbon-list landing-footer-ul'>
                  <li>{t('landingbottom.list1')}</li>
                  <li>
                  {t('landingbottom.list2')}
                  </li>
                  <li>{t('landingbottom.list3')}</li>
                  <li>
                  {t('landingbottom.list4')}
                  </li>
                  <li>{t('landingbottom.list5')}</li>
                </ul>
              </Col>
              <Col md={7}>
                <div className='col-inner landing-footer-form-col'>
                  <Row className='align-center'>
                    <Col md={4}>
                      <div className='img-wrap img-proven-ways'>
                        <img src={img16ProvenWays} alt='16 proven ways' />
                      </div>
                    </Col>
                    <Col md={8} className='landing-footer-form-right'>
                      <p className='text-center font-20 medium mb-0 landing-footer-p'>
                      {t('landingbottom.send_me_more_info')}
                      </p>
                      <div className='img-wrap text-center mt-10'>
                        <img src={imgOvato} alt='Ovato' width='75' />
                      </div>
                      <Form className='landing-footer-form mt-15'>
                        <Row>
                          <Col md={6}>
                            <input
                              type='text'
                              name='fname'
                              value={this.state.fname}
                              onChange={this.handleInput}
                              placeholder={t('landingbottom.first_name')}
                            />
                          </Col>
                          <Col md={6}>
                            <input
                              type='text'
                              name='lname'
                              value={this.state.lname}
                              onChange={this.handleInput}
                              placeholder={t('landingbottom.last_name')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              type='text'
                              name='phone'
                              value={this.state.phone}
                              onChange={this.handleInput}
                              placeholder={t('landingbottom.phone_number')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              type='email'
                              name='email'
                              value={this.state.email}
                              onChange={this.handleInput}
                              placeholder={t('landingbottom.email')}
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              type='text'
                              name='country'
                              value={this.state.country}
                              onChange={this.handleInput}
                              placeholder={t('landingbottom.country')}
                            />
                          </Col>
                        </Row>
                        <Row className='mt-20 checkbox-row'>
                          <Col className=''>
                            <div class='custom-control custom-checkbox text-center'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='Invest'
                                checked={this.state.check1}
                                onClick={() => this.handleCheck(1)}
                              />
                              <label class='custom-control-label' for='Invest'>
                                {t('landingbottom.invest')}
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <div class='custom-control custom-checkbox text-center'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='Earn'
                                checked={this.state.check2}
                                onClick={() => this.handleCheck(2)}
                              />
                              <label class='custom-control-label' for='Earn'>
                                {t('landingbottom.earn')}
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <div class='custom-control custom-checkbox text-center'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='Partner'
                                checked={this.state.check3}
                                onClick={() => this.handleCheck(3)}
                              />
                              <label class='custom-control-label' for='Partner'>
                                {t('landingbottom.partner')}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row className='mt-20'>
                          <Col>
                            <button type='submit' onClick={this.handleSend}>{t('landingbottom.send_now')}</button>
                          </Col>
                        </Row>
                        <Row className='mt-20 landing-footer-form-logo'>
                          <Col>
                            <div className='img-wrap text-right'>
                              <a
                                alt='Google Play'
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                              >
                                <img alt='Google Play' src={imgGooglePlay} />
                              </a>
                            </div>
                          </Col>
                          <Col>
                            <div className='img-wrap text-left'>
                              <a
                                alt='App Store'
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                              >
                                <img alt='App Store' src={imgAppStore} />
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(LandingBottom);
