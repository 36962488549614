import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import ImgHeatMap from '../../../assets/img/premium-wallet/img-heatmap-box-org.png'

class HeatMapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    return (
        <div className='org-box'>
            <div className='poppins v2-font-15 semi-bold blue-text org-box-header'>
                Heat Map
            </div>

            <div className='org-box-body'>
                <img src={ImgHeatMap} />
            </div>
        </div>
    );
  }
}
export default withTranslation()(HeatMapBox);
