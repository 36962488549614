import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import {
  Container,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
class BrandWrapper extends Component {
    constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDropdown = (num) => {
    this.setState({ open: !this.state.open });
  };
  render() {
    const { t } = this.props;
    const image = this.props.image;
    const title = this.props.title;
    const subtitle = this.props.subtitle;
    const downloads = this.props.downloads
    return (
      <>
      <div className='brand-new-wrapper'>
          <div className='brand-new-img'>
            <img src={image} />
          </div>
          <div className='brand-new-title'>
            {title}
          </div>
          { subtitle && 
          <div className='brand-new-subtitle'>
            {subtitle}
          </div>
          }
          <div className='brand-new-btn-wrap'>
            <Dropdown
                    isOpen={this.state.open}
                    size='sm'
                    toggle={() => this.handleDropdown()}
                    className='brand_drpdown mt-3'
                  >
                    <DropdownToggle tag='a' className='nav-link'>
                      <Button
                        className='brand_download mt-2'
                        style={{ borderRadius: 60 }}
                      >
                        {t('brandassets.download')}{' '}
                        <i class='fas fa-caret-down' />
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu className='brand_download_drpdwn'>
                      <a
                        href={downloads.png}
                        download={title+'.png'}
                      >
                        <DropdownItem className='brand_download_drpdwn_list'>
                          {t('brandassets.png')}
                        </DropdownItem>
                      </a>
                      <a
                        href={downloads.jpg}
                        download={title + '.jpg'}
                      >
                        <DropdownItem className='brand_download_drpdwn_list'>
                          {t('brandassets.jpg')}
                        </DropdownItem>
                      </a>
                      <a
                        href={downloads.pdf}
                        download={title + '.pdf'}
                      >
                        <DropdownItem className='brand_download_drpdwn_list'>
                          {t('brandassets.pdf')}
                        </DropdownItem>
                      </a>
                      <DropdownItem className='brand_download_drpdwn_list'>
                        {t('brandassets.pngwhite')}
                      </DropdownItem>
                      <DropdownItem className='brand_download_drpdwn_list'>
                        {t('brandassets.pdfwhite')}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
          </div>
      </div>
      
      </>
    );
  }
}
export default withTranslation()(BrandWrapper);
