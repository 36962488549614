import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgPayment from '../assets/img/img-mwa-4-new.png';
import imgPaymentMobile from '../assets/img/img-mwa-4-mobile.png';
class MerchantSection4 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
    
     const { t } = this.props;
    return (
      <>
        <section className='cwa-4 mwa-4 desktop'>
            <Container>
                <Row>
                    <Col md={6} className='cwa-4-col-2'>
                        <h2 className='poppins font-50 bold dark-text-2 lh-1-2 cwa-4-heading'>{t('merchantWallet4.receive')} <br /> {t('merchantWallet4.with')}</h2>
                        <ul className='cwa-ul-4 cwa-ul'>
                            <li>
                                <strong>{t('merchantWallet4.blockchain')}</strong>
                                <p>{t('merchantWallet4.all')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet4.fast')}</strong>
                                <p>{t('merchantWallet4.transactions')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet4.end')}</strong>
                                <p>{t('merchantWallet4.fully')}</p>
                            </li>
                        </ul>
                        <div className='btn-wrap cwa-btn-wrap-4 mwa-btn-wrap-4'>
                             <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet4.download')}</a>
                        </div>
                    </Col>
                    <Col md={6} className='cwa-4-col-1'>
                        <div className='img-wrap cwa-4-img-wrap'>
                            <img src={imgPayment} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Mobile */}
        <section className='cwa-4 mwa-4 mobile'>
            <Container>
                <Row>
                    <Col md={6} className='cwa-4-col-2'>
                        <h2 className='poppins font-58 bold dark-text-2 lh-1-2 cwa-4-heading'>{t('merchantWallet4.receive')} <br /> {t('merchantWallet4.with')}</h2>
                        <ul className='cwa-ul-4 cwa-ul'>
                            <li>
                                <strong>{t('merchantWallet4.blockchain')}</strong>
                                <p>{t('merchantWallet4.all')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet4.fast')}</strong>
                                <p>{t('merchantWallet4.transactions')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet4.end')}</strong>
                                <p>{t('merchantWallet4.fully')}</p>
                            </li>
                        </ul>
                        <div className='btn-wrap cwa-btn-wrap-4'>
                             <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet4.download')}</a>
                        </div>
                    </Col>
                    <Col md={6} className='cwa-4-col-1'>
                        <div className='img-wrap cwa-4-img-wrap'>
                            <img src={imgPaymentMobile} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(MerchantSection4);