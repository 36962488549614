import React, { Component } from 'react';
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Fancybox from '../containers/FancyBox';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Images
import ImgBanner from '../assets/img/img-bug-bounty-banner.png'
import Img2 from '../assets/img/img-bug-bounty-2.png'
import ImgReward from '../assets/img/img-bounty-rewards.png'
import ImgEligible from '../assets/img/img-bug-bounty-eligible.png'
import ImgInEligible from '../assets/img/img-ineligible.png'
import ImgPayments from '../assets/img/img-bounty-payments.png'

class BugBounty extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav v3-main press-main-new'>
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new bug-bounty-banner'>
          <Container>
            <Row className='align-center'>
              <Col md={7}>
                <h2 className='Poppins font-55 bold dark-text-5 bug-bounty-banner-heading'>
                  { t('bugBounty.ovato') }
                </h2>
                <p className='Poppins font-18 light dark-text-3 bug-bounty-banner-text'>
                  { t('bugBounty.theDash') }
                </p>
                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul'>
                  <li>{ t('bugBounty.mainnet') }</li>
                  <li>{ t('bugBounty.dashCore') }</li>
                  <li>{ t('bugBounty.dashWalletAndroid') }</li>
                  <li>{ t('bugBounty.dashWalletiOS') }</li>
                  <li>{ t('bugBounty.textWallet') }</li>
                </ul>
                <div className='btn-wrap bug-bounty-banner-btn'>
                  <a href={'mindmap/connect'} className='custom-new-btn'>
                    { t('bugBounty.report') }
                  </a>
                </div>
              </Col>
              <Col md={5} className='press-banner-new-col-2'>
                <div className='img-wrap bug-bounty-banner-img-wrap'>
                  <img
                    src={ImgBanner}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Section 2 */}
        <section className='custom-gradient-1 bug-bounty-2 white-top-curve-2'>
          <Container>
            <Row className='bug-bounty-2-row-1'>
              <Col>
                <div className='bug-bounty-2-box'>
                  <h2 className='Poppins font-30 bold dark-text-5'>
                    { t('bugBounty.responsible') }
                  </h2>
                  <p className='Poppins font-18 light dark-text-3'>
                    { t('bugBounty.as') }
                  </p>
                </div>
              </Col>
            </Row>
            <Row className='bug-bounty-2-row-2'>
              <Col md={5}>
                <div className='img-wrap bug-bounty-2-img-wrap'>
                  <img src={Img2} />
                </div>
              </Col>
              <Col md={7}>
                <h2 className='Poppins font-46 bold dark-text-5 bug-bounty-2-row-2-heading'>
                  { t('bugBounty.eligibility') }
                </h2>
                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul mt-30 bug-bounty-2-ul'>
                  <li>{ t('bugBounty.youCannot') }</li>
                  <li>
                    { t('bugBounty.youCannot2') }
                  </li>
                  <li>{ t('bugBounty.youCannot3') }</li>
                  <li>
                    { t('bugBounty.youCannot4') }
                  </li>
                  <li>
                    { t('bugBounty.youCannot5') }
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section 3 */}
        <section className='custom-gradient-1 bug-bounty-3'>
          <Container>
            <Row className='align-center'>
              <Col md={5}>
                <h2 className='Poppins font-46 bold dark-text-5 bug-bounty-3-heading'>
                  { t('bugBounty.bounty') }
                </h2>
                <p className='Poppins font-18 light dark-text-3'>
                  { t('bugBounty.theGoal') }
                </p>
                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul mt-40'>
                  <li>{ t('bugBounty.level1') }</li>
                  <li>{ t('bugBounty.level2') }</li>
                  <li>{ t('bugBounty.level3') }</li>
                  <li>{ t('bugBounty.level4') }</li>
                  <li>{ t('bugBounty.level5') }</li>
                </ul>
                <div className='btn-wrap mt-30'>
                  <a href={'mindmap/connect'} className='custom-new-btn mt-15'>
                    { t('bugBounty.learn') }
                  </a>
                </div>
              </Col>
              <Col md={7}>
                <div className='img-wrap bug-bounty-3-img-wrap'>
                  <img src={ImgReward} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section 4 */}
        <section className='bug-bounty-4'>
          <Container>
            <Row className='align-center'>
              <Col md={7}>
                <div className='img-wrap bug-bounty-4-img-wrap'>
                  <img
                    src={ImgEligible}
                  />
                </div>
              </Col>
              <Col md={5}>
                <h2 className='Poppins font-46 bold dark-text-5 bug-bounty-4-heading'>
                  { t('bugBounty.eligible') }
                </h2>
                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul mt-40'>
                  <li>
                    { t('bugBounty.identify') }
                  </li>
                  <li>
                    { t('bugBounty.such') }
                  </li>
                  <li>
                    { t('bugBounty.include') }
                    <ul>
                      <li>
                        { t('bugBounty.provide') }
                      </li>
                    </ul>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section 5 */}
        <section className='bug-bounty-5'>
          <Container>
            <Row className='align-center'>
              <Col md={5}>
                <h2 className='Poppins font-46 bold dark-text-5 bug-bounty-5-heading'>
                  { t('bugBounty.ineligible') }
                </h2>
                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul mt-40'>
                  <li>
                    { t('bugBounty.vulnerabilities') }
                  </li>
                  <li>
                    { t('bugBounty.thirdParty') }
                  </li>
                </ul>
              </Col>
              <Col md={7}>
                <div className='img-wrap bug-bounty-5-img-wrap'>
                  <img
                    src={ImgInEligible}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section 6 */}
        <section className='custom-gradient-1 white-top-curve-3 bug-bounty-6'>
            <Container>
                <Row className='bug-bounty-6-row-1'>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    1
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.severityHigh') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.30points') }</p>
                                <p className='bug-bounty-6-text-1'>{ t('bugBounty.without') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.private') }</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    2
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.severityMedium') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.20points') }</p>
                                <p className='bug-bounty-6-text-1'>{ t('bugBounty.without2') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.cannot') }</p>
                                <h2 className='bug-bounty-6-subheading'>{ t('bugBounty.breach') }</h2>
                                <p className='bug-bounty-6-text-1'>{ t('bugBounty.without3') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.private2') }</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    3
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.severityLow') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.10points') }</p>
                                <p className='bug-bounty-6-text-1'>{ t('bugBounty.with') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.incorrect') }</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='bug-bounty-6-row-2'>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    1
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.priorityHigh') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.30points2') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.very') }</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    2
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.priorityMedium') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.20points2') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.moderate') }</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='bug-bounty-box-inner'>
                            <div className='bug-bounty-6-box'>
                                <div className='bug-bounty-6-box-num'>
                                    2
                                </div>
                                <h2 className='bug-bounty-6-heading'>{ t('bugBounty.priorityLow') }</h2>
                                <p className='bug-bounty-6-subheading'>{ t('bugBounty.10points2') }</p>
                                <p className='bug-bounty-6-text-2'>{ t('bugBounty.low') }</p>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
                <Row className='align-center bug-bounty-6-row-3'>
                    <Col md={6}>
                        <h2 className='Poppins font-46 bold dark-text-5'>
                            { t('bugBounty.bountyPayments') }
                        </h2>
                        <ul className='blue-check-ribbon-list blue-check-ribbon-list-small font-16 bug-bounty-banner-ul bug-bounty-banner-ul-6 mt-40'>
                            <li>
                                { t('bugBounty.awards') }
                                <ul>
                                    <li>{ t('bugBounty.dash') }</li>
                                </ul>
                            </li>
                            <li>
                                { t('bugBounty.payouts') }
                            </li>
                            <li>
                                { t('bugBounty.dcg') }
                            </li>
                        </ul>
                        <div class="btn-wrap mt-30"><a href={'mindmap/connect'} class="custom-new-btn">{ t('bugBounty.report2') }</a></div>
                    </Col>
                    <Col md={6}>
                        <div className='img-wrap bug-bounty-6-img-wrap'>
                        <img
                            src={ImgPayments}
                        />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(BugBounty);
