import React, { useEffect } from 'react';

import allNFTs from '../data/nfts'

const TestData = (props) => {

    const data = allNFTs

    const updatedData = data.map(item => {
        if (item.mask_type === "horns") {
            return { ...item, assets: 6000 };
        }
        return item;
    });

    useEffect(() => {
        // Code here will run on the first load
        // 
    
        // You can perform any initialization or setup here
        // For example, you can fetch initial data from an API
        // 
      }, []); 

  return (
    <div>
      <h1>Hello, {props.name}!</h1>
      <p>This is a functional component.</p>
    </div>
  );
};

export default TestData;