import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// CSS
import '../../assets/css/FloatingIcons.css'

// SVGS 
import { ReactComponent as IconYoutube } from '../../assets/img/floating-icons/icon-youtube.svg';
import { ReactComponent as IconLinkedIn } from '../../assets/img/floating-icons/icon-linked-in.svg';
import { ReactComponent as IconFacebook } from '../../assets/img/floating-icons/icon-facebook.svg';
import { ReactComponent as IconTwitter } from '../../assets/img/floating-icons/icon-twitter.svg';
import { ReactComponent as IconInstagram } from '../../assets/img/floating-icons/icon-instagram.svg';
import { ReactComponent as IconTelegram } from '../../assets/img/floating-icons/icon-telegram.svg';
import { ReactComponent as IconGithub } from '../../assets/img/floating-icons/icon-github.svg';

class FloatingIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className='floating-icons-wrap'>
        <ul className='floating-icons'>
            <li><a className='floating-icon-youtube' href='https://www.youtube.com/watch?v=QvFtAtVnSik' target='_blank' rel="noopener noreferrer"><IconYoutube /></a></li>
            <li><a className='floating-icon-linkedin' href='https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true' target='_blank' rel="noopener noreferrer"><IconLinkedIn /></a></li>
            <li><a className='floating-icon-facebook' href='https://www.facebook.com/OvatoCoin' target='_blank' rel="noopener noreferrer"><IconFacebook /></a></li>
            <li><a className='floating-icon-twitter' href='https://twitter.com/OvatoCoin' target='_blank' rel="noopener noreferrer"><IconTwitter /></a></li>
            <li><a className='floating-icon-instagram' href='https://www.instagram.com/ovatocoin/' target='_blank' rel="noopener noreferrer"><IconInstagram /></a></li>
            <li><a className='floating-icon-telegram' href='#' target='_blank' rel="noopener noreferrer"><IconTelegram /></a></li>
            <li><a className='floating-icon-github' href='http://localhost:3000/en#' target='_blank' rel="noopener noreferrer"><IconGithub /></a></li>
        </ul>
      </div>
    );
  }
}
export default withTranslation()(FloatingIcons);