import React from 'react';

import { formatID, nftFlag, numberWithCommas, capitalizeWords } from '../helpers/helpers';

const maskType = (num) => {
    if (num === 1) {
        return 'small';
    } else if (num === 2) {
        return 'medium';
    } else if (num === 3) {
        return 'large';
    }
}

const votes = (type) => {
    if (type === 1) {
        return '1';
    } else if (type === 2) {
        return '2';
    } else if (type === 3) {
        return '3';
    }
}

const nftOverview2 = (data) => {
    // return (
    //     `NFT #${formatID(data.nft_number)} falls in country ${data.country} 
    //     ${country.people ? `, a country with approximately ${country.people} people` : ''}
    //     ${country.gnp ? ` and a GNP of over ${ country.gnp },` : ''}
    //     has a ${capitalizeWords(data.mask_type)} Mask with ${capitalizeWords(data.expression)} expression. It has a total capacity of ${numberWithCommas(data.capacity)} with OVO Assets amounting to ${numberWithCommas(data.assets)}. You can earn ${data.retail_royalties} Royalty share with this NFT and get ${data.dao_participation} votes in DAO Participation, NFT wallet, and 3D Printing.`
    // )

    return (
        // `NFT #${formatID(data.nft_number)} falls in country ${data.country} 
        // has a ${maskType(data.type)} mask. It has a total capacity of ${numberWithCommas(data.cap)} with OVO Assets amounting to ${numberWithCommas(data.ovo_tokens)}. You can earn ${data.retailL2}% Royalty share with this NFT and get ${votes(data.type)} votes in DAO Participation, NFT wallet, and 3D Printing.`

         `NFT #${formatID(data.nft_number)} has a total capacity of ${numberWithCommas(data.cap)} with OVO Assets amounting to ${numberWithCommas(data.ovo_tokens)}. You can earn ${data.retailL2}% Royalty share with this NFT and get ${votes(data.type)} votes in DAO Participation, NFT wallet, and 3D Printing.`
    )
}
export default nftOverview2