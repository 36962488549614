import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MerchantTable from './MerchantTable';

// Images
import ImgBanner from '../assets/img/img-about-banner-new-mobile-min.png'
import ImgAbout2 from '../assets/img/img-about-2-new-min.png'

import ImgEconomy from '../assets/img/img-about-full-circle-economy-min.png'

import ImgEconomyMobile from '../assets/img/img-about-full-circle-economy-mobile-min.png'

class About extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav support_main about-main'>
        {/* Header Section */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new about-banner-new'>
            <Container>
                <Row className='align-center'>
                    <Col md={12}>
                        <h1 className='Poppins font-55 bold dark-text-5 brand-main-new-heading-1'>{t('about.welcome')}</h1>
                        <p className='Poppins font-18 light dark-text-4 text-capitalize'>{t('about.p')}</p>
                        <div className='btn-wrap'>
                          <a href='/wallet'
                            className='custom-new-btn' 
                          >
                            {t('about.contact')}
                          </a>
                        </div>
                        
                    </Col>
                    <Col className='mobile about-banner-new-mobile-col'>
                      <div className='img-wrap'>
                          <img src={ImgBanner} />
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
        {/* Section 2 */}
        <div className='about-2-new-2 white-bottom-curve'>
          <Container>
            <Row className='align-center about-2-new-2-row-1'>
              <Col md={6} className='about-2-new-col-1'>
                <div className='img-wrap about-2-new-img-wrap relative z-index-2'>
                  <img width={510} src={ImgAbout2} />
                </div>
              </Col>
              <Col md={6} className='about-2-new-col-2'>
                <h2 className='Poppins bold font-30 dark-text-5 text-center lh-1-1 relative z-index-2 about-3-new-title'>
                  {t('about.its')} <br />
                  {t('about.its2')}
                </h2>
                <p className='medium font-21 Poppins blue-text text-center lh-1-4 mt-30 relative z-index-2 about-3-new-text'>
                  {t('about.move')} {t('about.move2')}
                  
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Section 3 */}
        <section className='custom-gradient-1 about-4-new'>
          <Container className='about-4-new-container'>
            <Row className='about-4-new-row'>
              <Col className='about-4-new-col'>
                <h2 className='about-4-new-title Poppins bold font-46 dark-text-5 text-center lh-1-1'>
                  {t('about.increase')} <br />
                  {t('about.in_your')}
                </h2>
                <p className='about-4-new-text light dark-text-6 font-18 Poppins text-center lh-1-4 mt-30'>
                  {t('about.say')}
                </p>
                <MerchantTable />
              </Col>
            </Row>
            <Row className='align-center about-4-new-row-2 mt-80'>
              <Col lg='6' className='md-order-1'>
                <p className='Poppins font-18 light dark-text-4'>
                  {t('about.ovato_is_built')}
                </p>
                <p className='Poppins font-18 light dark-text-4'>
                  {t('about.ovato_is_spec')}
                </p>
                <div className='btn-wrap mt-30'>
                    <a href='/wallet'
                      className='custom-new-btn' 
                    >
                      {t('about.contact')}
                    </a>
                  </div>
              </Col>
              <Col lg='6' className='md-order-2'>
                <div className='img-wrap about-2-new-img desktop'>
                  <img
                  alt='Ovato'
                  src={ImgEconomy}
                />
                </div>
                <div className='img-wrap about-2-new-img mobile'>
                  <img
                  alt='Ovato'
                  src={ImgEconomyMobile}
                />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Footer Section */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(About);
