import React, { Component } from 'react';
import { Container, CardTitle, Button, Card } from 'reactstrap';
import BottomNav2 from '../containers/BottomNav2';
import TopNav from '../containers/TopNav';
import { withTranslation } from 'react-i18next';
class WordBackup extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <TopNav />
        <Container className='pt-5 pb-4 text-center wordbackup-container'>
          <div className='backup_notice pt-5 pb-5 mb-4 text-center backup_web'>
            <span className='backup_notice_imp'>{t('backup.notice')}</span>
            <span className='backup_notice_sub'>
              &emsp;|&emsp;{t('backup.noticeline')}
            </span>
          </div>
          <div className='backup_notice pt-5 pb-5 mb-4 text-center backup_mobile'>
            <p className='backup_notice_imp'>{t('backup.notice')}</p>
            <p className='backup_notice_sub'>{t('backup.noticeline')}</p>
          </div>
          <p className='backup_instruct'>{t('backup.tapwords')}</p>
          <center>
            <div className='backup_worddiv'>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>1 </span>
                  <span className='backup_word'>Fringe</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>2 </span>
                  <span className='backup_word'>Square</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>3 </span>
                  <span className='backup_word'>Weapon</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>4 </span>
                  <span className='backup_word'>Law</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>5 </span>
                  <span className='backup_word'>Faith</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>6 </span>
                  <span className='backup_word'>Below</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>7 </span>
                  <span className='backup_word'>Angry</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>8 </span>
                  <span className='backup_word'>Disorder</span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_count'>9 </span>
                  <span className='backup_word'>Rocket</span>
                </CardTitle>
              </Card>
            </div>
          </center>
          <center>
            <div className='backup_worddiv mt-5 mb-5'>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_word'>Square</span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_word'>Below</span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_wordbox'>
                <CardTitle>
                  <span className='backup_word'>Rocket</span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
              <Card className='backup_dashed_wordbox'>
                <CardTitle>
                  <span className='backup_word'></span>
                </CardTitle>
              </Card>
            </div>
          </center>
          <div className='backup_web mb-5'>
            <Button className='backup_continue' style={{ borderRadius: 60 }}>
              {t('backup.continue')}
            </Button>
            <Button className='backup_download' style={{ borderRadius: 60 }}>
              {t('backup.download')}
            </Button>
          </div>
          <div className='backup_mobile mb-5'>
            <Button className='backup_continue' style={{ borderRadius: 60 }}>
              {t('backup.continue')}
            </Button>
            <br />
            <Button className='backup_download' style={{ borderRadius: 60 }}>
              {t('backup.download')}
            </Button>
          </div>
        </Container>
        <BottomNav2 />
      </div>
    );
  }
}
export default withTranslation()(WordBackup);
