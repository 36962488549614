import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class BottomNav2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className='pink-border'></div>
        <div className='bottom_main bottom2_web'>
          <div className='text-center pt-4 pb-4'>
            <p className='bottom_info_text2'>
              {t('bottombar.privacy_policy')}&emsp;|&emsp;
              {t('bottombar.terms_and_condition')}&emsp;|&emsp;
              {t('bottombar.copyright')}&emsp;|&emsp;
              {t('bottombar.all_right_reserve')}
            </p>
          </div>
        </div>
        <div className='bottom_main bottom2_mobile'>
          <div className='text-center pt-4'>
            <p className='bottom_info_text2'>
              <a href='/privacy_policy'>{t('bottombar.privacy_policy')}</a>
            </p>
          </div>
          <div className='text-center'>
            <p className='bottom_info_text2'>
              <a href='/terms-and-condition'>
                {t('bottombar.terms_and_condition')}
              </a>
            </p>
          </div>
          <div className='text-center'>
            <p className='bottom_info_text2'>{t('bottombar.copyright')}</p>
          </div>
          <div className='text-center pb-4'>
            <p className='bottom_info_text2'>
              {t('bottombar.all_right_reserve')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(BottomNav2);
