import React, { Component } from 'react';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 15 * 60, // 15 minutes in seconds
      isActive: false, // Controls whether the timer is active
    };
    this.timer = null; // Timer ID for the interval
  }

  startTimer = () => {
    if (!this.state.isActive) {
      this.setState({ isActive: true });
      this.timer = setInterval(() => {
        this.setState(prevState => {
          if (prevState.time > 0) {
            return { time: prevState.time - 1 };
          } else {
            clearInterval(this.timer);
            return { isActive: false };
          }
        });
      }, 1000);
    }
  };

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  componentWillUnmount() {
    clearInterval(this.timer); // Cleanup the interval on unmount
  }

  render() {
    return (
      <div>
        <h1>Timer: {this.formatTime(this.state.time)}</h1>
        <button onClick={this.startTimer} disabled={this.state.isActive}>Start Timer</button>
      </div>
    );
  }
}

export default Timer;