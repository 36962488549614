import React, { Component } from 'react'
import { Row, Col, Container, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import YouTubePlayer from './YoutubePlayer';

// Components
import TopNav from '../containers/NewTopNav3';
import BottomNav from '../containers/NewBottomNav3';
import V3Button from './buttons/V3Button';

// CSS
import '../assets/css/NFTCollections2.css'

// Images
import imgOwlPeople from '../assets/img/img-owl-people.png'
import imgBanner from '../assets/img/nft-collections-2/img-banner.png'
import imgBannerMobile from '../assets/img/nft-collections-2/img-banner-mobile.png'
import imgBackStory from '../assets/img/img-backstory.png'
import imgBackStoryBig from '../assets/img/img-backstory-big.png'
import imgCollective from '../assets/img/img-collective.png'
import imgCollectiveBig from '../assets/img/img-collective-big.png'
import imgEarning from '../assets/img/img-earning.png'
import imgEarningBig from '../assets/img/img-earning-big.png'
import imgRealValue from '../assets/img/img-real-value.png'
import imgRealValueBig from '../assets/img/img-real-value-big.png'
import imgRealWorldUtility from '../assets/img/img-real-world-utility.png'
import imgRealWorldUtilityBig from '../assets/img/img-real-world-utility-big.png'
import imgRoadMap from '../assets/img/img-roadmap.png'
import imgCollection3 from '../assets/img/nft-collections-2/img-footer.png'
import imgClose from '../assets/img/icon-mindmap-modal-close.png';
import imgVisionAndPurpose from '../assets/img/img-mindmap-vision-and-purpose.png'; 
import imgMap from '../assets/img/img-nft-collection-map.png'; 
import imgHalfFace from '../assets/img/nft-collections-2/img-small.png';
import imgFullFace from '../assets/img/nft-collections-2/img-large.png';
import imgPremiumFace from '../assets/img/nft-collections-2/img-medium.png';
import imgVideoLeft from '../assets/img/nft-collections-2/img-left.png'
import imgVideoRight from '../assets/img/nft-collections-2/img-right.png'
import imgGovato from '../assets/img/img-govato-modal.png'
import imgAppStore from '../assets/img/img-modal-app-store.png'
import imgGooglePlay from '../assets/img/img-modal-google-play.png'
import imgGovatoText from '../assets/img/img-govato-nft-collections.png'
import imgOwlPeopleWhite from '../assets/img/img-owl-people-white.png'

class NFTCollections2 extends Component {
    constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      show: false,
      show2: false,
      show3: false,
      modalImg: 'img-mindmap-modal-default.png',
      modalTitle: '',
      modalNum: '',
      modalContent: [],
      modalClass: '',
    };
  }
handleClose3 = () => {
    this.setState({show3: false});
  }
  handleClose = () => {
    this.setState({show: false});
  }
  handleClose2 = () => {
    this.setState({show2: false});
  }
  handleShow = (obj) => {
      this.setState({
        modalTitle: obj.title,
        modalImg: obj.img,
        modalNum: obj.number,
        modalContent: obj.content,
        modalClass: obj.id,
        show: true
      });
  };
  handleShow2 = () => {
      this.setState({show2: true});
  };
  handleShow3 = (e) => {
    e.preventDefault();
      this.setState({show3: true});
  };
  render() {
    const { t } = this.props;
    return (
        <>
      <div className='nft-collections-main'>
        <TopNav />
        {/* Banner */}
        <section className='first-section v2-nft-collection-banner'>
            <Container>
                <Row className='mw-1140 mx-auto'>
                    <Col md={7} className='v2-mt-2 v2-pr-4 v2-m-pr-1'>
                        <h1 className='poppins v2-font-37 bold white-text nft-collections-1-heading'>{t('nftCollections.introducing')} <br/> {t('nftCollections.premiumWallet')}</h1>

                        <p className='v2-mt-2 v2-m-mt-1 poppins v2-font-16 light white-text'>{t('nftCollections.anEnhanced')}</p>
                        
                        <div className='v2-mt-2'>
                            <V3Button text={t('nftCollections.learnMore')} href="/wallet" />
                        </div>
                        
                    </Col>
                    <Col md={5} className='v2-nft-collections-1-right'>
                        <div className='img-wrap desktop v2-nft-collection-1-img-wrap'>
                            <img className='img-shadow' src={imgBanner} />
                        </div>

                        <div className='img-wrap mobile v2-m-mt-2 v2-nft-collection-1-img-wrap'>
                            <img className='img-shadow' src={imgBannerMobile} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 1 */}
        <section className='v2-pb-6 v2-pt-0 v2-m-pt-2 nft-collections-1 v2-nft-collections-1'>
            <Container>
                
                <Row className='nft-row-2 nft-row-2-1 nft-row-2-box'>
                    <Col>

                        <Row>
                            <Col>
                                <h2 className='poppins font-26 medium white-text text-center nft-row-2-1-heading'>
                                    <span className='nft-row-2-box-title'>{t('nftCollections.each6')} </span>
                                </h2>
                            </Col>
                        </Row>

                        <Row className='nft-row-2 nft-row-2-2'>
                            <Col>
                                <ul className='nft-collections-1-ul'>
                                    <li>{t('nftCollections.ovato2')} </li>
                                    <li>{t('nftCollections.ips')} </li>
                                    <li>{t('nftCollections.revenue')} </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul className='nft-collections-1-ul'>
                                    <li>{t('nftCollections.territories')} </li>
                                    <li>{t('nftCollections.free')} </li>
                                    <li>{t('nftCollections.income3')} </li>
                                </ul>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                

                <Row className='nft-row-2 nft-row-2-2'>
                    <Col>
                        <h2 className='poppins n-font-25 regular white-text text-center desktop'>{t('nftCollections.exclusively')}  <img width={70} src={imgGovatoText} /> {t('nftCollections.members')} </h2>
                        <h2 className='poppins n-font-25 regular white-text text-center mobile display-mobile-br'>{t('nftCollections.exclusively2')}  <br/> {t('nftCollections.to')}  <img width={50} src={imgGovatoText} /> Members</h2>
                    </Col>
                </Row>

                <Row className='mw-1920 mx-auto nft-row-2-4'>
                    <Col>
                        <div className='video-wrap nft-collections-video-wrap'>
                            <div className='img-wrap nft-coll-main-img-wrap-1'>
                                <img src={imgVideoLeft} />
                            </div>
                            
                            <div class='nft-coll-main-video-wrap'>
                                <YouTubePlayer videoId={'f_w6CzDuadE'} />
                            </div>
                            <div className='img-wrap nft-coll-main-img-wrap-2'>
                                <img src={imgVideoRight} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='nft-row-2 mt-45 nft-row-2-3'>
                    <Col>
                        <h2 className='poppins font-26 medium white-text text-center'>{t('nftCollections.your')}</h2>
                        <div class='img-wrap mt-30 nft-collection-1-img-wrap-2'>
                            <img src={imgMap} alt='NFT Sale' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Section 2 */}
        <section className='nft-collection-2 v2-nft-collection-2'>
            <Container className='nft-collection-2-container'>
                <Row>
                    <Col>
                        <h2 className='poppins font-46 v2-tp-font-32 v2-m-font-28 bold white-text text-center nft-collection-2-heading'>{t('nftCollections.discover2')}</h2>
                    </Col>
                </Row>
                <Row className='nft-collection-2-row-2'>
                    <Col>
                        <div className='nft-collection-2-box-wrap'>
                            {/* BACKSTORY */}
                            <div className='nft-collection-2-box-col' onClick={() => this.handleShow(
                                {
                                    id: 'the-backstory',
                                    img: imgBackStoryBig,
                                    title: t('nftCollections.theBackstory'),
                                    number: '01',
                                    content: [
                                        t('nftCollections.popup-1-1'),
                                        t('nftCollections.popup-1-2')
                                    ]
                                })}>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img'>
                                        <img src={imgBackStory} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>01</p>
                                    <p className='nft-collection-2-box-col-title'>{t('nftCollections.theBackstory')}</p>
                                </div>
                            </div>
                            {/* Real Value */}
                            <div className='nft-collection-2-box-col' onClick={() => this.handleShow(
                                {
                                    id: 'real-value',
                                    img: imgRealValueBig,
                                    title: t('nftCollections.real'),
                                    number: '02',
                                    content: [
                                        t('nftCollections.popup-2-1')
                                    ]
                                })}>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img'>
                                        <img src={imgRealValue} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>02</p>
                                    <p className='nft-collection-2-box-col-title'>{t('nftCollections.real')}</p>
                                </div>
                            </div>
                            {/* Real World Utility */}
                            <div className='nft-collection-2-box-col' onClick={() => this.handleShow(
                                {
                                    id: 'real-world-utility',
                                    img: imgRealWorldUtilityBig,
                                    title: t('nftCollections.real2'),
                                    number: '03',
                                    content: [
                                        t('nftCollections.popup-3-1'),
                                        t('nftCollections.popup-3-2')
                                    ]
                                })}>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img'>
                                        <img src={imgRealWorldUtility} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>03</p>
                                    <p className='nft-collection-2-box-col-title'>{t('nftCollections.real2')}</p>
                                </div>
                            </div>
                            {/* Earning */}
                            <div className='nft-collection-2-box-col' onClick={() => this.handleShow(
                                {
                                    id: 'earning',
                                    img: imgEarningBig,
                                    title: t('nftCollections.earning'),
                                    number: '04',
                                    content: [
                                        t('nftCollections.popup-4-1'),
                                        t('nftCollections.popup-4-2'),
                                        t('nftCollections.popup-4-3')
                                    ]
                                })}>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img earning'>
                                        <img src={imgEarning} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>04</p>
                                    <p className='nft-collection-2-box-col-title'>{t('nftCollections.earning')}</p>
                                </div>
                            </div>
                            {/* Earning */}
                            <div className='nft-collection-2-box-col' onClick={() => this.handleShow(
                                {
                                    id: 'collective',
                                    img: imgCollectiveBig,
                                    title: t('nftCollections.collective'),
                                    number: '05',
                                    content: [
                                        t('nftCollections.popup-5-1')
                                    ]
                                })}>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img'>
                                        <img src={imgCollective} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>05</p>
                                    <p className='nft-collection-2-box-col-title collective'>{t('nftCollections.collective')}</p>
                                </div>
                            </div>
                            {/* Earning */}
                            <div className='nft-collection-2-box-col'>
                                <div className='nft-collection-2-box-col-inner'>
                                    <div className='img-wrap nft-collection-2-box-col-img'>
                                        <img src={imgRoadMap} />
                                    </div>
                                    <p className='nft-collection-2-box-col-num'>06</p>
                                    <p className='nft-collection-2-box-col-title'>{t('nftCollections.roadmap')}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='nft-collection-4 v2-nft-collection-4'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-46 v2-tp-font-32 v2-m-font-28 bold white-text text-center nft-collection-4-heading'>{t('nftCollections.discover4')}</h2>
                    </Col>
                </Row>
                <Row className='mt-40 nft-collection-4-row-2'>
                    <Col className='v2-m-mt-3'>
                        <h3 class='nft-collection-4-title'>{t('nftCollections.small')}</h3>
                        <div className='nft-collection-4-box'>
                            <div className='nft-collection-4-box-img'>
                                <img src={imgHalfFace} />
                            </div>
                            <div className='nft-collection-4-box-footer'>
                                <div class='nft-collection-4-box-footer-left'>
                                    <div class='nft-collection-4-num'>0098</div>
                                    <div class='nft-collection-4-country'>China</div>
                                </div>
                                <div class='nft-collection-4-box-footer-right'>
                                    <a onClick={(e) => this.handleShow3(e)} class='nft-collection-4-box-btn' href='#'>{t('nftCollections.reserve')}</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='v2-m-mt-3'>
                        <h3 class='nft-collection-4-title'>{t('nftCollections.medium')}</h3>
                        <div className='nft-collection-4-box'>
                            <div className='nft-collection-4-box-img'>
                                <img src={imgPremiumFace} />
                            </div>
                            <div className='nft-collection-4-box-footer'>
                                <div class='nft-collection-4-box-footer-left'>
                                    <div class='nft-collection-4-num'>0101</div>
                                    <div class='nft-collection-4-country'>China</div>
                                </div>
                                <div class='nft-collection-4-box-footer-right'>
                                    <a onClick={(e) => this.handleShow3(e)} class='nft-collection-4-box-btn' href='#'>{t('nftCollections.reserve')}</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='v2-m-mt-3'>
                        <h3 class='nft-collection-4-title'>{t('nftCollections.large')}</h3>
                        <div className='nft-collection-4-box'>
                            <div className='nft-collection-4-box-img'>
                                <img src={imgFullFace} />
                            </div>
                            <div className='nft-collection-4-box-footer'>
                                <div class='nft-collection-4-box-footer-left'>
                                    <div class='nft-collection-4-num'>0091</div>
                                    <div class='nft-collection-4-country'>China</div>
                                </div>
                                <div class='nft-collection-4-box-footer-right'>
                                    <a onClick={(e) => this.handleShow3(e)} class='nft-collection-4-box-btn' href='#'>{t('nftCollections.reserve')}</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div class='v2-mt-4 text-center'>
                            <V3Button text={t('nftCollections.learnMore')} href="/wallet" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Section 3 */}
        <section className='nft-collection-3 v2-nft-collection-3'> 
            <Container>
                <Row className='nft-collection-3-row-1'>
                    <Col md={6} className='desktop'>
                        <div className='img-wrap nft-collection-3-img'>
                            <img src={imgCollection3} />
                        </div>
                    </Col>
                    <Col md={6} className='nft-collection-3-row-1-right'>
                        <h2 className='poppins v2-font-24 v2-m-font-20 medium white-text nft-collection-3-heading'>{t('nftCollections.compound')}</h2>
                        <p className='v2-mt-2 poppins v2-font-16 medium white-text'>{t('nftCollections.create')}</p>
                        <div class='v2-mt-2'>
                            <V3Button text={t('nftCollections.learnMore')} href="/wallet" />
                        </div>
                    </Col>
                    <Col md={6} className='mobile'>
                        <div className='img-wrap nft-collection-3-img'>
                            <img src={imgCollection3} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <BottomNav />
      </div>

      {/* MODAL */}
        <Modal 
            className={'mindmap-modal nft-collection-modal' + ' ' + this.state.modalClass}
            isOpen={this.state.show} 
        >
            <ModalHeader>
                <a className='mindmap-modal-close' onClick={this.handleClose}>
                    <img src={imgClose} alt='Close' />
                </a>
            </ModalHeader>
            <ModalBody>
                <div className='mindmap-modal-body-wrap'>
                    <div className='mindmap-modal-body-col mindmap-modal-body-col-left'>
                        <div className='mindmap-modal-body-col-inner'>
                            <div className='mindmap-modal-body-col-img'>
                                <img src={this.state.modalImg} />
                            </div>
                            <div className='mindmap-modal-body-col-title'>{this.state.modalTitle}</div>
                            <div className='mindmap-modal-body-col-num'>{this.state.modalNum}</div>
                        </div>
                    </div>
                    <div className='mindmap-modal-body-col mindmap-modal-body-col-right'>
                        <div className='mindmap-modal-body-col-inner'>
                            <div className='mindmap-modal-body-col-text'>
                                {this.state.modalContent.map(content => (  
                                    <p>  
                                        {content}  
                                    </p>  
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        {/* White List Modal */}
        <Modal 
            className={'mindmap-modal nft-collection-modal whitelist-modal'}
            isOpen={this.state.show3} 
        >
            <ModalHeader>
                <a className='mindmap-modal-close' onClick={this.handleClose3}>
                    <img src={imgClose} alt='Close' />
                </a>
            </ModalHeader>
            <ModalBody>
                <div className='whitelist-modal-header'>
                    <h2 className='poppins font-33 bold blue-text text-center'>{t('nftCollections.getWhite')}</h2>
                </div>
                <div className='whitelist-modal-body'>
                    <p className='poppins font-21 bold blue-text'>{t('nftCollections.join2')}</p>
                    <div className='img-wrap'>
                        <a href='https://govato.io/c/b5246e' target='_blank'><img width='118' src={imgGovato} /></a>
                    </div>
                    <ul className='whitelist-social-wrap'>
                        <li><a href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' target='_blank'><img width='80' src={imgAppStore} /></a></li>
                        <li><a href='https://play.google.com/store/apps/details?id=com.ovato.wallet' target='_blank'><img width='80' src={imgGooglePlay} /></a></li>
                    </ul>
                </div>
                <div className='whitelist-modal-footer'>
                    <a href='https://govato.io/c/b5246e' target='_blank' className='custom-new-btn'>{t('nftCollections.join3')}</a>
                </div>
            </ModalBody>
        </Modal>

      </>
    )
  }
}
export default withTranslation()(NFTCollections2);