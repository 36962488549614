import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, name, label, value, currency } = this.props;

    return (
        <div className='pw-field-wrap'>
            <div className='pw-field-label'>
                {label}
            </div>

            <div className='pw-field-input'>
                <input type='text' name={name} value={value} />
                { currency && <span className='pw-currency'>{currency}</span> }
            </div>
        </div>
    );
  }
}
export default withTranslation()(InputField);
