import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class LeaderboardLoadMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

  render() {
    const { t, data } = this.props;

    return (
        <div className='btn-wrap'>
            <a href='#' className='site-btn leaderboard-load-more-btn'>Load More</a>
        </div>
    );
  }
}
export default withTranslation()(LeaderboardLoadMore);