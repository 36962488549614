import React, { Component } from 'react';
import MerchantSection1 from './MerchantSection1';
import MerchantSection2 from './MerchantSection2';
import MerchantSection3 from './MerchantSection3';
import MerchantSection4 from './MerchantSection4';
import MerchantSection5 from './MerchantSection5';
import MerchantSection6 from './MerchantSection6';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';

import RelaunchingPopup from './popup/RelaunchingPopup';

export default class MerchantWalletApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  render() {
    const { t, modal } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <div className='consumer-wallet-app-main wallet-app-main merchant-wallet-app-main'>
        <TopNav />
        <MerchantSection1 toggleModal={toggleModal} />
        <MerchantSection2 />
        <MerchantSection3 />
        <MerchantSection4 />
        <MerchantSection5 />
        <MerchantSection6 toggleModal={toggleModal} />
        <BottomNav />

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal}/>
      </div>
    )
  }
}