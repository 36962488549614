import React, { Component } from 'react';
import TopNav from '../containers/NewTopNav3';
import BottomNav from '../containers/NewBottomNav3';

import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import '../assets/css/Exchange.css'

// Images
import ImgBanner from '../assets/img/exchange/img-banner.png'
import ImgCoin from '../assets/img/exchange/img-coin.png'

class Exchange extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='exchange-main-wrap v3-exchange-main'>
        {/* HEADER */}
        <TopNav />

        {/* BANNER */}
        <div className='v3-new-first-row v2-tp-pb-4 v3-exchange-1'>
            <Container>
                <Row className=''>
                    <Col md={6}>
                        <h1 className='poppins v2-font-48 v2-tl-font-40 v2-tp-font-32 v2-m-font-28 v2-m-mt-0 semi-bold white-text v2-pl-6 v2-tp-pl-2 v2-m-pl-0 v2-mt-7'>{t('newExchange.buy')}</h1>
                    </Col>

                    <Col md={6}>
                        <div className='img-wrap v3-exhange-1-img-wrap v2-m-mt-2'>
                            <img src={ImgBanner} className='img-shadow v2-m-w-300' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        {/* SECTION 2 */}
        <div className='exchange-2 v2-pt-10 v2-tl-pt-12 v3-exchange-2' id='stack-now'>
          <Container className='relative z-index-2'>
            <Row className='exchange-row-new'>
              {/* PROBIT */}
              <Col lg='4' className='text-center mb-3 exchange-col-new'>
                <div className='exchange-col-new-inner'>
                  <p>
                    <span className='pink-span'>{t('exchange.usa')}</span>&nbsp;
                    <span className='pink-span'>{t('exchange.asia')}</span>
                  </p>
                  <div>
                    <img
                      alt='Probit'
                      src={require('../assets/img/img-probit-new.png')}
                      width='100%'
                    />
                    <br />
                    <p className='instant instant-new instant-new-1'>{t('exchange.level')}</p>
                  </div>
                  <div class="btn-wrap"><a href='http://probit.com' target='_blank' class="gradient-pink-btn gradient-pink-btn-3 c-md-btn inline-btn full-width">{t('newExchange.trade')}</a></div>
                </div>
              </Col>
              {/* P2P B2B */}
              <Col lg='4' className='text-center mb-3 exchange-col-new'>
                <div className='exchange-col-new-inner'>
                  <p>
                    <span className='pink-span'>{t('exchange.europe')}</span>&nbsp;
                    <span className='pink-span'>{t('exchange.usa')}</span>
                  </p>
                  <div>
                    <img
                      alt='P2P B2B'
                      src={require('../assets/img/img-p2p-to-b2b-new.png')}
                      width='100%'
                    />
                    <br />
                    <p className='instant instant-new instant-new-2'>{t('exchange.cryptocurrency')}</p>
                    <div class="btn-wrap"><a href='http://p2pb2b.io' target='_blank' class="gradient-pink-btn gradient-pink-btn-3 c-md-btn inline-btn full-width">{t('newExchange.trade')}</a></div>
                  </div>
                </div>
              </Col>
              { /* LBANK */ }
              <Col lg='4' className='text-center mb-3 exchange-col-new'>
                <div className='exchange-col-new-inner'>
                  <p>
                    <span className='pink-span'>{t('exchange.asia')}</span>
                    <span className='pink-span'>{t('exchange.europe')}</span>
                  </p>
                  <div>
                    <img
                      alt='LBANK'
                      src={require('../assets/img/img-lbank.png')}
                      width='100%'
                    />
                    <br />
                    <p className='instant instant-new instant-new-4'>{t('exchange.lbank')}</p>
                    <div class="btn-wrap"><a href='https://www.lbank.info/' target='_blank' class="gradient-pink-btn gradient-pink-btn-3 c-md-btn inline-btn full-width">{t('newExchange.trade')}</a></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* SECTION 3 */}
        <div className='exchange_main v3-bottom-last v3-exchange-3'>
          <Container>
            <Row className='align-center'>
              <Col md='8'>
                <h2 className='poppins v2-font-36 v2-m-font-24 v2-tl-font-32 bold dark-text-5 exchange-3-heading'>{t('newExchange.then')}</h2>
                <p className='poppins v2-font-18 v2-m-font-16 medium blue-text'>{t('exchange.For')}</p>
                <div className='mt-30 v2-m-mt-2 btn-wrap'>
                  <a href='#stack-now' className='custom-new-btn exchange-3-custom-new-btn'>{t('newExchange.stack')}</a>
                </div>
              </Col>
              <Col md='4'>
                <div className='exchange-3-img-wrap'>
                  <img
                    alt='exchange'
                    src={ImgCoin}
                    className='img-exchange'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* FOOTER */}
        <BottomNav />
        
      </div>
    );
  }
}
export default withTranslation()(Exchange);