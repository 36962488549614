import React, { Component, useState } from 'react';
import {
  Row,
  Col,
  Container,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgVisionAndPurpose from '../assets/img/img-mindmap-vision-and-purpose.png';
import imgCommunity from '../assets/img/img-mindmap-community.png';
import imgCommunityModal from '../assets/img/img-community-modal.png';
import imgMerchantAndUsers from '../assets/img/img-mindmap-merchant-and-users.png';
import imgDAO from '../assets/img/img-mindmap-dao.png';
import imgDAOModal from '../assets/img/img-dao-modal.png';
import imgNFTS from '../assets/img/img-mindmap-nfts-new.png';
import imgMetaverse from '../assets/img/img-mindmap-metaverse.png';
import imgMetaverseModal from '../assets/img/img-metaverse-modal.png';
import imgConnectYourMind from '../assets/img/img-mindmap-connect-your-mind-new.png';
import imgConnectYourMindMobile from '../assets/img/img-mindmap-connect-your-mind-mobile.png';
import imgBGMobile from '../assets/img/img-mindmap-body-mobile-new-2.jpg';
import imgClose from '../assets/img/icon-mindmap-modal-close.png';
class MindmapBody extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      show: false,
      show2: false,
      modalImg: 'img-mindmap-modal-default.png',
      modalTitle: '',
      modalNum: '',
      modalContent: [],
      modalClass: '',
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleClose2 = () => {
    this.setState({ show2: false });
  };
  handleShow = (obj) => {
    this.setState({
      modalTitle: obj.title,
      modalImg: obj.img,
      modalNum: obj.number,
      modalContent: obj.content,
      modalClass: obj.id,
      show: true,
    });
  };
  handleShow2 = () => {
    this.setState({ show2: true });
  };
  componentDidMount = () => {
    const path = window.location.pathname;
    if (path == '/en/mindmap/connect') {
      this.setState({ show2: true });
    } else {
      this.setState({ show2: false });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='mindmap-body'>
          <Container>
            <Row className='mindmap-body-row-1'>
              <Col>
                <h1 className='Poppins font-50 extra-bold dark-text-2 text-center mindmap-body-heading'>
                  {t('mindmap.mindmap')}
                </h1>
                <p className='Poppins font-18 light dark-text-2 text-center mindmap-body-text-1'>
                  {t('mindmap.we')}
                </p>
                <div className='img-wrap mindmap-body-row-1-img-wrap mobile '>
                  <img src={imgBGMobile} />
                </div>
              </Col>
            </Row>
            <Row className='mindmap-body-row-2'>
              <Col>
                <div className='mindmap-body-lists'>
                  {/* Vision and Purpose */}
                  <div
                    className='mindmap-body-list mindmap-body-list-1'
                    onClick={() =>
                      this.handleShow({
                        id: 'vision',
                        img: imgVisionAndPurpose,
                        title: t('mindmap.vision'),
                        number: '01',
                        content: [t('mindmap.vision1'), t('mindmap.vision2')],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgVisionAndPurpose} />
                    </div>
                    <p className='mindmap-body-list-num'>01</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.vision')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* Community */}
                  <div
                    className='mindmap-body-list mindmap-body-list-2'
                    onClick={() =>
                      this.handleShow({
                        id: 'community',
                        img: imgCommunityModal,
                        title: t('mindmap.community'),
                        number: '02',
                        content: [
                          t('mindmap.community1'),
                          t('mindmap.community2'),
                        ],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgCommunity} />
                    </div>
                    <p className='mindmap-body-list-num'>02</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.community')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* Merchant and Users */}
                  <div
                    className='mindmap-body-list mindmap-body-list-3'
                    onClick={() =>
                      this.handleShow({
                        id: 'merchant',
                        img: imgMerchantAndUsers,
                        title: t('mindmap.merchant'),
                        number: '03',
                        content: [
                          t('mindmap.merchant1'),
                          t('mindmap.merchant2'),
                        ],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgMerchantAndUsers} />
                    </div>
                    <p className='mindmap-body-list-num'>03</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.merchant')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* DAO */}
                  <div
                    className='mindmap-body-list mindmap-body-list-4'
                    onClick={() =>
                      this.handleShow({
                        id: 'dao',
                        img: imgDAOModal,
                        title: t('mindmap.dao'),
                        number: '04',
                        content: [t('mindmap.dao1'), t('mindmap.dao2')],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgDAO} />
                    </div>
                    <p className='mindmap-body-list-num'>04</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.dao')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* NFTS */}
                  <div
                    className='mindmap-body-list mindmap-body-list-5'
                    onClick={() =>
                      this.handleShow({
                        id: 'nfts',
                        img: imgNFTS,
                        title: t('mindmap.nfts'),
                        number: '05',
                        content: [
                          t('mindmap.nfts1'),
                          t('mindmap.nfts2'),
                          t('mindmap.nfts3'),
                        ],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgNFTS} />
                    </div>
                    <p className='mindmap-body-list-num'>05</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.nfts')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* METAVERSE */}
                  <div
                    className='mindmap-body-list mindmap-body-list-6'
                    onClick={() =>
                      this.handleShow({
                        id: 'metaverse',
                        img: imgMetaverseModal,
                        title: t('mindmap.metaverse'),
                        number: '06',
                        content: [
                          t('mindmap.metaverse1'),
                          t('mindmap.metaverse2'),
                        ],
                      })
                    }
                  >
                    <div className='mindmap-body-list-img'>
                      <img src={imgMetaverse} />
                    </div>
                    <p className='mindmap-body-list-num'>06</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.metaverse')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                  {/* CONNECT YOUR MIND */}
                  <div
                    className='mindmap-body-list mindmap-body-list-7'
                    onClick={() => this.handleShow2()}
                  >
                    <div className='mindmap-body-list-img'>
                      <img className='desktop' src={imgConnectYourMind} />
                      <img className='mobile' src={imgConnectYourMindMobile} />
                    </div>
                    <p className='mindmap-body-list-num'>07</p>
                    <p className='mindmap-body-list-title'>
                      {t('mindmap.connect')}{' '}
                      <i class='mindmap-body-arrow fas fa-arrow-right'></i>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* MODAL */}
        <Modal
          className={'mindmap-modal' + ' ' + this.state.modalClass}
          isOpen={this.state.show}
        >
          <ModalHeader>
            <a className='mindmap-modal-close' onClick={this.handleClose}>
              <img src={imgClose} alt='Close' />
            </a>
          </ModalHeader>
          <ModalBody>
            <div className='mindmap-modal-body-wrap'>
              <div className='mindmap-modal-body-col mindmap-modal-body-col-left'>
                <div className='mindmap-modal-body-col-inner'>
                  <div className='mindmap-modal-body-col-img'>
                    <img src={this.state.modalImg} />
                  </div>
                  <div className='mindmap-modal-body-col-title'>
                    {this.state.modalTitle}
                  </div>
                  <div className='mindmap-modal-body-col-num'>
                    {this.state.modalNum}
                  </div>
                </div>
              </div>
              <div className='mindmap-modal-body-col mindmap-modal-body-col-right'>
                <div className='mindmap-modal-body-col-inner'>
                  <div className='mindmap-modal-body-col-text'>
                    {this.state.modalContent.map((content) => (
                      <p>{content}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* 07: CONNECT YOUR MIND MODAL */}
        <Modal
          className={'mindmap-modal' + ' ' + 'mindmap-modal-connect'}
          isOpen={this.state.show2}
        >
          <ModalHeader>
            <a className='mindmap-modal-close' onClick={this.handleClose2}>
              <img src={imgClose} alt='Close' />
            </a>
          </ModalHeader>
          <ModalBody>
            <form className='mindmap-modal-form'>
              <div className='mindmap-modal-body-wrap'>
                <div className='mindmap-modal-body-col mindmap-modal-body-col-left'>
                  <div className='mindmap-modal-body-col-inner'>
                    <div className='mindmap-modal-body-col-num'>07</div>
                    <div className='mindmap-modal-body-col-title'>
                      {t('mindmap.connect')}
                    </div>
                    <div className='mindmap-form-group'>
                      <input
                        className='mindmap-form-group-input'
                        type='text'
                        name='name'
                        placeholder='Name'
                      />
                    </div>
                    <div className='mindmap-form-group'>
                      <input
                        className='mindmap-form-group-input'
                        type='email'
                        name='email'
                        placeholder='Email'
                      />
                    </div>
                    <div className='mindmap-form-group'>
                      <input
                        className='mindmap-form-group-input'
                        type='text'
                        name='tel'
                        placeholder='Tel (not required)'
                      />
                    </div>
                  </div>
                </div>
                <div className='mindmap-modal-body-col mindmap-modal-body-col-right'>
                  <div className='mindmap-modal-body-col-inner'>
                    <div className='mindmap-form-group'>
                      <textarea
                        className='mindmap-form-group-input mindmap-form-group-input-textarea'
                        placeholder='Tell us your thoughts'
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mindmap-modal-submit-wrap'>
                <button className='mindmap-modal-submit-btn' name='submit'>
                  SUBMIT MESSAGE
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default withTranslation()(MindmapBody);
