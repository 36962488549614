import React from 'react'
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import './PledgeDrive.css'
import { useCountdown } from '../../hooks/useCountdown';
import ImgKraken from '../../assets/img/pledge-drive/img-pledge-kraken.jpg'
import ImgOvato from '../../assets/img/pledge-drive/img-pledge-ovato.png'
import ImgOpenSea from '../../assets/img/pledge-drive/img-pledge-open-sea.png'
import ImgAllOrNone from '../../assets/img/pledge-drive/img-pledge-all-or-none.png'
import ImgPreferredStatus from '../../assets/img/pledge-drive/img-pledge-preferred-status.png'
const PledgeDrive = () => {
    const [days, hours, minutes, seconds] = useCountdown('March 31 2023');
  return (
    <div className='pledge-drive-main'>
        
        {/* PLEDGE 1 */}
        <section className='pledge-1'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <img width={150} src={ImgKraken} />
                    </Col>
                    <Col className='text-center'>
                        <a href='/' target='_blank'><img width={110} src={ImgOvato} /></a>
                    </Col>
                    <Col className='text-right'>
                        <img width={200} src={ImgOpenSea} />
                    </Col>
                </Row>
            </Container>
        </section>
        {/* PLEDGE 2 */}
        <section className='pledge-2'>
            <Container>
                <Row>
                    <Col>
                        <h1 className='poppins n-font-41 bold white-text text-center pledge-2-heading'>Senior Exchange Pledge Drive</h1>
                        <p className='poppins font-126 bold white-text text-center m-0 lh-1 pledge-2-text'>$500,000</p>
                        <div className='text-center p-t-3'>
                            <img width={75} src={ImgAllOrNone} alt='All or none offer' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* PLEDGE 3 */}
        <section className='pledge-3'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-36 medium pink-text-2 text-center m-0 pledge-3-heading'>We can do this !</h2>
                        <h3 className='poppins n-font-33 bold text-center blue-text-5 m-0 pledge-3-subheading'>
                            <span className='pledge-border'>Get Preferred Status</span>
                        </h3>
                        <div className='pledge-3-box'>
                            <img width='350' src={ImgPreferredStatus} className='pledge-3-box-img' />
                           <p className='poppins n-font-29 medium dark-text-7 pledge-3-box-text'>Get priority access to customer support</p> 
                        </div>
                        <p className='poppins n-font-23 medium text-center dark-text-7 pledge-3-text desktop'>Pledge now and pay by March 31 Available to existing stakeholders only New holders participate through NFT</p>
                        <p className='poppins n-font-23 medium text-center dark-text-7 pledge-3-text mobile'>
                            Pledge now and pay by March 31 <br /> 
                            Available to existing stakeholders only <br />
                            New holders participate through NFT
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 4 */}
        <section className='pledge-4'>
            <Container>
                <Row>
                    <Col>
                        <div className='pledge-4-time'>
                            <div className='pledge-4-time-col'>
                                <span className='pledge-4-time-col-num'>{days}</span>
                                <span className='pledge-4-time-col-text'>DAYS</span>
                            </div>
                            <div className='pledge-4-time-sep'>:</div>
                            <div className='pledge-4-time-col'>
                                <span className='pledge-4-time-col-num'>{hours}</span>
                                <span className='pledge-4-time-col-text'>HOURS</span>
                            </div>
                            <div className='pledge-4-time-sep'>:</div>
                            <div className='pledge-4-time-col'>
                                <span className='pledge-4-time-col-num'>{minutes}</span>
                                <span className='pledge-4-time-col-text'>MINUTES</span>
                            </div>
                            <div className='pledge-4-time-sep'>:</div>
                            <div className='pledge-4-time-col'>
                                <span className='pledge-4-time-col-num'>{seconds}</span>
                                <span className='pledge-4-time-col-text'>SECONDS</span>
                            </div>
                        </div>
                        <form className='pledge-form'>
                            <div className='pledge-group'>
                                <div className='pledge-form-field'>
                                    <label>Pledged amount:</label>
                                    <input type='number' name='pledged-amount' />
                                </div>
                                <div className='pledge-form-field'>
                                    <label>Indexed amount:</label>
                                    <input type='number' name='indexed-amount' />
                                </div>
                            </div>
                            
                            <div className='text-center pledge-group pledge-group-btn'>
                                <button className='pledge-form-button'>Sign Up Now</button>
                            </div>
                        </form>
                        <div className='pledge-4-container'>
                            <p className='poppins n-font-23 medium dark-text-7 mb-3'>Rules</p>
                            <ul className='pledge-ul'>
                                <li>Must be an existing stakeholder to participate (all others participate in NFT sales )</li>
                                <li>Must participate in social media</li>
                                <li>Must have version 3.0 of the app</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
  )
}
export default PledgeDrive