import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ImgOvato from '../assets/img/img-cyln-ovato.png'
import ImgCard from '../assets/img/img-cyln-card.jpg'
import ImgFullFace from '../assets/img/img-cyln-full-face.png'
import ImgHalfFace from '../assets/img/img-cyln-half-face.png'
import ImgPremiumFace from '../assets/img/img-cyln-premium-face.png'
class CollectYourLoyalNFT extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
      <div className='cyln-main'>
        {/* SECTION 1 */}
        <section className='cyln-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='img-wrap text-center cyln-1-img-wrap'>
                            <a href='/' target='_blank'><img src={ImgOvato} alt='Ovato' /></a>
                        </div>
                        <h1 className='poppins n-font-48 bold white-text text-center'>Collect your loyalty NFT</h1>
                    </Col>
                </Row>
                <Row className='cyln-1-row-2'>
                    <Col>
                        <p className='poppins n-font-30 bold white-text cyln-1-left-text'><span className='cyln-text-underline cyln-right-arrow'>Holds up to 700 OVO</span></p>
                    </Col>
                    <Col>
                        <div className='cyln-card-wrap'>
                            <div className='cyln-img-wrap'>
                                <img src={ImgCard} alt='Ovato Nation' />
                            </div>
                            <div className='cyln-card-footer'>
                                <div className='cyln-card-footer-left'>
                                    <p className='cyln-card-footer-left-num'>159</p>
                                    <p className='cyln-card-footer-left-text'>OVATO NATION</p>
                                </div>
                                <div className='cyln-card-footer-right'>
                                    <a href='#' className='cyln-card-footer-btn'>Reserve Now</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <ul className='cyln-1-ul'>
                            <li>
                                Great store of Value - Sell at Premium
                            </li>
                            <li>
                                Linked to Stacking Pool - earn 30% per year
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            
        </section>
        {/* SECTION 2 */}
        <section className='cyln-2'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-31 bold blue-text text-center'>
                            <span className='cyln-heading-underline'>Upgrade to General Collection</span>
                        </h2>
                    </Col>
                </Row>
                <Row className='cyln-2-row-2'>
                    <Col>
                        <h3>Half</h3>
                        <div className='cyln-card-wrap'>
                            <div className='cyln-img-wrap'>
                                <img src={ImgHalfFace} alt='Ovato Nation' />
                            </div>
                            <div className='cyln-card-footer'>
                                <div className='cyln-card-footer-left'>
                                    <p className='cyln-card-footer-left-num'>58</p>
                                    <p className='cyln-card-footer-left-text'>China</p>
                                </div>
                                <div className='cyln-card-footer-right'>
                                    <a href='#' className='cyln-card-footer-btn'>Reserve Now</a>
                                </div>
                            </div>
                        </div>
                        <p className='clyn-2-footer-text'>Store Up to 5000 OVO</p>
                    </Col>
                    <Col>
                        <h3>Full Face</h3>
                        <div className='cyln-card-wrap'>
                            <div className='cyln-img-wrap'>
                                <img src={ImgFullFace} alt='Ovato Nation' />
                            </div>
                            <div className='cyln-card-footer'>
                                <div className='cyln-card-footer-left'>
                                    <p className='cyln-card-footer-left-num'>912</p>
                                    <p className='cyln-card-footer-left-text'>Canada</p>
                                </div>
                                <div className='cyln-card-footer-right'>
                                    <a href='#' className='cyln-card-footer-btn'>Reserve Now</a>
                                </div>
                            </div>
                        </div>
                        <p className='clyn-2-footer-text'>Store Up to 25,000 OVO</p>
                    </Col>
                    <Col>
                        <h3>Premium</h3>
                        <div className='cyln-card-wrap'>
                            <div className='cyln-img-wrap'>
                                <img src={ImgPremiumFace} alt='Ovato Nation' />
                            </div>
                            <div className='cyln-card-footer'>
                                <div className='cyln-card-footer-left'>
                                    <p className='cyln-card-footer-left-num'>5369</p>
                                    <p className='cyln-card-footer-left-text'>OVATO NATION</p>
                                </div>
                                <div className='cyln-card-footer-right'>
                                    <a href='#' className='cyln-card-footer-btn'>Reserve Now</a>
                                </div>
                            </div>
                        </div>
                        <p className='clyn-2-footer-text'>Unlimited Store</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className='cyln-2-ul'>
                            <li>
                                NFT earns on all activity in territory
                            </li>
                            <li>
                                Strip your OVO out
                            </li>
                            <li>
                                Earn 2% on every transaction
                            </li>
                            <li>
                                All NFTs Preloaded with 100% to 500% OVO
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='cyln-2-box'>
                            <h4 className='cyln-2-box-heading'><span className='cyln-2-box-heading-text'>Lot A Sales Pricing</span></h4>
                            <div className='cyln-2-box-wrap'>
                                <div className='cyln-2-box-col cyln-2-box-col-1'>
                                    <p>Numbers</p>
                                </div>
                                 <div className='cyln-2-box-col cyln-2-box-col-2'>
                                    <p className='cyln-2-box-col-text-1'>51-100</p>
                                    <p className='cyln-2-box-col-text-2'>500%</p>
                                </div>
                                <div className='cyln-2-box-col'>
                                    <p className='cyln-2-box-col-text-1'>101-200</p>
                                    <p className='cyln-2-box-col-text-2'>300%</p>
                                </div>
                                <div className='cyln-2-box-col'>
                                    <p className='cyln-2-box-col-text-1'>201-500</p>
                                    <p className='cyln-2-box-col-text-2'>200%</p>
                                </div>
                                <div className='cyln-2-box-col'>
                                    <p className='cyln-2-box-col-text-1'>500 plus</p>
                                    <p className='cyln-2-box-col-text-2'>100%</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 3 */}
        <section className='cyln-3'>
            <Container>
                <Row>
                    <Col>
                        <form className='cyln-3-form'>
                            <div className='cyln-3-form-row'>
                                <div className='cyln-3-label'>
                                    <label>Name</label>
                                </div>
                                <div className='cyln-3-input'>
                                    <input type='text' name='name' />
                                </div>
                            </div>
                            <div className='cyln-3-form-row'>
                                <div className='cyln-3-label'>
                                    <label>Mobile</label>
                                </div>
                                <div className='cyln-3-input'>
                                    <input type='text' name='mobile' />
                                </div>
                            </div>
                            <div className='cyln-3-form-row'>
                                <div className='cyln-3-label'>
                                    <label>Ovo Store Amount</label>
                                </div>
                                <div className='cyln-3-input'>
                                    <input type='text' name='amount' />
                                </div>
                            </div>
                            <div className='cyln-3-form-row cyln-3-form-row-column cyln-3-form-row-checkbox-heading'>
                                <div className='cyln-3-top-line'></div>
                                <p className='cyln-3-checkbox-heading'><span className='cyln-3-checkbox-heading-text'>Upgrade</span></p>
                                <ul className='cyln-3-checkbox'>
                                    <li>
                                        <label>
                                            <input type='radio' name='upgrade' value='Half' /> Half
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type='radio' name='upgrade' value='Full' /> Full
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type='radio' name='upgrade' value='Premium' /> Premium
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className='text-center cyln-3-form-row cyln-3-form-row-column'>
                                <button type='submit' name='submit'>Submit</button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
      </div>
      </>
    );
  }
}
export default withTranslation()(CollectYourLoyalNFT);
