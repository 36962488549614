import React, { Component, Fragment } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { UserAuth } from '../providers/UserAuth'
import { toast } from 'react-toastify';
import { Button, Alert } from 'reactstrap';
import FormValidator from '../constants/FormValidator/FormValidator'

import { secret, toastStyle } from "../constants/defaultValues";
import Crypto from 'crypto-js'
import Loading from 'react-fullscreen-loading';

import NewTopBar from '../containers/NewTopNav3'
import NewBottomBar from '../containers/NewBottomNav3'

import '../assets/css/PasswordReset.css'

export default class PasswordReset extends Component {

  constructor(props) {
    super(props);
    this.validator1 = new FormValidator([
      {
        field: 'emailRegister',
        method: 'isEmail',
        validWhen: true,
        message: 'That is not a valid email.'
      }
    ]);

    //
    this.validator2 = new FormValidator([
      {
        field: 'emailLogin',
        method: 'isEmail',
        validWhen: true,
        message: 'That is not a valid email.'
      }
    ]);

    this.validator3 = new FormValidator([
      {
        field: "passRegister",
        method: 'matches',
        args: [/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/],
        validWhen: true,
        message: 'Password must be atleast 8 characters long including Atleast one number and one special character'
      }
    ]);

    this.state = {
      matchpassword: false,
      passRegister: '',
      cpassRegister: '',
      email: this.props.match.params.email,
      token: this.props.match.params.token,
      showSpinner: false
    };
  }

  register = () => {

    // console.log(" inside reset",this.props.location.search)
    let url_= this.props.location.pathname.split('/')
    let token =url_[url_.length - 1]

    if (this.state.passRegister === '' && this.state.cpassRegister === '') {
        toast.error('Please enter your password')
        return
    }

    if (this.state.passRegister.length < 8) {
        toast.error('Password must be at least 8 characters long')
        return;
    }

    if ((this.state.passRegister !== '' && this.state.cpassRegister !== '' ) && (this.state.passRegister === this.state.cpassRegister)) {

      let payload = {
        password: this.state.passRegister
      }

      // console.log(payload,token);

    //   payload.password = Crypto
    //     .createHmac("sha512", secret)
    //     .update(payload.password)
    //     .digest("hex");
      //   console.log("before")

      payload.password = Crypto.HmacSHA512(payload.password, secret).toString(Crypto.enc.Hex);
      
      // console.log(payload,token);

      UserAuth.requestPasswordReset(payload, token)
      .then(res => {
        // console.log(res);
        toast.success("Password Reset Successfully, Please Login Again", 3000, null, 100, 'primary');
        this.setState({ matchpassword: false })
      })
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data) {
          const errMsg = err.response.data.error;
          console.log(errMsg, "YY")
          toast.error(
            errMsg,
            errMsg,
            4000,
            null,
            100,
            "primary"
          );
        } else {
          toast.error('Please try again', 'Invalid Request', 3000, null, 100, 'primary');
        }
      })
      .finally(() => {
        this.props.history.push('/wallet')
      })
    } 
    else {
        //   if (!this.state.matchpassword) {
        //     toast.error("Passwords don't match", "Passwords don't match", 3000, null, 100, 'primary');
        //   }
        toast.error("Passwords don't match", "Passwords don't match", 3000, null, 100, 'primary');
    }
  }

  componentWillMount() {
    if (!this.state.email || !this.state.token) {
      toast.error("Invalid Request, Please try again !", toastStyle);
      this.props.history.push('/wallet')
    }
    // console.debug("spmewhere in ", this.props.match.params)
    // let email
    // = this.props.computedMatch.params.emai

    // let Hash = this.props.computedMatch.params.email;

    let emailHash = this.props.location.pathname
    let splitArray = emailHash.split("/")
    let payload = {
      email: splitArray[3], hash: splitArray[4]
    }
  }


  handleInputChange = (e) => {
    let name = e.target.name
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (name === "cpassRegister") {
        this.handleConfirmPassword()
      }
    });
  }

  handleConfirmPassword = () => {

    // console.debug("insideeee")
    let validation3 = this.validator3.validate(this.state);

    this.setState({ validation3 });

    this.submitted3 = true;

    if (validation3.isValid) {

      this.submitted3 = false;

      this.setState({
        validation3: this.validator3.valid()
      });

      if (this.state.passRegister === this.state.cpassRegister) {
        this.setState({ matchpassword: true })
      } else {
        this.setState({ matchpassword: false })
      }
    }
  }

  render() {
    let validation2 = this.submitted2 // if the form has been submitted at least once
      ? this.validator2.validate(this.state) // then check validity every time we render
      : this.state.validation2; // otherwise just use what's in state


    let validation3 = this.submitted3 // if the form has been submitted at least once
      ? this.validator3.validate(this.state) // then check validity every time we render
      : this.state.validation3;

    return (
      <div class='blue-nav pr-main'>
        <NewTopBar handleToggleWallet={this.toggleWallet}/> 
        
        {(this.state.showSpinner) &&
          <Loading loading background="rgba(97,97,194,0.57) none repeat scroll 0% 0%" loaderColor="black none repeat scroll 0% 0%" />
        }

        <div className="new-first-row password_reset_div">
          <div className='password-reset-inner'>

            <h1 style={{ textAlign: 'center', color: "#1b236b", fontFamily: "Poppins" }}>Reset Password</h1><br />

            <div style={{ marginTop: '1em' }}>
              <p style={{ fontSize: "16px", color: "#1b236b" }}>
                New Password
              </p>

              <input type='password' name='passRegister' value={this.state.passRegister} style={{ borderRadius: '20px', height: "50px", width: "100%" }} onChange={this.handleInputChange} />

              {validation3 && validation3.passRegister.message && (
                <Fragment>
                  <Alert color='danger'>{validation3.passRegister.message}</Alert>
                </Fragment>
              )}
            </div>

            <div style={{ marginTop: '1em' }}>
              <p style={{ fontSize: "16px", color: "#1b236b" }}>
                Confirm Password
              </p>
              <div className='reset-input-group'>
                <input type='password' name='cpassRegister' value={this.state.cpassRegister} style={{ borderRadius: '20px', height: "50px", width: "100%" }} onChange={this.handleInputChange} />
                {this.state.matchpassword && <FaCheckCircle size="1.5em" color="green" className="reset_check_box" />}
              </div>
            </div>

            <div className='text-center' style={{ marginTop: '1em' }}>
              <Button size='sm' className='login-button' style={{ color: "white" }} onClick={this.register}>
                {' '}
                Reset
              </Button>
            </div>
          </div>
        </div>
      <div/>

      <NewBottomBar />
      </div>
    )
  }
}