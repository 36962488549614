import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgOvato from '../assets/img/icon-ovato-consumer-wallet-app.png';
import imgAppStore from '../assets/img/img-consumer-wallet-app-app-store.png';
import imgPlayStore from '../assets/img/img-consumer-wallet-app-play-store.png';
import imgBanner from '../assets/img/img-merchant-wallet-app-banner-new.png';
import imgBannerMobile from '../assets/img/img-merchant-wallet-app-banner-mobile.png';
class MerchantSection1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
    const { t, toggleModal } = this.props;
    return (
      <>
        <section className='cwa-1 mwa-1 desktop' id='consumer-wallet-app-banner'>
            <Container>
                {/* <Row>
                    <Col>
                        <div class='img-wrap cwa-1-logo'>
                            <a href='/'><img src={imgOvato} width={112} /></a>
                        </div>
                    </Col>
                    <Col>
                        <div class='img-wrap cwa-1-img-1'>
                            <a href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' target='_blank' rel="noopener noreferrer">
                                <img src={imgAppStore} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.ovato.wallet' target='_blank' rel="noopener noreferrer">
                                <img src={imgPlayStore} />
                            </a>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={6}>
                        <h1 className='poppins font-50 bold dark-text-2 lh-1-2 cwa-1-heading mwa-1-heading'>{t('merchantWallet1.not')} <br /> {t('merchantWallet1.its')} <br /> {t('merchantWallet1.of')}</h1>
                        <p className='font-20 poppins gray-text-2 mt-30 cwa-1-text mwa-1-text'>{t('merchantWallet1.all')}</p>
                        <div class='btn-wrap cwa-1-btn-wrap mt-40'>
                            <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet1.download')}</a>
                            <a href='https://www.ovato.com/en/landing' target='_blank' rel="noopener noreferrer" className='custom-pink-btn custom-blue-btn-transparent'>{t('merchantWallet1.discover')}</a>
                        </div>
                        <div class='img-wrap cwa-1-img-2'>
                            <a 
                            onClick={toggleModal}
                            //href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgAppStore} /></a>
                            <a 
                            onClick={toggleModal}
                            //href='https://play.google.com/store/apps/details?id=com.ovato.wallet' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgPlayStore} /></a>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div class='img-wrap cwa-1-img-3'>
                            <img src={imgBanner} />
                        </div>
                    </Col>
                </Row>
                <Row className='cwa-1-row-3'>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>2+</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.years')} <br /> {t('merchantWallet1.industry')}</p>
                        </div>
                        
                    </Col>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>2M+</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.blocks')} <br /> {t('merchantWallet1.settled')}</p>
                        </div>
                    </Col>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>1000%</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.ovo')} <br /> {t('merchantWallet1.increased')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* MOBILE */}
        <section className='cwa-1 mwa-1 mobile' id='consumer-wallet-app-banner'>
            <Container>
                <Row>
                    {/* <Col>
                        <div class='img-wrap cwa-1-logo'>
                            <a href='/'><img src={imgOvato} width={112} /></a>
                        </div>
                    </Col> */}
                    <Col id='consumer-wallet-app-banner-mobile'>
                        <div class='img-wrap cwa-1-img-1'>
                            <a 
                            onClick={toggleModal}
                            href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' 
                            target='_blank' rel="noopener noreferrer">
                                <img src={imgAppStore} />
                            </a>
                            <a 
                            onClick={toggleModal}
                            href='https://play.google.com/store/apps/details?id=com.ovato.wallet' 
                            target='_blank' rel="noopener noreferrer">
                                <img src={imgPlayStore} />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <h1 className='poppins font-58 bold violet-text lh-1-1 cwa-1-heading'>{t('merchantWallet1.not')} <br /> {t('merchantWallet1.its')}</h1>
                        <p className='font-20 poppins gray-text-2 mt-30 cwa-1-text'>{t('merchantWallet1.all')}</p>
                        <div class='btn-wrap cwa-1-btn-wrap mt-40'>
                            <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet1.download')}</a>
                            <a href='https://www.ovato.com/en/landing' target='_blank' rel="noopener noreferrer" className='custom-pink-btn custom-blue-btn-transparent'>{t('merchantWallet1.discover')}</a>
                        </div>
                    </Col>
                    <Col md={6} className='cwa-1-img-3-col'>
                        <div class='img-wrap cwa-1-img-3'>
                            <img src={imgBannerMobile} />
                        </div>
                    </Col>
                </Row>
                <Row className='cwa-1-row-3'>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>2+</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.years')} <br /> {t('merchantWallet1.industry')}</p>
                        </div>
                        
                    </Col>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>2M+</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.blocks')} <br /> {t('merchantWallet1.settled')}</p>
                        </div>
                    </Col>
                    <Col>
                        <div className='cwa-1-count-wrap'>
                            <p className='cwa-1-count'>1000%</p>
                            <p className='cwa-1-count-text'>{t('merchantWallet1.ovo')} <br /> {t('merchantWallet1.increased')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(MerchantSection1);
