import React, { Component } from 'react';
import ProfileImage from '../../../assets/img/premium-wallet/img-pw-1.png';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class ProfileBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <div className='v2-p-1-5 pw-box pw-box-1'>
            <div className='flex align-center space-between pw-box-1-header'>
                <div className='poppins v2-font-16 semi-bold blue-text-6'>#0053</div>
                <div className='poppins v2-font-14 semi-bold dark-text-10'>CAPACITY: 400,000</div>
            </div>
            
            <div className='v2-mt-0-75 text-center'>
                <Slider className='pw-img-slider' {...settings}>
                    <div className='pw-img-slider-col'>
                        <div>
                            <img src={ProfileImage} />
                        </div>
                    </div>
                    <div className='pw-img-slider-col'>
                        <div>
                            <img src={ProfileImage} />
                        </div>
                    </div>
                    <div className='pw-img-slider-col'>
                        <div>
                            <img src={ProfileImage} />
                        </div>
                    </div>
                </Slider>
            </div>

            <div className='v2-mt-0-75'>
                <a className='pw-btn' href='#'>Download</a>
            </div>

            <div className='v2-mt-0-75'>
                <a className='pw-btn pw-btn-2' href='#'>Download 3d</a>
            </div>
        </div>
    );
  }
}
export default withTranslation()(ProfileBox);
