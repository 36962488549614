import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import whitePaper from '../assets/img/OVATO_WHITEPAPER.pdf';
import OVATO_WHITEPAPER from '../assets/img/OVATO_WHITEPAPER.pdf';
import OVATO_WHITEPAPER_GERMAN from '../assets/img/OVATO_WHITEPAPER_GERMAN.pdf';
import OVATO_WHITEPAPER_KOREAN from '../assets/img/OVATO_WHITEPAPER_KOREAN.pdf';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import {
  Container,
  Row,
  Col,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Images
import ImgWhitePaper from '../assets/img/img-white-paper-new.png'
class WhitePaper extends Component {
  constructor(props){
    super(props); 
    this.state = {
      numPages: null,
      file: whitePaper,
      name: 'Select Language',
      actions: [],
      dropDownValue: 'Select action',
      dropdownOpen: false,
    }
  }
  
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  toggle = (event) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  changeValue = (e) => {
    this.setState({ dropDownValue: e.currentTarget.textContent });
    let id = e.currentTarget.getAttribute('id');
  };
  selectPaper = (val) => {
    switch (val) {
      case 1:
        this.setState({ file: OVATO_WHITEPAPER, name: 'English' });
        break;
      case 2:
        this.setState({ file: OVATO_WHITEPAPER_GERMAN, name: 'German' });
        break;
      case 3:
        this.setState({ file: OVATO_WHITEPAPER_KOREAN, name: 'Korean' });
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    
  }
  render() {
    const { t } = this.props;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // const {file}= this.state;
    return (
      <div className='blue-nav support_main'>
        {/* HEADER */}
        <TopNav />
         {/* BANNER */}
        <div className='new-first-row brand-main-new yellow-paper-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={12}>
                <h1 className='Poppins font-43 bold blue-text text-uppercase text-center yellow-paper-banner-new-heading'>{ t('newWhitePaper.whitePaper') }</h1>
                <div className='white-paper-button-wrap'>
                  <ButtonDropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                      className='white-paper-button'
                    >
                      <DropdownToggle
                        caret
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        defaultChecked={1}
                      >
                        {this.state.name}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this.selectPaper(1)}>
                          English
                        </DropdownItem>
                        <DropdownItem onClick={() => this.selectPaper(2)}>
                          German
                        </DropdownItem>
                        <DropdownItem onClick={() => this.selectPaper(3)}>
                          Korean
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  <div className='btn-wrap text-center'>
                      <a className='gradient-pink-btn gradient-pink-btn-3 c-md-btn inline-btn white-paper-dl-btn' href={this.state.file == OVATO_WHITEPAPER ? require('../assets/img/OVATO_WHITEPAPER.pdf'): this.state.file == OVATO_WHITEPAPER_GERMAN ? require('../assets/img/OVATO_WHITEPAPER_GERMAN.pdf') : require('../assets/img/OVATO_WHITEPAPER_KOREAN.pdf')}
                      download={this.state.file == OVATO_WHITEPAPER ? 'OVATO_WHITEPAPER.pdf' : this.state.file == OVATO_WHITEPAPER_GERMAN ? 'OVATO_WHITEPAPER_GERMAN.pdf' : 'OVATO_WHITEPAPER_KOREAN.pdf'}>{ t('newWhitePaper.download') }</a>
                    </div>
                </div>
              </Col>
              <Col md={12} className='yellow-paper-banner-new-col-2'>
                <div className='img-wrap desktop yellow-paper-banner-new-img-wrap'>
                  <img src={ImgWhitePaper} />
                </div>
                <div className='img-wrap mobile yellow-paper-banner-new-img-wrap'>
                  <img src={ImgWhitePaper} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <section className='yellow-paper-2 white-top-curve-4'>
          <Container className='text-center'>
            <center>
              <Row className='m-0'>
                <Col lg='5'></Col>
                <Col lg='4'>
                  
                </Col>
                <Col lg='4'></Col>
              </Row>
              <br />
              <Document
                file={this.state.file}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                {Array.from(new Array(this.state.numPages), (el, index) => (
                  <Page
                    className='page'
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    size='A4'
                    wrap
                  >
                    <p
                      className='pageNumbers '
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                      }
                      fixed
                    />
                  </Page>
                ))}
              </Document>
            </center>
          </Container>
        </section>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(WhitePaper);