import React, { Component } from 'react';
import { Row, Col, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgMerchantWallet from '../assets/img/img-ovato-merchant-wallet.png';
import imgMultiSigWallet from '../assets/img/img-ovato-multi-sig-wallet.png';
import imgTextWallet from '../assets/img/img-ovato-text-wallet.png';
import imgUserWallets from '../assets/img/img-ovato-user-wallets.png';
class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          fade={this.state.fade}
          toggle={this.toggle}
          size='lg'
          centered={true}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <iframe
              width='100%'
              height='400'
              src='https://www.youtube.com/embed/QvFtAtVnSik'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen='true'
            ></iframe>
          </ModalBody>
        </Modal>
        <section className='section-3-new desktop pb-100'>
          <Container className='max-width-full'>
            <Row>
              <Col>
                <div className='col-inner section-3-new-col-inner mr-60 ml-auto mt-80'>
                  <h2 className='blue-text extra-bold font-57 lh-1-2'>
                    {t('section3.head')}
                    <br />
                    {t('section3.currency')}
                  </h2>
                  <p className='font-20 medium mt-30'>{t('section3.detail')}</p>
                </div>
              </Col>
              <Col className='section-3-new-right-col-new pt-100 pb-100'>
                <h2 className='bold font-37 blue-text landing-home-3-text'>
                  {t('section3.neighbor')}
                </h2>
                <div class='text-center mask-img home-why-img-video landing-home-video mt-30'>
                  <img
                    alt='Why'
                    src={require('../assets/img/img-why.jpg')}
                    onClick={this.toggle}
                    className='mask-img-img'
                  />
                </div>
              </Col>
            </Row>
            <Row className='section-3-new-row'>
              <Col>
                <div className='section-3-right-inner-new'>
                  <h3 className='blue-text font-33 bold text-center'>
                    {t('section3.ovato_products')}
                  </h3>
                  <div className='section-3-box-wrap mt-30'>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img src={imgUserWallets} alt='Ovato User Wallets' />
                        </div>
                        <p>
                          {t('section3.user_wallets')}
                          <br />
                          {t('section3.place')}
                        </p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img
                            src={imgMerchantWallet}
                            alt='Ovato Merchant Wallet'
                          />
                        </div>
                        <p>{t('section3.merchant_wallet')}</p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img src={imgTextWallet} alt='Ovato Text Wallet' />
                        </div>
                        <p>
                          {t('section3.text_wallet')}{' '}
                          {t('section3.text_wallet_rest')}
                        </p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img
                            src={imgMultiSigWallet}
                            alt='Ovato Multi Sig Wallet'
                          />
                        </div>
                        <p>{t('section3.multi_sig')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-3-mobile mobile pt-20 pb-40'>
          <Container>
            <Row>
              <Col sm={12}>
                <div className='col-inner section-3-new-col-inner'>
                  <h2 className='blue-text extra-bold font-47 lh-1-2 text-center'>
                    {t('section3.head')}
                    {t('section3.currency')}
                  </h2>
                  <p className='font-18 medium mt-15 text-center'>
                    {t('section3.detail')}
                  </p>
                </div>
              </Col>
              <Col
                sm={12}
                className='section-3-new-right-col-new pt-40 pb-40 mt-30'
              >
                <h2 className='bold font-37 blue-text landing-home-3-text text-center'>
                  {t('section3.neighbor')}
                </h2>
                <div class='text-center mask-img home-why-img-video landing-home-video mt-30'>
                  <img
                    alt='Why'
                    src={require('../assets/img/img-why.jpg')}
                    onClick={this.toggle}
                    className='mask-img-img'
                  />
                </div>
              </Col>
            </Row>
            <Row className='section-3-new-row'>
              <Col>
                <div className='section-3-right-inner-new'>
                  <h3 className='blue-text font-33 bold text-center'>
                    {t('section3.ovato_products')}
                  </h3>
                  <div className='section-3-box-wrap mt-30'>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img src={imgUserWallets} alt='Ovato User Wallets' />
                        </div>
                        <p>
                          {t('section3.user_wallets')}
                          <br />
                          {t('section3.place')}
                        </p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img
                            src={imgMerchantWallet}
                            alt='Ovato Merchant Wallet'
                          />
                        </div>
                        <p>{t('section3.merchant_wallet')}</p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img src={imgTextWallet} alt='Ovato Text Wallet' />
                        </div>
                        <p>
                          {t('section3.text_wallet')}{' '}
                          {t('section3.text_wallet_rest')}
                        </p>
                      </div>
                    </div>
                    <div className='box-col'>
                      <div className='box-col-inner'>
                        <div className='img-wrap'>
                          <img
                            src={imgMultiSigWallet}
                            alt='Ovato Multi Sig Wallet'
                          />
                        </div>
                        <p>{t('section3.multi_sig')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section3);
