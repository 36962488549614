import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgAppStore from '../assets/img/img-consumer-wallet-app-app-store.png';
import imgPlayStore from '../assets/img/img-consumer-wallet-app-play-store.png';
import imgCP from '../assets/img/img-mwa-6.png';
import imgCPMobile from '../assets/img/img-cwa-6-mobile.png';
class MerchantSection6 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
    const { t, toggleModal } = this.props;
    return (
      <>
        <section className='cwa-6 mwa-6 desktop'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='img-wrap mwa-6-img-wrap-1'>
                            <img src={imgCP} />
                        </div>
                    </Col>
                    <Col md={6} className='cwa-6-col-2'>
                        <h2 className='poppins font-58 bold dark-text-2'>{t('merchantWallet6.start')} <br /> {t('merchantWallet6.wallet')}</h2>
                        <p className='poppins font-20 regular gray-text-2 mt-20'>{t('merchantWallet6.start2')}</p>
                        <div class='btn-wrap cwa-6-btn-wrap mt-40'>
                            <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet6.download')}</a>
                        </div>
                        <div class='img-wrap mt-45 cwa-6-img-2'>
                            <a 
                            onClick={toggleModal}
                            // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgAppStore} /></a>
                            <a 
                            onClick={toggleModal}
                            //href='https://play.google.com/store/apps/details?id=com.ovato.wallet' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgPlayStore} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Mobile */}
        <section className='cwa-6 mwa-6 mobile'>
            <Container>
                <Row>
                    <Col md={6} className='cwa-6-col-2'>
                        <h2 className='poppins font-58 bold dark-text-2'>{t('merchantWallet6.start')} <br />  {t('merchantWallet6.wallet')}</h2>
                        <p className='poppins font-20 regular gray-text-2 mt-20'>{t('merchantWallet6.start2')}</p>
                        <div class='btn-wrap cwa-6-btn-wrap mt-40'>
                            <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet6.download')}</a>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='img-wrap cwa-6-img-wrap-1'>
                            <img src={imgCP} />
                        </div>
                        <div class='img-wrap mt-45 cwa-6-img-2'>
                            <a 
                            onClick={toggleModal}
                            //href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgAppStore} /></a>
                            <a 
                            onClick={toggleModal}
                            //href='https://play.google.com/store/apps/details?id=com.ovato.wallet' 
                            href='#'
                            target='_blank' rel="noopener noreferrer"><img src={imgPlayStore} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(MerchantSection6);