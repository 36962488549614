import React, { Component } from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Images
import ImgBanner from '../assets/img/img-media-banner-new.png'
import ImgFast from '../assets/img/img-fast-avow.jpg'
import ImgWings from '../assets/img/img-kryptowings.jpg'
import ImgScoop from '../assets/img/img-scoopnix.jpg'
import ImgBlock from '../assets/img/img-blockchain-interview.jpg'
import ImgGlobe from '../assets/img/img-crypto-globe.jpg'
import ImgYellow from '../assets/img/img-yellow-text.jpg'
import ImgFirst from '../assets/img/img-first-crypto-news.jpg'
import ImgBullish from '../assets/img/img-bullish-net.jpg'
import ImgRole from '../assets/img/img-role-bitcoin.jpg'

class Media extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav media-main'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new support-banner-new media-banner-new custom-gradient-2'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <h1 className='Poppins font-61 bold blue-text support-banner-new-heading'>{t('media.media')}</h1>
              </Col>
              <Col md={6} className='support-banner-new-col-2'>
                <div className='img-wrap desktop'>
                  <img src={ImgBanner} />
                </div>
                <div className='img-wrap mobile'>
                  <img src={ImgBanner} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='media-2-new'>
          <Container>
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgFast}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h1')}"</p>
                        <p className='date'>May 31, 2021</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgWings}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h2')}"</p>
                        <p className='date'>March 17, 2021</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgScoop}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h3')}"</p>
                        <p className='date'>December 15, 2020</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgBlock}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h4')}"</p>
                        <p className='date'>December 5, 2020</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgFast}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h5')}"</p>
                        <p className='date'>October 29,2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgGlobe}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h6')}"</p>
                        <p className='date'>October 29, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgYellow}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h7')}"</p>
                        <p className='date'>October 29, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgFirst}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h8')}"</p>
                        <p className='date'>October 29, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgWings}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h9')}"</p>
                        <p className='date'>October 15, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgBullish}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h10')}"</p>
                        <p className='date'>October 14, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={ImgRole}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h11')}"</p>
                        <p className='date'>October 13, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card className='privacy_card mb-2'>
                  <CardBody>
                    <Row>
                      <Col lg='4'>
                        <img
                          alt='timer'
                          src={require('../assets/img/img-blocko-news.jpg')}
                        ></img>
                      </Col>
                      <Col lg='8'>
                        <p className='media-p'>"{t('media.h12')}"</p>
                        <p className='date'>October 8, 2019</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <div className='page media-pagination'>
              <Pagination size='lg' aria-label='Page navigation example'>
                <PaginationItem>
                  <PaginationLink previous href='#' className='previous' />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href='#'>1</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href='#'>2</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href='#'>3</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next href='#' className='next' />
                </PaginationItem>
              </Pagination>
            </div> */}
          </Container>
        </div>
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Media);
