import React from 'react'
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import '../../assets/css/OvatoNation.css'
import ImgOvato from '../../assets/img/logo-ovato-nation.png'
import ImgChatSupport from '../../assets/img/img-o-n-3-chat-support.png'
import ImgFreeTshirt from '../../assets/img/img-o-n-4-free-tshirt.png'
import ImgMembershipsAndWallets from '../../assets/img/img-o-n-memberships-and-wallets.png'
import ImgNFTs from '../../assets/img/img-o-n-nfts.png'
import ImgBuildingWealth from '../../assets/img/img-o-n-building-wealth.png'
import ImgLeaderboard from '../../assets/img/img-o-n-4-leaderboard.jpg'
import ImgLeaderboardMobile from '../../assets/img/img-o-n-4-leaderboard-mobile.jpg'
import ImgTwitterSpace from '../../assets/img/img-o-n-twitter-space.png'
import ImgExchanges from '../../assets/img/img-o-n-exchanges.png'
import ImgVideoBuildingSocial from '../../assets/img/img-o-n-video-building-social.jpg'
import ImgVideoBuildingWealth from '../../assets/img/img-o-n-video-building-wealth.jpg'
import ImgVideoMarketingInPerson from '../../assets/img/img-o-n-video-marketing-in-person.jpg'
import ImgVideoWhereToStart from '../../assets/img/img-o-n-video-where-to-start.jpg'
import ImgVideoEarn30 from '../../assets/img/img-video-earn-30-percent.jpg'
import ImgVideoEarnRoyalties from '../../assets/img/img-video-earn-royalties.jpg'
import ImgVideoFixSupply from '../../assets/img/img-video-fix-supply.jpg'
import ImgVideoMerchantTools from '../../assets/img/img-video-merchant-tools.jpg'
import ImgVideoSignUpPerson from '../../assets/img/img-video-sign-up-in-person.jpg'
import ImgVideoSignUpMerchant from '../../assets/img/img-video-sign-up-merchants.jpg'
import USAFlag from '../../assets/img/newsletter-usa-flag.png'
import GovatoTools from '../../assets/img/govato-tools-sign-up.png'
import NFTUSA from '../../assets/img/img-newsletter-nft-usa.png' 
import NFTCheckout from '../../assets/img/img-newsletter-nft-checkout.png'
import ImgStack from '../../assets/img/img-stack-for-coins.png'
import ImgStackMobile from '../../assets/img/img-stack-for-coins-mobile.png'
import ImgStack2 from '../../assets/img/img-stack-for-value.png'
import ImgStack2Mobile from '../../assets/img/img-stack-for-value-mobile.png'
const OvatoNation2 = () => {
  return (
    <div className='ovato-nation-main'>
      
      {/* SECTION 1 */}
      <section className='o-n-1'>
        <Container>
          <Row>
            <Col sm={9} className='o-n-1-left'>
              <div className='o-n-1-logo'>
                <a href='/'><img src={ImgOvato} alt='Ovato' /></a>
                <p className='white-text text-center poppins font-18 regular text-uppercase mt--1'>symbol: OVO</p>
              </div>
              <div className='o-n-1-left-2'>
                <h1 className='poppins mb-2 lh-1 n-font-45 semi-bold white-text'>Welcome to Ovato Nation!</h1>
                <p className='poppins lh-1 n-font-30 semi-bold white-text'>Let's Go!</p>
              </div>
            </Col>
            <Col sm={3} className='o-n-1-right-col'>
              <p className='poppins n-font-18 regular white-text o-n-1-right-text'>Volume 1, Issue 2</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 2 */}
      <section className='o-n-2 o-n-2-new'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-2-left-col o-n-2-left-col-new'>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Welcome to Ovato Nation, this is the first of our newsletter series that hope’s to convey the underlying core primary purpose of Ovato which is building a true economy of like minded community  members.
Over the course of Ovato' s journey, Ovato has acquired a truly unique knowledge base of what is actually required and how to successfully migrate users and scale while providing a safe and secure alternative means of exchanging value aka currency.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Ovato has developed a robust product line in order to fulfill our mission and we encourage all of you to discover and to help spread the vision.</p>
            </Col>
            <Col sm={4}>
              <div className='o-n-2-box o-n-2-box-new'>
                <h2 className='poppins n-font-30 bold dark-text-5 mb-4'>Helpful Hints</h2>
                <ul className='o-n-2-ul'>
                  <li>Always update your App</li>
                  <li>If you're not trading you should be stacking</li>
                  <li>Use chat support and don't rely on third party info</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 3 */}
      <section className='o-n-3'>
        <Container>
          <Row className='align-center'>
            <Col sm={5}>
              <div className='o-n-3-img-wrap'>
                <img src={ImgChatSupport} alt='Chat Support' />
              </div>
            </Col>
            <Col sm={7} className='o-n-3-left o-n-3-left-new'>
                <div className='img'>
                    <img width='75' src={USAFlag} alt='USA Flag' />
                </div>
                <p className='poppins font-18 medium pink-text-3 text-underline o-mt-1 o-n-3-left-new-text-1'>USA stakeholders contact support Immediately</p>
              <h2 className='poppins n-font-37 bold dark-text-5'>Chat Support</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Our chat support is operated directly by Ovato CoinHolders and associates of Ovato Core and is the best source of real-time information.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Hours of operation are from 8am - 8pm GMT</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 4 */}
      <section className='o-n-4 o-n-4-new'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-4-left o-n-4-left-new'>
              <h2 className='poppins n-font-37 bold dark-text-5'>Govato is Live</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Govato is now live and a great way for everyone to be involved in the build out of our Economy. The platform provides a fun, easy and profitable forum for users to share links and information and make money every step of the way.</p>
              <p className='poppins n-font-16 semi-bold dark-text-3 lh-1-7 o-mt-3'>Tools available from affiliate links to sign up users and merchants along with printed material for merchants</p>
              <div className='mt-40 o-n-4-img-wrap-2'>
                <img src={GovatoTools} alt='Govato Tools' />
              </div>
            </Col>
            <Col sm={4}>
              <div className='o-n-box o-n-box-new'>
                <h3 className='poppins n-font-30 bold text-center dark-text-5'>3 ways to Earn</h3>
                <div className='o-n-box-img'>
                  <img src={ImgMembershipsAndWallets} alt='Memberships and Wallets' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Memberships and Wallets</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgNFTs} alt='NFTs' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>NFT's</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgBuildingWealth} alt='Building Wealth' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Building Wealth</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='o-n-5-v1-i2'>
        <Container>
            <Row className='o-n-5-v1-i2-row'>
                <Col sm={8} className='c-pr-4 c-pr-4-new'>
                    <h2><span className='poppins font-62 bold dark-text-5'>NFT</span> <span className='poppins font-31 medium dark-text-5'>Launches March 15th</span></h2>
                    <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Each NFT includes: </p>
                    <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Over 100% face value of Ovato (OVO)</p>
                    <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Each NFT earns 10% Merchant Transaction exclusively within their territory forever
Each NFT is loaded with rights and privileges and true intellectual property represented by unique individual art and memorialized on the blockchain
Ability to earn additional income with one year free membership to GOVATO ( $600 value)</p>
                    <div className='img c-mt-3 o-n-5-v1-i2-img-wrap'>
                        <img src={NFTUSA} alt='NFT USA' width={300} />
                    </div>
                </Col>
                <Col sm={4}>
                    <div className='img o-n-5-v1-i2-img-wrap-2'>
                        <img src={NFTCheckout} alt='Checkout' width={350} />
                    </div>
                </Col>
            </Row>
            
        </Container>
      </section>
      <section className='o-n-6-v1-i2'>
        <Container>
          <Row>
            <Col>
              <div className='mt-40 o-n-4-img-wrap text-center c-mx-auto o-n-4-tshirt-img-wrap-2'>
                <img src={ImgFreeTshirt} alt='Govato Free Tshirt' />
              </div>
              <div className='desktop o-n-4-leaderboard-img-wrap-2'>
                <img src={ImgLeaderboard} alt='Leaderboard' />
              </div>
              <div className='mobile o-n-4-leaderboard-img-wrap-2'>
                <img src={ImgLeaderboardMobile} alt='Leaderboard' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 5 */}
      <section className='o-n-5 o-n-5-i2'>
        <Container>
          <Row className='align-center o-n-5-row-1'>
            <Col sm={5}>
              <div className='o-n-5-img-wrap-2'>
                <img src={ImgTwitterSpace} alt='Twitter Logo' />
              </div>
            </Col>
            <Col sm={7}>
              <h2 className='poppins n-font-37 bold dark-text-5 o-n-twitter-heading'>Twitter Spaces</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Our twitter spaces weekly meet ups  are moving from Tuesdays to Sunday mornings at 11am EST. Join us on Twitter @OvatoCoin</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Twitter Spaces is a cool new audio-based meeting room where you will be able to connect with other people in our community to ask questions, get insights, share ideas and collaborate. </p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Each week we will be giving out prizes and rewards, so please schedule time and contribute - you will be helping us to shape and improve this community!</p>
            </Col>
          </Row>
          <Row className='o-n-5-i2-row-main'>
            <Col>
              <h2 className='poppins n-font-37 bold dark-text-5 text-center on-5-i2-2-heading'>Stacking</h2>
              <p className='poppins font-24 light dark-text-3 text-center on-5-i2-2-text'>Stacking for value is surest way Of growing value while protecting for downside risk</p>
              <p className='poppins n-font-24 medium dark-text-3 text-center on-5-i2-2-text-2'>There are two great ways to participate</p>
              <div className='o-n-5-i2-img-row'>
                <div>
                  <img src={ImgStack} className='desktop' />
                  <img src={ImgStackMobile} className='mobile' />
                </div>
                <div>
                  <span className='poppins font-37 medium dark-text-5 text-center'>or</span>
                </div>
                <div>
                  <img src={ImgStack2} className='desktop' />
                  <img src={ImgStack2Mobile} className='mobile' />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 6 */}
      <section className='o-n-6 o-n-6-i2'>
        <Container>
          <Row>
            <Col>
              <h2 className='poppins n-font-37 bold white-text o-n-6-heading'>Videos to note</h2>
              <ul className='o-n-6-ul o-n-6-ul-2'>
                <li>
                  <img src={ImgVideoBuildingSocial} alt='Video Building Social' />
                </li>
                <li>
                  <img src={ImgVideoMarketingInPerson} alt='Video Marketing in Person' />
                </li>
                <li>
                  <img src={ImgVideoBuildingWealth} alt='Video Building Wealth' />
                </li>
                <li>
                  <img src={ImgVideoWhereToStart} alt='Video Where to Start' />
                </li>
                <li>
                  <img src={ImgVideoFixSupply} alt='Fix Supply with minimum' />
                </li>
                <li>
                  <img src={ImgVideoEarn30} alt='Earn 30% APR' />
                </li>
                <li>
                  <img src={ImgVideoSignUpMerchant} alt='Sign Up Merchants' />
                </li>
                <li>
                  <img src={ImgVideoMerchantTools} alt='Merchant Tools' />
                </li>
                
                <li>
                  <img src={ImgVideoEarnRoyalties} alt='Earn Royalties' />
                </li>
                <li>
                  <img src={ImgVideoSignUpPerson} alt='Sign Up In Person' />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default OvatoNation2  