import React, { Component } from 'react';
import ConsumerSection1 from './ConsumerSection1';
import ConsumerSection2 from './ConsumerSection2';
import ConsumerSection3 from './ConsumerSection3';
import ConsumerSection4 from './ConsumerSection4';
import ConsumerSection5 from './ConsumerSection5';
import ConsumerSection6 from './ConsumerSection6';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';

import RelaunchingPopup from './popup/RelaunchingPopup';

export default class ConsumerWalletApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  render() {
    const { t, modal } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }
    
    return (
      <div className='consumer-wallet-app-main wallet-app-main'>
        <TopNav />
        <ConsumerSection1 toggleModal={toggleModal} />
        <ConsumerSection2 />
        <ConsumerSection3 />
        <ConsumerSection4 />
        <ConsumerSection5 />
        <ConsumerSection6 toggleModal={toggleModal} />
        <BottomNav />

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal}/>
      </div>
    )
  } 
}