import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class TotalBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    return (
        <div className='total-box'>
            <div className='poppins v2-font-15 medium blue-text total-box-title'>
                {title}
            </div>
            
            <div className='poppins v2-font-20 bold blue-text total-box-val'>
                {value}
            </div>
        </div>
    );
  }
}
export default withTranslation()(TotalBox);
