import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MerchantTable from './MerchantTable';
import MeetUpTextIcon from './MeetUpTextIcon';
import { ToastContainer, toast } from 'react-toastify';
import { deviceDetect } from '../constants/defaultValues';
import { Service } from '../providers/Services';
const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class MeetUps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            city: ''
        };
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    handleSend = (e) => {
         e.preventDefault()
        if (this.state.email === '') {
            toast.error("Email can't be empty !", toaststyle);
        } else {
        const payload = {
            email: this.state.email,
            name: this.state.name,
            city: this.state.city,
            os: deviceDetect.os,
            browser: deviceDetect.browsername,
            device: deviceDetect.devicetype,
            plateform: deviceDetect.plateform,
            type: 'meetups',
        };
        Service.meetupForm(payload)
            .then((res) => {
            this.setState({ email: '', name: '', city: '' });
            toast.success(
                'Thank you for reaching out to us. We will get back to you as soon as possible!',
                toaststyle
            );
            })
            .catch((error) => {
            if (error.status === 400) {
                toast.error(
                'Email is invalid, please enter a valid email!',
                toaststyle
                );
            } else {
                toast.error(
                error && error.response
                    ? error.response.data.message
                    : 'Some error occurred!',
                toaststyle
                );
            }
            });
        }
    };
    
  render() {
    const { t } = this.props;
    return (
      <div className='support_main'>
      <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      <TopNav />
      
        {/* BANNER */}
        <div className='new-first-row brand-main-new wallet-products-new meet-up-banner-new'>
            <Container>
                <Row className='align-center'>
                    <Col md={12}>
                        <h1 className='Poppins text-uppercase font-43 extra-bold blue-text brand-main-new-heading-1'>{t('meetups.ovato')}</h1>
                        <div className='img-wrap mobile'>
                            <img src={require('../assets/img/img-ovato-meetups-new.png')} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      <section className='meet-up-2'>
        <Container>
            <Row className='meet-up-2-row-1'>
                <Col md={12} className='meet-up-2-row-1-col-1'>
                    <MeetUpTextIcon image={require('../assets/img/icon-connection.png')} title={t('meetups.connecting')} content={t('meetups.come')} />
                </Col>
            </Row>
            <Row className='meet-up-2-row-2'>
                <Col md={6} className='meet-up-2-row-2-col-1 desktop'>
                    <div className='meet-up-2-img-wrap'>
                        <img src={require('../assets/img/img-ovato-meetups-2-new.png')} />
                    </div>
                </Col>
                <Col md={6} className='meet-up-2-row-2-col-2'>
                    <MeetUpTextIcon image={require('../assets/img/icon-together.png')} title={t('meetups.were')} content={t('meetups.provide')} />
                    <MeetUpTextIcon image={require('../assets/img/icon-people.png')} title={t('meetups.reach')} content={t('meetups.connect')} />
                </Col>
            </Row>
        </Container>
      </section>
      <section className='meet-up-3'>
          <Container>
              <Row>
                  <Col md={6} className='meet-up-3-left'>
                    <div className='inner'>
                        <h2 className='font-45 semi-bold text-center blue-text'>{t('meetups.attend')}</h2>
                        <div className='img-wrap text-center mt-15'>
                            <img src={require('../assets/img/img-meet-up.png')} width='175' />
                        </div>
                        <div className='btn-wrap mt-15'>
                            <a target='_blank' rel='noopener noreferrer' href="https://www.meetup.com/ovatocoin" class="gradient-pink-btn gradient-pink-btn-3 gradient-pink-btn-4 c-md-btn full-width mt-5">{t('meetups.visit') + ' @ MEETUP.COM'} </a>
                        </div>
                    </div>
                    <div className='meet-up-3-or'>
                        <img width='58' src={require('../assets/img/img-or-text.png')} />
                    </div>
                  </Col>
                  <Col md={6} className='meet-up-3-right'>
                    <div className='inner'>
                        <h2 className='font-45 semi-bold text-center blue-text Poppins mb-0'>{t('meetups.start')}</h2>
                        <p className='font-20 regular text-center Poppins'>{t('meetups.looking')}</p>
                        <form className='meetup-form'>
                            <div>
                                <input value={this.state.name} onChange={this.handleInput} required type='text' name='name' placeholder={t('meetups.name')} />
                            </div>
                            <div>
                                <input value={this.state.email} onChange={this.handleInput} required type='text' name='email' placeholder={t('meetups.email')} />
                            </div>
                            <div>
                                <input value={this.state.city} onChange={this.handleInput} required type='text' name='city' placeholder={t('meetups.city')} />
                            </div>
                            <div class='form-submit'>
                                <button type='submit' onClick={this.handleSend} className='gradient-pink-btn gradient-pink-btn-3'>{t('meetups.submit')}</button>
                            </div>
                        </form>
                    </div>
                  </Col>
              </Row>
          </Container>
      </section>
      <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(MeetUps);
