import React, { Component } from 'react'
import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink, Collapse, NavbarToggler, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import i18next from '../i18n';
import { withTranslation } from 'react-i18next';
import { WALLET_URL, EXPLORER_URL } from '../constants/defaultValues'
import ReactGA from 'react-ga';
import {GA_ID} from '../constants/defaultValues'
import DaoComingSoonModal from './DaoComingSoonModal'

// SVGS
import { ReactComponent as IconProfileMenu } from '../assets/img/premium-wallet/svgs/icon-premium-profile.svg';
import { ReactComponent as IconSendReceiveMenu } from '../assets/img/premium-wallet/svgs/icon-premium-send-receive.svg';
import { ReactComponent as IconToolsMenu } from '../assets/img/premium-wallet/svgs/icon-premium-tools.svg';
import { ReactComponent as IconEarningsMenu } from '../assets/img/premium-wallet/svgs/icon-premium-earnings.svg';
import { ReactComponent as IconOrganizationMenu } from '../assets/img/premium-wallet/svgs/icon-premium-organization.svg';
import { ReactComponent as IconStackingMenu } from '../assets/img/premium-wallet/svgs/icon-premium-stacking.svg';
import { ReactComponent as IconPremiumMyAccount } from '../assets/img/premium-wallet/svgs/icon-premium-my-account.svg';
import { ReactComponent as IconPremiumDropDown } from '../assets/img/premium-wallet/svgs/icon-premium-dropdown.svg';
import { ReactComponent as IconPremiumMobileBtn } from '../assets/img/premium-wallet/svgs/icon-premium-mobile-btn.svg';
import { ReactComponent as IconPremiumClose } from '../assets/img/premium-wallet/svgs/icon-premium-close.svg';
import { ReactComponent as IconPremiumOvato } from '../assets/img/premium-wallet/svgs/icon-premium-ovato.svg';

ReactGA.initialize(GA_ID);
class TopNavPremium extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            dropdown: false,
            langcode: localStorage.getItem('i18nextLng'),
            sidebar: false,
            logout: true,
            reloadpath: '',
            scrolled: false,
            showDaoModal: false,
            lang : localStorage.getItem('i18nextLng'),
            myAccountNavMenu: false,
            showSideMenuLeft: false
        }
    }
    handleScroll = () => {
        const offset= window.scrollY;
        if(offset > 200 ){
            this.setState({scrolled: true});
        }
        else{
            this.setState({scrolled: false});
        }
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleDropdown = () => {
        this.setState({ dropdown: true })
    }
    handleLanguage = (e) => {
        const langcode = localStorage.getItem('i18nextLng')
        const reloadpath = "/" + langcode + window.location.pathname.substring(3, window.location.pathname.length)
        this.setState({ langcode, reloadpath, dropdown: false })
        window.history.pushState(this.state, "", reloadpath)
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    toggleSidebar = () => {
        this.setState({ sidebar: !this.state.sidebar })
    }
    changeLanguage(lang) {
        i18next.changeLanguage(lang)
    }
    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }
    activeMenuClass = (selectedURL) => {
        const url = window.location.href;
        const path = new URL(url).pathname;
        if (path === selectedURL) {
            return 'active';
        } else {
            return '';
        }
    }
    toggleDaoModal = (e, value) => {
        e.preventDefault();
        this.setState({ showDaoModal: value })
    }
    toggleMyAccountNavMenu = () => {
        this.setState({ myAccountNavMenu: !this.state.myAccountNavMenu })
    }
    toggleSideMenuLeft = () => {
        this.setState({ showSideMenuLeft: !this.state.showSideMenuLeft })
    }
    render() {
        if (this.state.lang == null) {
            localStorage.setItem('i18nextLng', 'en');
        }
        else{
            this.state.lang = localStorage.getItem('i18nextLng')
        }

        const { t } = this.props;
        
        return (
            <>
            <div className={'mindmap-header ' + ( this.state.scrolled ? 'active' : '' )  + 'top-nav-premium-main'}>
                {/* <div className='top_main'>
                    <Container className='top_main_container'>
                        <div className='top_slogan'>{t('topbar.top_slogan')} <a href='/landing' style={{ color: '#D12476' }}>{t('topbar.learn_more')}</a></div>
                        <div className='top-social'>
                            <a rel='noopener noreferrer' href="https://www.youtube.com/watch?v=QvFtAtVnSik" target='_blank'><i className="fab fa-youtube fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true" target='_blank'><i className="fab fa-linkedin fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.facebook.com/OvatoCoin" target='_blank'><i className="fab fa-facebook fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://twitter.com/OvatoCoin" target='_blank'><i className="fab fa-twitter fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.instagram.com/ovatocoin/" target='_blank'><i className="fab fa-instagram fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://t.me/Ovato_Coin" target='_blank'><i className="fab fa-telegram fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://bitcointalk.org/index.php?topic=3012780.msg30971176#msg30971176" target='_blank'><i className="fab fa-bitcoin fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.reddit.com/user/ovatotoken/" target='_blank'><i className="fab fa-reddit fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://github.com/Ovato-Pte-Ltd" target='_blank'><i className="fab fa-github fa-lg top_icons"></i></a>
                        </div>
                    </Container>
                </div> */}
                <div className='top_secondary top_secondary_web'>
                    <Container className='pt-3 pb-2'>
                        <Navbar light expand='md'>
                            <div className='premium-mobile-menu-btn'>
                                <a onClick={this.toggleSideMenuLeft} href='#'><IconPremiumMobileBtn /></a>
                            </div>
                            <NavbarBrand href="/"><img alt="Ovato Logo" src={require("../assets/img/img-ovato-mindmap-logo.png")} className="top_logo" /></NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className='top_navbar_toggler' />
                            <Collapse isOpen={this.state.isOpen} navbar className='premium-nav-mid'>
                                <Nav navbar className='top_nav_list premium-top-nav-list'>
                                    <NavItem>
                                        <Link className={`top_nav_list_items ${this.activeMenuClass(`/${this.state.lang}/premium/profile`)}`} to={'/premium/profile'}>
                                            <div className='premium-top-nav-icon'><IconProfileMenu /></div>
                                            <div className='premium-top-nav-text'>Profile</div>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className={`top_nav_list_items ${this.activeMenuClass(`/${this.state.lang}/premium/send-receive`)}`} to="/premium/send-receive">
                                            <div className='premium-top-nav-icon'><IconSendReceiveMenu /></div>
                                            <div className='premium-top-nav-text'>Send/Receive</div>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className={`top_nav_list_items ${this.activeMenuClass(`/${this.state.lang}/premium/tools`)}`} to="/premium/tools">
                                            <div className='premium-top-nav-icon'><IconToolsMenu /></div>
                                            <div className='premium-top-nav-text'>Tools</div>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className={`top_nav_list_items ${this.activeMenuClass(`/${this.state.lang}/premium/earnings`)}`} to="/premium/earnings">
                                            <div className='premium-top-nav-icon'><IconEarningsMenu /></div>
                                            <div className='premium-top-nav-text'>Earnings</div>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className={`top_nav_list_items ${this.activeMenuClass(`/${this.state.lang}/premium/organization`)}`} to="/premium/organization">
                                            <div className='premium-top-nav-icon'><IconOrganizationMenu /></div>
                                            <div className='premium-top-nav-text'>Organization</div>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className='top_nav_list_items' to="/premium/stacking">
                                            <div className='premium-top-nav-icon'><IconStackingMenu /></div>
                                            <div className='premium-top-nav-text'>Stacking</div>
                                        </Link>
                                    </NavItem>    
                                </Nav>
                            </Collapse>
                            <div className='premium-my-account-nav'>
                                <div className='premium-my-account-nav-desktop'>
                                    <Dropdown isOpen={this.state.myAccountNavMenu} toggle={this.toggleMyAccountNavMenu}>
                                        <DropdownToggle>
                                            <IconPremiumMyAccount className='icon-my-account' />
                                            <span className='my-account-text'>My Account</span>
                                            <IconPremiumDropDown className='icon-dropdown' />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                        <DropdownItem>Menu 1</DropdownItem>
                                        <DropdownItem>Menu 2</DropdownItem>
                                        <DropdownItem>Menu 3</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Another Menu 1</DropdownItem>
                                        <DropdownItem>Another Menu 2</DropdownItem>
                                        <DropdownItem>Another Menu 3</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>

                                <div className='premium-my-account-nav-mobile'>
                                    <a onClick={this.toggleSideMenuLeft} href='#'><IconPremiumMyAccount className='icon-my-account' /></a>
                                </div>
                            </div>
                        </Navbar>
                    </Container>
                </div>
                <div className='top_secondary top_secondary_phone'>
                    <Container>
                        <Navbar light expand='md' className='pt-2'>
                            <div className='navbar'>
                                <Link to='#' className='menu-bars'>
                                    <FaBars onClick={this.toggleSidebar} />
                                </Link>
                            </div>
                            <NavbarBrand href="/"><img alt="Ovato Logo" src={require("../assets/img/img-ovato-mindmap-logo.png")} className="top_logo ml-3" /></NavbarBrand>
                            <Dropdown isOpen={this.state.dropdown} size="sm" toggle={this.handleDropdown}>
                                {this.state.logout ?
                                    <><DropdownToggle tag='a' className="nav-link top_language_code" caret>
                                        {this.state.langcode === 'en' ? <><Flag name='us' /><span className='top_language_code'>EN</span></> :
                                            this.state.langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                                                this.state.langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                                                    this.state.langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                                                        <><Flag name='kr' className='top_language_code' /><span>KO</span></>}
                                    </DropdownToggle>
                                        <DropdownMenu onClick={this.handleLanguage} className='top_language_drpdwn'>
                                            <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                                            <DropdownItem value='english' onClick={() => this.changeLanguage('en')}><Flag name='us' />{t('topbar.english')}</DropdownItem>
                                            <DropdownItem value='chinese' onClick={() => this.changeLanguage('zh')}><Flag name='cn' />{t('topbar.chinese')}</DropdownItem>
                                            <DropdownItem value='german' onClick={() => this.changeLanguage('de')}><Flag name='de' />{t('topbar.german')}</DropdownItem>
                                            <DropdownItem value='korean' onClick={() => this.changeLanguage('ko')}><Flag name='kr' />{t('topbar.korean')}</DropdownItem>
                                            <DropdownItem value='japanese' onClick={() => this.changeLanguage('ja')}><Flag name='jp' />{t('topbar.japanese')}</DropdownItem>
                                        </DropdownMenu></> :
                                    <><DropdownToggle tag='a' className="nav-link" caret>
                                        <i className="far fa-user-circle top_user_icon" />
                                    </DropdownToggle>
                                        <DropdownMenu className='top_user_drpdwn'>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.profile')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/wordbackup'>{t('topbar.backup')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.wallet')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.balance')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/stackcoins'>{t('topbar.stack')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.graph')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.logout')}</DropdownItem>
                                        </DropdownMenu></>
                                }
                            </Dropdown>
                        </Navbar>
                    </Container>
                    <nav className={this.state.sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <div className='top_slogan'>{t('topbar.top_slogan')} <a href='/landing' style={{ color: '#D12476' }}><u>{t('topbar.learn_more')}</u></a></div>
                        <ul className='nav-menu-items'>
                            <li className='navbar-toggle'>
                                <Link to='#' className='menu-bars_cross text-white'>
                                    <AiOutlineClose onClick={this.toggleSidebar} />
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <Link to='/mindmap'>
                                    <span>{t('newHeader.mindmap')}</span>
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <a href={WALLET_URL}>
                                    <span>{t('newHeader.explorer')}</span>
                                </a>
                            </li>
                            <li className='nav-text'>
                                <Link to='/wallet'>
                                    <span>{t('newHeader.getStarted')}</span>
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <a href='/support'>
                                    <span>{t('newHeader.support')}</span>
                                </a>
                            </li>
                            <li className='nav-text'>
                                <a href={'#'} onClick={(event) => this.toggleDaoModal(event, true)}>
                                    <span>{t('newHeader.dao')}</span>
                                </a>
                            </li>
                            <li className='nav-text'>
                                <a target='_blank' href={WALLET_URL}>
                                    <span>{t('newHeader.login')}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            
            {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}
            
            {/* Side Menu Left */}
            <div className={`premium-side-menu-left ${this.state.showSideMenuLeft && 'active'}`}>
                <div className='premium-side-menu-main'>
                    <div className='premium-side-menu-header'>
                        <IconPremiumClose onClick={this.toggleSideMenuLeft} className='premium-side-menu-close' />
                        <span className='premium-side-menu-text'>Premium Wallet</span>
                        <IconPremiumOvato className='premium-side-menu-ovato' />
                    </div>
                    <div className='premium-side-menu-body'>
                        <ul className='premium-side-menu-ul'>
                            <li><a href='/premium/profile'>Profile</a></li>
                            <li><a href='/premium/send-receive'>Send/Receive</a></li>
                            <li><a href='/premium/tools'>Tools</a></li>
                            <li><a href='/premium/earnings'>Earnings</a></li>
                            <li><a href='/premium/organization'>Organization</a></li>
                            <li><a href='#'>Stacking</a></li>
                        </ul>
                    </div>
                </div>
                <div className='premium-side-menu-overlay'></div>
            </div>
            </>
        )
    }
}
export default withTranslation()(TopNavPremium)