import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class DemographicsBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <div className='v2-mt-1-5 pw-box pw-box-2'>

            <div className='v2-p-1-5 flex align-center space-between pw-box-header-title'>
               Demographics
            </div>
            
            <div className='v2-p-1-5 pw-box-body'>
                <div class='pw-level'>Level 1</div>

                <ul class='pw-level-list'>
                    <li>Canada: 50%</li>
                    <li>USA: 10%</li>
                    <li>Mexico: 5%</li>
                    <li>Singapore: 3%</li>
                    <li>China: 2%</li>
                </ul>
            </div>

            <div class='v2-p-1-5 pw-box-body'>
                <div class='pw-level'>Level 2</div>

                <ul class='pw-level-list'>
                    <li>USA: 50%</li>
                    <li>Canada: 50%</li>
                    <li>Singapore: 3%</li>
                    <li>China: 2%</li>
                </ul>
            </div>
        </div>
    );
  }
}
export default withTranslation()(DemographicsBox);
