import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// SVGS
import { ReactComponent as IconArrowUp } from '../../assets/img/premium-wallet/svgs/icon-arrow-up.svg';
import { ReactComponent as IconArrowDown } from '../../assets/img/premium-wallet/svgs/icon-arrow-down.svg';

// Containers
import TopNavPremium from '../../containers/TopNavPremium';
import BottomNav from '../../containers/NewBottomNav';

// Components
import WhiteBox from '../../components/premium/send-receive/WhiteBox';
import RecentTransactionsMain from '../../components/premium/recent-transactions/RecentTransactionsMain';
import TransferPopup from '../../components/premium/send-receive/TransferPopup';


class SendReceive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferModal: false
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    const toggleTransferModal = () => {
        this.setState(prevState => ({
            transferModal: !prevState.transferModal
        }));
    }

    return (
        <>
            {/* Header */}
            <TopNavPremium />

            <section className='top-section pw-1 sr-1'>
                <div className='container'>

                    <div className='row sr-row-1'>
                        <div className='col'>
                            <WhiteBox title={'Total Balance'} value={'100,000.00'} />
                        </div>
                        <div className='col v2-m-mt-2'>
                            <WhiteBox title={'Stacked'} value={'50,000.00'} />
                        </div>
                        <div className='col v2-tp-mt-2'>
                            <WhiteBox title={'Free Trading'} value={'50,000.00'} />
                        </div>
                    </div>

                    <div className='row v2-mt-2'>
                        <div className='col'>
                            <div className='premium-white-box'>

                                <div className='row'>
                                    <div className='col'>
                                        <div className='btn-wrap'>
                                            <a href='#' className='premium-btn'><IconArrowUp /> Send</a>
                                        </div>
                                    </div>

                                    <div className='v2-m-mt-1-5 col'>
                                        <div className='btn-wrap'>
                                            <a href='#' className='premium-btn premium-btn-2'><IconArrowDown /> Receive</a>
                                        </div>
                                    </div>

                                    <div className='v2-m-mt-1-5 col'>
                                        <div className='btn-wrap'>
                                            <a href='#' className='premium-btn premium-btn-3' onClick={toggleTransferModal}>Transfer</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    {/* Recent Transactions */}
                    <RecentTransactionsMain />
                </div>
            </section>

            {/* Footer */}
            <BottomNav />

            {/* Popup */}
            <TransferPopup modal={this.state.transferModal} toggle={toggleTransferModal}  />
        </>
    );
  }
}
export default withTranslation()(SendReceive);