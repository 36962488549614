import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgBulletproof from '../assets/img/img-cwa-2-bulletproof.png';
import imgFast from '../assets/img/img-cwa-2-fast.png';
import imgBank from '../assets/img/img-cwa-2-bank.png';
class ConsumerSection2 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='cwa-2 desktop'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-50 bold dark-text-2 text-center'>{t('consumer2.why')}</h2>
                        <p className='poppins font-20 gray-text-2 text-center'>{t('consumer2.ovato')} <br /> {t('consumer2.contribute')}</p>
                    </Col>
                </Row>
                <Row className='cwa-2-row-2'>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgBulletproof} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.bulletproof')} <br /> {t('consumer2.security')}</h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.all')} <br /> {t('consumer2.the')} <br /> {t('consumer2.in')}</p>
                    </Col>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgFast} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.fast')} <br /> {t('consumer2.easy')}</h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.end')} <br /> {t('consumer2.users')} <br /> {t('consumer2.about')}</p>
                    </Col>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgBank} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.you')} <br /> {t('consumer2.own')} </h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.your')} <br /> {t('consumer2.custodial')} <br /> {t('consumer2.you2')}</p>
                    </Col>
                </Row>
                <Row className='cwa-2-row-3'>
                    <Col>
                        <div class='btn-wrap text-center'>
                            <a href='/wallet' className='custom-pink-btn'>{t('consumer2.download')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Mobile */}
        <section className='cwa-2 mobile'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins font-58 bold dark-text-2 text-center'>{t('consumer2.why')}</h2>
                        <p className='poppins font-20 gray-text-2 text-center'>{t('consumer2.ovato')} <br /> {t('consumer2.contribute')}</p>
                    </Col>
                </Row>
                <Row className='cwa-2-row-2'>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgBulletproof} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.bulletproof')} <br /> {t('consumer2.security')}</h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.all')} <br /> {t('consumer2.the')} <br /> {t('consumer2.in')}</p>
                    </Col>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgFast} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.fast')} <br /> {t('consumer2.easy')}</h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.end')} <br /> {t('consumer2.users')} <br /> {t('consumer2.about')}</p>
                    </Col>
                    <Col md={4}>
                        <div className='img-wrap cwa-2-img-wrap text-center'>
                            <img src={imgBank} />
                        </div>
                        <h3 className='poppins font-23 bold dark-text-2 text-center'>{t('consumer2.you')} <br /> {t('consumer2.own')} </h3>
                        <p className='poppins font-20 gray-text-2 text-center lh-1-5 mt-20'>{t('consumer2.your')} <br /> {t('consumer2.custodial')} <br /> {t('consumer2.you2')}</p>
                    </Col>
                </Row>
                <Row className='cwa-2-row-3'>
                    <Col>
                        <div class='btn-wrap text-center'>
                            <a href='/wallet' className='custom-pink-btn'>{t('consumer2.download')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(ConsumerSection2);
