import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Images
import ImgUSA from '../../assets/img/leaderboard/img-usa.jpg'
import Flag from 'react-world-flags';
import { getCode } from 'country-list';
import { customConsole } from '../../constants/defaultValues';

const renderCountryFlag = (countryName) => {
  // Convert country name to ISO 3166-1 alpha-2 country code
  const countryCode = getCode(countryName);

  return (
    <div className='text-right org-tbl-country'>
      {countryCode ? <Flag code={countryCode} alt={`${countryName} flag`} /> : null}
    </div>
  );
};
class LeaderboardTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    formatEarnings = (number) => {
        const formattedEarnings = new Intl.NumberFormat('en-US').format(number);
        return formattedEarnings;
    }

  render() {
    const { t, data } = this.props;
    customConsole.log(data)
    return (
        <div className='leaderboard-tbl'>
        {/* LEADERBOARD HEADER */}
            <div className='leaderboard-header'>

                <div className='leaderboard-header-col'>
                    <div className='leaderboard-header-text'>
                        Username
                    </div>
                </div>

                {/* <div className='leaderboard-header-col'>
                    <div className='text-center leaderboard-header-text'>
                        No. of Wallets Opened
                    </div>
                </div> */}

                <div className='leaderboard-header-col'>
                    <div className='text-center leaderboard-header-text'>
                        Wallet Earnings
                    </div>
                </div>

                <div className='leaderboard-header-col'>
                    <div className='text-center leaderboard-header-text'>
                        NFT Earnings
                    </div>
                </div>

                <div className='leaderboard-header-col'>
                    <div className='text-center leaderboard-header-text'>
                        Total Earnings
                    </div>
                </div>

                {/* <div className='leaderboard-header-col'>
                    <div className='text-center leaderboard-header-text'>
                        Total number in organization
                    </div>
                </div> */}
            </div>

            {/* LEADERBOARD BODY */}
            <div className='leaderboard-body'>
                { data.map((item, key) => (
                    <div className='leaderboard-body-row'>
                        <div className='leaderboard-body-col'>
                            <div className='leaderboard-body-text'>
                                <span className='leaderboard-username-text' title={item.username}>{key + 1}. { item.username }</span> {renderCountryFlag(item.country)}
                            </div>
                        </div>

                        {/* <div className='leaderboard-body-col'>
                            <div className='text-center leaderboard-body-text'>
                                { item.no_of_wallets_opened }
                            </div>
                        </div> */}

                        <div className='leaderboard-body-col'>
                            <div className='text-center leaderboard-body-text'>
                                { this.formatEarnings(item.wallet_earnings) } USD
                            </div>
                        </div>

                        <div className='leaderboard-body-col'>
                            <div className='text-center leaderboard-body-text'>
                                { this.formatEarnings(item.nft_earnings) } USD
                            </div>
                        </div>

                        <div className='leaderboard-body-col'>
                            <div className='text-center leaderboard-body-text'>
                                { this.formatEarnings(item.total_earnings) } USD
                            </div>
                        </div>

                        {/* <div className='leaderboard-body-col'>
                            <div className='text-center leaderboard-body-text'>
                                { item.total_number_in_organization }
                            </div>
                        </div> */}
                    </div>
                )) }
            </div>
        </div>
    );
  }
}
export default withTranslation()(LeaderboardTable);