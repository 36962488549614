import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
class DearStakeholders extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='stake-main'>
        <section className='stake-1'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='img-wrap'><img width={100} src={require('../assets/img/icon-stake-ovato.png')} /></div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='stake-2'>
            <Container className='stake-2-container'>
                <div className='stake-2-inner'>
                    <Row>
                        <Col md={12}>
                            <h1 className='Poppins bold font-35 pink-text-2 cmb-15'>{t('dear.dear')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <p className='Poppins font-23 semi-bold dark-text cmb-5'>{t('dear.please')}  </p>
                            <p className='Poppins font-20 regular lh-1-7'>{t('dear.we')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='img-wrap img-stake-sent img-stake-wrap desktop'>
                                <img src={require('../assets/img/img-stake-sent.png')} />
                            </div>
                            <div className='img-wrap img-stake-sent img-stake-wrap mobile'>
                                <img src={require('../assets/img/img-stake-sent-mobile.png')} />
                            </div>
                            
                            <div className='img-stake-sent-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.coinmarket')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.while')}</p>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='img-wrap img-stake-wrap img-stake-nft'>
                                <img src={require('../assets/img/img-stake-nfts.png')} />
                            </div>
                            <div className='img-stake-nft-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.nft')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.weAre')} </p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.for')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.lastly')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='img-wrap img-stake-stacking img-stake-wrap desktop'>
                                <img src={require('../assets/img/img-stake-stacking.png')} />
                            </div>
                            <div className='img-wrap img-stake-stacking img-stake-wrap mobile'>
                                <img src={require('../assets/img/img-stake-stacking-mobile.png')} />
                            </div>
                            <div className='img-stake-stacking-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.stacking')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.our')}</p>
                            </div>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='img-wrap img-stake-explorer img-stake-wrap desktop'>
                                <img src={require('../assets/img/img-stake-explorer-new.png')} />
                            </div>
                            <div className='img-wrap img-stake-explorer img-stake-wrap mobile'>
                                <img src={require('../assets/img/img-stake-explorer-mobile.png')} />
                            </div>
                            <div className='img-stake-explorer-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.explorer')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.just')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='img-wrap img-stake-wallet img-stake-wrap desktop'>
                                <img src={require('../assets/img/img-stake-wallet.png')} />
                            </div>
                            <div className='img-wrap img-stake-wallet img-stake-wrap mobile'>
                                <img src={require('../assets/img/img-stake-wallet-mobile.png')} />
                            </div>
                            <div className='img-stake-wallet-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.wallet')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.currently')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-center'>
                        <Col md={6}>
                            <div className='img-wrap img-stake-new-exchanges img-stake-wrap desktop'>
                                <img src={require('../assets/img/img-stake-new-exchanges.png')} />
                            </div>
                            <div className='img-wrap img-stake-new-exchanges img-stake-wrap mobile'>
                                <img src={require('../assets/img/img-new-exchanges-mobile.png')} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='img-stake-new-exchanges-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.new')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.plans')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-center stake-atms-row'>
                        <Col md={6} className='order-2 mobile-order-1'>
                            <div className='img-wrap img-stake-atms img-stake-wrap'>
                                <img src={require('../assets/img/img-stake-atms.png')} />
                            </div>
                        </Col>
                        <Col md={6} className='order-1 mobile-order-2'>
                            <div className='img-stake-new-atms-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.atms')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.ourFirst')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-center stake-private-row'>
                        <Col md={6} className='order-1 mobile-order-1'>
                            <div className='img-wrap img-stake-private img-stake-wrap'>
                                <img src={require('../assets/img/img-stake-private-placement.png')} />
                            </div>
                        </Col>
                        <Col md={6} className='order-2 mobile-order-2'>
                            <div className='img-stake-private-content'>
                                <p className='Poppins font-31 semi-bold dark-text cmb-15'>{t('dear.private')}</p>
                                <p className='Poppins font-20 regular lh-1-7'>{t('dear.opened')}</p>
                                <div className='stake-pink-circle'></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
        <section className='stake-3'>
            <Container>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
            </Container>
        </section>
      </div>
    );
  }
}
export default withTranslation()(DearStakeholders);
