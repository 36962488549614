import React, { Component } from 'react';
import { Row, Col, Input, Label, Button, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { deviceDetect, EXPLORER_URL, WALLET_URL } from '../constants/defaultValues';
import { Service } from '../providers/Services';
import { ToastContainer, toast } from 'react-toastify';
import DaoComingSoonModal from './DaoComingSoonModal';

// Popups
import RelaunchingPopup from '../components/popup/RelaunchingPopup';

const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class NewBottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isInvalid: '',
      showDaoModal: false,
      modal: false
    };
  }
  toggleDaoModal = (e, value) => {
    e.preventDefault();
        this.setState({ showDaoModal: value })
    }
  handleInput = (e) => {
    this.setState({
      isInvalid: false,
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() { 
    Service.getCopyright()
    .then(res=>{
      this.setState({copyright:res.msg})
    })
   }
  handleSubmit = () => {
    if (this.state.email === '') {
      toast.error("Email can't be empty !", toaststyle);
      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
      const payload = {
        email: this.state.email,
        os: deviceDetect.os,
        browser: deviceDetect.browsername,
        device: deviceDetect.devicetype,
        plateform: deviceDetect.plateform,
        type: 'home',
      };
      Service.subscribeForm(payload)
        .then((res) => {
          this.setState({ email: '' });
          toast.success('Successfully subscribed!', toaststyle);
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.error(
              'Email is invalid, please enter a valid email!',
              toaststyle
            );
          } else {
            toast.error(
              error && error.response
                ? error.response.data.message
                : 'Some error occurred!',
              toaststyle
            );
          }
        });
    }
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <>
      <div>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <div className='bottom_main new-bottom-main'>
          <Container className='footer-container-1'>
            <Row className='bottom_main_row'>
              {/* COMPANY COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  {t('bottombar.company')}
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href='/about'>{t('bottombar.about')}</a>
                  </li>
                  <li>
                    <a href='/media'>{t('bottombar.media')}</a>
                  </li>
                  <li>
                    <a href='/contact'>{t('bottombar.contact_us')}</a>
                  </li>
                  <li>
                    <a href={WALLET_URL} target='_blank'>{t('bottombar.get_started')}</a>
                  </li>
                  <li>
                    <a href='/yellowpaper'>{t('bottombar.technology')}</a>
                  </li>
                  <li>
                    <a href='/support'>{t('bottombar.support')}</a>
                  </li>
                  <li>
                    <a href='/exchange'>{t('bottombar.exchanges')}</a>
                  </li>
                  <li>
                    <a href='/wallet'>{t('newFooter.wallets')}</a>
                  </li>
                  <li>
                    <a href='/merchant'>{t('newFooter.become')}</a>
                  </li>
                  <li>
                    <a href='/wallet'>{t('newFooter.get')}</a>
                  </li>
                </ul>
              </Col>
              {/* LEARN MORE COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  {t('bottombar.learn_more')}
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href='/press'>{t('bottombar.press')}</a>
                  </li>
                  <li>
                    <a href={WALLET_URL} target='_blank'>
                      {t('bottombar.application')}
                    </a>
                  </li>
                  <li>
                    <a href='/whitepaper'>{t('bottombar.white')}</a>
                  </li>
                  <li>
                    <a href={EXPLORER_URL} target='_blank'>{t('bottombar.explore')}</a>
                  </li>
                  <li>
                    <a href='/brand-assets'>{t('bottombar.brand')}</a>
                  </li>
                  <li>
                    <a href='http://faucet.ovato.com/' target='_blank'>
                      {t('bottombar.faucet')}
                    </a>
                  </li>
                  <li>
                    <a href='/stacking'>{t('bottombar.stack')}</a>
                  </li>
                  <li>
                    <a href='/media'>{t('newFooter.media')}</a>
                  </li>
                  <li>
                    <a href='/support'>{t('newFooter.support')}</a>
                  </li>
                  <li>
                    <a href='/bug-bounty'>{t('newFooter.report')}</a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                  {t('newFooter.connect')}
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    {' '}
                    <a
                      href='https://www.facebook.com/OvatoCoin'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('bottombar.facebook')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.youtube.com/watch?v=QvFtAtVnSik'
                      target='_blank'
                    >
                      {t('bottombar.youtube')}
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true'
                      target='_blank'
                    >
                      {t('bottombar.linkedin')}
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://twitter.com/OvatoCoin'
                      target='_blank'
                    >
                      {t('bottombar.twitter')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.instagram.com/ovatocoin/'
                      target='_blank'
                    >
                      {t('bottombar.instagram')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='#'
                      target='_blank'
                    >
                     Discord
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='#'
                      target='_blank'
                    >
                     Github
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.reddit.com/user/ovatotoken/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('bottombar.reddit')}
                    </a>
                  </li>
                  <li>
                    <a
                      href='/meet-ups'
                    >
                      Meet Ups
                    </a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                  {t('newFooter.affiliated')}
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    {' '}
                    <a
                      href='#'
                      target=''
                      rel='noopener noreferrer'
                    >
                      Owards.com
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='http://otext.net/'
                      target='_blank'
                    >
                      otext.net
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='/nft-collections'
                    >
                      Ovato.nft
                    </a>
                  </li>
                  <li>
                    <a 
                      href='#'
                      onClick={(event) => this.toggleDaoModal(event, true)}
                    >
                      Ovato.dao
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://bitovation.com/'
                      target='_blank'
                    >
                      Bitovation.com
                    </a>
                  </li>
                </ul>
              </Col>
              {/* Join our newsletter col */}
              <Col className='text-center new-footer-join-newsletter-col'>
                <a href='/'>
                  <img
                    alt='Ovato RGB'
                    src={require('../assets/img/img-ovato-footer-logo-new.png')}
                    className='bottom_ovato_img'
                  />
                </a>
                <Label style={{ fontWeight: 'bold', fontSize: 19 }}>
                  {t('bottombar.join_our_newsletter')}
                </Label>
                <center>
                  <Input
                    type='email'
                    name='email'
                    value={this.state.email}
                    placeholder={t('bottombar.email')}
                    className='bottom_input footer-email-input'
                    style={{
                      borderRadius: 60,
                      height: 50,
                      border: '1px solid white',
                      color: 'white',
                      paddingLeft: '20px',
                    }}
                    required
                    invalid={this.state.isInvalid}
                    onChange={this.handleInput}
                  />
                </center>
                <Button
                  className='bottom_subscribe'
                  style={{ borderRadius: 60 }}
                  onClick={this.handleSubmit}
                >
                  {t('bottombar.subscribe_now')}
                </Button>
                <div className='bottom-nav-download-wrap'>
                  <a
                    //href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='App Store'
                      className='bottom_storeicon'
                      src={require('../assets/img/img-app-store-footer.png')}
                      width={50} 
                    />
                  </a>
                  &nbsp;
                  <a
                    // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer' 
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='Google Play'
                      className='bottom_storeicon'
                      src={require('../assets/img/img-google-play-footer.png')} 
                      width={50} 
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <div className='text-center mt-5 footer-container-2'>
            <p className='bottom_info_text desktop'>
              <a href='/privacy-policy'>{t('bottombar.privacy_policy')}</a>
              &emsp;|&emsp;
              <a href='/terms-and-condition'>
                {t('bottombar.terms_and_condition')}
              </a>
              &emsp;|&emsp;{this.state.copyright}&emsp;|&emsp;
              {t('bottombar.all_right_reserve')}
            </p>
            <p className='bottom_info_text mobile'>
              <a href='/privacy-policy'>{t('bottombar.privacy_policy')}</a>
              <br />
              <a href='/terms-and-condition'>
                {t('bottombar.terms_and_condition')}
              </a>
              <br />
              {this.state.copyright}
              <br />
              {t('bottombar.all_right_reserve')}
            </p>
          </div>
        </div>
      </div>
      {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}

      {/* Modals */}
      <RelaunchingPopup toggle={toggleModal} modal={this.state.modal} />
      </>
    );
  }
}
export default withTranslation()(NewBottomNav);
