import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Images
import ImgHeatMapSample from '../../../assets/img/premium-wallet/img-heatmap-sample.png'

class HeatMapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
        activeTab: 0 // Initially setting the first tab as active
    };
  }

  // Function to handle tab change
  handleTabChange = (index) => {
        this.setState({ activeTab: index });
    };

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;
    const { activeTab } = this.state;

    return (
        <div className='pw-box pw-box-3 hm-box'>

            <div className='v2-py-1-5 v2-px-2 flex align-center space-between pw-box-header-title'>
                Heat Map
            </div>
            
            <div className='v2-py-1-5 pw-box-body'>
                <div className="pw-tabs-container">
                    {/* Tab buttons */}
                    <div className="pw-tab-buttons">
                        <button onClick={() => this.handleTabChange(0)} className={activeTab === 0 ? "active" : ""}>Level 1</button>
                        <button onClick={() => this.handleTabChange(1)} className={activeTab === 1 ? "active" : ""}>Level 2</button>
                        <button onClick={() => this.handleTabChange(2)} className={activeTab === 2 ? "active" : ""}>Wallets</button>
                    </div>

                    {/* Tab content */}
                    <div className="pw-tab-content">
                        {activeTab === 0 && <div>
                            <img src={ImgHeatMapSample} />
                        </div>}
                        {activeTab === 1 && <div>
                            <img src={ImgHeatMapSample} />
                        </div>}
                        {activeTab === 2 && <div>
                            <img src={ImgHeatMapSample} />  
                        </div>}
                    </div>
                </div>

                
            </div>
        </div>
    );
  }
}
export default withTranslation()(HeatMapBox);
