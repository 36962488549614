import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import yellowPaper from '../assets/img/OVATO_YELLOWPAPER.pdf';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
class Mining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  render() {
    const { t } = this.props;
    return (
        <>
      <div className='support_main mining-main'>
        {/* HEADER */}
        <TopNav />
         {/* BANNER */}
        <div className='new-first-row brand-main-new banner-mining-new'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <h1 className='Poppins font-46 bold dark-text-5 mining-banner-heading'>{t('mining.mining')}</h1>
                <p className='poppins font-21 medium blue-text'>{t('mining.ovato')}</p>
              </Col>
              <Col md={6} className='yellow-paper-banner-new-col-2'>
                <div className='img-wrap desktop banner-mining-img-wrap'>
                  <img src={require('../assets/img/img-mining-banner.png')} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <section className='white-top-curve-9 mining-2'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <div className='img-wrap mining-2-img-wrap'>
                            <img src={require('../assets/img/img-green-mining.png')} />
                        </div>
                    </Col>
                    <Col className='mining-2-right-col'>
                        <h2 className='poppins font-30 bold dark-text-5'>{t('mining.fast')}</h2>
                        <p className='poppins font-18 light dark-text-3'>{t('mining.through')}</p>
                    </Col>
                </Row>
                <Row className='align-center mining-2-row-2'>
                    <Col md={7} className='mining-2-row-2-col-left'>
                        <h2 className='poppins font-30 bold dark-text-5'>{t('mining.how')}</h2>
                        <p className='poppins font-18 light dark-text-3'>{t('mining.like')}</p>
                        <p className='poppins font-18 light dark-text-3'>{t('mining.this')}</p>
                    </Col>
                    <Col md={5}>
                        <div className='mining-white-box'>
                            <h2 className='poppins font-30 bold dark-text-5'>{t('mining.resources')}</h2>
                            <div className='mining-white-box-div'>
                                <h3 className='poppins font-21 medium blue-text'>{t('mining.miningGuide')}</h3>
                                <p className='poppins font-16 light dark-text-3'>{t('mining.detailed')}</p>
                                <div className='btn-wrap'>
                                    <a href='#' className='mining-btn'>{t('mining.see')}</a>
                                </div>
                            </div>
                            <div className='mining-white-box-div'>
                                <h3 className='poppins font-21 medium blue-text'>{t('mining.miningPools')}</h3>
                                <p className='poppins font-16 light dark-text-3'>{t('mining.information')}</p>
                                <div className='btn-wrap'>
                                    <a href='#' className='mining-btn'>{t('mining.see2')}</a>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
      </>
    );
  }
}
export default withTranslation()(Mining);