import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Components
import RelaunchingPopup from '../components/popup/RelaunchingPopup';
import QRDispensary from '../components/qrcode/QRDispensary';

// CSS
import '../assets/css/Dispensory.css'
import '../assets/css/Escorts.css'

// Images
import imgEscortBanner from '../assets/img/escorts/img-escort-banner.png'
import imgEscort2 from '../assets/img/escorts/img-escort-2.png'
import imgCBDStore from '../assets/img/dispensory/img-cbd-store.png'
import imgEasyBoarding from '../assets/img/dispensory/img-easy-on-boarding.png'
import imgFreeWallet from '../assets/img/dispensory/img-free-wallet.png'
import imgCannabis from '../assets/img/dispensory/img-cannabis.png'

import { withAffCodeURL } from '../helpers/helpers';

import imgCannabisMobile from '../assets/img/dispensory/img-cannabis-mobile.png'

class Escorts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletmodal: false,
      fade: false,
      merchantmodal: false,
      modal: false
    };
  }
  toggleWallet = () => {
    this.setState({ walletmodal: !this.state.walletmodal });
  };
  toggleMerchant = () => {
    this.setState({ merchantmodal: !this.state.merchantmodal });
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    const boxData = [
        { id: 1, title: t('dispensory.title1'), content: t('dispensory.content1'), },
        { id: 2, title: t('dispensory.title2'), content: t('dispensory.content2'), },
        { id: 3, title: t('dispensory.title3'), content: t('dispensory.content3') },
        { id: 4, title: t('dispensory.title4'), content: t('dispensory.content4') },
        { id: 5, title: t('dispensory.title5'), content: t('dispensory.content5') },
        { id: 6, title: t('dispensory.title6'), content: t('dispensory.content6') }
    ]

    const boxData2 = [
        { 
            id: 1, 
            title: t('escorts.title1'), 
            content: [
                { value: t('escorts.content1') },
                { value: t('escorts.content11') }
            ],
        },
        { 
            id: 2, 
            title: t('escorts.title1'), 
            content: [
                { value: t('escorts.content2') },
                { value: t('escorts.content22') }
            ],
        },
        { 
            id: 3, 
            title: t('escorts.title3'), 
            content: [
                { value: t('escorts.content3') },
            ],
        },
        { 
            id: 4, 
            title: t('escorts.title4'), 
            content: [
                { value: t('escorts.content4') },
            ],
        },
        { 
            id: 5, 
            title: t('escorts.title5'), 
            content: [
                { value: t('escorts.content5') },
            ],
        },
        { 
            id: 6, 
            title: t('escorts.title6'), 
            content: [
                { value: t('escorts.content6') },
            ],
        },
    ]

    return (
      <div className='blue-nav v3-main dispen-main'>
        {/* Header */}
        <TopNav />

        {/* Section 1 */}
        <section className='new-first-row v2-pb-6 v2-tl-pb-0 v2-m-pb-3 dispen-1'>
            <Container>
                <Row className='align-center'>
                    <Col className='v2-tp-col-12' sm={6}>
                        <h1 className='v2-font-35 v2-tl-font-32 poppins v2 v2-tp-font-32 v2-m-font-24 v2-tp-text-center bold dark-text-5 v2-mb-0 v2-mt-2 v2-m-mt-0'>{t('escorts.ovato')}</h1>

                        <p className='poppins v2-font-26 v2-tl-font-24 v2-tp-font-22 v2-m-font-18 bold dark-text-5 v2-mt-1 v2-mb-1 v2-tp-text-center lh-1-2'>{t('dispensory.theNewLegal')}</p>

                        <div className='v2-hidden v2-tp-show text-center v2-mx-auto img-wrap escort-1-img-wrap v2-mt-2 v2-tl-mw-60 v2-m-mw-90'>
                            <img src={imgEscortBanner} />
                        </div>
                    </Col>
                </Row>
                <div className='img-wrap dispen-1-img-wrap v2-tp-hidden escort-1-img-wrap'>
                    <img src={imgEscortBanner} />
                </div>
            </Container>
        </section>

        <section className='white-top-curve-14 v2-pt-8 v2-m-pt-6 v2-pb-4 dispen-2 escort-2'>
            <Container className='relative z-index-2'>
                <Row className='align-center'>
                    <Col sm={5} className='v2-tp-col-12'>
                        <div className='img-wrap dispen-2-img-wrap v2-tp-text-center v2-tp-mw-60 v2-m-mw-90 v2-tp-mx-auto'>
                            <img src={imgEscort2} />
                        </div>
                    </Col>

                    <Col sm={7} className='v2-pl-4 v2-tp-pl-2 v2-m-pl-1 v2-tp-col-12'>
                        <h2 className='poppins v2-font-26 v2-m-font-22 bold dark-text-5 v2-mw-80 v2-tp-mt-4 v2-m-mt-2 v2-tp-text-center v2-tp-mw-100'>{t('escorts.why')}</h2>
                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-m-text-left'>{t('escorts.as')}</p>
                    </Col>
                </Row>

                <Row className='v2-mt-2 v2-m-mt-0'>
                    { boxData.map((box) => (
                        <Col sm={4} className='v2-mt-6 v2-tp-mt-4 v2-tp-col-6 v2-m-col-12'>
                            <div className='dispen-box'>
                                <div className='dispen-box-num'>{box.id}</div>
                                <h3 className='dispen-box-title'>{box.title}</h3>
                                <p className='dispen-box-content'>{box.content}</p>
                            </div>
                        </Col>
                    )) }
                </Row>

                <Row>
                    <Col sm={8} className='v2-mx-auto v2-mt-6 v2-m-mt-4 v2-tp-col-12'>
                        <div className='dispen-box dispen-box-2'>
                            <div className='dispen-box-num'>7</div>

                            <div>
                                
                                <h3 className='dispen-box-title'>{t('dispensory.easy')}</h3>
                                <p className='dispen-box-content'>{t('dispensory.ovatoProvides')}</p>
                            </div>
                            
                            <div class='v2-m-mt-2 dispen-box-img'>
                                <img src={imgEasyBoarding} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='white-top-curve-15 v2-pt-18 v2-tl-pt-16 v2-tp-pt-10 v2-m-pt-8 v2-mb--20 v2-m-mb-0 escort-3'>
            <Container>
                <Row className='relative z-index-2'>
                    <Col>
                        <h2 className='v2-font-35 v2-tl-font-32 poppins v2 v2-tp-font-28 v2-m-font-24 v2-tp-text-center bold dark-text-5 v2-mb-0 v2-mt-2 v2-m-mt-0 text-center'>{t('escorts.benefits')}</h2>
                    </Col>
                </Row>

                <Row className='v2-mt-2 v2-m-mt-0'>
                    { boxData2.map((box2) => (
                        <Col sm={4} className='v2-mt-6 v2-tp-mt-4 v2-tp-col-6 v2-m-col-12'>
                            <div className='dispen-box escort-box'>
                                <div className='dispen-box-num'>{box2.id}</div>
                                <h3 className='dispen-box-title'>{box2.title}</h3>
                                {box2.content && 
                                    box2.content.map((content, index) => (
                                        <p key={index} className='dispen-box-content'>{content.value}</p>
                                    ))
                                }
                            </div>
                        </Col>
                    )) }
                </Row>

                <Row className='v2-mt-6 v2-m-mt-4 relative z-index-3'>
                    <Col sm={8} className='v2-mx-auto relative'>
                        <div className='dispen-blue-box'>
                            <Row className='align-center'>
                                <Col sm={7} className='v2-tp-col-12'>
                                    <h2 className='poppins v2-font-26 bold white-text v2-tp-font-24 v2-m-font-22 v2-tp-text-center'>{t('escorts.ready')}</h2>
                                    <p className='poppins v2-font-15 light white-text v2-m-text-left'>{t('escorts.dont')}</p>
                                </Col>
                                <Col sm={5} className='v2-tp-col-12'>
                                    <div className='text-right img-wrap v2-tp-text-center v2-tp-mt-2 v2-m-mw-70 v2-m-mx-auto'>
                                        {/* <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank'><img width={225} src={imgFreeWallet} /></a> */}
                                        <a target='_blank' href={withAffCodeURL('https://wallet.ovato.com/')}><QRDispensary url={withAffCodeURL('https://wallet.ovato.com/')} /></a>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='v2-mt-2 v2-tp-mt-1'>
                                <Col>
                                    <div className='v2-mt-2 btn-wrap text-center v2-tp-text-center'>
                                        <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='site-btn dispen-btn'>{t('unbanked.get')}</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        {/* Footer */}
        <BottomNav/>

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </div>
    );
  }
}
export default withTranslation()(Escorts);