import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// SVGS
import { ReactComponent as IconPopupClose } from '../../../assets/img/premium-wallet/svgs/icon-popup-close.svg';
import { ReactComponent as IconOvoCoin } from '../../../assets/img/premium-wallet/svgs/icon-ovo-coin.svg';
import { ReactComponent as Icon4Square } from '../../../assets/img/premium-wallet/svgs/icon-4-square.svg';
import { ReactComponent as IconSelectDown } from '../../../assets/img/premium-wallet/svgs/icon-select-down.svg';

class TransferPopup extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   modal: false
    // };
    // this.toggle = this.toggle.bind(this);
  }

//   toggle() {
//     this.setState(prevState => ({
//       modal: !prevState.modal
//     }));
//   }

  render() {

    const { t, toggle, modal} = this.props;

    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop={true} className={'premium-modal'}>
          
          <ModalBody>

            <div className='poppins v2-font-22 bold text-uppercase blue-text text-center'>Transfer Ovo</div>

            <div className='premium-modal-close'><a href='#' onClick={toggle}><IconPopupClose /></a></div>

            <form>
                <div className='v2-mt-1 pm-form-wrap'>
                    <div className='pm-form-label'>
                        <label>From</label>
                    </div>
                    
                    <div className='pm-form-select-wrap'>
                        <select className='pm-form-select'>
                            <option value='Default Wallet'>Default Wallet</option>
                        </select>
                    </div>
                </div>

                <div className='v2-mt-1 pm-form-wrap pm-w-control'>
                    <div className='pm-form-label'>
                        <label>To</label>
                    </div>
                    
                    <div className='pm-form-field-wrap'>
                        <input className='pm-form-field' type='text' name='to' />

                        <div className='pm-form-field-ctrl'>
                            <span>
                                <Icon4Square className='pm-icon-4-square' />
                            </span>
                            <span>
                                <IconSelectDown className='pm-icon-select-down' />
                            </span>
                        </div>
                    </div>
                </div>

                <div className='v2-mt-1 pm-form-wrap'>
                    <p className='mb-0 poppins v2-font-16 light blue-text text-uppercase text-center'>Wallet Balance</p>
                    <p className='mt-0 poppins v2-font-23 bold bold blue-text text-center pm-balance-text'>
                        <IconOvoCoin className='pm-balance-icon' />
                        100,000.00
                    </p>
                </div>

                <div className='v2-mt-1 pm-form-wrap'>
                    <div className='pm-form-label'>
                        <label>Amount</label>
                    </div>
                </div>

                <div className='pm-form-wrap pm-amount-wrap pm-w-control'>
                    <div>
                        <div className='pm-form-field-wrap'>
                            <input className='pm-form-field' type='text' name='amount_ovo' placeholder='0' />

                            <div className='pm-form-field-ctrl'>
                                <span className='pm-form-currency'>
                                    OVO
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className='blue-text bold pm-amount-equals'>=</div>

                    <div>
                        <div className='pm-form-field-wrap'>
                            <input className='pm-form-field' type='text' name='amount_ovo' placeholder='0' />

                            <div className='pm-form-field-ctrl'>
                                <span className='pm-form-currency'>
                                    USD
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='v2-mt-2 pm-form-wrap'>
                    <div className='pm-form-field-wrap'>
                        <textarea className='pm-form-field pm-form-field-textarea' placeholder="Note (optional)"></textarea>
                    </div>
                </div>

                <div className='v2-mt-1-5 pm-form-wrap'>
                    <button className='pm-form-btn' type="submit">Transfer</button>
                </div>
            </form>
          </ModalBody>
          
        </Modal>
      </div>
    );
  }
}

export default TransferPopup;
