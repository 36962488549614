import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
  } from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

import Fancybox from '../containers/FancyBox';

// CSS
import '../assets/css/Congratulations.css'

// Images
import Logo from '../assets/img/congratulations/logo.png'
import ImgVideo from '../assets/img/congratulations/video.jpg'


class Congratulations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletmodal: false,
      fade: false,
      merchantmodal: false,
      modal: false
    };
  }
  toggleWallet = () => {
    this.setState({ walletmodal: !this.state.walletmodal });
  };
  toggleMerchant = () => {
    this.setState({ merchantmodal: !this.state.merchantmodal });
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <div className='blue-nav cong-main'>
        {/* Header */}
        {/* <TopNav /> */}

        <section className='v2-pt-2 cong-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='text-center img-wrap'>
                            <a href='/'><img width='111' className='cong-1-img' src={Logo} /></a>
                        </div>

                        <h1 className='poppins v2-font-30 v2-m-font-24 semi-bold white-text text-center'>Congratulations! <br className='mobile' /> You've been given Ovato!</h1>
                    </Col>
                </Row>

                <Row className='align-center v2-mt-5 v2-tp-mt-3'>
                    <Col>
                        <div className='text-center video-wrap'>
                            <Fancybox>
                                <a data-fancybox href='https://www.youtube.com/watch?v=2aR_5-JI_oA'><img src={ImgVideo} className='v2-tp-mw-60 v2-m-mw-90' /></a>
                            </Fancybox>
                        </div>
                    </Col>
                    <Col className='v2-pl-4 v2-m-pl-2'>
                        <ul className='cong-ul'>
                            <li>Digital currency, we can all use and trust </li>
                            <li>Highly appreciable </li>
                            <li>Earn on all your activities</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='cong-2 v2-mt--8 v2-tp-mt--2 v2-m-mt-2 v2-pb-4'>
            <Container>
                <Row>
                    <Col>
                        <div className='text-center btn-wrap'>
                            <a href='#' className='cong-btn'>Collect Now</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        {/* Footer */}
        {/* <BottomNav/> */}
      </div>
    );
  }
}
export default withTranslation()(Congratulations);
