import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Images
import Img1 from '../assets/img/logo-ovato-privacy-policy-new.png'
import Img1Mobile from '../assets/img/logo-mobile-terms-of-use-new.png'
class Terms extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav v3-main privacy-main'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new privacy-banner-new terms-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={5}>
                <h1 className='Poppins font-43 bold blue-text text-uppercase privacy-banner-new-heading'>{t('terms.terms')}</h1>
              </Col>
              <Col md={7} className='privacy-banner-new-col-2'>
                <div className='img-wrap desktop privacy-banner-new-img-wrap'>
                  <img src={Img1} />
                </div>
                <div className='img-wrap mobile privacy-banner-new-img-wrap'>
                  <img src={Img1Mobile} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='privacy_div privacy-new-2 terms-new-2'>
          <Container>
            <Card className='privacy_card pr-3'>
              <CardBody>
                <ol>
                  <h2>{t('terms.agreement')}</h2>
                  <p>
                    {t('terms.a1')}
                    <br />
                    <br />
                    {t('terms.a2')}
                    <br />
                    <br />
                    {t('terms.a3')}
                    <br />
                    <br />
                    {t('terms.a4')}
                  </p>
                  <h2>{t('terms.property')}</h2>
                  <p>
                    {t('terms.b1')}
                    <br />
                    <br />
                    {t('terms.b2')}
                  </p>
                  <h2>{t('terms.user')}</h2>
                  <p>
                    {t('terms.c1')}
                    <br />
                    <br />
                    {t('terms.c2')}
                  </p>
                  <h2>{t('terms.prohibited')}</h2>
                  <p>
                    {t('terms.d1')}
                    <br />
                    <br />
                    {t('terms.d2')}
                    <br />
                    <br />
                    {t('terms.dd1')}
                    <br />
                    {t('terms.dd2')}
                    <br />
                    {t('terms.dd3')}
                    <br />
                    {t('terms.dd4')}
                    <br />
                    {t('terms.dd5')}
                    <br />
                    {t('terms.dd6')}
                    <br />
                    {t('terms.dd7')}
                    <br />
                    {t('terms.dd8')}
                    <br />
                    {t('terms.dd9')}
                    <br />
                    {t('terms.dd10')}
                    <br />
                    {t('terms.dd11')}
                    <br />
                    {t('terms.dd12')}
                    <br />
                    {t('terms.dd13')}
                    <br />
                    {t('terms.dd14')}
                    <br />
                    {t('terms.dd15')}
                    <br />
                    {t('terms.dd16')}
                    <br />
                    {t('terms.dd17')}
                    <br />
                    {t('terms.dd18')}
                    <br />
                    {t('terms.dd19')}
                    <br />
                    {t('terms.dd20')}
                    <br />
                    {t('terms.dd21')}
                    <br />
                    {t('terms.dd22')}
                    <br />
                    {t('terms.dd23')}
                    <br />
                    {t('terms.dd24')}
                  </p>
                  <h2>{t('terms.generated')}</h2>
                  <p>
                    {t('terms.e1')}
                    <br />
                    <br />
                    {t('terms.ee1')}
                    <br />
                    {t('terms.ee2')}
                    <br />
                    {t('terms.ee3')}
                    <br />
                    {t('terms.ee4')}
                    <br />
                    {t('terms.ee5')}
                    <br />
                    {t('terms.ee6')}
                    <br />
                    {t('terms.ee7')}
                    <br />
                    {t('terms.ee8')}
                    <br />
                    {t('terms.ee9')}
                    <br />
                    {t('terms.ee10')}
                    <br />
                    {t('terms.ee11')}
                    <br />
                    {t('terms.ee12')}
                    <br />
                    {t('terms.ee13')}
                    <br />
                    {t('terms.ee14')}
                    <br />
                    <br />
                    {t('terms.e2')}
                  </p>
                  <h2>{t('terms.license')}</h2>
                  <p>
                    {t('terms.f1')}
                    <br />
                    <br />
                    {t('terms.f2')}
                    <br />
                    <br />
                    {t('terms.f3')}
                  </p>
                  <h2>{t('terms.reviews')}</h2>
                  <p>
                    {t('terms.g1')}
                    <br />
                    <br />
                    {t('terms.g2')}
                  </p>
                  <h2>{t('terms.internal')}</h2>
                  <p>{t('terms.weHaveInternal')}</p>
                  <h2>{t('terms.stacking')}</h2>
                  <p>{t('terms.ovatoHave')} <u>{t('terms.internal')}</u></p>
                  <p><h5>{t('terms.hardStack')}</h5></p>
                  <p>{t('terms.earnings')} <u>support@ovato.com</u>. {t('terms.forMore')} <a href="/faqs">FAQ</a></p>
                  <h2>{t('terms.mobile')}</h2>
                  <p>
                    <h5>{t('terms.mobile1')}</h5>
                    <p>{t('terms.h1')}</p>
                    <h5>{t('terms.mobile2')}</h5>
                    <p>{t('terms.h2')}</p>
                  </p>
                  <h2>{t('terms.submit')}</h2>
                  <p>{t('terms.i1')}</p>
                  <h2>{t('terms.site')}</h2>
                  <p>{t('terms.j1')}</p>
                  <h2>{t('terms.privacy')}</h2>
                  <p>{t('terms.k1')}</p>
                  <h2>{t('terms.termsand')}</h2>
                  <p>
                    {t('terms.l1')}
                    <br />
                    <br />
                    {t('terms.l2')}
                  </p>
                  <h2>{t('terms.modify')}</h2>
                  <p>
                    {t('terms.m1')}
                    <br />
                    <br />
                    {t('terms.m2')}
                  </p>
                  <h2>{t('terms.law')}</h2>
                  <p>{t('terms.n1')}</p>
                  <h2>{t('terms.resolution')}</h2>
                  <p>
                    <h5>{t('terms.resolution1')}</h5>
                    <p>{t('terms.o1')}</p>
                    <h5>{t('terms.resolution2')}</h5>
                    <p>{t('terms.o2')}</p>
                    <h5>{t('terms.resolution3')}</h5>
                    <p>{t('terms.o3')}</p>
                  </p>
                  <h2>{t('terms.correct')}</h2>
                  <p>{t('terms.p1')}</p>
                  <h2>{t('terms.dis')}</h2>
                  <p>{t('terms.q1')}</p>
                  <h2>{t('terms.limit')}</h2>
                  <p>{t('terms.r1')}</p>
                  <h2>{t('terms.ind')}</h2>
                  <p>{t('terms.s1')}</p>
                  <h2>{t('terms.userdata')}</h2>
                  <p>{t('terms.t1')}</p>
                  <h2>{t('terms.electric')}</h2>
                  <p>{t('terms.u1')}</p>
                  <h2>{t('terms.mis')}</h2>
                  <p>{t('terms.v1')}</p>
                  <h2>{t('terms.contact')}</h2>
                  <p>
                    {t('terms.w1')}
                    <br />
                    <br />
                    <b>{t('terms.support')}</b>
                  </p>
                </ol>
              </CardBody>
            </Card>
          </Container>
        </div>
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Terms);
