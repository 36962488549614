export default
[
  {
    "id": 1,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0001",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3, // 1 2 3 // small medium large
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 2,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0002",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 3,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0003",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 4,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0004",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 5,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0005",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 6,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0006",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 7,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0007",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 8,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0008",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 9,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0009",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 10,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0010",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 11,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0011",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 12,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0012",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 13,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0013",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 14,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0014",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 15,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0015",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 16,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0016",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 17,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0017",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 18,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0018",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 19,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0019",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 20,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0020",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 21,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0021",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 22,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0022",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 23,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0023",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 24,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0024",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 25,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0025",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 26,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0026",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 27,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0027",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 28,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0028",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 29,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0029",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 30,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0030",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 31,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0031",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 32,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0032",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 33,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0033",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 34,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0034",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 35,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0035",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 36,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0036",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 37,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0037",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 38,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0038",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 39,
    "name": "",
    "country": "SINGAPORE",
    "category": "LARGE FACE",
    "image_name": "img-0039",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 40,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0040",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 41,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0041",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 42,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0042",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 43,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0043",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 44,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0044",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 45,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0045",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 46,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0046",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 47,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0047",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 48,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0048",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 49,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0049",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 50,
    "name": "",
    "country": "UAE",
    "category": "LARGE FACE",
    "image_name": "img-0050",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "foundersClub": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 51,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0051-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 52,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0052-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "neutral",
    "background": "yellow",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 53,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0053-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 54,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0054-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 55,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0055-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 56,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0056-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "lover",
    "background": "yellow",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 57,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0057-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 58,
    "name": "",
    "country": "USA",
    "category": "LARGE FACE",
    "image_name": "img-0058-usa-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 2300,
    "expression": "mask on mask",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 59,
    "name": "",
    "country": "USA",
    "category": "SMALL FACE",
    "image_name": "img-0059-usa-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 2300,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 60,
    "name": "",
    "country": "USA",
    "category": "SMALL FACE",
    "image_name": "img-0060-usa-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 2300,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 61,
    "name": "",
    "country": "USA",
    "category": "SMALL FACE",
    "image_name": "img-0061-usa-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 2300,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 62,
    "name": "",
    "country": "USA",
    "category": "MEDIUM FACE",
    "image_name": "img-0062-usa-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 2300,
    "expression": "neutral",
    "background": "blue",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 63,
    "name": "",
    "country": "USA",
    "category": "MEDIUM FACE",
    "image_name": "img-0063-usa-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 2300,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 64,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0064-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 65,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0065-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 66,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0066-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 67,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0067-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 68,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0068-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "tongue",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 69,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0069-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 70,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0070-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 71,
    "name": "",
    "country": "UK",
    "category": "LARGE FACE",
    "image_name": "img-0071-uk-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 72,
    "name": "",
    "country": "UK",
    "category": "SMALL FACE",
    "image_name": "img-0072-uk-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "gold",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 73,
    "name": "",
    "country": "UK",
    "category": "SMALL FACE",
    "image_name": "img-0073-uk-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 74,
    "name": "",
    "country": "UK",
    "category": "SMALL FACE",
    "image_name": "img-0074-uk-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 75,
    "name": "",
    "country": "UK",
    "category": "MEDIUM FACE",
    "image_name": "img-0075-uk-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 300,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 76,
    "name": "",
    "country": "UK",
    "category": "MEDIUM FACE",
    "image_name": "img-0076-uk-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 300,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 77,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0077-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 78,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0078-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "neutral",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 79,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0079-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 80,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0080-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "smirk",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 81,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0081-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 82,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0082-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 83,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0083-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 84,
    "name": "",
    "country": "JAPAN",
    "category": "LARGE FACE",
    "image_name": "img-0084-japan-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 500,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 85,
    "name": "",
    "country": "JAPAN",
    "category": "SMALL FACE",
    "image_name": "img-0085-japan-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 500,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 86,
    "name": "",
    "country": "JAPAN",
    "category": "SMALL FACE",
    "image_name": "img-0086-japan-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 500,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 87,
    "name": "",
    "country": "JAPAN",
    "category": "SMALL FACE",
    "image_name": "img-0087-japan-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 500,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 88,
    "name": "",
    "country": "JAPAN",
    "category": "MEDIUM FACE",
    "image_name": "img-0088-japan-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 500,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 89,
    "name": "",
    "country": "JAPAN",
    "category": "MEDIUM FACE",
    "image_name": "img-0089-japan-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 500,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 90,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0090-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 91,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0091-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 92,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0092-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 93,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0093-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 94,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0094-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "tongue",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 95,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0095-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 96,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0096-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 97,
    "name": "",
    "country": "CHINA",
    "category": "LARGE FACE",
    "image_name": "img-0097-china-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 1600,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 98,
    "name": "",
    "country": "CHINA",
    "category": "SMALL FACE",
    "image_name": "img-0098-china-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 1600,
    "expression": "anonymous",
    "background": "white",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 99,
    "name": "",
    "country": "CHINA",
    "category": "SMALL FACE",
    "image_name": "img-0099-china-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 1600,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 100,
    "name": "",
    "country": "CHINA",
    "category": "SMALL FACE",
    "image_name": "img-0100-china-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 1600,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 101,
    "name": "",
    "country": "CHINA",
    "category": "MEDIUM FACE",
    "image_name": "img-0101-china-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 1600,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 102,
    "name": "",
    "country": "CHINA",
    "category": "MEDIUM FACE",
    "image_name": "img-0102-china-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 1600,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 103,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0103-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 104,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0104-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "neutral",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 105,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0105-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 106,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0106-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 107,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0107-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 108,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0108-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 109,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0109-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 110,
    "name": "",
    "country": "CANADA",
    "category": "LARGE FACE",
    "image_name": "img-0110-canada-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 180,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 111,
    "name": "",
    "country": "CANADA",
    "category": "SMALL FACE",
    "image_name": "img-0111-canada-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 180,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 112,
    "name": "",
    "country": "CANADA",
    "category": "SMALL FACE",
    "image_name": "img-0112-canada-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 180,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 113,
    "name": "",
    "country": "CANADA",
    "category": "SMALL FACE",
    "image_name": "img-0113-canada-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 180,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 114,
    "name": "",
    "country": "CANADA",
    "category": "MEDIUM FACE",
    "image_name": "img-0114-canada-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 180,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 115,
    "name": "",
    "country": "CANADA",
    "category": "MEDIUM FACE",
    "image_name": "img-0115-canada-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 180,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 116,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0116-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 117,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0117-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 118,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0118-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 119,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0119-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 120,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0120-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "tongue",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 121,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0121-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 122,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0122-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 123,
    "name": "",
    "country": "FRANCE",
    "category": "LARGE FACE",
    "image_name": "img-0123-france-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 290,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 124,
    "name": "",
    "country": "FRANCE",
    "category": "SMALL FACE",
    "image_name": "img-0124-france-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 290,
    "expression": "anonymous",
    "background": "red",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 125,
    "name": "",
    "country": "FRANCE",
    "category": "SMALL FACE",
    "image_name": "img-0125-france-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 290,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 126,
    "name": "",
    "country": "FRANCE",
    "category": "SMALL FACE",
    "image_name": "img-0126-france-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 290,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 127,
    "name": "",
    "country": "FRANCE",
    "category": "MEDIUM FACE",
    "image_name": "img-0127-france-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 290,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 128,
    "name": "",
    "country": "FRANCE",
    "category": "MEDIUM FACE",
    "image_name": "img-0128-france-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 290,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 129,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0129-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 130,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0130-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "neutral",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 131,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0131-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 132,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0132-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 133,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0133-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 134,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0134-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "lover",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 135,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0135-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 136,
    "name": "",
    "country": "GERMANY",
    "category": "LARGE FACE",
    "image_name": "img-0136-germany-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 400,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 137,
    "name": "",
    "country": "GERMANY",
    "category": "SMALL FACE",
    "image_name": "img-0137-germany-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 400,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 138,
    "name": "",
    "country": "GERMANY",
    "category": "SMALL FACE",
    "image_name": "img-0138-germany-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 400,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 139,
    "name": "",
    "country": "GERMANY",
    "category": "SMALL FACE",
    "image_name": "img-0139-germany-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 400,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 140,
    "name": "",
    "country": "GERMANY",
    "category": "MEDIUM FACE",
    "image_name": "img-0140-germany-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 400,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 141,
    "name": "",
    "country": "GERMANY",
    "category": "MEDIUM FACE",
    "image_name": "img-0141-germany-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 400,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 142,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0142-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 143,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0143-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 144,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0144-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 145,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0145-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 146,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0146-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "tongue",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 147,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0147-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 148,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0148-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 149,
    "name": "",
    "country": "INDIA",
    "category": "LARGE FACE",
    "image_name": "img-0149-india-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 300,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 150,
    "name": "",
    "country": "INDIA",
    "category": "SMALL FACE",
    "image_name": "img-0150-india-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "black",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 151,
    "name": "",
    "country": "INDIA",
    "category": "SMALL FACE",
    "image_name": "img-0151-india-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 152,
    "name": "",
    "country": "INDIA",
    "category": "SMALL FACE",
    "image_name": "img-0152-india-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 300,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 153,
    "name": "",
    "country": "INDIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0153-india-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 300,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 154,
    "name": "",
    "country": "INDIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0154-india-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 300,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 155,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0155-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 156,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0156-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 157,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0157-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 158,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0158-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 159,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0159-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 160,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0160-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "lover",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 161,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0161-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 162,
    "name": "",
    "country": "ITALY",
    "category": "LARGE FACE",
    "image_name": "img-0162-italy-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 200,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 163,
    "name": "",
    "country": "ITALY",
    "category": "SMALL FACE",
    "image_name": "img-0163-italy-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 200,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 164,
    "name": "",
    "country": "ITALY",
    "category": "SMALL FACE",
    "image_name": "img-0164-italy-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 200,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 165,
    "name": "",
    "country": "ITALY",
    "category": "SMALL FACE",
    "image_name": "img-0165-italy-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 200,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 166,
    "name": "",
    "country": "ITALY",
    "category": "MEDIUM FACE",
    "image_name": "img-0166-italy-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 200,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 167,
    "name": "",
    "country": "ITALY",
    "category": "MEDIUM FACE",
    "image_name": "img-0167-italy-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 200,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 168,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0168-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 169,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0169-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 170,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0170-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 171,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0171-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 172,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0172-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "tongue",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 173,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0173-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 174,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0174-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 175,
    "name": "",
    "country": "SPAIN",
    "category": "LARGE FACE",
    "image_name": "img-0175-spain-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 176,
    "name": "",
    "country": "SPAIN",
    "category": "SMALL FACE",
    "image_name": "img-0176-spain-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "red",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 177,
    "name": "",
    "country": "SPAIN",
    "category": "SMALL FACE",
    "image_name": "img-0177-spain-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 178,
    "name": "",
    "country": "SPAIN",
    "category": "SMALL FACE",
    "image_name": "img-0178-spain-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 179,
    "name": "",
    "country": "SPAIN",
    "category": "MEDIUM FACE",
    "image_name": "img-0179-spain-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 180,
    "name": "",
    "country": "SPAIN",
    "category": "MEDIUM FACE",
    "image_name": "img-0180-spain-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 181,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0181-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 182,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0182-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "neutral",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 183,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0183-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 184,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0184-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 185,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0185-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 186,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0186-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 187,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0187-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 188,
    "name": "",
    "country": "IRELAND",
    "category": "LARGE FACE",
    "image_name": "img-0188-ireland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 37,
    "expression": "mask on mask",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 189,
    "name": "",
    "country": "IRELAND",
    "category": "SMALL FACE",
    "image_name": "img-0189-ireland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 37,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 190,
    "name": "",
    "country": "IRELAND",
    "category": "SMALL FACE",
    "image_name": "img-0190-ireland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 37,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 191,
    "name": "",
    "country": "IRELAND",
    "category": "SMALL FACE",
    "image_name": "img-0191-ireland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 37,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 192,
    "name": "",
    "country": "IRELAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0192-ireland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 37,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 193,
    "name": "",
    "country": "IRELAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0193-ireland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 37,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 194,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0194-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 195,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0195-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 196,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0196-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 197,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0197-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 198,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0198-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 199,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0199-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 200,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0200-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 201,
    "name": "",
    "country": "COLUMBIA",
    "category": "LARGE FACE",
    "image_name": "img-0201-columbia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 202,
    "name": "",
    "country": "COLUMBIA",
    "category": "SMALL FACE",
    "image_name": "img-0202-columbia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "white",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 203,
    "name": "",
    "country": "COLUMBIA",
    "category": "SMALL FACE",
    "image_name": "img-0203-columbia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 204,
    "name": "",
    "country": "COLUMBIA",
    "category": "SMALL FACE",
    "image_name": "img-0204-columbia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 205,
    "name": "",
    "country": "COLUMBIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0205-columbia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 206,
    "name": "",
    "country": "COLUMBIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0206-columbia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 207,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0207-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 208,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0208-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "neutral",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 209,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0209-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 210,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0210-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "smirk",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 211,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0211-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 212,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0212-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "lover",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 213,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0213-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 214,
    "name": "",
    "country": "MEXICO",
    "category": "LARGE FACE",
    "image_name": "img-0214-mexico-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 120,
    "expression": "mask on mask",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 215,
    "name": "",
    "country": "MEXICO",
    "category": "SMALL FACE",
    "image_name": "img-0215-mexico-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 120,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 216,
    "name": "",
    "country": "MEXICO",
    "category": "SMALL FACE",
    "image_name": "img-0216-mexico-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 120,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 217,
    "name": "",
    "country": "MEXICO",
    "category": "SMALL FACE",
    "image_name": "img-0217-mexico-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 120,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 218,
    "name": "",
    "country": "MEXICO",
    "category": "MEDIUM FACE",
    "image_name": "img-0218-mexico-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 120,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 219,
    "name": "",
    "country": "MEXICO",
    "category": "MEDIUM FACE",
    "image_name": "img-0219-mexico-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 120,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 220,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0220-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "citizen",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 221,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0221-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 222,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0222-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 223,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0223-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 224,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0224-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "tongue",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 225,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0225-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 226,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0226-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 227,
    "name": "",
    "country": "NETHERLANDS",
    "category": "LARGE FACE",
    "image_name": "img-0227-netherlands-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 228,
    "name": "",
    "country": "NETHERLANDS",
    "category": "SMALL FACE",
    "image_name": "img-0228-netherlands-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "gold",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 229,
    "name": "",
    "country": "NETHERLANDS",
    "category": "SMALL FACE",
    "image_name": "img-0229-netherlands-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 230,
    "name": "",
    "country": "NETHERLANDS",
    "category": "SMALL FACE",
    "image_name": "img-0230-netherlands-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 231,
    "name": "",
    "country": "NETHERLANDS",
    "category": "MEDIUM FACE",
    "image_name": "img-0231-netherlands-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 232,
    "name": "",
    "country": "NETHERLANDS",
    "category": "MEDIUM FACE",
    "image_name": "img-0232-netherlands-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 233,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0233-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 234,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0234-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 235,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0235-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 236,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0236-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smirk",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 237,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0237-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 238,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0238-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "lover",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 239,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0239-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 240,
    "name": "",
    "country": "AUSTRALIA",
    "category": "LARGE FACE",
    "image_name": "img-0240-australia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 241,
    "name": "",
    "country": "AUSTRALIA",
    "category": "SMALL FACE",
    "image_name": "img-0241-australia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 242,
    "name": "",
    "country": "AUSTRALIA",
    "category": "SMALL FACE",
    "image_name": "img-0242-australia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 243,
    "name": "",
    "country": "AUSTRALIA",
    "category": "SMALL FACE",
    "image_name": "img-0243-australia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 244,
    "name": "",
    "country": "AUSTRALIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0244-australia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 245,
    "name": "",
    "country": "AUSTRALIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0245-australia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 246,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0272-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 247,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0273-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 248,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0274-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 249,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0275-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 250,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0276-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 251,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0277-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 252,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0278-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 253,
    "name": "",
    "country": "MALTA",
    "category": "LARGE FACE",
    "image_name": "img-0279-malta-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 254,
    "name": "",
    "country": "MALTA",
    "category": "SMALL FACE",
    "image_name": "img-0280-malta-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 255,
    "name": "",
    "country": "MALTA",
    "category": "SMALL FACE",
    "image_name": "img-0281-malta-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 256,
    "name": "",
    "country": "MALTA",
    "category": "SMALL FACE",
    "image_name": "img-0282-malta-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 257,
    "name": "",
    "country": "MALTA",
    "category": "MEDIUM FACE",
    "image_name": "img-0283-malta-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 258,
    "name": "",
    "country": "MALTA",
    "category": "MEDIUM FACE",
    "image_name": "img-0284-malta-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 259,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0298-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "citizen",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 260,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0299-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 261,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0300-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 262,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0301-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 263,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0302-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "tongue",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 264,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0303-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 265,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0304-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 266,
    "name": "",
    "country": "BRAZIL",
    "category": "LARGE FACE",
    "image_name": "img-0305-brazil-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 140,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 267,
    "name": "",
    "country": "BRAZIL",
    "category": "SMALL FACE",
    "image_name": "img-0306-brazil-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 268,
    "name": "",
    "country": "BRAZIL",
    "category": "SMALL FACE",
    "image_name": "img-0307-brazil-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 269,
    "name": "",
    "country": "BRAZIL",
    "category": "SMALL FACE",
    "image_name": "img-0308-brazil-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 140,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 270,
    "name": "",
    "country": "BRAZIL",
    "category": "MEDIUM FACE",
    "image_name": "img-0309-brazil-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 271,
    "name": "",
    "country": "BRAZIL",
    "category": "MEDIUM FACE",
    "image_name": "img-0310-brazil-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 140,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 272,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0337-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 273,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0338-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 274,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0339-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 275,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0340-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 276,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0341-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 277,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0342-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 278,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0343-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 279,
    "name": "",
    "country": "UKRAINE",
    "category": "LARGE FACE",
    "image_name": "img-0344-ukraine-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 280,
    "name": "",
    "country": "UKRAINE",
    "category": "SMALL FACE",
    "image_name": "img-0345-ukraine-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 281,
    "name": "",
    "country": "UKRAINE",
    "category": "SMALL FACE",
    "image_name": "img-0346-ukraine-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 282,
    "name": "",
    "country": "UKRAINE",
    "category": "SMALL FACE",
    "image_name": "img-0347-ukraine-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 283,
    "name": "",
    "country": "UKRAINE",
    "category": "MEDIUM FACE",
    "image_name": "img-0348-ukraine-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 284,
    "name": "",
    "country": "UKRAINE",
    "category": "MEDIUM FACE",
    "image_name": "img-0349-ukraine-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 285,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0363-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 286,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0364-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 287,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0365-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 288,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0366-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 289,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0367-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 290,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0368-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 291,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0369-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 292,
    "name": "",
    "country": "OVATO NATION",
    "category": "LARGE FACE",
    "image_name": "img-0370-ovato-nation-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 293,
    "name": "",
    "country": "OVATO NATION",
    "category": "SMALL FACE",
    "image_name": "img-0371-ovato-nation-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 294,
    "name": "",
    "country": "OVATO NATION",
    "category": "SMALL FACE",
    "image_name": "img-0372-ovato-nation-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 295,
    "name": "",
    "country": "OVATO NATION",
    "category": "SMALL FACE",
    "image_name": "img-0373-ovato-nation-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 296,
    "name": "",
    "country": "OVATO NATION",
    "category": "MEDIUM FACE",
    "image_name": "img-0374-ovato-nation-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 297,
    "name": "",
    "country": "OVATO NATION",
    "category": "MEDIUM FACE",
    "image_name": "img-0375-ovato-nation-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 298,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0376-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 299,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0377-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 300,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0378-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 301,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0379-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 302,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0380-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 303,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0381-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 304,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0382-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 305,
    "name": "",
    "country": "SWITZERLAND",
    "category": "LARGE FACE",
    "image_name": "img-0383-switzerland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 306,
    "name": "",
    "country": "SWITZERLAND",
    "category": "SMALL FACE",
    "image_name": "img-0384-switzerland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "red",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 307,
    "name": "",
    "country": "SWITZERLAND",
    "category": "SMALL FACE",
    "image_name": "img-0385-switzerland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 308,
    "name": "",
    "country": "SWITZERLAND",
    "category": "SMALL FACE",
    "image_name": "img-0386-switzerland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 309,
    "name": "",
    "country": "SWITZERLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0387-switzerland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 310,
    "name": "",
    "country": "SWITZERLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0388-switzerland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 311,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0402-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 312,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0403-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 313,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0404-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 314,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0405-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 315,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0406-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "tongue",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 316,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0407-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 317,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0408-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 318,
    "name": "",
    "country": "AUSTRIA",
    "category": "LARGE FACE",
    "image_name": "img-0409-austria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 47,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 319,
    "name": "",
    "country": "AUSTRIA",
    "category": "SMALL FACE",
    "image_name": "img-0410-austria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 47,
    "expression": "anonymous",
    "background": "red",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 320,
    "name": "",
    "country": "AUSTRIA",
    "category": "SMALL FACE",
    "image_name": "img-0411-austria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 47,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 321,
    "name": "",
    "country": "AUSTRIA",
    "category": "SMALL FACE",
    "image_name": "img-0412-austria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 47,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 322,
    "name": "",
    "country": "AUSTRIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0413-austria-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 47,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 323,
    "name": "",
    "country": "AUSTRIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0414-austria-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 47,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 324,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0415-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 325,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0416-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 326,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0417-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 327,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0418-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 328,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0419-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 329,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0420-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 330,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0421-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 331,
    "name": "",
    "country": "BELARUS",
    "category": "LARGE FACE",
    "image_name": "img-0422-belarus-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 332,
    "name": "",
    "country": "BELARUS",
    "category": "SMALL FACE",
    "image_name": "img-0423-belarus-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 333,
    "name": "",
    "country": "BELARUS",
    "category": "SMALL FACE",
    "image_name": "img-0424-belarus-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 334,
    "name": "",
    "country": "BELARUS",
    "category": "SMALL FACE",
    "image_name": "img-0425-belarus-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 335,
    "name": "",
    "country": "BELARUS",
    "category": "MEDIUM FACE",
    "image_name": "img-0426-belarus-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 336,
    "name": "",
    "country": "BELARUS",
    "category": "MEDIUM FACE",
    "image_name": "img-0427-belarus-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 337,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0428-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 338,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0429-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 339,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0430-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 340,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0431-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 341,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0432-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 342,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0433-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 343,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0434-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 344,
    "name": "",
    "country": "HUNGARY",
    "category": "LARGE FACE",
    "image_name": "img-0435-hungary-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 345,
    "name": "",
    "country": "HUNGARY",
    "category": "SMALL FACE",
    "image_name": "img-0436-hungary-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "red",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 346,
    "name": "",
    "country": "HUNGARY",
    "category": "SMALL FACE",
    "image_name": "img-0437-hungary-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 347,
    "name": "",
    "country": "HUNGARY",
    "category": "SMALL FACE",
    "image_name": "img-0438-hungary-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 348,
    "name": "",
    "country": "HUNGARY",
    "category": "MEDIUM FACE",
    "image_name": "img-0439-hungary-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 349,
    "name": "",
    "country": "HUNGARY",
    "category": "MEDIUM FACE",
    "image_name": "img-0440-hungary-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 350,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0441-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 351,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0442-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 352,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0443-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 353,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0444-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 354,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0445-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 355,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0446-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 356,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0447-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 357,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "LARGE FACE",
    "image_name": "img-0448-luxembourg-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 358,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "SMALL FACE",
    "image_name": "img-0449-luxembourg-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 359,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "SMALL FACE",
    "image_name": "img-0450-luxembourg-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 360,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "SMALL FACE",
    "image_name": "img-0451-luxembourg-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 361,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "MEDIUM FACE",
    "image_name": "img-0452-luxembourg-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 362,
    "name": "",
    "country": "LUXEMBOURG",
    "category": "MEDIUM FACE",
    "image_name": "img-0453-luxembourg-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 363,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0454-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 364,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0455-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 365,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0456-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 366,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0457-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 367,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0458-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 368,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0459-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 369,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0460-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 370,
    "name": "",
    "country": "MONTENEGRO",
    "category": "LARGE FACE",
    "image_name": "img-0461-montenegro-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 371,
    "name": "",
    "country": "MONTENEGRO",
    "category": "SMALL FACE",
    "image_name": "img-0462--montenegro-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 372,
    "name": "",
    "country": "MONTENEGRO",
    "category": "SMALL FACE",
    "image_name": "img-0463-montenegro-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 373,
    "name": "",
    "country": "MONTENEGRO",
    "category": "SMALL FACE",
    "image_name": "img-0464-montenegro-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 374,
    "name": "",
    "country": "MONTENEGRO",
    "category": "MEDIUM FACE",
    "image_name": "img-0465-montenegro-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 375,
    "name": "",
    "country": "MONTENEGRO",
    "category": "MEDIUM FACE",
    "image_name": "img-0466-montenegro-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 376,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0467-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 377,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0468-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 378,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0469-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 379,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0470-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 380,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0471-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 381,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0472-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 382,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0473-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 383,
    "name": "",
    "country": "BULGARIA",
    "category": "LARGE FACE",
    "image_name": "img-0474-bulgaria-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 384,
    "name": "",
    "country": "BULGARIA",
    "category": "SMALL FACE",
    "image_name": "img-0475-bulgaria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 385,
    "name": "",
    "country": "BULGARIA",
    "category": "SMALL FACE",
    "image_name": "img-0476-bulgaria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 386,
    "name": "",
    "country": "BULGARIA",
    "category": "SMALL FACE",
    "image_name": "img-0477-bulgaria-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 387,
    "name": "",
    "country": "BULGARIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0478-bulgaria-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 388,
    "name": "",
    "country": "BULGARIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0479-bulgaria-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 389,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0493-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 390,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0494-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 391,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0495-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 392,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0496-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 393,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0497-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 394,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0498-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 395,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0499-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 396,
    "name": "",
    "country": "HOLY SEE",
    "category": "LARGE FACE",
    "image_name": "img-0500-holy-see-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 397,
    "name": "",
    "country": "HOLY SEE",
    "category": "SMALL FACE",
    "image_name": "img-0501-holy-see-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 398,
    "name": "",
    "country": "HOLY SEE",
    "category": "SMALL FACE",
    "image_name": "img-0502-holy-see-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 399,
    "name": "",
    "country": "HOLY SEE",
    "category": "SMALL FACE",
    "image_name": "img-0503-holy-see-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 400,
    "name": "",
    "country": "HOLY SEE",
    "category": "MEDIUM FACE",
    "image_name": "img-0504-holy-see-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 401,
    "name": "",
    "country": "HOLY SEE",
    "category": "MEDIUM FACE",
    "image_name": "img-0505-holy-see-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 402,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0506-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 403,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0507-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 404,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0508-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 405,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0509-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 406,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0510-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 407,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0511-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 408,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0512-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 409,
    "name": "",
    "country": "CROATIA",
    "category": "LARGE FACE",
    "image_name": "img-0513-croatia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 410,
    "name": "",
    "country": "CROATIA",
    "category": "SMALL FACE",
    "image_name": "img-0514-croatia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "white",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 411,
    "name": "",
    "country": "CROATIA",
    "category": "SMALL FACE",
    "image_name": "img-0515-croatia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 412,
    "name": "",
    "country": "CROATIA",
    "category": "SMALL FACE",
    "image_name": "img-0516-croatia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 413,
    "name": "",
    "country": "CROATIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0517-croatia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 414,
    "name": "",
    "country": "CROATIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0518-croatia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 415,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0519-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 416,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0520-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 417,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0521-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 418,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0522-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 419,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0523-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 420,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0524-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "lover",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 421,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0525-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 422,
    "name": "",
    "country": "NORWAY",
    "category": "LARGE FACE",
    "image_name": "img-0526-norway-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 45,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 423,
    "name": "",
    "country": "NORWAY",
    "category": "SMALL FACE",
    "image_name": "img-0527-norway-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 45,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 424,
    "name": "",
    "country": "NORWAY",
    "category": "SMALL FACE",
    "image_name": "img-0528-norway-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 45,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 425,
    "name": "",
    "country": "NORWAY",
    "category": "SMALL FACE",
    "image_name": "img-0529-norway-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 45,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 426,
    "name": "",
    "country": "NORWAY",
    "category": "MEDIUM FACE",
    "image_name": "img-0530-norway-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 45,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 427,
    "name": "",
    "country": "NORWAY",
    "category": "MEDIUM FACE",
    "image_name": "img-0531-norway-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 45,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 428,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0532-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 429,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0533-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 430,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0534-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 431,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0535-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 432,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0536-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 433,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0537-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 434,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0538-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 435,
    "name": "",
    "country": "SLOVAKIA",
    "category": "LARGE FACE",
    "image_name": "img-0539-slovakia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 436,
    "name": "",
    "country": "SLOVAKIA",
    "category": "SMALL FACE",
    "image_name": "img-0540-slovakia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 437,
    "name": "",
    "country": "SLOVAKIA",
    "category": "SMALL FACE",
    "image_name": "img-0541-slovakia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 438,
    "name": "",
    "country": "SLOVAKIA",
    "category": "SMALL FACE",
    "image_name": "img-0542-slovakia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 439,
    "name": "",
    "country": "SLOVAKIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0543-slovakia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 440,
    "name": "",
    "country": "SLOVAKIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0544-slovakia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 441,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0545-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 442,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0546-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "neutral",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 443,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0547-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 444,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0548-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "smirk",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 445,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0549-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 446,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0550-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "lover",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 447,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0551-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 448,
    "name": "",
    "country": "DENMARK",
    "category": "LARGE FACE",
    "image_name": "img-0552-denmark-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 39,
    "expression": "mask on mask",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 449,
    "name": "",
    "country": "DENMARK",
    "category": "SMALL FACE",
    "image_name": "img-0553-denmark-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 39,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 450,
    "name": "",
    "country": "DENMARK",
    "category": "SMALL FACE",
    "image_name": "img-0554-denmark-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 39,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 451,
    "name": "",
    "country": "DENMARK",
    "category": "SMALL FACE",
    "image_name": "img-0555-denmark-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 39,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 452,
    "name": "",
    "country": "DENMARK",
    "category": "MEDIUM FACE",
    "image_name": "img-0556-denmark-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 39,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 453,
    "name": "",
    "country": "DENMARK",
    "category": "MEDIUM FACE",
    "image_name": "img-0557-denmark-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 39,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 454,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0558-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 455,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0559-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 456,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0560-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 457,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0561-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 458,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0562-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 459,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0563-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 460,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0564-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 461,
    "name": "",
    "country": "FINLAND",
    "category": "LARGE FACE",
    "image_name": "img-0565-finland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 462,
    "name": "",
    "country": "FINLAND",
    "category": "SMALL FACE",
    "image_name": "img-0566-finland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 463,
    "name": "",
    "country": "FINLAND",
    "category": "SMALL FACE",
    "image_name": "img-0567-finland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 464,
    "name": "",
    "country": "FINLAND",
    "category": "SMALL FACE",
    "image_name": "img-0568-finland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 465,
    "name": "",
    "country": "FINLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0569-finland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 466,
    "name": "",
    "country": "FINLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0570-finland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 467,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0571-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 468,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0572-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 469,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0573-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 470,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0574-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 471,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0575-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 472,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0576-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 473,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0577-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 474,
    "name": "",
    "country": "ICELAND",
    "category": "LARGE FACE",
    "image_name": "img-0578-iceland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 475,
    "name": "",
    "country": "ICELAND",
    "category": "SMALL FACE",
    "image_name": "img-0579-iceland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 476,
    "name": "",
    "country": "ICELAND",
    "category": "SMALL FACE",
    "image_name": "img-0580-iceland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 477,
    "name": "",
    "country": "ICELAND",
    "category": "SMALL FACE",
    "image_name": "img-0581-iceland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 478,
    "name": "",
    "country": "ICELAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0582-iceland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "white",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 479,
    "name": "",
    "country": "ICELAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0583-iceland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 480,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0584-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "citizen",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 481,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0585-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 482,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0586-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "smile",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 483,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0587-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 484,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0588-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "tongue",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 485,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0589-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 486,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0590-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 487,
    "name": "",
    "country": "POLAND",
    "category": "LARGE FACE",
    "image_name": "img-0591-poland-full-face",
    "assets": 25000,
    "capacity": 400000,
    "economy": 60,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 488,
    "name": "",
    "country": "POLAND",
    "category": "SMALL FACE",
    "image_name": "img-0592-poland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 60,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 489,
    "name": "",
    "country": "POLAND",
    "category": "SMALL FACE",
    "image_name": "img-0593-poland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 60,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 490,
    "name": "",
    "country": "POLAND",
    "category": "SMALL FACE",
    "image_name": "img-0594-poland-half-face",
    "assets": 5000,
    "capacity": 25000,
    "economy": 60,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 491,
    "name": "",
    "country": "POLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0595-poland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 60,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 492,
    "name": "",
    "country": "POLAND",
    "category": "MEDIUM FACE",
    "image_name": "img-0596-poland-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "economy": 60,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 493,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0597-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 494,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0598-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 495,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0599-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 496,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0600-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 497,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0601-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 498,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0602-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 499,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0603-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 500,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "LARGE FACE",
    "image_name": "img-0604-liechtenstein-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 501,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "SMALL FACE",
    "image_name": "img-0605-liechtenstein-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 502,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "SMALL FACE",
    "image_name": "img-0606-liechtenstein-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 503,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "SMALL FACE",
    "image_name": "img-0607-liechtenstein-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 504,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "MEDIUM FACE",
    "image_name": "img-0608-liechtenstein-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 505,
    "name": "",
    "country": "LIECHTENSTEIN",
    "category": "MEDIUM FACE",
    "image_name": "img-0609-liechtenstein-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 506,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0610-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 507,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0611-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 508,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0612-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 509,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0613-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 510,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0614-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 511,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0615-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 512,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0616-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 513,
    "name": "",
    "country": "MONACO",
    "category": "LARGE FACE",
    "image_name": "img-0617-monaco-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 514,
    "name": "",
    "country": "MONACO",
    "category": "SMALL FACE",
    "image_name": "img-0618-monaco-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "gold",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 515,
    "name": "",
    "country": "MONACO",
    "category": "SMALL FACE",
    "image_name": "img-0619-monaco-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 516,
    "name": "",
    "country": "MONACO",
    "category": "SMALL FACE",
    "image_name": "img-0620-monaco-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 517,
    "name": "",
    "country": "MONACO",
    "category": "MEDIUM FACE",
    "image_name": "img-0621-monaco-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 518,
    "name": "",
    "country": "MONACO",
    "category": "MEDIUM FACE",
    "image_name": "img-0622-monaco-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 519,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0623-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 520,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0624-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 521,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0625-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 522,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0626-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 523,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0627-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 524,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0628-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 525,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0629-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 526,
    "name": "",
    "country": "MOLDOVA",
    "category": "LARGE FACE",
    "image_name": "img-0630-moldova-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "black",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 527,
    "name": "",
    "country": "MOLDOVA",
    "category": "SMALL FACE",
    "image_name": "img-0631-moldova-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 528,
    "name": "",
    "country": "MOLDOVA",
    "category": "SMALL FACE",
    "image_name": "img-0632-moldova-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 529,
    "name": "",
    "country": "MOLDOVA",
    "category": "SMALL FACE",
    "image_name": "img-0633-moldova-half-price",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 530,
    "name": "",
    "country": "MOLDOVA",
    "category": "MEDIUM FACE",
    "image_name": "img-0634-moldova-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "black",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 531,
    "name": "",
    "country": "MOLDOVA",
    "category": "MEDIUM FACE",
    "image_name": "img-0635-moldova-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 532,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0636-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 533,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0637-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 534,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0638-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 535,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0639-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 536,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0640-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 537,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0641-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 538,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0642-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "white",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 539,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "LARGE FACE",
    "image_name": "img-0643-north-macedonia-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 540,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "SMALL FACE",
    "image_name": "img-0644-north-macedonia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 541,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "SMALL FACE",
    "image_name": "img-0645-north-macedonia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 542,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "SMALL FACE",
    "image_name": "img-0646-north-macedonia-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "blue",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 543,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0647-north-macedonia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 544,
    "name": "",
    "country": "NORTH MACEDONIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0648-north-macedonia-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "red",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 545,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0649-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "citizen",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 546,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0650-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 547,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0651-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 548,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0652-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smirk",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 549,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0653-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "tongue",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 550,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0654-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "lover",
    "background": "red",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 551,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0655-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "smile",
    "background": "striped",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 552,
    "name": "",
    "country": "LITHUANIA",
    "category": "LARGE FACE",
    "image_name": "img-0656-lithuania-full-face",
    "assets": 25000,
    "capacity": 400000,
    "expression": "mask on mask",
    "background": "blue",
    "mask_type": "large face",
    "retail_royalties": "50%",
    "dao_participation": 3,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 5000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "30%",
    "memberships_level_2": "25%",
    "nfts_level_2": "25%",
    "merchants_level_2": 30,
    "retail_royalties_level_2": "35%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 553,
    "name": "",
    "country": "LITHUANIA",
    "category": "SMALL FACE",
    "image_name": "img-0657-lithuania-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 554,
    "name": "",
    "country": "LITHUANIA",
    "category": "SMALL FACE",
    "image_name": "img-0658-lithuania-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "yellow",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "sold": true,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 555,
    "name": "",
    "country": "LITHUANIA",
    "category": "SMALL FACE",
    "image_name": "img-0659-lithuania-half-face",
    "assets": 5000,
    "capacity": 25000,
    "expression": "anonymous",
    "background": "striped",
    "mask_type": "small face",
    "retail_royalties": "15%",
    "dao_participation": 1,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 1500,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "10%",
    "memberships_level_2": "15%",
    "nfts_level_2": "15%",
    "merchants_level_2": 20,
    "retail_royalties_level_2": "20%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 556,
    "name": "",
    "country": "LITHUANIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0660-lithuania-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "gold",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  },
  {
    "id": 557,
    "name": "",
    "country": "LITHUANIA",
    "category": "MEDIUM FACE",
    "image_name": "img-0661-lithuania-premium-face",
    "assets": 12000,
    "capacity": 100000,
    "expression": "neutral",
    "background": "striped",
    "mask_type": "horns",
    "retail_royalties": "35%",
    "dao_participation": 2,
    "nft_wallet": "yes",
    "printing_3d": "yes",
    "price": 3000,
    "merchants_level_1": "$10-$100",
    "retail_royalties_level_1": "15%",
    "wallets_level_2": "20%",
    "memberships_level_2": "20%",
    "nfts_level_2": "20%",
    "merchants_level_2": 25,
    "retail_royalties_level_2": "30%",
    "wallets_level_1": "$2-$20",
    "memberships_level_1": "25%",
    "nfts_level_1": "25%"
  }
]