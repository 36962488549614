import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import '../../assets/css/NFTHeaderSeries.css'

// Images
import ImgStartingSoon from '../../assets/img/nft-header-series/img-starting-soon.png'
import ImgAvailableNow from '../../assets/img/nft-header-series/img-available-now.png'

import ImgSmall from '../../assets/img/nft-header-series/img-small.png'
import ImgMedium from '../../assets/img/nft-header-series/img-medium.png'
import ImgLarge from '../../assets/img/nft-header-series/img-large.png'

class NFTHeaderSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    const { t } = this.props;

    return (
        <section className='nft-series-row'>
            <Container>
                <Row>
                    <Col className='col' sm={3}>
                        <div className='nft-series-box'>

                            <div className='nft-series-status'>
                                <img src={ImgStartingSoon} />
                            </div>

                            <div className='nft-series-header'>
                                <div className='nft-series-type'>
                                    Series B
                                </div>
                            </div>

                            <div className='nft-series-title'>
                                "The Innovators"
                            </div>

                            <div className='nft-series-no'>
                                No's 550 - 4000
                            </div>

                            <div className='nft-series-bonus'>
                                <div className='nft-series-bonus-left'></div>
                                <div className='nft-series-bonus-text'>100% Bonus</div>
                                <div className='nft-series-bonus-right'></div>
                            </div>
                            
                        </div>
                    </Col>

                    <Col className='col' sm={5}>
                        <div className='nft-series-box nft-series-box-main'>
                            <div className='nft-series-status'>
                                <img src={ImgAvailableNow} />
                            </div>

                            <div className='nft-series-header'>
                                <div className='nft-series-type'>
                                    Series A
                                </div>

                                <div className='nft-series-type-nos'>
                                    No's 1-550
                                </div>
                            </div>
                            
                            <div className='nft-series-title'>
                                "The Founders"
                            </div>

                            <div className='nft-series-list'>
                                <div className='nft-series-list-img'>
                                    <img src={ImgSmall} />
                                </div>
                                <div className='nft-series-list-info'>
                                    <div className='nft-series-list-title'>Small</div>
                                    <div className='nft-series-list-ovo'>5000 OVO</div>
                                </div>
                            </div>

                            <div className='nft-series-list'>
                                <div className='nft-series-list-img'>
                                    <img src={ImgMedium} />
                                </div>
                                <div className='nft-series-list-info'>
                                    <div className='nft-series-list-title'>Medium</div>
                                    <div className='nft-series-list-ovo'>12,000 OVO</div>
                                </div>
                            </div>

                            <div className='nft-series-list'>
                                <div className='nft-series-list-img'>
                                    <img src={ImgLarge} />
                                </div>
                                <div className='nft-series-list-info'>
                                    <div className='nft-series-list-title'>Large</div>
                                    <div className='nft-series-list-ovo'>25,000 OVO</div>
                                </div>
                            </div>

                            <div className='nft-series-bonus'>
                                <div className='nft-series-bonus-left'></div>
                                <div className='nft-series-bonus-text'>200% Bonus</div>
                                <div className='nft-series-bonus-right'></div>
                            </div>
                        </div>
                    </Col>

                    <Col className='col' sm={3}>
                        <div className='nft-series-box'>

                            <div className='nft-series-header'>
                                <div className='nft-series-type'>
                                    Series C
                                </div>
                            </div>

                            <div className='nft-series-title'>
                                "The Community"
                            </div>

                            <div className='nft-series-no'>
                                No's 4000 - 10,000
                            </div>

                            <div className='nft-series-coverage'>
                                100% Coverage
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
  }
}
export default withTranslation()(NFTHeaderSeries);