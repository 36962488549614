import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import {
  Container,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import BrandWrapper from './BrandWrapper';
class BrandBoxContainer extends Component {
  render() {
    const title = this.props.title;
    const record = this.props.record;
    const customCSS = this.props.className || ''
    return (
      <>
      <div className={'container brand-box-new-container ' + customCSS}>
        <Row className='brand-box-new-header'>
            <Col>
                <h2 className='brand-box-new-title'><span>{title}</span></h2>
            </Col>
        </Row>
        <Row className='brand-box-new-body'>
            { record && record.map((value, index) => (
                <Col key={value} className='brand-box-new-col'> 
                    <BrandWrapper 
                        image={value.image} 
                        title={value.title} 
                        subtitle={value.subtitle} 
                        downloads={value.downloads} 
                    />
                </Col>
            ))}
        </Row>
    </div>
      </>
    );
  }
}
export default withTranslation()(BrandBoxContainer);
