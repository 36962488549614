import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Components
import RelaunchingPopup from '../components/popup/RelaunchingPopup';

// CSS
import '../assets/css/Dao.css'

// Images
import imgOvato from '../assets/img/dao/img-ovato.png'
import imgDaoMobile from '../assets/img/dao/img-dao-mobile.png'
import imgTransparent from '../assets/img/dao/img-transparent.png'


class Dao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletmodal: false,
      fade: false,
      merchantmodal: false,
      modal: false
    };
  }
  toggleWallet = () => {
    this.setState({ walletmodal: !this.state.walletmodal });
  };
  toggleMerchant = () => {
    this.setState({ merchantmodal: !this.state.merchantmodal });
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <div className='blue-nav dao-main'>
        {/* Header */}
        <TopNav />

        {/* Section 1 */}
        <section className='new-first-row v2-pb-6 v2-m-pb-4 dao-1'>
            <Container>
                <Row className='content-end'>
                    <Col className='v2-tp-col-12' sm={7}>
                        <h1 className='v2-font-36 poppins v2-tp-font-32 v2-m-font-28 v2-tp-text-center bold dark-text-5 v2-mb-0 v2-mt-2 v2-m-mt-0'>{t('dao2.welcome')}</h1>

                        <p className='poppins v2-font-21 v2-tp-font-20 v2-m-font-18 bold dark-text-5 v2-mb-1 v2-tp-text-center lh-1-2'>{t('dao2.decentralized')}</p>

                        <p className='poppins v2-font-16 v2-tp-font-15 v2-tp-text-center light dark-text-3'>{t('dao2.at')}</p>

                        <div className='v2-tp-text-center v2-tp-mt-2 img-wrap mobile'>
                            <img src={imgDaoMobile} width={250} />
                        </div>

                        <h2 className='poppins v2-font-26 v2-tp-font-24 v2-m-font-22 v2-tp-text-center bold dark-text-5 v2-mb-1 v2-mt-3 v2-m-mt-2'>{t('dao2.what')}</h2>
                        <p className='poppins v2-font-16 light dark-text-3 v2-tp-font-15 v2-tp-text-center'>{t('dao2.ovato')}</p>
                    </Col>

                    <Col className='v2-tp-col-12' sm={5}>
                        <div className='v2-tp-text-center v2-tp-mt-2 img-wrap desktop'>
                            <img src={imgOvato} width={331} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 2 */}
        <section className='white-top-curve-13 v2-pt-12 v2-tl-pt-10 v2-tp-pt-8 v2-m-pt-6 v2-mb--10 v2-m-mb-0 dao-2'>
            <Container className='relative z-index-2'>
                <Row>
                    <Col>
                        <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-5 text-center'>{t('dao2.key')}</h2>
                    </Col>
                </Row>

                <Row className='v2-mt-4'>
                    <Col className='v2-pl-3 v2-pr-3 v2-tp-col-12'>
                        <div className='text-center img-wrap'>
                            <img width={125} src={imgTransparent} />
                        </div>

                        <h3 className='poppins v2-font-22 v2-m-font-20 bold dark-text-5 text-center'>{t('dao2.transparent')} <br /> {t('dao2.decision')}</h3>

                        <p className='poppins v2-font-16 v2-tp-font-15  light dark-text-6 text-center'>{t('dao2.all')}</p>
                    </Col>

                    <Col className='v2-pl-3 v2-pr-3 v2-tp-col-12 v2-tp-mt-3'>
                        <div className='text-center img-wrap'>
                            <img width={125} src={imgTransparent} />
                        </div>

                        <h3 className='poppins v2-font-22 v2-m-font-20 bold dark-text-5 text-center'>{t('dao2.decentralized2')} <br /> {t('dao2.governance')}</h3>

                        <p className='poppins v2-font-16 v2-tp-font-15 light dark-text-6 text-center'>{t('dao2.ovatoCoin')}</p>
                    </Col>

                    <Col className='v2-pl-3 v2-pr-3 v2-tp-col-12 v2-tp-mt-3'>
                        <div className='text-center img-wrap'>
                            <img width={125} src={imgTransparent} />
                        </div>

                        <h3 className='poppins v2-font-22 v2-m-font-20 bold dark-text-5 text-center'>{t('dao2.allocation')} <br /> {t('dao2.of')}</h3>

                        <p className='poppins v2-font-16 v2-tp-font-15 light dark-text-6 text-center'>{t('dao2.ovatoDao')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='v2-mt-8 v2-tp-mt-4 divider'></div>
                    </Col>
                </Row>

                <Row className='v2-mt-6 v2-tp-mt-4'>
                    <Col>
                        <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-24 bold dark-text-5 text-center'>{t('dao2.how')}</h2>

                        <Row className='v2-mt-4 dao-2-blue-box'>
                            <Col>
                                <div className='num'>1</div>
                                <h3 className='dao-2-blue-box-title'>{t('dao2.proposal')}</h3>
                            </Col>
                            <Col>
                                <p className='dao-2-blue-box-text'>{t('dao2.any')}</p>
                            </Col>
                        </Row>

                        <Row className='dao-2-blue-box'>
                            <Col>
                                <div className='num'>2</div>
                                <h3 className='dao-2-blue-box-title'>{t('dao2.voting')}</h3>
                            </Col>
                            <Col>
                                <p className='dao-2-blue-box-text'>{t('dao2.once')}</p>
                            </Col>
                        </Row>

                        <Row className='dao-2-blue-box'>
                            <Col>
                                <div className='num'>3</div>
                                <h3 className='dao-2-blue-box-title'>{t('dao2.execution')}</h3>
                            </Col>
                            <Col>
                                <p className='dao-2-blue-box-text'> {t('dao2.if')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className='v2-mt-6 v2-m-mt-3'>
                    <Col>
                        <div className='dao-2-box'>
                            <h2 className='v2-font-22 poppins bold white-text'>{t('dao2.join')}</h2>

                            <p className='v2-mt-1-5 poppins v2-font-16 v2-m-font-15 regular white-text'>{t('dao2.and')}</p>

                            <div className='v2-mt-2 btn-wrap'>
                                <a href="https://wallet.ovato.com/" target="_blank" class="custom-new-btn dao-btn">{t('dao2.get')}</a>
                            </div>
                        </div>

                        <p className='poppins v2-font-16 v2-m-font-15 light blue-text-9 text-center v2-mt-2'>{t('dao2.stay')}</p>
                    </Col>
                </Row>
            </Container>
        </section>
        
        {/* Footer */}
        <BottomNav/>

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </div>
    );
  }
}
export default withTranslation()(Dao);
