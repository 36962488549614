import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Generate random values for each month from January 2024 to December 2024
const generateRandomData = () => {
    const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const data = [];
  
    for (let i = 0; i < 12; i++) {
      const month = months[i];
      const value = Math.floor(Math.random() * 5000); // Generate a random value between 0 and 5000
      const year = 2024;
      const name = `${month} ${year}`;
      data.push({ name, ovo: value });
    }
  
    return data;
  };

const data = generateRandomData()

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0" stroke="#83ACBE"/>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="linear" dataKey="ovo" stroke="#2E91C4" fill="#E3F0F7" strokeWidth={2} />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
