import React, { Component } from 'react'

import TopNav from '../containers/NewTopNav3';
import BottomNav from '../containers/NewBottomNav3';

import Section1 from '../components/new-home-3/Section1';
import Section2 from '../components/new-home-3/Section2';
import Section3 from '../components/new-home-3/Section3';
import Section4 from '../components/new-home-3/Section4';
import Section5 from '../components/new-home-3/Section5';

// CSS 
import '../assets/css/Home3.css'

export default class NewHome extends Component {
  render() {
    return (
      <div className='new-home-main-3 v3-home-3'>
        <TopNav />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <BottomNav />
      </div>
    )
  }
}