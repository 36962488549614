import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgInvestTrade from '../assets/img/img-invest-trade-9.png';
import imgOwardsRewards from '../assets/img/img-owards-rewards-9.png';
import imgBitovation from '../assets/img/img-bitovation-9.png';
class Section9 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='section-9-new pt-100 pb-100 desktop'>
          <Container>
            <Row className='section-9-row-1'>
              <Col>
                <h2 className='font-57 extra-bold text-uppercase blue-text text-center mb-10'>
                  {t('section9.three_ways')}
                </h2>
              </Col>
            </Row>
            <Row className='mt-90 section-9-row-2'>
              <Col className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>1</div>
                  <h3>{t('section9.invest_and_trade')}</h3>
                  <ul>
                    <li>
                    {t('section9.list1')}
                    </li>
                  </ul>
                  <h4 className='mt-15'>{t('section9.esrn_more')}</h4>
                  <ul className='mt-15'>
                    <li>
                    {t('section9.list2')}
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-40'>
                    <img src={imgInvestTrade} alt='Invest and Trade' />
                  </div>
                </div>
              </Col>
              <Col className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>2</div>
                  <h3>{t('section9.earn')}</h3>
                  <ul>
                    <li>{t('section9.list3')}</li>
                    <li>
                    {t('section9.list4')}
                    </li>
                    <li>
                    {t('section9.list5')}
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-15'>
                    <img
                      src={imgOwardsRewards}
                      alt='Owards Rewards'
                      width='200'
                    />
                  </div>
                </div>
              </Col>
              <Col className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>3</div>
                  <h3>{t('section9.partner')}</h3>
                  <ul>
                    <li>{t('section9.list6')}</li>
                    <li>{t('section9.list7')}</li>
                    <li>
                    {t('section9.list8')} <a href='/'>{t('section9.list9')}</a>
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-40'>
                    <img src={imgBitovation} alt='Bitovation' width='225' />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-9-new pt-60 pb-60 mobile'>
          <Container>
            <Row>
              <Col>
                <h2 className='font-50 extra-bold text-uppercase blue-text text-center mb-10 lh-1'>
                  {t('section9.three_ways')}
                </h2>
              </Col>
            </Row>
            <Row className='mt-90'>
              <Col md={12} className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>1</div>
                  <h3>{t('section9.invest_and_trade')}</h3>
                  <ul>
                    <li>
                    {t('section9.list1')}
                    </li>
                  </ul>
                  <h4 className='mt-15'>{t('section9.esrn_more')}</h4>
                  <ul className='mt-15'>
                    <li>
                    {t('section9.list2')}
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-40'>
                    <img src={imgInvestTrade} alt='Invest and Trade' />
                  </div>
                </div>
              </Col>
              <Col md={12} className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>2</div>
                  <h3>{t('section9.earn')}</h3>
                  <ul>
                    <li>{t('section9.list3')}</li>
                    <li>
                    {t('section9.list4')}
                    </li>
                    <li>
                    {t('section9.list5')}
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-15'>
                    <img
                      src={imgOwardsRewards}
                      alt='Owards Rewards'
                      width='200'
                    />
                  </div>
                </div>
              </Col>
              <Col md={12} className='section-9-box'>
                <div className='col-inner'>
                  <div className='col-num'>3</div>
                  <h3>{t('section9.partner')}</h3>
                  <ul>
                    <li>{t('section9.list6')}</li>
                    <li>{t('section9.list7')}</li>
                    <li>
                    {t('section9.list8')} <a href='/'>{t('section9.list9')}</a>
                    </li>
                  </ul>
                  <div className='img-wrap text-center mt-40'>
                    <img src={imgBitovation} alt='Bitovation' width='225' />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section9);
