import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
export default class NotFound extends Component {
  render() {
    return (
      <>
        <TopNav />
        <div className='support_main p-5'>
          <Container className='text-center'>
            <h1 className='text-center' style={{ fontSize: '3rem' }}>
              404 Not found{' '}
            </h1>
            <a href='/'>
              <Button className='pink-btn w-25'>Return to Homepage</Button>
            </a>
          </Container>
        </div>
        <BottomNav />
      </>
    );
  }
}
