import React, { Component, useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import { withTranslation } from 'react-i18next';
class PressBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        modal: false,
        fade: false,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
        modal: !this.state.modal,
        });
    }
  render() {
    const { t } = this.props;
    const image = this.props.image;
    const title = this.props.title;
    const date = this.props.date;
    const content = this.props.content
    const link = this.props.link
    return (
      <>
        <div className='press-box-wrap'>
            <div className='press-box-inner'>
                <div className='press-box-img'>
                    <img src={image} alt='img' />
                </div>
                <div className='press-box-body'>
                    <h3 className='press-box-title'>
                        {title}
                    </h3>
                    <div className='press-box-date'>{date}</div>
                    <div className='press-box-content'>
                        <p>{content}</p>
                    </div>
                    <div className='press-box-cta'>
                        <a href={link} target='_blank' className='custom-new-btn custom-new-btn-transparent custom-new-btn-small press-box-btn'>{ t('newPress.read') }</a>
                    </div>
                </div>
            </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          fade={this.state.fade}
          toggle={this.toggle}
          size='lg'
          className='modal-press' 
        >
            <ModalBody>
                <div className='modal-press-close-wrap'>
                    <a onClick={this.toggle} className='modal-press-close'><img src={require('../assets/img/img-close-modal.png')} alt='close'/></a>
                </div>
                <div className='modal-press-img'>
                    <img src={image} alt='img' />
                </div>
                <div className='modal-press-body'>
                    <h3 className='modal-press-title'>{title}</h3>
                    <div className='modal-press-date'>{date}</div>
                    <p className='modal-press-content'>{content}</p>
                </div>
            </ModalBody>
        </Modal>
      </>
    );
  }
}
export default withTranslation()(PressBox);
