import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ImgOvato from '../assets/img/logo-ltsh-ovato.png'
import Img2023 from '../assets/img/img-ltsh-2023.png'
import img2 from '../assets/img/img-ltsh-2.png'
import img3Left from '../assets/img/img-ltsh-3-left.png'
import img3LeftMobile from '../assets/img/img-ltsh-3-left-mobile.png'
import img3Right from '../assets/img/img-ltsh-3-right.png'
import img3Ovato from '../assets/img/img-ltsh-3-ovato-nation.png'
import img4Govato from '../assets/img/img-ltsh-4-govato.png'
import imgWallets from '../assets/img/img-ltsh-wallets.png'
import imgMembership from '../assets/img/img-ltsh-membership.png'
import imgNFTs from '../assets/img/img-ltsh-nfts.png'
import imgCoinSales from '../assets/img/img-ltsh-coin-sales.png'
import imgMerchants from '../assets/img/img-ltsh-merchants.png'
import imgRoyalties from '../assets/img/img-ltsh-royalties.png'
import imgProbit from '../assets/img/img-ltsh-probit.png'
import imgP2P from '../assets/img/img-ltsh-p2p.png'
import imgWhitebit from '../assets/img/img-ltsh-whitebit.png'
import imgLbank from '../assets/img/img-ltsh-lbank.png'
import imgOpenSea from '../assets/img/img-ltsh-open-sea.png'
import img7 from '../assets/img/img-ltsh-7.png'
import img8_1 from '../assets/img/img-ltsh-8-1.png'
import img8_2 from '../assets/img/img-ltsh-8-2.png'
import img8Twitter from '../assets/img/icon-ltsh-8-twitter.png'
import img9 from '../assets/img/img-ltsh-9.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
class LettersToStakeholders extends Component {
  render() {
    const { t } = this.props;
    const sliders = [
      { title: 'Wallets', content: [{heading: 'Earn $2 to $20', paragraph: 'Earn $2 to $20 for every free wallet installed '}], img: imgWallets },
      { title: 'Membership', content: [{heading: 'Earn 50%', paragraph: 'Memberships Earn 50% on all membership sales in perpetuity. A great way to earn reoccurring money'}], img: imgMembership },
      { title: 'NFTs', content: [{heading: 'Earn 25%', paragraph: 'Everything in membership plus, Earn 25% on all NFT sales'}], img: imgNFTs },
      { title: 'Coin Sales', content: [{heading: 'And 10%', paragraph: 'Qualified plus:'}, {heading: '', paragraph: 'Earn 10% on your downlines stocking'}], img: imgCoinSales },
      { title: 'Merchants', content: [{heading: 'Earn $100', paragraph: 'Accredited plus,'}, {heading: '', paragraph: 'Open up merchants and receive $100 bounty for every merchant that signs up. It\'s easy and once you\'ve signed up 3 you earn a percentage of all the revenue as an owlet '}], img: imgMerchants },
      { title: 'Royalties', content: [{heading: 'Earn 4%', paragraph: 'in perpetuity on all NFTs sales'}, {heading: 'Earn 10%', paragraph: 'on all merchant rebates'}], img: imgRoyalties },
    ];
    const mobileSlider = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className='ltsh-main'>
        {/* SECTION 1 */}
        <section className='ltsh-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='img-wrap ltsh-1-img-wrap'>
                            <a href='/' target='_blank'><img src={ImgOvato} /></a>
                        </div>
                    </Col>
                </Row>
                <Row className='align-center'>
                    <Col>
                        <h1 className='lh-1 poppins n-font-69 bold dark-text-5'>Letter to Stakeholders</h1>
                        <h2 className='poppins n-font-30 bold dark-text-5 ltsh-1-h2'><span className='w-double-underline'>Overview</span></h2>
                        <p className='mt-20 poppins n-font-16 regular dark-text-5'>It has been a very interesting year for Ovato and the industry in general. We have never been more convinced of our approach. We offer a friendly alternative solution to the medium of an exchange. Additionally, the company’s stacking program is proving to be a big success yielding 30% APR be it in the form of coins or value. Ovato’s primary purpose is to build an alternative currency and provide a legitimate solution of storing and exchanging value. To that end we are committed to providing actual utility in ecosystems that add value to participants along the way.</p>
                    </Col>
                    <Col>
                        <div className='img-wrap ltsh-1-img-right'>
                            <img src={Img2023} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 2 */}
        <section className='ltsh-2'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <div className='img-wrap ltsh-2-img-left'>
                            <img src={img2} />
                        </div>
                    </Col>
                    <Col>
                        <h2 className='poppins n-font-35 bold dark-text-5 ltsh-1-h2'><span className='w-double-underline w-double-underline-2'>The Industry and Outlook</span></h2>
                        <p className='mt-20 poppins n-font-15 light dark-text-5'>We have all witnessed many vulnerabilities this year in our industry. Allow us to highlight that these problems were created almost exclusively at the Exchange level. While it is truly unfortunate and an extreme interruption to the "Buzz" of the entire Blockchain industry; it really has nothing to do with "the movement" or the trustworthiness of technology or the underlying desire of people everywhere to take their financial matters into their own hands. Also, it is very important to remember that when you place your coins onto an exchange, you are generally giving your coins to the exchange to conduct your business on your behalf. As the saying goes "Not your keys, not your money".  By its very nature, this conflicts with an issuer and their agenda of moving people from the "Old World" (centralized approach) to this "New World" (Decentralized Approach"). Ovato's position is that Exchanges are not a long-term store of value but rather a medium to trade.</p>  
<p className='mt-20 poppins n-font-15 light dark-text-5'>Additionally, with government deficits, global inflation and a global recession likely, our Stacking program is an industry leader yielding 30% APR for either value or coins. People are now beginning to truly evaluate alternative means of storing value. 
</p>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 3 */}
        <section className='ltsh-3'>
            <Container>
                <Row className='align-center'>
                    
                    <Col>
                        <h2 className='poppins n-font-35 bold dark-text-5 ltsh-3-h2'><span className='w-double-underline w-double-underline-2'>NFTs Going live:</span></h2>
                        <p className='mt-20 poppins n-font-15 light dark-text-5'>The Ovato NFT collection is launching in January, and we believe the collection provides a lot of “industry firsts”. We will be opening it up to the community 1 week prior to its worldwide release on Opensea. It promises to be the first of its kind with our NFTs truly having utility with inherent storage, rights and royalties included inside.</p>  
                        <div className='img-wrap desktop ltsh-3-img-left'>
                            <img src={img3Left} />
                        </div>
                        <div className='img-wrap mobile ltsh-3-img-left'>
                            <img src={img3LeftMobile} />
                        </div>
                    </Col>
                    <Col>
                        <div className='img-wrap ltsh-3-img-right'>
                            <img src={img3Right} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className='poppins text-center n-font-32 semi-bold dark-text-5 ltsh-3-h2-2'><span className='w-double-underline w-double-underline-2'>Your immediate action required Your Loyalty NFT must be collected...</span></h2>
                    </Col>
                </Row>
                <Row className='align-center'>
                    <Col md={5}>
                        <div className='img-wrap ltsh-3-img-bottom text-center'>
                            <img src={img3Ovato} />
                        </div>
                    </Col>
                    <Col md={7}>
                        <p className='poppins n-font-15 light dark-text-3 text-underline'>Your Loyalty NFTs are being minted must be claimed by January 9,2023.</p>
                        <p className='poppins n-font-15 light dark-text-3'>Each "Loyalty NFT" can hold anywhere from 100 to 1000 OVO and represent a great store of value. We believe that our NFT collection will trade at a premium with Ovato holdings from the royalties, Ovato inside, and territory exclusivity associated with each NFT.</p>
                        <p className='poppins n-font-15 light dark-text-3 text-underline'>Please chat ovato.com or contact a representative prior to January 9,2023 to collect and allocate holdings amount</p>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 4 */}
        <section className='ltsh-4'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <div className='img-wrap ltsh-4-img-wrap'>
                            <a href='https://govato.io/' target='_blank'><img src={img4Govato} /></a>
                        </div>
                        <h2 className='poppins n-font-57 bold white-text text-center mt-0 lh-1'>Going Live</h2>
                    </Col>
                    <Col>
                        <p className='poppins n-font-16 light white-text ltsh-4-p'>As you may know the marketing arm for Ovato is Govato (govato.io) and is going live in the next week. Members of Govato can earn money in a variety of forms from sharing content and promoting the wallets to promoting NFT sales and setting up merchants. The basic membership is $50 per month or free for NFT holders. Govato has tons of giveaways, prizes and swag to promote Ovato.</p>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 5 */}
        <section className='ltsh-5'>
            <Container>
                <Row className='align-center desktop'>
                    <Col>
                        <div className='ltsh-5-box-wrap'>
                            {/* WALLETS */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-1'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>Wallets</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn $2 to $20 </h4>
                                        <p>Earn $2 to $20 for every free wallet installed </p>
                                        <div className='img-wrap'>
                                            <img src={imgWallets} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Membership */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-2'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>Membership</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn 50% </h4>
                                        <p>Memberships Earn 50% on all membership sales in perpetuity. A great way to earn reoccurring money</p>
                                        <div className='img-wrap'>
                                            <img src={imgMembership} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NFTs */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-3'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>NFTs</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn 25% </h4>
                                        <p>Everything in membership plus, Earn 25% on all NFT sales</p>
                                        <div className='img-wrap'>
                                            <img src={imgNFTs} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Coin Sales */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-4'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>Coin Sales</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn 10% </h4>
                                        <p>Qualified plus:</p>
                                        <p>Earn 10% on your downlines stocking</p>
                                        <div className='img-wrap'>
                                            <img src={imgCoinSales} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Merchants */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-5'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>Merchants</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn $100 </h4>
                                        <p>Accredited plus,</p>
                                        <p>Open up merchants and receive $100 bounty for every merchant that signs up. It’s easy and once you’ve signed up 3 you earn a percentage of all the revenue as an owlet</p>
                                        <div className='img-wrap'>
                                            <img src={imgMerchants} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Royalties */}
                            <div className='ltsh-5-box-col ltsh-5-box-col-6'>
                                <div className='ltsh-5-box-col-inner'>
                                    <h3>Royalties</h3>
                                    <div className='ltsh-5-box-col-blue'>
                                        <h4>Earn 4%</h4>
                                        <p>in perpetuity on all NFTs sales</p>
                                        <h4>Earn 10%</h4>
                                        <p>on all merchant rebates</p>
                                        <div className='img-wrap'>
                                            <img src={imgRoyalties} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='mobile'>
                    <Col>
                        <Slider
                            {...mobileSlider}
                            className='ltsh-5-box-wrap'
                        >
                            {sliders.map((slider) => (
                                <div className='ltsh-5-box-col ltsh-5-box-col-6'>
                                    <div className='ltsh-5-box-col-inner'>
                                        <h3>{slider.title}</h3>
                                        <div className='ltsh-5-box-col-blue'>
                                            
                                            { slider.content.map((content) => (
                                                <>
                                                    <h4>{ content.heading }</h4>
                                                    <p>{ content.paragraph }</p>
                                                </>
                                            )) }
                                            
                                            <div className='img-wrap'>
                                                <img src={slider.img} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 6 */}
        <section className='ltsh-6'>
            <Container>
                <Row className='align-center'>
                    <Col className='ltsh-6-col-left'>
                        <h2 className='poppins n-font-32 semi-bold dark-text-5 ltsh-6-h2-2'><span className='w-double-underline w-double-underline-2'>Exchanges</span></h2>
                        <p className='poppins n-font-15 light dark-text-3'>As we mentioned, the “Exchange Marketplace” is going through some very significant changes and is in a very fluid situation. Ovato is committed to its approach of partnering with like-minded credible and trustworthy exchanges. We are currently listed on 4 with an additional 2 top 5 exchanges currently in the works. As for our Utility NFT collection, that will premiere on Opensea.</p>
                    </Col>
                    <Col className='ltsh-6-col-right'>
                        <Row>
                            <Col>
                                <div className='img-wrap'>
                                    <a href='http://probit.com/' target='_blank'><img src={imgProbit} /></a>
                                </div>
                            </Col>
                            <Col>
                                <div className='img-wrap'>
                                    <a href='http://p2pb2b.io/' target='_blank'><img src={imgP2P} /></a>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='img-wrap'>
                                    <a href='http://whitebit.com/' target='_blank'><img src={imgWhitebit} /></a>
                                </div>
                            </Col>
                            <Col>
                                <div className='img-wrap'>
                                    <a href='https://www.lbank.info/' target='_blank'><img src={imgLbank} /></a>
                                </div>
                            </Col>
                        </Row>
                        <Row className='ltsh-6-col-right-row-3'>
                            <Col>
                                <div className='img-wrap img-wrap-full'>
                                    <a href='' target='_blank'><img src={imgOpenSea} /></a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 7 */}
        <section className='ltsh-7'>
            <Container>
                <Row className='align-center'>
                    <Col md={5}>
                        <div className='img-wrap ltsh-7-img-wrap'>
                            <img src={img7} />
                        </div>
                    </Col>
                    <Col md={7}>
                        <h2 className='poppins n-font-32 semi-bold dark-text-5 ltsh-6-h2-2'><span className='w-double-underline w-double-underline-2'>Stacking Program</span></h2>
                        <p className='poppins n-font-15 light dark-text-3 mt-30'>If you are not currently in the stacking program but plan to hold your coins, then you are missing out. Ovato has one of the best stacking programs in the industry, earning participants 30% per year.</p>
                        <p className='poppins n-font-15 light dark-text-3'>The Hard Stack program will begin to be phased out in Q1 and all stacking will be completely self-administered directly through your own wallets.</p>
                        <p className='poppins n-font-15 light dark-text-3'>For those still unfamiliar with the stacking program. The program allows participants to lock in rates of returns for periods anywhere from 10 days (for merchants) and up to 1 year for users. The entire program is “Off Chain” to protect the network speed and done through the stacking pool.</p>
                    </Col>
                </Row>
            </Container>
            
        </section>
        {/* SECTION 8 */}
        <section className='ltsh-8'>
            <Container>
                <Row>
                    <Col>
                        <Row className='ltsh-8-row-1'>
                            <Col>
                                <h2 className='poppins n-font-35 semi-bold white-text text-center'>Take Part Twitter Spaces</h2>
                                <p className='poppins n-font-17 light white-text text-center'>Govato will be holding weekly meetings via audio participation to answer questions regarding earning and partnering with Ovato.</p> 
                                <p className='poppins n-font-17 light white-text text-center'>Please join us on our first meeting:
                                Thursday 7pm EST, 12am GMT on January 3, 2023
                                </p>
                            </Col>
                            <Col>
                                <div className='img-wrap ltsh-8-img-twitter'>
                                    <img src={img8Twitter} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-32 semi-bold dark-text-5 ltsh-8-h2 text-center'><span className='w-double-underline w-double-underline-2'>Peer to Peer Transactions</span></h2>
                        <p className='poppins n-font-15 light dark-text-3 mt-30 text-center ltsh-8-p'>We are seeing a rise in Peer-to-Peer transactions and encourage coin holders to participate, be it in the form seller or buyer. If you need help locating one, let us know and we will help facilitate. Additionally, by participating in Govato it allows participants a phenomenal way to experience exchanging value with Ovato while earning and helping us all grow our economy. </p>
                        <div className='text-center img-wrap ltsh-8-img-wrap-1'>
                            <img src={img8_1} />
                        </div>
                        <div className='text-center img-wrap ltsh-8-img-wrap-2'>
                            <img src={img8_2} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 9 */}
        <section className='ltsh-9'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <h2 className='poppins n-font-33 semi-bold white-text ltsh-9-h2'>Messaging and Scope of Service</h2>
                        <p className='poppins n-font-16 light white-text mt-30'>Effective January 1, 2023, we are moving communications from support@ovato.com to online chat.  We have opened our online chat platform to answer any questions you have. Email service will continue to operate, however not in a real time capacity. For real time service please go online.</p>
                        <p className='poppins n-font-16 light white-text'>Note: Please understand that Ovato is a true decentralized currency and the operators on the chat platform are stakeholders themselves and participating to help grow the Ovato economy and relay the information back to Ovato Core Foundation. If you discover problems… great! We pay you bounties to you to help identify them. </p>
                        <p className='poppins n-font-16 light white-text mt-30'>Report a bug <br /> 
                        <a className='white-text text-underline' href='https://ovato.com/en/bug-bounty' target='_blank'>https://ovato.com/en/bug-bounty</a></p>
                        <p className='poppins n-font-16 light white-text mt-30'>Yours truly, <br />
                            Ovato Core Foundation
                        </p>
                    </Col>
                    <Col>
                        <div className='img-wrap ltsh-9-img-wrap'>
                            <img src={img9} />
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </section>
      </div>
    );
  }
}
export default withTranslation()(LettersToStakeholders);
