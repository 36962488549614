import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios

import { Service } from "../providers/Services";

// Components
import CheckoutForm from "../components/checkout/CheckoutStripeForm";

import { WALLET_URL } from '../constants/defaultValues';
import Header from "../containers/NewTopNav3";
import Footer from "../containers/NewBottomNav3";

// CSS
import '../assets/css/Checkout.css'
import { Col, Container, Row } from "reactstrap";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51PN5FUIyXXACTIES4DbZIul9uLjvD8Eb3VmPh8iwMbWi1RcSZOdNqnLWii2sPzVEYEwPWPgzAQ4iFfZXVN9kKeDT00cIsaOt2r");

export default function CheckoutStripe() {

  const [clientSecret, setClientSecret] = useState("");
  const [data, setData] = useState("");

  const { id } = useParams();

  // Set requested data
  useEffect(() => {
    Service.getNFT(id)
    .then(res => {
        setData(res)
        console.log('data', res)
    })
    .catch(err => {
        console.error('Error fetching data:', err);
    })
  }, [])

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch(WALLET_URL + 'nfts/create_intent', {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json", 'Authorization': `Bearer ` + localStorage.getItem('x-access-token') },
    //   body: JSON.stringify({ nft: '666917cf2975787a2d34989c', payment_mode: 0 }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));

    axios.post(
      WALLET_URL + 'nfts/create_intent', 
      {
          // amount,
          // currency: 'usd','
          // nft: '666917cf2975787a2d34989c',
          nft: id,
          payment_mode: 0
      },
      {
          headers: {
          'Authorization': `Bearer ` + localStorage.getItem('x-access-token')
          }
      }
    )
    .then((response) => {
      setClientSecret(response.data.clientSecret);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="blue-nav checkout-main">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Header />
            <section className='new-first-row checkout-banner'>
              <Container>
                <Row>
                  <Col>
                    <h1 className='text-center'>Checkout</h1>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className='v2-mt-4 checkout-1'>
              <Container className='relative z-index-2'>
                <Row>
                  <Col sm={7} className='v2-m-mt-3 v2-m-order-2'>
                    <CheckoutForm options={options} />
                  </Col>
                  <Col className='v2-pl-4 v2-m-order-1 v2-m-pl-1'>
                    <h2 className='v2-font-20 text-center'>Order Summary</h2>
                    { data ? (
                      <div className='checkout-summary-box'>
                        <ul class="nft-checkout-modal-items">
                          <li>
                            <div class="nft-checkout-modal-items-img">
                              <img src={require(`../assets/img/nft-images/${data.nft_name}.png`).default} />
                            </div>
                            <span class="nft-checkout-modal-items-checked material-symbols-outlined">check_circle</span>
                            <div class="nft-checkout-modal-items-desc">
                              <p class="nft-checkout-modal-items-desc-title">NFT #:{data.nft_number}</p>
                              <p class="nft-checkout-modal-items-desc-price">Price ${data.price}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div className='text-center'>Loading...</div>
                    ) }
                  </Col>
                </Row>
              </Container>
            </section>
          <Footer />
        </Elements>
      )}
    </div>
  );
}