import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../helpers/helpers';

// Components
import RelaunchingPopup from '../components/popup/RelaunchingPopup';

// CSS
import '../assets/css/Wallet.css'
import '../assets/css/Wallet3.css'

// Images
import ImgBasicWallet from '../assets/img/wallet/img-basic-wallet.png'
import ImgPremiumWallet from '../assets/img/wallet-3/img-step-2.png'
import ImgPremiumWalletMobile from '../assets/img/wallet-3/mobile-step-2.png'
import ImgPaperWallet from '../assets/img/img-paper-wallet-2.png'
import ImgOvatoMerchantWallet from '../assets/img/img-ovato-merchant-wallet-2.png'
import ImgIconWalletApple from '../assets/img/icon-wallet-apple.png'
import ImgIconWalletAndroid from '../assets/img/icon-wallet-android.png'
import ImgTextWallet from '../assets/img/img-text-wallet.png'

class Wallet3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletmodal: false,
      fade: false,
      merchantmodal: false,
      modal: false
    };
  }
  toggleWallet = () => {
    this.setState({ walletmodal: !this.state.walletmodal });
  };
  toggleMerchant = () => {
    this.setState({ merchantmodal: !this.state.merchantmodal });
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <div className='support_main wallet-main v2-wallet-main v3-wallet-main v3-site'>
        <TopNav />

        <div className='new-first-row support-header v3-wallet-1'>
            <Container>
                <Row className='align-center support-box-row wallet-box-row-2'>
                    <Col className='support-box-col-content'>
                      <h2 className='poppins font-30 v2-m-font-24 bold white-text'>{t('wallet2.basicWallet')}</h2>
                      <p className='poppins v2-font-16 light white-text'>Start by getting some free money from the person who introduced you and experience for yourself just how good it can be</p>
                      <ul className='poppins font-16 light white-text pink-bullets pl-3 lh-1-5 mb-0 support-ul'>
                          <li>Be your own bank- Secure and Private</li>
                          <li>Do transactions that subtle over the block chain in under 10 seconds</li>
                          <li>Send and receive via mobile. username or wallet address instantly</li>
                      </ul>
                      <div class="mt-4 btn-wrap v3-wallet-btn-wrap"><a href={withAffCodeURL("https://wallet.ovato.com/")} target="_blank" class="v3-wallet-btn"><span>start now</span></a></div>
                    </Col>
                    <Col className='support-box-col-img'>
                      <div className='img-wrap text-center'>
                          <img src={ImgBasicWallet} width={375} className='support-box-img-shadow' />
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>

        {/* Step 2 Premium Wallet */}
        <section className='v2-pb-30 v2-m-pb-0 v2-mb--30 v2-tl-mb--40 v2-tp-mb--50 v2-m-mb-0 v3-wallet-2'>
          <Container>
            <Row>
              <Col className='flex align-center m-flex-flow-column'>
                <h2 className='m-0 v3-wallet-step'>STEP 2</h2>
                <p className='poppins v2-font-24 v2-m-font-20 bold blue-text lh-1-3 v2-ml-2 v2-m-ml-0 v2-m-mt-2'>Then start building <br/> Wallet tool box to fit all your needs</p>
              </Col>
            </Row>

            <Row className='align-center support-box-row support-box-row-2 v3-wallet-2-box-row-2'>
              <Col sm={6} className='support-box-col-content'>
                <h2 className='poppins font-30 v2-m-font-24 bold blue-text'>{t('wallet2.premium')}</h2>
                <p className='poppins v2-font-16 light dark-text-3'>
                  Start earning on all your activities while developing a business NFT that can be sold
                </p>
                <ul className='poppins font-16 light dark-text-3 pl-3 lh-1-5 mb-0 support-ul'>
                  <li>Earn on all actives</li>
                  <li>Earn on multiple forms of appreciation </li>
                  <li>Protect your pricing from pricing fluctuations</li>
                </ul>
                <div class="mt-4 btn-wrap v3-wallet-btn-wrap"><a href={withAffCodeURL("https://wallet.ovato.com/")} target="_blank" class="v3-wallet-btn"><span>start now</span></a></div>
              </Col>
              <Col sm={6} className='support-box-col-img'>
                <div className='img-wrap text-center v3-wallet-2-img-wrap'>
                  <img src={ImgPremiumWallet} className='desktop support-box-img-shadow' />
                  <img src={ImgPremiumWalletMobile} className='mobile support-box-img-shadow' />
                </div>
              </Col>
            </Row>

            <Row className='v3-wallet-2-box-row-3'>
              <Col>
                {/* Ovato Merchant Wallet */}
                <div className='align-center support-box-row support-box-row-2 v3-wallet-coming-soon-badge'>
                    <div className='img-wrap text-center'>
                      <img src={ImgOvatoMerchantWallet} width={350} />
                    </div>
                    <h2 className='poppins font-30 v2-m-font-24 bold blue-text mt-0'>{t('wallet2.ovato')}</h2>
                    <ul className='poppins font-16 light dark-text-3 pl-3 lh-1-5 mb-0 support-ul checked-ul'>
                      <li>{t('wallet2.designed')}</li>
                      <li>{t('wallet2.sync')}</li>
                      <li>{t('wallet2.integrate')}</li>
                    </ul>
                    <ul className='icon-download-wrap'>
                      <li>
                        <a onClick={(e) => toggleModal(e)} 
                        // href='https://apps.apple.com/in/app/ovato-merchant-wallet/id1563552465' 
                        href='#'
                        target='_blank'>
                          <img src={ImgIconWalletApple} width={40} />
                        </a>
                      </li>
                      <li>
                        <a onClick={(e) => toggleModal(e)} 
                        // href='https://play.google.com/store/apps/details?id=com.merchantwalletapp' 
                        href='#'
                        target='_blank'>
                          <img src={ImgIconWalletAndroid} width={40} />
                        </a>
                      </li>
                    </ul>
                </div>
              </Col>

              <Col>
                <div className='align-center support-box-row support-box-row-2 v3-wallet-coming-soon-badge'>
                    <div className='img-wrap text-center'>
                      <img src={ImgTextWallet} width={400} />
                    </div>
                    <h2 className='poppins font-30 v2-m-font-24 bold blue-text mt-3-new text-center'>{t('wallet2.text')}</h2>
                    <ul className='poppins font-16 light dark-text-3 pl-3 lh-1-5 mb-0 support-ul checked-ul'>
                      <li>{t('wallet2.instantly')}</li>
                      <li>{t('wallet2.no')}</li>
                    </ul>
                </div>
              </Col>
            </Row>

            <Row className='align-center support-box-row support-box-row-2 support-box-row-3 support-box-paper v3-wallet-coming-soon-badge'>
              <Col md={5} className='support-box-col-content'>
                <h2 className='poppins font-30 v2-m-font-24 bold blue-text'>{t('wallet2.paper')}</h2>
                <p className='poppins font-16 light dark-text-3'>{t('wallet2.ovato2')}</p>
              </Col>
              <Col md={7} className='support-box-col-img'>
                <div className='img-wrap text-center'>
                  <img src={ImgPaperWallet} width={576} />
                </div>
              </Col>
            </Row>

          </Container>
        </section>

        <BottomNav/>

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </div>
    );
  }
}
export default withTranslation()(Wallet3);