import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import '../assets/css/Stacking3.css';

import imgGovatoSmall from '../assets/img/img-govato-small.png';
import imgBanner from '../assets/img/img-stacking-2-banner.png';
import imgBanner2 from '../assets/img/img-stacking-banner-2-new.png';
import imgStackForCoins from '../assets/img/img-stacking-3-for-coins.png'
import imgGrowCoins from '../assets/img/img-stacking-3-grow-your-coins.png'
import imgGrowCoinsMobile from '../assets/img/img-grow-coins-mobile.png'
import imgValue from '../assets/img/img-stacking-3-value.png'
import imgGrowValue from '../assets/img/img-stacking-3-grow-value.png'
import imgGrowValueMobile from '../assets/img/img-grow-value-mobile.png'
import imgVideo from '../assets/img/img-stacking-3-video.jpg'

import imgGovato from '../assets/img/logo-govato-stacking.png'
import imgNFT from '../assets/img/img-stacking-nft.png'
import imgVideoFinally from '../assets/img/img-video-finally.jpg'

import imgVideoLeft from '../assets/img/img-s4-video-left.png'
import imgVideoRight from '../assets/img/img-s4-video-right.png'

class Stacking2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='white-header support-main s4-main'>
        {/* Header */}
        <TopNav />
        
        {/* Banner */}
        <section className='s3-banner s4-banner'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        <h1 className='poppins v2-font-45 v2-tp-font-36 v2-m-font-28 bold white-text'>Grow Your Holdings Exponentially!</h1>
                        <p className='poppins v2-font-29 v2-tp-font-24 v2-m-font-20 regular white-text'>And stack them in your NFT</p>
                        <div className='btn-wrap'>
                            <a href='https://govato.io' target='_blank' className='s4-btn'>Offered exclusively by <img width={33} src={imgGovato} /></a>
                        </div>
                    </Col>

                    <Col>
                        <div className='img-wrap s4-banner-img '>
                            <img src={imgNFT} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 2 */}
        <section className='s3-2 s4-2'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-37 tt-n-font-32 m-n-font-28 medium text-center blue-text s3-2-heading'><span>Earn</span></h2>
                        <h3 className='poppins n-font-35 tt-n-font-28 m-n-font-22 bold dark-text-5 text-center mt-40'>Select from 3 terms</h3>
                    </Col>
                </Row>

                <Row className='mt-80'>
                    <Col md={4}>
                        <div className='s3-2-box'>
                            <p className='s3-2-box-num'>1</p>
                            <h4 className='s3-2-box-year'>1 Year</h4>
                            <div className='s3-2-box-divider'></div>
                            <p className='s3-2-box-percent'>30%</p>
                            <p className='s3-2-box-ex'>ex 1 = 1.3</p>
                        </div>
                    </Col>

                    <Col md={4} className='tt-mt-80'>
                        <div className='s3-2-box'>
                            <p className='s3-2-box-num'>2</p>
                            <h4 className='s3-2-box-year'>2 Years</h4>
                            <div className='s3-2-box-divider'></div>
                            <p className='s3-2-box-percent'>69%</p>
                            <p className='s3-2-box-ex'>ex 1 = 1.69</p>
                        </div>
                    </Col>

                    <Col md={4} className='tt-mt-80'>
                        <div className='s3-2-box'>
                            <p className='s3-2-box-num'>3</p>
                            <h4 className='s3-2-box-year'>3 Years</h4>
                            <div className='s3-2-box-divider'></div>
                            <p className='s3-2-box-percent'>219%</p>
                            <p className='s3-2-box-ex'>ex 1 = 2.19</p>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-60 tt-mt-80 m-mt-40'>
                    <Col>
                        <h2 className='poppins n-font-37 tt-n-font-32 m-n-font-28 medium text-center blue-text s3-2-heading'><span>Choose</span></h2>
                    </Col>
                </Row>

                <Row className='mt-40 align-center s3-2-choose-2'>
                    <Col md={5}>
                        <h3 className='poppins n-font-34 t-n-font-28 m-n-font-24 medium blue-text text-center'>Stack For Coins</h3>
                        <div className='s3-2-box s3-2-box-2 mt-60 t-mt-60'>
                            <p className='s3-2-box-num'>1</p>

                            <div className='img-wrap text-center mt-20'>
                                <img src={imgStackForCoins} width={299} />
                            </div>

                            <div className='img-wrap s3-2-box-img-float-left'>
                                <img src={imgGrowCoins} width={131} className='desktop' />
                                <img src={imgGrowCoinsMobile} width={131} className='mobile' />
                            </div>
                        </div>
                    </Col>

                    <Col md={1}>
                        <p className='poppins n-font-37 m-n-font-32 medium blue-text text-center mt-100 tt-mt-20'>or</p>
                    </Col>

                    <Col md={5}>
                        <h3 className='poppins n-font-34 t-n-font-28 m-n-font-24 medium blue-text text-center tt-mt-20'>Stack For Value</h3>

                        <div className='s3-2-box s3-2-box s3-2-box-2 mt-60 t-mt-60'>
                            <p className='s3-2-box-num'>2</p>

                            <div className='img-wrap text-center mt-20'>
                                <img src={imgValue} width={249} />
                            </div>

                            <div className='img-wrap s3-2-box-img-float-right'>
                                <img src={imgGrowValue} width={145} className='desktop' />
                                <img src={imgGrowValueMobile} width={145} className='mobile' />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-80 m-mt-60'>
                    <Col>
                        <h2 className='poppins n-font-37 tt-n-font-32 m-n-font-28 medium text-center blue-text s3-2-heading'><span>How it Works</span></h2>
                    </Col>
                </Row>

                <Row className='s3-works'>
                    <Col>
                        <div className='img-wrap text-center mt-40 s3-video-wrap s4-video-wrap'>
                            <img className='s4-video-img s4-video-img-left' src={imgVideoLeft} />
                            <a href='https://youtu.be/f_w6CzDuadE' data-fancybox>
                                <img width={600} src={imgVideoFinally} />
                            </a>
                            <img className='s4-video-img s4-video-img-right' src={imgVideoRight} />
                        </div>

                        <div className='btn-wrap text-center mt-60'>
                            <a className='custom-new-btn' target='_blank' href='https://govato.io/'>discover Govato Now</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Footer */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Stacking2);
