import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// CSS
import '../assets/css/Maintenance.css';

// Images
import img1 from '../assets/img/maintenance/img-maintenance.png'
import imgOvato from '../assets/img/maintenance/img-ovato.png'

class Maintenance extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='maintenance-main'>     
        <section className='maintenance-1'>
            <Container>
                <Row>
                    <Col className='text-center v2-pt-8 v2-pb-8'>
                        <div className='img-wrap'>
                            <img width={518} src={img1} />
                        </div>
                        <h1 className='poppins v2-font-36 lh-1-2 v2-m-font-32 bold blue-text-8 text-uppercase v2-mb-0-25 v2-mt-2-5'>We are doing maintenance</h1>
                        <p className='poppins v2-font-22 v2-m-font-20 bold dark-text-7 v2-mt-0'>We will be right back.</p>
                        <div className='img-wrap'>
                            <img width={100} src={imgOvato} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </div>
    );
  }
}
export default withTranslation()(Maintenance);
