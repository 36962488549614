export default [
  {
    id: 1,
    name: 'Divyae',
    img: 'img-01-divyae.jpg',
    sold: true,
  },
  {
    id: 2,
    name: 'Galdur',
    img: 'img-02-galdur.jpg',
    sold: false
  },
  {
    id: 3,
    name: 'Jinx',
    img: 'img-03-jinx.jpg',
  },
  {
    id: 4,
    name: 'Kessem',
    img: 'img-04-kessem.jpg',
    sold: true
  },
  {
    id: 5,
    name: 'Khomsan',
    img: 'img-05-khomsan.jpg',
    sold: false
  },
  {
    id: 6,
    name: 'Magic',
    img: 'img-06-magic.jpg',
    sold: false
  },
  {
    id: 7,
    name: 'Ramil',
    img: 'img-07-ramil.jpg',
    sold: true
  },
  {
    id: 8,
    name: 'Sahar',
    img: 'img-08-sahar.jpg',
    sold: false
  },
  {
    id: 9,
    name: 'Saksan',
    img: 'img-09-saksan.jpg',
    sold: false
  },
  {
    id: 10,
    name: 'Sihir',
    img: 'img-10-sihir.jpg',
    sold: true
  },
  {
    id: 11,
    name: 'Tenho',
    img: 'img-11-tenho.jpg',
    sold: false
  },
  {
    id: 12,
    name: 'Thelxion',
    img: 'img-12-thelxion.jpg',
    sold: false
  },
  {
    id: 13,
    name: 'Aspen',
    img: 'img-13-aspen.jpg',
    sold: false
  },
  {
    id: 14,
    name: 'Cedar',
    img: 'img-14-cedar.jpg',
    sold: false
  },
  {
    id: 15,
    name: 'Corvus',
    img: 'img-15-corvus.jpg',
    sold: false
  },
  {
    id: 16,
    name: 'Coven',
    img: 'img-16-coven.jpg',
    sold: false
  },
  {
    id: 17,
    name: 'Kiano',
    img: 'img-17-kiano.jpg',
    sold: false
  },
  {
    id: 18,
    name: 'Linden',
    img: 'img-18-linden.jpg',
    sold: false
  },
  {
    id: 19,
    name: 'Rowan',
    img: 'img-19-rowan.jpg',
    sold: true
  },
  {
    id: 20,
    name: 'Rune',
    img: 'img-20-rune.jpg',
    sold: false
  },
  {
    id: 21,
    name: 'Sage',
    img: 'img-21-sage.jpg',
    sold: false
  },
  {
    id: 22,
    name: 'Salem',
    img: 'img-22-salem.jpg',
    sold: false
  },
  {
    id: 23,
    name: 'Bruxo',
    img: 'img-23-bruxo.jpg',
    sold: false
  },
  {
    id: 24,
    name: 'Burvis',
    img: 'img-24-burvis.jpg',
    sold: false
  },
  {
    id: 25,
    name: 'Cyfrin',
    img: 'img-25-cyfrin.jpg',
    sold: false
  },
  {
    id: 26,
    name: 'Dewin',
    img: 'img-26-dewin.jpg',
    sold: true
  },
  {
    id: 27,
    name: 'Guiden',
    img: 'img-27-guiden.jpg',
    sold: false
  },
  {
    id: 28,
    name: 'Mage',
    img: 'img-28-mage.jpg',
    sold: true
  },
  {
    id: 29,
    name: 'Mago',
    img: 'img-29-mago.jpg',
    sold: false
  },
  {
    id: 30,
    name: 'Maldue',
    img: 'img-30-maldue.jpg',
    sold: false
  },
  {
    id: 31,
    name: 'Sahir',
    img: 'img-31-sahir.jpg',
    sold: false
  },
  {
    id: 32,
    name: 'Sorgin',
    img: 'img-32-sorgin.jpg',
    sold: false
  },
  {
    id: 33,
    name: 'Taikuri',
    img: 'img-33-taikuri.jpg',
    sold: true
  },
  {
    id: 34,
    name: 'Tovenaar',
    img: 'img-34-tovenaar.jpg',
    sold: false
  },
  {
    id: 35,
    name: 'Vanir',
    img: 'img-35-vanir.jpg',
    sold: false
  },
  {
    id: 36,
    name: 'Velho',
    img: 'img-36-velho.jpg',
    sold: false
  },
  {
    id: 37,
    name: 'Aelfdene',
    img: 'img-37-aelfdene.jpg',
    sold: false
  },
  {
    id: 38,
    name: 'Alberich',
    img: 'img-38-alberich.jpg',
    sold: false
  },
  {
    id: 39,
    name: 'Alwin',
    img: 'img-39-alwin.jpg',
    sold: false
  },
  {
    id: 40,
    name: 'Arad',
    img: 'img-40-arad.jpg',
    sold: false
  },
  {
    id: 41,
    name: 'Aubury',
    img: 'img-41-aubury.jpg',
    sold: false
  },
  {
    id: 42,
    name: 'Bari',
    img: 'img-42-bari.jpg',
    sold: false
  },
  {
    id: 43,
    name: 'Bayard',
    img: 'img-43-bayard.jpg',
    sold: false
  },
  {
    id: 44,
    name: 'Cadmus',
    img: 'img-44-cadmus.jpg',
    sold: true
  },
  {
    id: 45,
    name: 'Drake',
    img: 'img-45-drake.jpg',
    sold: false
  },
  {
    id: 46,
    name: 'Fenris',
    img: 'img-46-fenris.jpg',
    sold: true
  },
  {
    id: 47,
    name: 'Griffin',
    img: 'img-47-griffin.jpg',
    sold: false
  },
  {
    id: 48,
    name: 'Ilasiaq',
    img: 'img-48-ilasiaq.jpg',
    sold: false
  },
  {
    id: 49,
    name: 'Phoenix',
    img: 'img-49-phoenix.jpg',
    sold: false
  },
  {
    id: 50,
    name: 'Sphinx',
    img: 'img-50-sphinx.jpg',
    sold: true
  },
];
