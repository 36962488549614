import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

// Images
import imgGooglePlay from '../../assets/img/img-google-play-home.png'
import imgAppStore from '../../assets/img/img-apple-store-home.png'
import imgOvato from '../../assets/img/new-home-3/img-ovato.png'
import imgOvatoMobile from '../../assets/img/new-home-3/logo-ovato-mobile.png'

// Components
import RelaunchingPopup from '../popup/RelaunchingPopup';
import V3Button from '../buttons/V3Button';

class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  
  render() {
    const { t, modal } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <>
      <section className='v2-pt-12 v2-m-pt-8 v3-home-1'>
        <Container>
          <Row>
            <Col md={7} className='v2-mt-2 v2-pl-6 v2-tp-pl-2'>

              <h1 className='poppins v2-font-38 v2-tl-font-36 v2-tp-font-32 v2-m-font-28 semi-bold white-text'>Next generation money you can earn, grow and use</h1>
              <p className='poppins v2-font-18 v2-m-font-16 regular white-text v2-mt-1-5'>Be your own Bank and do Super fast transactions that settle over the blockchain within seconds</p>

              <div className='v2-mt-2-5'>
                <V3Button text={'Get Started'} href={withAffCodeURL("wallet")} />
              </div>
              
              <div className='img-wrap new-home-1-img-wrap-1 v2-mt-2-5'>
                <a
                  rel='noopener noreferrer'
                  // href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                  href='#'
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgGooglePlay} 
                    width={100}
                  />
                </a>
                <a
                  rel='noopener noreferrer'
                  // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                  href='#' 
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgAppStore}
                    width={100}
                  />
                </a>
              </div>

              <div className='mobile v2-mt-2 v3-home-1-img-wrap-mobile'>
                <img src={imgOvatoMobile} width={300} />
              </div>
            </Col>
          </Row>
        </Container>
        <div className='v3-home-floating-img desktop'>
            <img src={imgOvato} width={300} />
        </div>
      </section>
      
      {/* Modals */}
      <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </>
    );
  }
}
export default withTranslation()(Section1);
