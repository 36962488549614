import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgCP from '../assets/img/img-mwa-3.png';
import imgCPMobile from '../assets/img/img-mwa-3-mobile.png';
class MerchantSection3 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      
    };
  }
  render() {
     const { t } = this.props;
    return (
      <>
        <section className='cwa-3 mwa-3 desktop'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='img-wrap cwa-3-img-wrap'>
                            <img src={imgCP} />
                        </div>
                    </Col>
                    <Col md={6} className='cwa-3-col-2'>
                        <h2 className='poppins font-50 bold dark-text-2 lh-1-2 cwa-3-heading'>{t('merchantWallet3.top')} <br /> {t('merchantWallet3.implemented')}</h2>
                        <ul className='cwa-ul-3 cwa-ul'>
                            <li>
                                <strong>{t('merchantWallet3.real')}</strong>
                                <p>{t('merchantWallet3.discover')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet3.notifications')}</strong>
                                <p>{t('merchantWallet3.get')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet3.search')}</strong>
                                <p>{t('merchantWallet3.search2')}</p>
                            </li>
                        </ul>
                        <div className='btn-wrap cwa-btn-wrap-3'>
                             <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet3.download')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Mobile */}
        <section className='cwa-3 mwa-3 mobile'>
            <Container>
                <Row>
                    <Col md={6} className='img-wrap cwa-3-img-col'>
                        <div className='img-wrap cwa-3-img-wrap'>
                            <img src={imgCPMobile} />
                        </div>
                    </Col>
                    <Col md={6} className='cwa-3-col-2'>
                        <h2 className='poppins font-58 bold dark-text-2 lh-1-2 cwa-3-heading'>{t('merchantWallet3.top')} <br /> {t('merchantWallet3.implemented')}</h2>
                        <ul className='cwa-ul-3 cwa-ul'>
                            <li>
                                <strong>{t('merchantWallet3.real')}</strong>
                                <p>{t('merchantWallet3.discover')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet3.notifications')}</strong>
                                <p>{t('merchantWallet3.get')}</p>
                            </li>
                            <li>
                                <strong>{t('merchantWallet3.search')}</strong>
                                <p>{t('merchantWallet3.search2')}</p>
                            </li>
                        </ul>
                        <div className='btn-wrap cwa-btn-wrap-3'>
                             <a href='/wallet' className='custom-pink-btn custom-blue-btn'>{t('merchantWallet3.download')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(MerchantSection3);