import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Components
import InputField from '../InputField';

class IncomeBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <div className='pw-box pw-box-3'>

            <div className='v2-py-1-5 v2-px-2 flex align-center space-between pw-box-header-title'>
                Income
            </div>
            
            <div className='v2-py-1-5 v2-px-2 pw-box-body'>
               <InputField label='Total Income (USD)' name='total_income_usd' value={0} />

               <InputField label='Total Income (OVO)' name='total_income_ovo' value={0} />

               <InputField label='Total Withdrawal (USD)' name='total_withdrawal_usd' value={0} />

               <InputField label='Total Withdrawal (OVO)' name='total_withdrawal_ovo' value={0} />
            </div>
        </div>
    );
  }
}
export default withTranslation()(IncomeBox);
