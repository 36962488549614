import React from 'react'
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import '../../assets/css/OvatoNation.css'
import ImgOvato from '../../assets/img/logo-ovato-nation.png'
import ImgChatSupport from '../../assets/img/img-o-n-3-chat-support.png'
import ImgFreeTshirt from '../../assets/img/img-o-n-4-free-tshirt.png'
import ImgMembershipsAndWallets from '../../assets/img/img-o-n-memberships-and-wallets.png'
import ImgNFTs from '../../assets/img/img-o-n-nfts.png'
import ImgBuildingWealth from '../../assets/img/img-o-n-building-wealth.png'
import ImgLeaderboard from '../../assets/img/img-o-n-4-leaderboard.jpg'
import ImgLeaderboardMobile from '../../assets/img/img-o-n-4-leaderboard-mobile.jpg'
import ImgTwitterSpace from '../../assets/img/img-o-n-twitter-space.png'
import ImgExchanges from '../../assets/img/img-o-n-exchanges.png'
import ImgVideoBuildingSocial from '../../assets/img/img-o-n-video-building-social.jpg'
import ImgVideoBuildingWealth from '../../assets/img/img-o-n-video-building-wealth.jpg'
import ImgVideoMarketingInPerson from '../../assets/img/img-o-n-video-marketing-in-person.jpg'
import ImgVideoWhereToStart from '../../assets/img/img-o-n-video-where-to-start.jpg'
const OvatoNation = () => {
  return (
    <div className='ovato-nation-main'>
      
      {/* SECTION 1 */}
      <section className='o-n-1'>
        <Container>
          <Row>
            <Col sm={9} className='o-n-1-left'>
              <div className='o-n-1-logo'>
                <a href='/'><img src={ImgOvato} alt='Ovato' /></a>
              </div>
              <div className='o-n-1-left-2'>
                <h1 className='poppins mb-2 lh-1 n-font-45 semi-bold white-text'>Welcome to Ovato Nation!</h1>
                <p className='poppins lh-1 n-font-30 semi-bold white-text'>Let's Go!</p>
              </div>
            </Col>
            <Col sm={3} className='o-n-1-right-col'>
              <p className='poppins n-font-18 regular white-text o-n-1-right-text'>Volume 1, Issue 1</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 2 */}
      <section className='o-n-2'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-2-left-col'>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Welcome to Ovato Nation, this is the first of our newsletter series that hope’s to convey the underlying core primary purpose of Ovato which is building a true economy of like minded community  members.
Over the course of Ovato' s journey, Ovato has acquired a truly unique knowledge base of what is actually required and how to successfully migrate users and scale while providing a safe and secure alternative means of exchanging value aka currency.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Ovato has developed a robust product line in order to fulfill our mission and we encourage all of you to discover and to help spread the vision.</p>
            </Col>
            <Col sm={4}>
              <div className='o-n-2-box'>
                <h2 className='poppins n-font-30 bold dark-text-5 mb-4'>Helpful Hints</h2>
                <ul className='o-n-2-ul'>
                  <li>Always update your App</li>
                  <li>If you're not trading you should be stacking</li>
                  <li>Use chat support and don't rely on third party info</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 3 */}
      <section className='o-n-3'>
        <Container>
          <Row className='align-center'>
            <Col sm={5}>
              <div className='o-n-3-img-wrap'>
                <img src={ImgChatSupport} alt='Chat Support' />
              </div>
            </Col>
            <Col sm={7} className='o-n-3-left'>
              <h2 className='poppins n-font-37 bold dark-text-5'>Chat Support</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Our chat support is operated directly by Ovato CoinHolders and associates of Ovato Core and is the best source of real-time information.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Hours of operation are from 8am - 8pm GMT</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 4 */}
      <section className='o-n-4'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-4-left'>
              <h2 className='poppins n-font-37 bold dark-text-5'>Govato is Live</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>The Govato Affiliate Platform is now live and a great way for everyone to get involved in the build out of our economy.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>The platform provides a fun, easy and profitable forum for users to share links and information and make money every step of the way.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>We will provide daily video content that is produced by our own “Ovato Guy” and designed to be shared by participants via their own social media platforms. The Govato platform is the result of our cumulative knowledge on how to best onboard and scale which is what we believe has been the most difficult factor our industry faces.</p>
              <div className='mt-40 o-n-4-img-wrap'>
                <img src={ImgFreeTshirt} alt='Govato Free Tshirt' />
              </div>
            </Col>
            <Col sm={4}>
              <div className='o-n-box'>
                <h3 className='poppins n-font-30 bold text-center dark-text-5'>3 ways to Earn</h3>
                <div className='o-n-box-img'>
                  <img src={ImgMembershipsAndWallets} alt='Memberships and Wallets' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Memberships and Wallets</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgNFTs} alt='NFTs' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>NFT's</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgBuildingWealth} alt='Building Wealth' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Building Wealth</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='desktop o-n-4-leaderboard-img-wrap'>
                <img src={ImgLeaderboard} alt='Leaderboard' />
              </div>
              <div className='mobile o-n-4-leaderboard-img-wrap'>
                <img src={ImgLeaderboardMobile} alt='Leaderboard' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 5 */}
      <section className='o-n-5'>
        <Container>
          <Row className='align-center o-n-5-row-1'>
            <Col sm={5}>
              <div className='o-n-5-img-wrap-2'>
                <img src={ImgTwitterSpace} alt='Twitter Logo' />
              </div>
            </Col>
            <Col sm={7}>
              <h2 className='poppins n-font-37 bold dark-text-5'>Twitter Spaces</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Our twitter spaces weekly meet ups  are moving from Tuesdays to Sunday mornings at 11am EST. Join us on Twitter @OvatoCoin</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Twitter Spaces is a cool new audio-based meeting room where you will be able to connect with other people in our community to ask questions, get insights, share ideas and collaborate. </p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Each week we will be giving out prizes and rewards, so please schedule time and contribute - you will be helping us to shape and improve this community!</p>
            </Col>
          </Row>
          <Row className='align-center o-n-5-row-2'>
            <Col sm={7} className='o-n-5-row-2-left'>
              <h2 className='poppins n-font-37 bold dark-text-5'>Exchanges and Wallet Pricing</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>The company continues to operate on 4 exchanges and is experiencing no issues with those exchanges (except for some US participants who are intent on violating their terms of service). We are very excited to proceed with yet another major exchange soon, which will allow us a far greater footprint for our economy.</p>
            </Col>
            <Col className='o-n-5-row-2-right' sm={5}>
              <div className='o-n-5-img-wrap-2-2'>
                <img src={ImgExchanges} alt='Exchanges Logo' />
              </div>
            </Col>
          </Row>
          <Row className='align-center o-n-5-row-3'>
            <Col sm={12} className='o-n-5-row-2-left'>
              <h2 className='poppins n-font-37 bold dark-text-5 text-center'>Featured Question</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'><strong>How is wallet pricing calculated and why is it different in so many places?</strong></p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>Not all publishers report all of the information. Additionally each publisher assigns a value to the exchange and weighs that in its pricing according to its practices. The pricing in the wallet is the most accurate. Ovato’s methodology is VWAP practice ( Volume, Weighted, Average, Price ) </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 6 */}
      <section className='o-n-6'>
        <Container>
          <Row>
            <Col>
              <h2 className='poppins n-font-37 bold white-text o-n-6-heading'>Videos to note</h2>
              <ul className='o-n-6-ul'>
                <li>
                  <img src={ImgVideoBuildingSocial} alt='Video Building Social' />
                </li>
                <li>
                  <img src={ImgVideoBuildingWealth} alt='Video Building Wealth' />
                </li>
                <li>
                  <img src={ImgVideoMarketingInPerson} alt='Video Marketing in Person' />
                </li>
                <li>
                  <img src={ImgVideoWhereToStart} alt='Video Where to Start' />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default OvatoNation  