import React, { Component } from 'react'
import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink, Collapse, NavbarToggler, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import i18next from '../i18n';
import { withTranslation } from 'react-i18next';
import { WALLET_URL, EXPLORER_URL } from '../constants/defaultValues'
import ReactGA from 'react-ga';
import {GA_ID} from '../constants/defaultValues'
import DaoComingSoonModal from './DaoComingSoonModal'
import LoginModal from '../components/modal/LoginModal'
import { User } from '../providers/User'

// Helpers
import { withAffCodeURL } from '../../src/helpers/helpers';

import '../assets/css/TopNav3.css'

import V3NavMenu from '../components/nav-menu/V3NavMenu'

ReactGA.initialize(GA_ID);

class NewTopNav3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            dropdown: false,
            langcode: localStorage.getItem('i18nextLng'),
            sidebar: false,
            logout: true,
            reloadpath: '',
            scrolled: false,
            showDaoModal: false,
            showModal: false,
            showOTP: false,
            hasToken: false
        }
    }
    handleScroll = () => {
        const offset= window.scrollY;
        if(offset > 100 ){
            this.setState({scrolled: true});
        }
        else{
            this.setState({scrolled: false});
        }
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleDropdown = () => {
        this.setState({ dropdown: true })
    }
    handleLanguage = (e) => {
        const langcode = localStorage.getItem('i18nextLng')
        const reloadpath = "/" + langcode + window.location.pathname.substring(3, window.location.pathname.length)
        this.setState({ langcode, reloadpath, dropdown: false })
        window.history.pushState(this.state, "", reloadpath)
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    toggleSidebar = () => {
        this.setState({ sidebar: !this.state.sidebar })
    }
    changeLanguage(lang) {
        i18next.changeLanguage(lang)
    }
    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);

        // User.hasToken().then(isValid => {
        //     this.setState({ hasToken: isValid });
        // });

        User.getUser().then(data => {
            this.setState({ hasToken: true });
        }).catch(error => {
            this.setState({ hasToken: false });
        })
    }
    componentWillUnmount() {
        // Clean up the scroll event listener when the component is unmounted
        document.removeEventListener('scroll', this.handleScroll);
    }
    toggleDaoModal = (e, value) => {
        e.preventDefault();
        this.setState({ showDaoModal: value })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }

    handleOTPModal = (status) => {
        this.setState({ showOTP: status })
    }

    handleOTPModalClose = () => {
        this.setState({ showOTP: false })
    }

    handleModal = (e, status) => {
        e.preventDefault();
        this.setState({ showModal: status })
        // this.setState({ showOTP: status })
    }

    handleLogout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

    clearStorage = () => {
        localStorage.clear()
    }
    
    render() {
        const { t } = this.props;
        return (
            <>
            <div className={'mindmap-header v3-top-nav ' + ( this.state.scrolled ? 'active' : '' ) }>
                <div className='top_secondary top_secondary_web'>
                    <Container className='pt-3 pb-2'>
                        <Navbar light expand='md'>
                            <NavbarBrand href={withAffCodeURL("/")}><img alt="Ovato Logo" src={require("../assets/img/img-ovato-mindmap-logo.png")} className="top_logo" /></NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className='top_navbar_toggler' />
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav navbar className='top_nav_list'>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={withAffCodeURL("/mindmap")}>{t('newHeader.mindmap')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={withAffCodeURL('https://govato.io/')} target='_blank' rel="noopener noreferrer">Opportunity</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={withAffCodeURL('/financial-overview-and-roadmap-2024')}>Financial Overview</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        {/* <NavLink className='top_nav_list_items' href="/dao" onClick={(event) => this.toggleDaoModal(event, true)}>{t('newHeader.dao')}</NavLink> */}
                                        <NavLink className='top_nav_list_items' href={withAffCodeURL("/dao")}>{t('newHeader.dao')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={withAffCodeURL('https://wallet.ovato.com')} target='_blank' rel="noopener noreferrer">{t('topbar.explorer')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                    { this.state.hasToken ? 
                                        <NavLink className='top_nav_list_items top-nav-3-btn' target='_blank' href='#' onClick={() => this.handleLogout()}>{'Logout'}</NavLink>
                                        :
                                        <NavLink className='top_nav_list_items top-nav-3-btn' target='_blank' rel="noopener noreferrer" href={withAffCodeURL('https://wallet.ovato.com/')} 
                                        onClick={() => this.clearStorage()}>Send/Receive <br/> Login</NavLink>
                                     }
                                        {/* { User.hasToken() ? 
                                            <NavLink className='top_nav_list_items top-nav-3-btn' target='_blank' href='#' onClick={() => this.handleLogout()}>{'Logout'}</NavLink> : 
                                            <NavLink className='top_nav_list_items top-nav-3-btn' target='_blank' href='#' onClick={(e) => this.handleModal(e, true)}>Send/Receive <br/> Login</NavLink> } */}
                                    </NavItem>    
                                </Nav>
                            </Collapse>
                            <Dropdown isOpen={this.state.dropdown} size="sm" toggle={this.handleDropdown}>
                                {this.state.logout ?
                                    <><DropdownToggle tag='a' className="nav-link top_language_code" caret>
                                        {this.state.langcode === 'ko' ? <><Flag name='kr' /><span className='top_language_code'>KO</span></> :
                                            this.state.langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                                                this.state.langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                                                    this.state.langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                                                        <><Flag name='us' className='top_language_code' /><span>EN</span></>}
                                    </DropdownToggle>
                                        <DropdownMenu onClick={this.handleLanguage} className='top_language_drpdwn'>
                                            <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                                            <DropdownItem value='english' onClick={() => this.changeLanguage('en')}><Flag name='us' />{t('topbar.english')}</DropdownItem>
                                            <DropdownItem value='chinese' onClick={() => this.changeLanguage('zh')}><Flag name='cn' />{t('topbar.chinese')}</DropdownItem>
                                            <DropdownItem value='german' onClick={() => this.changeLanguage('de')}><Flag name='de' />{t('topbar.german')}</DropdownItem>
                                            <DropdownItem value='korean' onClick={() => this.changeLanguage('ko')}><Flag name='kr' />{t('topbar.korean')}</DropdownItem>
                                            <DropdownItem value='japanese' onClick=
                                                {() => this.changeLanguage('ja')}><Flag name='jp' />{t('topbar.japanese')}</DropdownItem>
                                        </DropdownMenu></> :
                                    <><DropdownToggle tag='a' className="nav-link" caret>
                                        <i className="far fa-user-circle top_user_icon" /><span className='top_myacc'>{t('topbar.account')}</span>
                                    </DropdownToggle>
                                        <DropdownMenu className='top_user_drpdwn'>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.profile')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/wordbackup'>{t('topbar.backup')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.wallet')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.balance')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/stackcoins'>{t('topbar.stack')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.graph')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.logout')}</DropdownItem>
                                        </DropdownMenu></>
                                }
                            </Dropdown>
                        </Navbar>
                    </Container>
                </div>
                <div className='top_secondary top_secondary_phone'>
                    <Container>
                        <Navbar light expand='md' className='pt-2'>
                            <div className='navbar'>
                            <a className='menu-bars'>
                                    <FaBars onClick={this.toggleSidebar} />
                                </a>
                            </div>
                            <NavbarBrand href={withAffCodeURL("")}><img alt="Ovato Logo" src={require("../assets/img/img-ovato-mindmap-logo.png")} className="top_logo ml-3" /></NavbarBrand>
                            <Dropdown isOpen={this.state.dropdown} size="sm" toggle={this.handleDropdown}>
                                {this.state.logout ?
                                    <><DropdownToggle tag='a' className="nav-link top_language_code" caret>
                                        {this.state.langcode === 'en' ? <><Flag name='us' /><span className='top_language_code'>EN</span></> :
                                            this.state.langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                                                this.state.langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                                                    this.state.langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                                                        <><Flag name='kr' className='top_language_code' /><span>KO</span></>}
                                    </DropdownToggle>
                                        <DropdownMenu onClick={this.handleLanguage} className='top_language_drpdwn'>
                                            <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                                            <DropdownItem value='english' onClick={() => this.changeLanguage('en')}><Flag name='us' />{t('topbar.english')}</DropdownItem>
                                            <DropdownItem value='chinese' onClick={() => this.changeLanguage('zh')}><Flag name='cn' />{t('topbar.chinese')}</DropdownItem>
                                            <DropdownItem value='german' onClick={() => this.changeLanguage('de')}><Flag name='de' />{t('topbar.german')}</DropdownItem>
                                            <DropdownItem value='korean' onClick={() => this.changeLanguage('ko')}><Flag name='kr' />{t('topbar.korean')}</DropdownItem>
                                            <DropdownItem value='japanese' onClick={() => this.changeLanguage('ja')}><Flag name='jp' />{t('topbar.japanese')}</DropdownItem>
                                        </DropdownMenu></> :
                                    <><DropdownToggle tag='a' className="nav-link" caret>
                                        <i className="far fa-user-circle top_user_icon" />
                                    </DropdownToggle>
                                        <DropdownMenu className='top_user_drpdwn'>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.profile')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/wordbackup'>{t('topbar.backup')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.wallet')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.balance')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/stackcoins'>{t('topbar.stack')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.graph')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.logout')}</DropdownItem>
                                        </DropdownMenu></>
                                }
                            </Dropdown>
                        </Navbar>
                    </Container>
                    
                    {/* Mobile Menu */}
                    <V3NavMenu active={this.state.sidebar} handleClose={this.toggleSidebar} handleModal={(e) => this.handleModal(e, true)} />
                </div>
            </div>
            
            {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}

            <LoginModal 
                showModal={this.state.showModal} 
                handleModalClose={this.handleModalClose} 
                showOTP={this.state.showOTP}
                handleOTPModal={this.handleOTPModal}
                handleOTPModalClose={this.handleOTPModalClose} />
            </>
        )
    }
}
export default withTranslation()(NewTopNav3)