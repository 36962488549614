import React, { Component } from 'react'
import MindmapTopNav from '../containers/NewTopNav3';
import MindmapBottomNav from '../containers/NewBottomNav3';
import MindmapBody from './MindmapBody';
export default class Mindmap extends Component {
  render() {
    return (
      <div className='blue-nav mindmap-main'>
        <MindmapTopNav />
        <MindmapBody />
        <MindmapBottomNav />
      </div>
    )
  }
}