import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Containers
import TopNavPremium from '../../containers/TopNavPremium';
import BottomNav from '../../containers/NewBottomNav';

// Components
import ToolsLink from '../../components/premium/tools/ToolsLink';

// CSS
import '../../assets/css/Tools.css'

// Images
import imgPlayVideo from '../../assets/img/tools/img-play-video.jpg'

import imgVideo1 from '../../assets/img/tools/img-video-1.png'
import imgVideo2 from '../../assets/img/tools/img-video-2.png'
import imgVideo3 from '../../assets/img/tools/img-video-3.png'
import imgVideo4 from '../../assets/img/tools/img-video-4.png'
import imgVideo5 from '../../assets/img/tools/img-video-5.png'
import imgVideo6 from '../../assets/img/tools/img-video-6.png'

import imgInWallet from '../../assets/img/tools/img-in-wallet.png'
import imgThankYou from '../../assets/img/tools/img-thank-you.png'

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <div className='tools-main'>
            {/* Header */}
            <TopNavPremium />
            
            {/* Tools 1 */}
            <section className='top-section tools-1 v2-pt-4 v2-tp-pt-3 v2-pb-1 v2-tp-pb-3'>
                <Container>
                    <Row className='v2-px-8 v2-m-px-1 align-center'>
                        <Col sm={6} className='v2-pr-8 v2-tp-pr-1 v2-tp-col-12'>
                            <h1 className='poppins v2-font-39 v2-tl-font-36 v2-m-font-32 bold dark-text-5 v2-tp-text-center'>Tools</h1>
                            <p className='poppins v2-font-17 v2-m-font-16 regular gray-text-2'>We provide you will all the tools necessary to grow your business and plan if ever you sell your business.</p>
                        </Col>

                        <Col sm={6} className='v2-tp-text-center v2-tp-col-12 v2-tp-mt-2'>
                            <div className='img-wrap'>
                                <img src={imgPlayVideo} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Tools 2 */}
            <section className='tools-curve v2-mb--8 v2-pt-4 tools-2'>
                <Container className='relative z-index-3'>
                    {/* General Links */}
                    <Row>
                        <Col className='v2-px-3 v2-m-px-1'>
                            <div className='tools-blue-box'>

                                <div className='flex align-center tools-blue-box-header'>
                                    <div className='tools-num'>1</div>
                                    <h2 className='v2-mt-0 v2-ml-2 tools-title'>General Links</h2>
                                </div>
                                
                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Home Page'} url={'Https://govato.io/c/dle66a'} clicks={168} wallets={'0'} />
                                </div>

                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Ovato Ecosystem'} url={'Https://govato.io/c/dle66a'} clicks={111} wallets={'1'} />
                                </div>

                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Financial Overview'} url={'Https://govato.io/c/dle66a'} clicks={222} wallets={'2'} />
                                </div>

                            </div>
                        </Col>
                    </Row>

                    {/* Specialty Links */}
                    <Row className='v2-mt-3'>
                        <Col className='v2-px-3 v2-m-px-1'>
                            <div className='tools-blue-box'>

                                <div className='flex align-center tools-blue-box-header'>
                                    <div className='tools-num'>2</div>
                                    <h2 className='v2-mt-0 v2-ml-2 tools-title'>Specialty Links</h2>
                                </div>
                                
                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Unbanked'} url={'Https://govato.io/c/dle66a'} clicks={168} wallets={'0'} />
                                </div>

                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Cannabis'} url={'Https://govato.io/c/dle66a'} clicks={111} wallets={'1'} />
                                </div>

                                <div className='v2-mt-1'>
                                    <ToolsLink title={'Entertainers'} url={'Https://govato.io/c/dle66a'} clicks={222} wallets={'2'} />
                                </div>

                            </div>
                        </Col>
                    </Row>

                    {/* Videos */}
                    <Row className='v2-mt-3'>
                        <Col className='v2-px-3 v2-m-px-1'>
                            <div className='tools-blue-box'>

                                <div className='flex align-center tools-blue-box-header'>
                                    <div className='tools-num'>3</div>
                                    <h2 className='v2-mt-0 v2-ml-2 tools-title'>Videos</h2>
                                </div>
                                
                                <Row className='v2-mt-0'>
                                    <Col sm={4} className='v2-mt-2 v2-m-mt-1 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo1} />
                                        </div>
                                    </Col>

                                    <Col sm={4} className='v2-mt-2 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo2} />
                                        </div>
                                    </Col>

                                    <Col sm={4} className='v2-mt-2 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo3} />
                                        </div>
                                    </Col>

                                    <Col sm={4} className='v2-mt-2 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo4} />
                                        </div>
                                    </Col>

                                    <Col sm={4} className='v2-mt-2 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo5} />
                                        </div>
                                    </Col>

                                    <Col sm={4} className='v2-mt-2 v2-tp-col-6 v2-m-col-12'>
                                        <div className='img-wrap'>
                                            <img src={imgVideo6} />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>

                    {/* In Person Wallets Links */}
                    <Row className='v2-mt-3'>
                        <Col className='v2-px-3 v2-m-px-1'>
                            <div className='tools-blue-box'>

                                <div className='flex align-center tools-blue-box-header'>
                                    <div className='tools-num'>4</div>
                                    <h2 className='v2-mt-0 v2-ml-2 tools-title'>In person, Wallets </h2>
                                </div>
                                
                                <Row className='v2-px-6 v2-m-px-1 flex align-center v2-mt-0'>
                                    <Col sm={6}>
                                        <p className='v2-pl-6 v2-tp-pl-0 poppins v2-font-24 v2-tp-font-20 v2-m-font-18 medium white-text'>Download Image QR to your mobile</p>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='img-wrap v2-m-mt-2'>
                                            <img className='v2-m-mw-80' src={imgInWallet} />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>

                    {/* In Person Wallets Links */}
                    <Row className='v2-mt-3'>
                        <Col className='v2-px-3 v2-m-px-1'>
                            <div className='tools-blue-box'>
                                <Row>
                                    <Col sm={6} className='v2-tp-col-12'>
                                        <div className='flex align-center tools-blue-box-header'>
                                            <div className='tools-num'>5</div>
                                            <h2 className='v2-mt-0 v2-ml-2 tools-title'>Sign Up Merchants</h2>
                                        </div>

                                        <p className='poppins v2-font-20 v2-tp-font-18  v2-m-font-17 regular white-text v2-tp-text-center'>Physical handouts to sign up merchants</p>
                                        
                                        <div className='v2-pl-4 v2-tp-pl-0 v2-mt-6 v2-tp-mt-4'>
                                            <div className='tools-dl-wrap'>
                                                <a href='#' className='tools-dl-btn'>download merchant intro</a>
                                            </div>

                                            <div className='v2-mt-3 tools-dl-wrap'>
                                                <a href='#' className='tools-dl-btn'>download merchant tool</a>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm={6} className='v2-pl-4 v2-tp-pl-1 v2-mt-2 v2-tp-mt-3 v2-tp-col-12'>
                                        <div className='img-wrap v2-tp-text-center'>
                                            <img className='v2-m-mw-70' width={300} src={imgThankYou} />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Footer */}
            <BottomNav />
        </div>
    );
  }
}
export default withTranslation()(Tools);