import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class TopCountriesBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    const record = [
        { 'country': 'Canada', 'value': '35%' },
        { 'country': 'USA', 'value': '20%' },
        { 'country': 'England', 'value': '18%' },
        { 'country': 'Germany', 'value': '12%' },
        { 'country': 'Singapore', 'value': '10%' },
        { 'country': 'China', 'value': '7%' },
        { 'country': 'Malaysia', 'value': '6%' },
        { 'country': 'Australia', 'value': '5%' },
        { 'country': 'Saudi', 'value': '4%' },
        { 'country': 'Taiwan', 'value': '3%' }
    ]

    return (
        <div className='org-box'>
            <div className='poppins v2-font-15 semi-bold blue-text org-box-header'>
                Top Countries
            </div>

            <div className='org-box-body'>
                <ul className='org-box-ul'>
                    {record.map((item,index) => (
                        <li><span className='org-box-ul-title'>{index + 1}. {item.country}</span> <span className='org-box-ul-val'>{item.value}</span></li>
                    ))}
                </ul>
            </div>
        </div>
    );
  }
}
export default withTranslation()(TopCountriesBox);
