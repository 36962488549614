import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import iconDownload from '../../../assets/img/premium-wallet/icon-download.png'

class RecentTransactionsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;
    const records = [
      { id: 1, name: 'Record 1', value: 'sent' },
      { id: 2, name: 'Record 2', value: 'received' },
      { id: 3, name: 'Record 3', value: 'sent' },
      { id: 4, name: 'Record 4', value: 'received' },
      { id: 5, name: 'Record 5', value: 'sent' },
      { id: 6, name: 'Record 6', value: 'received' },
      { id: 7, name: 'Record 7', value: 'sent' },
      { id: 8, name: 'Record 8', value: 'received' },
      { id: 9, name: 'Record 9', value: 'sent' }
    ];

    return (
        <>
          <div className='v2-mt-6 v2-m-mt-4 row'>
              <div class='col'>
                  <h2 className='poppins v2-font-21 semi-bold blue-text text-center'>Recent Transactions</h2>

                  <div className='pw-divider'></div>
              </div>
          </div>

          {/* Recent Transactions Control */}
          <div className='row align-center rtm-row-control'>
            <div class='col col-sm-3'>
              <select className='pw-select'>
                <option value="Default Wallet">Default Wallet</option>
                <option value="Default Wallet">Wallet 1</option>
                <option value="Default Wallet">Wallet 2</option>
                <option value="Default Wallet">Wallet 3</option>
                <option value="Default Wallet">Wallet 4</option>
                <option value="Default Wallet">Wallet 5</option>
              </select>
            </div>

            <div class='col'>
              <ul className='rt-ul'>
                <li><a href='#'>All</a></li>
                <li><a href='#'>Sent</a></li>
                <li><a href='#'>Received</a></li>
                <li><a href='#'>Transferred</a></li>
                <li><a href='#'><img src={iconDownload} /></a></li>
              </ul>
            </div>

            <div className='col col-sm-3'>
              <input className='pw-input' type='text' placeholder='Search' />
            </div>
          </div>

          {/* Recent Transaction Box */}
          <div className='rt-box'>
            { records.map((item, index) => (
              <div className='row rt-box-row'>

                <div className='col col-sm-3 rt-box-col'>

                  {item.value === 'sent' && <div className='rt-sent-rec rt-sent'>sent</div>}
                  {item.value === 'received' && <div className='rt-sent-rec rt-received'>received</div>}

                  <div className='rt-date'>2021 - 05 - 21, 5:18:22 PM</div>
                </div>

                <div className='col col-sm-6 rt-box-col v2-m-mt-1'>
                  <div className='poppins v2-font-15 v2-tp-font-14'><span className='blue-text medium'>Transaction:</span> <span className='v2-font-15 normal dark-text-2'>xxxxxxxxxxxxxxxxxxxxxxxxxx</span></div>
                  <div className='poppins v2-font-15 v2-tp-font-14 v2-mt-0-25'><span className='blue-text medium'>Note:</span> <span className='v2-font-15 normal dark-text-2'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</span></div>
                </div>

                <div className='col col-sm-3 rt-box-col v2-m-mt-1'>
                  <div className='rt-price'>$10,000.00</div>
                  <div className='rt-price-ovo v2-mt-0-5'>10,000.000000 OVO</div>
                </div>

              </div>
            )) 
            }
          </div>
        </>
    );
  }
}
export default withTranslation()(RecentTransactionsMain);
