import axios from 'axios'
import { baseUserAuthApiUrl } from '../constants/defaultValues'

const createInstance = function () {
    const axiosInstance = axios.create({
        baseURL: baseUserAuthApiUrl,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('x-access-token')
        }
    });
    return axiosInstance;
}

export const UserAuth = {

    login: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/auth/login", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    signup: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/auth/sign_up", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    setDeviceId: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/users/set_device_id", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    getUser: payload => {
        const axiosInstance = createInstance();
        return new Promise((resolve, reject) => {
            axiosInstance
                .get('/users/me')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateProfile: payload => {
        const axiosInstance = createInstance();
        return new Promise((resolve, reject) => {
            axiosInstance
                .put('/users/me', payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    unsetDeviceId: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("users/unset_device_id", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    validateOtp: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/auth/verify_otp", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    validateForgotOtp: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/auth/verify_forgot_otp", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    resendTwoFA: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post('auth/resend_otp', payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    forgotPassword: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post('password-resets', payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    requestPasswordReset: function (payload,token) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .put('password-resets/' + token, payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    updateMobile: payload => {
        const axiosInstance = createInstance();
        return new Promise((resolve, reject) => {
            axiosInstance
                .put('/auth/updateMobile', payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    requestPasswordReset: function (payload, token) {
        return new Promise(function (resolve, reject) {
          const axiosInstance = createInstance();
          axiosInstance
            .put('password-resets/' + token, payload)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response);
            });
        });
      },
      requestPasswordResetMerchant: function (payload, token) {
        return new Promise(function (resolve, reject) {
          const axiosInstance = createInstance();
          axiosInstance
            .put('password-resets/merchant/' + token, payload)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response);
            });
        });
      },
};
