import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

import Img1 from '../assets/img/logo-ovato-privacy-policy-new.png'

import Img1Mobile from '../assets/img/logo-mobile-terms-of-use-new.png'
class Privacy extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav v3-main privacy-main'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new privacy-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={5}>
                <h1 className='Poppins font-43 bold blue-text text-uppercase privacy-banner-new-heading'>{t('privacy.privacy')}</h1>
              </Col>
              <Col md={7} className='privacy-banner-new-col-2'>
                <div className='img-wrap desktop privacy-banner-new-img-wrap'>
                  <img src={Img1} />
                </div>
                <div className='img-wrap mobile privacy-banner-new-img-wrap'>
                  <img src={Img1Mobile} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='privacy_div privacy-new-2'>
          <Container>
            <Card className='privacy_card'>
              <CardBody>
                <ol>
                  <li>
                    <h2>{t('privacy.h1')}</h2>
                    <p>{t('privacy.p1')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h2')}</h2>
                    <p>{t('privacy.p2')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h3')}</h2>
                    <p>{t('privacy.p3')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h4')}</h2>
                    <p>{t('privacy.p4')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h5')}</h2>
                    <p>{t('privacy.p5')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h6')}</h2>
                    <p>{t('privacy.p6')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h7')}</h2>
                    <p>{t('privacy.p7')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h8')}</h2>
                    <p>{t('privacy.p8')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h9')}</h2>
                    <p>{t('privacy.p9')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h10')}</h2>
                    <p>{t('privacy.p10')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h11')}</h2>
                    <p>{t('privacy.p11')}</p>
                  </li>
                  <li>
                    <h2>{t('privacy.h12')}</h2>
                    <p>{t('privacy.p12')}</p>
                  </li>
                </ol>
              </CardBody>
            </Card>
          </Container>
        </div>
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Privacy);
