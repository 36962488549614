import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Service } from '../providers/Services';
import { deviceDetect } from '../constants/defaultValues';
import { ToastContainer, toast } from 'react-toastify';
const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      message: '',
      phone: '',
    };
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSend = () => {
    if (this.state.email === '') {
      toast.error("Email can't be empty !", toaststyle);
    } else {
      const payload = {
        email: this.state.email,
        name: this.state.name,
        phone: this.state.phone,
        message: this.state.message,
        os: deviceDetect.os,
        browser: deviceDetect.browsername,
        device: deviceDetect.devicetype,
        plateform: deviceDetect.plateform,
        type: 'contact',
      };
      Service.contactForm(payload)
        .then((res) => {
          this.setState({ email: '', name: '', message: '', phone: '' });
          toast.success(
            'Thank you for reaching out to us. We will get back to you as soon as possible!',
            toaststyle
          );
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.error(
              'Email is invalid, please enter a valid email!',
              toaststyle
            );
          } else {
            toast.error(
              error && error.response
                ? error.response.data.message
                : 'Some error occurred!',
              toaststyle
            );
          }
        });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav'>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        {/* HEADER */}
        <TopNav />
        {/* SECTION 1 */}
        <div className='contact-main'>
          <Container>
            <Row className='contact-main-row'>
              <Col lg='5' className='mb-4'>
                <Row className='contact-main-row-1'>
                  <Col lg='2' xs='2' className='text-center contact-col-icon'>
                    <img
                      alt='Location'
                      src={require('../assets/img/location.png')}
                      className='contact-icon'
                    ></img>
                  </Col>
                  <Col lg='10' xs='10' className='contact-col-text'>
                    <p className='blue-head mb-2'>{t('contact.drop')}</p>
                    <p className='contact-text2 contact-text2-1'>
                      60 Paya Lebar Road, #05-40B Paya Lebar Square, Singapore,
                      409051 Singapore
                    </p>
                  </Col>
                </Row>
                <br />
                <Row className='contact-main-row-2'>
                  <Col lg='2' xs='2' className='text-center contact-col-icon'>
                    <img
                      alt='Chat Us'
                      src={require('../assets/img/chat.png')}
                      className='contact-icon'
                    ></img>
                  </Col>
                  <Col lg='10' xs='10' className='contact-col-text'>
                    <p className='blue-head mb-2'>{t('contact.chat')}</p>
                    <p className='contact-text2 contact-text2-2'>
                      {t('contact.clickicon')}
                    </p>
                  </Col>
                </Row>
                <br />
                <Row className='contact-main-row-2'>
                  <Col lg='2' xs='2' className='text-center contact-col-icon'>
                    <img
                      alt='Call Us'
                      src={require('../assets/img/icon-phone-blue.png')}
                      className='contact-icon'
                    ></img>
                  </Col>
                  <Col lg='10' xs='10' className='contact-col-text'>
                    <p className='blue-head mb-2'>{t('contact.number')}</p>
                    <p className='contact-text2 contact-text2-3'>
                      {t('contact.singapore')} 6531381741 <br />
                    </p>
                  </Col>
                </Row>
                <br />
                <iframe
                  className='new-contact-map'
                  title='Location'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7615274985847!2d103.89037375031508!3d1.3187594620372154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da181811191ccf%3A0xd4e386a9eef755d8!2sPaya%20Lebar%20Square!5e0!3m2!1sen!2sin!4v1620803055131!5m2!1sen!2sin'
                  width='100%'
                  height='250'
                  allowfullscreen=''
                  loading='lazy'
                ></iframe>
              </Col>
              <Col lg='1'>
                <div className='border-contact'></div>
              </Col>
              <Col lg='6' className='col-right-contact'>
                <p className='blue-head mb-2 contact-2-right-text-2'>
                  {t('contact.reach')}
                </p>
                <br />
                <input
                  type='text'
                  name='name'
                  value={this.state.name}
                  placeholder={t('newContact.name')}
                  className='form-control contact-input'
                  onChange={this.handleInput}
                />
                <input
                  type='email'
                  name='email'
                  value={this.state.email}
                  placeholder={t('newContact.email')}
                  className='form-control contact-input'
                  required
                  onChange={this.handleInput}
                />
                <input
                  type='num'
                  name='phone'
                  value={this.state.phone}
                  placeholder={t('newContact.phone')}
                  className='form-control contact-input'
                  onChange={this.handleInput}
                />
                <textarea
                  className='form-control contact-text'
                  name='message'
                  value={this.state.message}
                  placeholder={t('newContact.your')}
                  row='9'
                  onChange={this.handleInput}
                ></textarea>
                <Button
                  className='btn form-control contact-btn'
                  type='submit'
                  onClick={this.handleSend}
                >
                  {t('contact.send')}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Contact);
