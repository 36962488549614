import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgGetAWallet from '../../assets/img/img-5-get-a-wallet.png'
import imgLearn from '../../assets/img/img-5-new-learn.png'
import imgPurchaseOvato from '../../assets/img/img-5-new-purchase-ovato.png'
import imgOvatoNFTS from '../../assets/img/img-ovato-nfts-new.png'
import imgOvatoDAO from '../../assets/img/img-5-new-ovato-dao.png'
import DaoComingSoonModal from '../../containers/DaoComingSoonModal';
import V3Button from '../buttons/V3Button';

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

// Images
import imgPremium from '../../assets/img/new-home-3/img-premium.png'

class Section5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDaoModal: false
        }
    }
    toggleDaoModal = (e, value) => {
        e.preventDefault();
        this.setState({ showDaoModal: value })
    }
  render() {
    const { t } = this.props;
    return (
        <>
      <section className='new-home-5 white-top-curve-7 v3-bottom-last v2-pb-10 v2-m-pb-0 v3-new-home-5'>
        <Container>
            <Row className='new-home-5-row-1'>
                <Col>
                    <h2 className='Poppins font-46 v2-font-32 v2-m-font-28 bold dark-text-5 text-center new-home-5-row-1-heading'>Your Ovato Journey</h2>
                </Col>
            </Row>
            <div className='new-home-5-inner'>
                {/* Start */}
                <Row className='new-home-5-row new-home-5-inner-row-1'>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgGetAWallet} />
                        </div>
                    </Col>
                    <Col md={3} className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            <span className='new-home-5-num-1'>step</span>
                            <span className='new-home-5-num-2'>1</span>
                        </div>
                    </Col>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>Start With A Basic Wallet</h3>
                        <p className='new-home-5-col-text-content'>
                            Get some money from the person who introduced you to Ovato and experience firsthand how easy and secure this alternative can be.
                        </p>
                        <div className='v2-mt-1-5'>
                            <V3Button text={'Start Now'} href={withAffCodeURL("wallet")} />
                        </div>
                    </Col>
                </Row>
                {/* Premium */}
                <Row className='new-home-5-row new-home-5-inner-row-2'>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>Get Premium <br /> Earn on Everything!</h3>
                        <p className='new-home-5-col-text-content'>
                            Build a business and earn on multiple streams of  income on all of your networks actions. Then sell it right inside and in an NFT
                        </p>
                        <div className='v2-mt-1-5'>
                            <V3Button text={'Discover the Opportunity'} href={withAffCodeURL("wallet")} />
                        </div>
                    </Col>
                    <Col md={3} className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                           <span className='new-home-5-num-1'>step</span>
                            <span className='new-home-5-num-2'>2</span>
                        </div>
                    </Col>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgPremium}  width={400} />
                        </div>
                    </Col>
                </Row>
                {/* Join */}
                <Row className='new-home-5-row new-home-5-inner-row-3'>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgOvatoDAO} />
                        </div>
                    </Col>
                    <Col md={3} className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            <span className='new-home-5-num-1'>step</span>
                            <span className='new-home-5-num-2'>3</span>
                        </div>
                    </Col>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>Join the Movement  - The DAO</h3>
                        <p className='new-home-5-col-text-content'>
                            Discover how you can join our community and help shape it while doing good play with real donations that local communities
                        </p>
                        <div className='v2-mt-1-5'>
                            <V3Button text={'Discover Now'} href={withAffCodeURL("wallet")} />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
      </section>
      {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}
      </>
    );
  }
}
export default withTranslation()(Section5);