import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgToken from '../assets/img/img-section-2-token.png';
import imgOvato from '../assets/img/img-ovato-new-logo-2.png';
import imgTokenMobile from '../assets/img/img-mobile-section-2-token.png';
class Section2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='section-2-new desktop'>
          <Container>
            <Row>
              <Col md={5}>
                <div className='img-wrap section-2-img-token'>
                  <img src={imgToken} alt='Token' />
                </div>
              </Col>
              <Col md={7} className='mt-80 pl-40'>
                <div className='img-wrap img-ovato-2'>
                  <img src={imgOvato} alt='Ovato' width="150" />
                </div>
                <h2 className='font-31 bold blue-text lh-1-2 mt--30'>
                  {t('section2.welcome1')}
                  <br />
                  {t('section2.welcome2')}
                </h2>
                <p className='font-20 medium mt-30'>
                  {t('section2.detail1')}
                  <br />
                </p>
                <p className='font-20 medium'>{t('section2.detail2')}</p>
                <p className='font-20 medium'>{t('section2.detail3')}</p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-2-new-mobile mobile pt-40 pb-20'>
          <Container>
            <Row>
              <Col>
                <div className='img-wrap text-center'>
                  <img src={imgOvato} alt='Ovato' width='150' />
                </div>
                <h2 className='font-25 bold blue-text lh-1-2 mt--30 text-center'>
                  {t('section2.welcome1')} {t('section2.welcome2')}
                </h2>
                <p className='font-18 medium text-center mt-15'>
                  {t('section2.detail1')}
                  <br />
                </p>
                <p className='font-18 medium text-center mt-15'>
                  {t('section2.detail2')}
                </p>
                <p className='font-18 medium text-center mt-15'>
                  {t('section2.detail3')}
                </p>
                <div className='img-wrap section-2-img-token-mobile mt-30'>
                  <img src={imgTokenMobile} alt='Token' />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section2);
