import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import IconFlagCanada from '../../../assets/img/premium-wallet/icon-flag-canada.png'
import IconFlagUSA from '../../../assets/img/premium-wallet/icon-flag-usa.png'

class OrgTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    const record = [
        { date: '6-12-2023', name: 'Name goes here', country: 'USA', total: 312 },
        { date: '6-12-2023', name: 'Name goes here', country: 'Canada', total: 312 },
        { date: '6-12-2023', name: 'Name goes here', country: 'USA', total: 415 },
        { date: '6-12-2023', name: 'Name goes here', country: 'Canada', total: 312 },
        { date: '6-12-2023', name: 'Name goes here', country: 'USA', total: 415 },
        { date: '6-12-2023', name: 'Name goes here', country: 'Canada', total: 312 },
        { date: '6-12-2023', name: 'Name goes here', country: 'USA', total: 415 },
    ]

    const countryFlag = (country) => {
        return country == 'Canada' ? <img src={IconFlagCanada} /> : <img src={IconFlagUSA} />
    }

    return (
        <>
        <div className='desktop org-tbl'>
            <div className='org-tbl-header'>
                <div>Date</div>
                <div>User</div>
                <div>Country</div>
                <div>Organization Total</div>
                <div>Contact Info</div>
            </div>

            <div className='org-tbl-body'>
                { record.map((item) => (
                    <div className='org-tbl-row'>
                        <div>{item.date}</div>
                        <div>{item.name}</div>
                        <div>{countryFlag(item.country)} <span className='text-capitalize'>{item.country}</span></div>
                        <div>{item.total}</div>
                        <div>
                            <ul class='org-social'>
                                <li><a href='#'><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href='#'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href='#'><i class="fab fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                )) }
            </div>
        </div>

        <div className='mobile org-tbl-mobile'>
            { record.map((item) => (
                <div className='org-tbl-row-mobile'>
                    <div className='org-tbl-row-left'>
                        <div className='org-tbl-name poppins v2-font-18 semi-bold blue-text'>{item.name}</div>
                        <div className='org-tbl-total poppins v2-font-14 regular gray-text v2-mt-0-25'>Org Total: {item.total}</div>
                        <div className='org-tbl-date poppins v2-font-14 regular gray-text v2-mt-0-25'>{item.date}</div>
                    </div>
                    
                    <div className='org-tbl-row-right'>
                        <div className='text-right org-tbl-country'>{countryFlag(item.country)}</div>
                        <div>
                            <ul class='v2-mt-1 org-social'>
                                <li><a href='#'><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href='#'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href='#'><i class="fab fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )) }
        </div>
        </>
    );
  }
}
export default withTranslation()(OrgTable);
