import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Graph from './Graph'

class EarningWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    return (
        <div className='earning-wrap'>
            <div className='flex space-between align-center earning-header'>
                <div className='earning-header-left'>
                    <div className='v2-m-flex v2-m-column earning-header-total'>
                        <span className='poppins v2-font-21 v2-tl-font-18 v2-m-font-16 semi-bold blue-text'>Total Income:</span>
                        <span className='poppins v2-font-20 v2-tl-font-18 v2-m-font-20 bold blue-text v2-ml-0-5 v2-m-ml-0 v2-m-mt-0-5'>999999 OVO</span></div>
                </div>

                <div className='flex v2-m-hidden v2-tp-mt-2 align-center earning-header-right v2-m-column'>
                    <ul className='earning-header-date'>
                        <li><a href='#'>7d</a></li>
                        <li><a href='#'>1m</a></li>
                        <li><a className='active' href='#'>3m</a></li>
                        <li><a href='#'>1y</a></li>
                        <li><a href='#'>Max</a></li>
                    </ul>
                    <div className='poppins v2-font-20 v2-tl-font-18 v2-m-font-20 v2-m-mt-1 bold blue-text v2-ml-2 earning-header-ovo'>
                        99999 OVO
                    </div>
                </div>

            </div>

            <div className='text-center v2-mt-2 earning-graph-wrap'>
                <Graph/>
            </div>

            <div className='v2-hidden v2-m-flex v2-tp-mt-2 align-center earning-header-right v2-m-column'>
                <ul className='earning-header-date'>
                    <li><a href='#'>7d</a></li>
                    <li><a href='#'>1m</a></li>
                    <li><a className='active' href='#'>3m</a></li>
                    <li><a href='#'>1y</a></li>
                    <li><a href='#'>Max</a></li>
                </ul>
                <div className='poppins v2-font-20 v2-tl-font-18 v2-m-font-20 v2-m-mt-1 bold blue-text v2-ml-2 earning-header-ovo'>
                    99999 OVO
                </div>
            </div>
        </div>
    );
  }
}
export default withTranslation()(EarningWrap);
