import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Fancybox from '../containers/FancyBox';
import { Service } from '../providers/Services';
import { scroller } from "react-scroll";
import imgGooglePlay from '../assets/img/img-ln-google-play.png'
import imgAppStore from '../assets/img/img-ln-app-store.png'
import imgLN1 from '../assets/img/img-ln-1-gift-profile.png'
import imgOvato from '../assets/img/img-ln-2-ovato.png'
import imgLN2Company from '../assets/img/img-ln-2-company.png'
import imgLN2Ovato from '../assets/img/img-ln-2-ovato-big.png'
import imgLN2BigImg from '../assets/img/img-ln-2-big-bg.png'
import imgLN2BigImgMobile from '../assets/img/img-ln-2-big-mobile.jpg'
import imgLN3 from '../assets/img/img-ln3-mission.png'
import imgLN3Video from '../assets/img/img-ln3-video.png'
import imgLN4Full from '../assets/img/img-ln-4-full-circle-economy.png' 
import imgLN4FullMobile from '../assets/img/img-ln-4-full-mobile.png' 
import imgLN4Products from '../assets/img/img-ln-4-products.png' 
import imgLN4ProductsMobile from '../assets/img/img-ln-4-products-mobile.png' 
import imgLN5Invest from '../assets/img/img-ln-5-invest-and-trade.png'
import imgLN5Owl from '../assets/img/img-ln-5-owl-people.png' 
import imgLN5Ovato from '../assets/img/img-ln-5-ovato.png'  
import imgLN6Ovato from '../assets/img/img-ln-6-ovato.png'
import imgLN6Probit from '../assets/img/img-ln-6-probit.png'
import imgLN6P2P from '../assets/img/img-ln-6-p2p.png'
import imgLN6WhiteBit from '../assets/img/img-ln-6-white-bit.png'
import imgLN6Lbank from '../assets/img/img-ln-6-lbank.png'
import imgLN6Coin from '../assets/img/img-ln-6-coin.png'
import imgLN7OwlPeople from '../assets/img/img-ln-7-owl-people.png'
import imgLN7OwlPeopleBig from '../assets/img/img-ln-7-owl-people-big.png'
import imgLN7Map from '../assets/img/img-ln-7-map.png'
import imgLN8Govato from '../assets/img/img-ln-8-govato.png'
import imgLN8Seller from '../assets/img/img-ln-8-seller.png' 
import imgLN9Box from '../assets/img/img-ln-9-box.png' 
import imgLN9Books from '../assets/img/img-ln-9-ovato-products-new.png' 
class LandingNew extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            exch: '',
            exchloaded: false
        };
    }
    componentDidMount() {
        Service.getExch()
        .then((res) => {
            this.setState({ exch: res.usd, exchloaded: true });
        })
        .catch((err) => {
        });
    }
    customScrollTo = (sectionClassName) => {
        scroller.scrollTo(sectionClassName, {
            duration: 1000,
            delay: 0,
            smooth: "easeInOut",
        });
    }
  render() {
    const { t } = this.props;
    return (
      <div className='ln-main'>
        {/* SECTION 1  */}
        <section className='ln-1'>
            <Container>
                <Row>
                    <Col sm={7} className='ln-1-left-col'>
                        <div className='ln-1-gift-wrap'>
                            <div className='ln-1-gift-left'>
                                <img src={imgLN1} alt='' />
                            </div>
                            <div className='ln-1-gift-right'>
                                <div className='poppins font-45 semi-bold blue-text lh-1-2 ln-1-gift-heading'>{t('landingNew.special')}</div>
                                <div className='ln-1-gift-sponsor-wrap'>
                                    <span className='poppins font-21 semi-bold blue-text ln-1-gift-text'>{t('landingNew.sponsor')}</span>
                                    {/* <span className='ln-1-gift-line' title='this is a sample referral link'>this is a sample referral link</span> */}
                                    <span className='ln-1-gift-line'></span>
                                    <a href='#' className='ln-1-gift-link'>{t('landingNew.get')}</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={5} className='ln-1-right-col'>
                        <ul className='ln-1-download-wrap'>
                            <li><a href='https://play.google.com/store/apps/details?id=com.ovato.wallet' rel="noopener noreferrer" target='_blank'><img src={imgGooglePlay} alt='Google Play' /></a></li>
                            <li><a href='https://apps.apple.com/us/app/ovato-wallet/id1520107226' rel="noopener noreferrer" target='_blank'><img src={imgAppStore} alt='App Store' /></a></li>
                        </ul>
                        <ul className='ln-1-trading-wrap'>
                            <li>{t('landingNew.trading')}: <strong>OVO</strong></li>
                            <li>{t('landingNew.current')}: <strong>${this.state.exch}</strong></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 1 */}
        {/* SECTION 2 */}
        <section className='half-curve ln-2'>
            <Container>
                <Row>
                    <Col className='ln-2-left'>
                        <div className='ln-2-header'>
                            <div className='ln-2-header-left'>
                                <a href='/'><img src={imgOvato} /></a>
                            </div>
                            <div className='ln-2-header-right'>
                                <p>{t('landingNew.aBetter')} <a href='/'>{t('landingNew.learn')}</a></p>
                            </div>
                        </div>
                        <h1 className='poppins font-55 bold dark-text-5 lh-1-2 ln-2-heading'>{t('landingNew.finally')}</h1>
                        <p className='mt-20 ln-2-text'>{t('landingNew.super')}</p>
                        <p className='ln-2-text'>{t('landingNew.blockchain')} </p>
                        <p className='ln-2-text'>{t('landingNew.cost')}</p>
                        <p className='ln-2-text'>{t('landingNew.purpose')}</p>
                        <p className='ln-2-text-2'>{t('landingNew.because')}</p>
                        <ul className='ln-2-list'>
                            <li onClick={() => this.customScrollTo('ln-6')}>{t('landingNew.coins')}</li>
                            <li onClick={() => this.customScrollTo('ln-7')}>{t('landingNew.nft')}</li>
                            <li onClick={() => this.customScrollTo('ln-8')}>{t('landingNew.earn')}</li>
                        </ul>
                        <div className='desktop img-wrap mt-30 ln-2-img-wrap'>
                            <img src={imgLN2Company} />
                        </div>
                    </Col>
                    <Col className='ln-2-right'>
                        <div className='desktop img-wrap ln-2-right-img-wrap-1'>
                            <img src={imgLN2BigImg} />
                        </div>
                        <div className='desktop img-wrap ln-2-right-img-wrap-2'>
                            <img src={imgLN2Ovato} />
                        </div>
                        <div className='mobile img-wrap ln-2-big-img-wrap-mobile'>
                            <img src={imgLN2BigImgMobile} />
                        </div>
                        <div className='mobile img-wrap mt-30 ln-2-img-wrap'>
                            <img src={imgLN2Company} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* SECTION 3 */}
        <section className='ln-3-top-curve ln-3'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-35 bold dark-text-5 text-center'>{t('landingNew.mission')}</h2>
                    </Col>
                </Row>
                <Row className='mt-60 align-center ln-3-row-2'>
                    <Col>
                        <div className='img-wrap ln-3-img-wrap'>
                            <img src={imgLN3} />
                        </div>
                    </Col>
                    <Col>
                        <h3 className='poppins n-font-55 bold dark-text-5 ln-3-heading-1'>
                            <span className='ln-3-underline'>{t('landingNew.mission2')}</span>
                        </h3>
                        <p className='poppins n-font-16 bold dark-text-6 mt-20'>{t('landingNew.together')} </p>
                        <p className='poppins n-font-16 light dark-text-6'>{t('landingNew.there')}</p>
                        <h3 className='poppins n-font-55 bold dark-text-5 mt-40 ln-3-heading-2'>
                            <span className='ln-3-underline ln-3-underline-2'>{t('landingNew.opportunity')}</span>
                        </h3>
                        <p className='poppins n-font-16 light dark-text-6 mt-20'>{t('landingNew.we')}</p>
                    </Col>
                </Row>
                <Row className='align-center mt-80 ln-3-row-3'>
                    <Col md={6} className='ln-3-row-3-left'>
                        <h3 className='poppins n-font-32 semi-bold dark-text-5'>{t('landingNew.ovatoIn')}</h3>
                        <p className='poppins n-font-21 bold blue-text-3'>{t('landingNew.weAre')}</p>
                        <p className='poppins n-font-16 light dark-text-6'>{t('landingNew.ovatoIs')}</p>
                    </Col>
                    <Col md={6} className='ln-3-row-3-right'>
                        <div className='img-wrap ln-3-img-wrap-2'>
                            <Fancybox>
                                <a data-fancybox href='https://www.youtube.com/watch?v=QvFtAtVnSik'>
                                    <img className='ln-3-img' src={imgLN3Video} />
                                </a>
                            </Fancybox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 3 */}
        {/* SECTION 4 */}
        <section className='ln-4-top-curve ln-4'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-42 bold dark-text-5 text-center'>{t('landingNew.ovatoProducts')}</h2>
                        <p className='poppins n-font-16 light dark-text-6 text-center ln-4-text'>{t('landingNew.ovatoHas')}</p>
                        <div className='img-wrap ln-4-img-wrap'>
                            <img className='desktop' src={imgLN4Full} />
                            <img className='mobile' src={imgLN4FullMobile} />
                        </div>
                        <div className='img-wrap ln-4-img-wrap-2'>
                            <img className='desktop' src={imgLN4Products} />
                            <img className='mobile' src={imgLN4ProductsMobile} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 4 */}
        {/* SECTION 5 */}
        <section className='ln-5'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-39 bold text-center white-text'>{t('landingNew.3ways')}</h2>
                    </Col>
                </Row>
                <Row className='ln-5-row-2'>
                    <Col>
                        <div className='ln-5-box'>
                            <div className='ln-5-box-num'>1</div>
                            <h3 className='ln-5-box-heading'>{t('landingNew.invest')}</h3>
                            <ul className='ln-5-box-ul'>
                                <li>
                                    {t('landingNew.haveThe')}
                                    <strong>{t('landingNew.earnUp')}</strong>
                                </li>
                                <li>
                                    {t('landingNew.earnMore')}
                                </li>
                            </ul>
                            <div className='img-wrap ln-5-box-img ln-5-box-img-1'>
                                <img src={imgLN5Invest} />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='ln-5-box'>
                            <div className='ln-5-box-num'>2</div>
                            <h3 className='ln-5-box-heading'>{t('landingNew.nft2')}</h3>
                            <ul className='ln-5-box-ul'>
                                <li>
                                    {t('landingNew.getReal')}
                                </li>
                                <li>
                                    {t('landingNew.original')}
                                </li>
                                <li>
                                    {t('landingNew.getReal2')}
                                </li>
                            </ul>
                            <div className='img-wrap ln-5-box-img ln-5-box-img-2'>
                                <img src={imgLN5Owl} />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='ln-5-box'>
                            <div className='ln-5-box-num'>3</div>
                            <h3 className='ln-5-box-heading'>{t('landingNew.earn2')}</h3>
                            <ul className='ln-5-box-ul'>
                                <li>
                                    {t('landingNew.become')}
                                </li>
                                <li>
                                    {t('landingNew.increase')}
                                </li>
                                <li>
                                    {t('landingNew.looking')}
                                </li>
                            </ul>
                            <div className='img-wrap ln-5-box-img ln-5-box-img-3'>
                                <img src={imgLN5Ovato} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 5 */}
        {/* SECTION 6 */}
        <section className='ln-6'>
            <Container>
                <Row className='align-center ln-6-row-1'>
                    <Col md={4}>
                        <div className='mobile ln-6-header-num'>1</div>
                        <div className='img-wrap ln-6-row-1-img'>
                            <img src={imgLN6Ovato} />
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className='ln-6-header-wrap'>
                            <div className='desktop ln-6-header-left'>
                                <div className='ln-6-header-num'>1</div>
                            </div>
                            <div className='ln-6-header-right'>
                                <h2 className='poppins n-font-44 bold violet-text'>{t('landingNew.invest2')}</h2>
                                <p className='poppins n-font-16 light dark-text-5'>{t('landingNew.buy')}</p>
                                <p className='poppins n-font-35 bold violet-text lh-1-2 mt-20 ln-6-header-right-text-3'>{t('landingNew.buy2')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-80 ln-6-row-2'>
                    <Col>
                        <h3 className='poppins n-font-25 medium text-center violet-text ln-6-row-2-text'>{t('landingNew.ovatoIs2')}</h3>
                    </Col>
                </Row>
                <Row className='mt-40 ln-6-row-3'>
                    <Col>
                        <div className='img-wrap'>
                            <img src={imgLN6Probit} />
                        </div>
                        <div className='btn-wrap'>
                            <a className='ln-btn' href='http://probit.com/' target='_blank' rel='noopener noreferrer'>{t('landingNew.buySell')}</a>
                        </div>
                    </Col>
                    <Col>
                        <div className='img-wrap'>
                            <img src={imgLN6P2P} />
                        </div>
                        <div className='btn-wrap'>
                            <a className='ln-btn' href='http://p2pb2b.io/' target='_blank' rel='noopener noreferrer'>{t('landingNew.buySell')}</a>
                        </div>
                    </Col>
                    <Col>
                        <div className='img-wrap'>
                            <img src={imgLN6WhiteBit} />
                        </div>
                        <div className='btn-wrap'>
                            <a className='ln-btn' href='http://whitebit.com/' target='_blank' rel='noopener noreferrer'>{t('landingNew.buySell')}</a>
                        </div>
                    </Col>
                    <Col>
                        <div className='img-wrap'>
                            <img src={imgLN6Lbank} />
                        </div>
                        <div className='btn-wrap'>
                            <a className='ln-btn' href='http://lbank.info/' target='_blank' rel='noopener noreferrer'>{t('landingNew.buySell')}</a>
                        </div>
                    </Col>
                </Row>
                <Row className='align-center ln-6-row-4'>
                    <Col>
                        <h3 className='poppins n-font-23 bold dark-text-5 text-uppercase'>{t('landingNew.earnMore2')}</h3>
                        <p className='poppins n-font-16 medium dark-text-5'>{t('landingNew.buyOn')}</p>
                        <h3 className='poppins n-font-23 semi-bold pink-text-2'>{t('landingNew.earn3')}</h3>
                        <p className='poppins font-16 medium violet-text mb-0 '>{t('landingNew.1month')}</p>
                        <p className='poppins font-16 medium violet-text mb-0 mt-5'>{t('landingNew.3month')}</p>
                        <p className='poppins font-16 medium violet-text mb-0 mt-5'>{t('landingNew.12month')}</p>
                        <div className='btn-wrap mt-20'>
                            <a className='ln-btn' href='/'>{t('landingNew.findOut')}</a>
                        </div>
                    </Col>
                    <Col>
                        <p className='ln-6-earn'><span className='ln-6-earn-text'>{t('landingNew.earn30')}</span></p>
                        <div className='img-wrap ln-6-earn-img'>
                            <img src={imgLN6Coin} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 6 */}
        {/* SECTION 7 */}
        <section className='ln-7'>
            <Container>
                <Row className='align-center'>
                    <Col md={6} className='ln-7-left'>
                        <div className='ln-7-num'>2</div>
                        <h2 className='poppins n-font-35 bold dark-text-5 ln-7-left-heading'>{t('landingNew.nfts')}</h2>
                        <div className='mt-30 img-wrap ln-7-img-1-wrap'>
                            <img src={imgLN7OwlPeople} />
                        </div>
                        <p className='poppins n-font-16 light dark-text-3 mt-15'>{t('landingNew.ovatoBrings')}</p>
                        <ul className='ln-7-ul'>
                            <li>{t('landingNew.a10000')}</li>
                            <li>{t('landingNew.eachNFT')}</li>
                            <li>{t('landingNew.resale')}</li>
                            <li>{t('landingNew.ability')}</li>
                            <li>{t('landingNew.theAbility')}</li>
                            <li>{t('landingNew.theAbility2')} </li>
                            <li>{t('landingNew.andWallet')}</li>
                            <li>{t('landingNew.ability2')}  </li>
                        </ul>
                        <div className='btn-wrap mt-30'>
                            <a className='ln-btn' href='/nft-collections'>{t('landingNew.discover')}</a>
                        </div>
                    </Col>
                    <Col md={6} className='ln-7-right'>
                        <div className='img-wrap ln-7-right-img'>
                            <img src={imgLN7OwlPeopleBig} />
                        </div>
                    </Col>
                </Row>
                <Row className='ln-7-row-2'>
                    <Col>
                        <p className='poppins n-font-20 medium violet-text text-center'>{t('landingNew.yourNFT')}</p>
                        <div className='img-wrap ln-7-img-2-wrap  mt-30'>
                            <img src={imgLN7Map} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 7 */}
        {/* SECTION 8 */}
        <section className='ln-8-top-curve ln-8'>
            <Container>
                <Row className='ln-8-row-1'>
                    <Col md={5} className='desktop ln-8-row-1-left'>
                        <div className='img-wrap ln-8-img-wrap'>
                            <img src={imgLN8Govato} />
                        </div>
                    </Col>
                    <Col md={7} className='ln-8-row-1-right'>
                        <div className='ln-7-num'>3</div>
                        <h2 className='poppins n-font-35 bold dark-text-5 ln-7-left-heading'>{t('landingNew.earn4')}</h2>
                        <div className='mobile img-wrap ln-8-img-wrap'>
                            <img src={imgLN8Govato} />
                        </div>
                        <p className='poppins n-font-16 light dark-text-3 mt-15'>{t('landingNew.ok')}</p>
                        <p className='poppins n-font-16 light dark-text-3 mt-15'>{t('landingNew.start')}</p>
                        <p className='poppins n-font-16 light dark-text-3 mt-15'>{t('landingNew.members')}</p>
                    </Col>
                </Row>
                <Row className='ln-8-row-2'>
                    <Col md={6} className='ln-8-row-2-left'>
                        <h3 className='poppins n-font-35 bold dark-text-5 ln-8-row-2-heading'>{t('landingNew.multiple')}</h3>
                        <h4 className='poppins n-font-18 bold dark-text-3'>{t('landingNew.memberships')}</h4>
                        <p className='poppins n-font-18 light dark-text-5'>{t('landingNew.easy')}</p>
                        <h4 className='poppins n-font-18 bold dark-text-3'>{t('landingNew.nfts2')}</h4>
                        <p className='poppins n-font-18 light dark-text-5'>{t('landingNew.earn25')}</p>
                        <h4 className='poppins n-font-18 bold dark-text-3'>{t('landingNew.buildingWealth')}</h4>
                        <p className='poppins n-font-18 light dark-text-5'>{t('landingNew.earnOvo')}</p>
                        <div className='btn-wrap mt-30'>
                            <a href='http://govato.io/' target='_blank' className='ln-btn'>{t('landingNew.viewWebsite')}</a>
                        </div>
                    </Col>
                    <Col md={6} className='ln-8-row-2-right'>
                        <div className='img-wrap ln-8-row-2-img-wrap'>
                            <img src={imgLN8Seller} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION 8 */}
        {/* SECTION 9 */}
        <section className='ln-9'>
            <Container>
                <Row>
                    <Col md={5}>
                        <h2 className='poppins n-font-33 semi-bold white-text'>{t('landingNew.getDaily')}</h2>
                        <ul className='ln-9-ul'>
                            <li>{t('landingNew.get20')}</li>
                            <li>{t('landingNew.discoverHow')}</li>
                            <li>{t('landingNew.learnHow')}</li>
                            <li>{t('landingNew.discoverMerchants')}</li>
                            <li>{t('landingNew.plus')}</li>
                        </ul>
                        <div className='ln-9-box'>
                            <div className='ln-9-box-left'>
                                <p>{t('landingNew.spend')} <a href='#'>{t('landingNew.learnHow2')}</a></p>
                            </div>
                            <div className='ln-9-box-right'>
                                <img src={imgLN9Box} />
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='img-wrap ln-9-img-wrap'>
                            <img src={imgLN9Books} />
                        </div>
                        <div className='btn-wrap ln-9-btn-wrap'>
                            <a href='#' className='ln-btn ln-9-btn'>{t('landingNew.join')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* END SECTION */}
      </div>
    );
  }
}
export default withTranslation()(LandingNew);
