import React, { useState, useEffect } from 'react';
import allNFTs from '../data/nfts'

const TestData = () => {
  const [data, setData] = useState(allNFTs);

  useEffect(() => {
    const updatedData = data.map((item, index) => {
        if (index < 58) {
          return {
            ...item,
            nfts_level_1: "25%"
          };
        } else if (
            (index >= 58 && (index - 58) % 13 === 0) ||
            (index >= 59 && (index - 59) % 13 === 0) ||
            (index >= 60 && (index - 60) % 13 === 0)
          ) {
          return {
            ...item,
            nfts_level_1: "25%"
          };
        } else if (
            (index >= 61 && (index - 61) % 13 === 0) ||
            (index >= 62 && (index - 62) % 13 === 0)
          ) {
          return {
            ...item,
            nfts_level_1: "25%"
          };
        } else if (
            (index >= 63 && index <= 70) ||
            (index >= 71 && (index - 63) % 13 === 0) ||
            (index >= 72 && (index - 64) % 13 === 0) ||
            (index >= 73 && (index - 65) % 13 === 0) ||
            (index >= 74 && (index - 66) % 13 === 0) ||
            (index >= 75 && (index - 67) % 13 === 0) ||
            (index >= 76 && (index - 68) % 13 === 0) ||
            (index >= 77 && (index - 69) % 13 === 0) ||
            (index >= 78 && (index - 70) % 13 === 0)
          ) {
          return {
            ...item,
            nfts_level_1: "25%"
          };
        } else {
          return {
            ...item,
            nfts_level_1: "NAN"
          };
        }
      });
      setData(updatedData);

    const updateCapacity = () => {
      const updatedArray = data.map(item =>
        item.mask_type === "large face" ? { ...item, capacity: 400000 } : item
      );
      setData(updatedArray);
    };

    // updateCapacity()
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default TestData;