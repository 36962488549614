import React from 'react'

export default function V3Button({text, handleClick, ...props}) {
  return (
    <div class="btn-wrap v3-wallet-btn-wrap">
        <a className="v3-wallet-btn" {...props}>
            <span>{text != '' ? text : 'start now'}</span>
        </a>
    </div>
  )
}