const formatID = (num) => {
    if (num >= 1 && num < 10) {
        return '000' + num;
    }
    else if (num >= 10 && num < 100) {
        return '00' + num;
    } else if (num > 100 && num < 1000) {
        return '0' + num;
    } else {
        return num;
    }
}
const nftFlag = (country) => {
    // Format spaces with hyphen and to lowercase
    const formatCountry =  country.replace(/\s+/g, '-').toLowerCase();
    return formatCountry
}
const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

const withAffCodeURL = (text) => {
    
    let base_url

    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get('affiliate');
    const langText = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en";

    if ( (text.includes("https://") || text.includes("http://")) && value ) {
        base_url = text
        return base_url + '?affiliate' + '=' + value + ''
    } else if ( (!text.includes("https://") || !text.includes("http://")) && value ) {
        base_url = `${window.location.protocol}//${window.location.host}`;
        return base_url + '/' + langText + '/' + text + '?affiliate=' + value + ''
    } else {
        return text
    }
}

export { formatID, nftFlag, numberWithCommas, capitalizeWords, withAffCodeURL }