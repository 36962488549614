import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgHome2 from '../../assets/img/img-new-home-2.png'
import imgHome2Mobile from '../../assets/img/img-new-home-2-mobile.png'
class NewHome2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='new-home-2 desktop'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <div className='img-wrap new-home-2-img-wrap'>
                  <img src={imgHome2} />
                </div>
              </Col>
              <Col md={6} className='new-home-2-col-right'>
                  <div className='new-home-2-col-inner'>
                      <h2 className='Poppins font-46 bold dark-text-5'>{t('newhome2.for')}</h2>
                      <p className='Poppins font-18 light dark-text-5'>{t('newhome2.ovato')}</p>
                      <div className='btn-wrap new-home-2-btn-wrap'>
                          <a href='/consumer-wallet-app' className='custom-new-btn'>{t('newhome2.learn')}</a>
                      </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* MOBILE */}
        <section className='new-home-2 mobile'>
          <Container>
            <Row className='align-center'>
              <Col md={6} className='new-home-2-col-right'>
                  <div className='new-home-2-col-inner'>
                      <h2 className='Poppins font-46 bold dark-text-5'>{t('newhome2.for')}</h2>
                      <p className='Poppins font-18 light dark-text-5'>{t('newhome2.ovato')}</p>
                      <div className='btn-wrap new-home-2-btn-wrap'>
                          <a href='/consumer-wallet-app' className='custom-new-btn'>{t('newhome2.learn')}</a>
                      </div>
                  </div>
              </Col>
              <Col md={6} className='new-home-2-col-2'>
                <div className='img-wrap new-home-2-img-wrap'>
                  <img src={imgHome2Mobile} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(NewHome2);
