import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import {
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  Table,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgCustomizable from '../assets/img/img-fast-and-easy-new.png';
import imgFitsForAll from '../assets/img/img-full-circle-ecosystem-new.png';
import imgInstant from '../assets/img/img-instant-home-4.png';
import imgJoinMerchant from '../assets/img/img-join-merchant-new.png'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import MerchantTable from './MerchantTable';

// Images
import ImgBanner from '../assets/img/img-about-banner-new-mobile-min.png'

class BecomeMerchant extends Component {
  render() {
    const { t } = this.props;
    const mobileSlider = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className='blue-nav support_main merchant-main'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new about-banner-new merchant-banner-new'>
            <Container>
                <Row className='align-center'>
                    <Col md={12}>
                        <h1 className='Poppins font-46 bold dark-text-5 merchant-banner-new-heading'>{ t('newMerchant.take') }</h1>
                        <p className='Poppins font-21 medium blue-text merchant-banner-new-subheading'>{ t('newMerchant.and') }</p>
                        <div className='btn-wrap'>
                          <a href='/wallet'
                            className='custom-new-btn' 
                          >
                            {t('merchant.get_started')}
                          </a>
                        </div>
                    </Col>
                    <Col className='mobile about-banner-new-mobile-col'>
                      <div className='img-wrap merchant-banner-new-img-wrap'>
                          <img src={ImgBanner} />
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
        {/* SECTION 2 */}
        <section className='white-top-curve merchant-2-new'>
          <Container className='text-center merchant-2-container'>
            <div>
              <Row className='merchant-2-row-1'>
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center merchant_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='merchant_card_img_div text-center'>
                          <img
                            alt='Merchant'
                            src={imgInstant}
                            className='merchant_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head merchant-card-head'>
                        {t('merchant.instant')}
                      </CardTitle>
                      <CardSubtitle className='merchant_card_subhead text-center'>
                        { t('newMerchant.fast') }
                      </CardSubtitle>
                      <CardText className='support_card_text merchant-card-text'>
                        { t('newMerchant.ovato') }
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center merchant_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='merchant_card_img_div text-center'>
                          <img
                            alt='Merchant 2'
                            src={imgCustomizable}
                            className='merchant_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head merchant-card-head'>
                        { t('newMerchant.fast2') }
                      </CardTitle>
                      <CardSubtitle className='merchant_card_subhead text-center'>
                        { t('newMerchant.merchant') } 
                      </CardSubtitle>
                      <CardText className='support_card_text merchant-card-text'>
                        { t('newMerchant.no') }
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center merchant_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='merchant_card_img_div text-center'>
                          <img
                            alt='Merchant 3'
                            src={imgFitsForAll}
                            className='merchant_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head merchant-card-head'>
                        { t('newMerchant.a') }
                      </CardTitle>
                      <CardSubtitle className='merchant_card_subhead text-center'>
                        { t('newMerchant.merchant2') }
                      </CardSubtitle>
                      <CardText className='support_card_text merchant-card-text'>
                        { t('newMerchant.value') }
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className='btn-wrap merchant-2-btn-wrap'>
                <a
                  href='/wallet'
                  className='gradient-pink-btn gradient-pink-btn-3 c-md-btn c-inline-btn merchant-main-btn'
                >
                  {t('merchant.get_started')}
                </a>
              </div>
            </div>
          </Container>
        </section>
        {/* SECTION 3 */}
        <section className='merchant-3-new'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <div className='img-wrap merchant-3-new-img-wrap'>
                  <img src={imgJoinMerchant} />
                </div>
              </Col>
              <Col md={6}>
                <h2 className='Poppins font-46 bold dark-text-5 lh-1-1 merchant-3-new-heading'>{ t('newMerchant.join') }</h2>
                <p className='Poppins font-21 medium blue-text lh-1-5 merchant-3-new-subheading'>{ t('newMerchant.while') }</p>
                <div className='btn-wrap merchant-3-new-btn-wrap'>
                  <a
                    href='/wallet'
                    className='gradient-pink-btn gradient-pink-btn-3 c-md-btn c-inline-btn merchant-main-btn'
                  >
                    {t('merchant.get_started')}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* SECTION 4 */}
        <div className='text-center merchant-5'>
          <h1 className='merchant_blue_heading  pl-5 pr-5'>
            {t('merchant.increase_revenue')} &nbsp;
            <br />
            {t('merchant.efficiency')}
          </h1>
          <p className='merchant_blue_text  pl-5 pr-5'>
            { t('newMerchant.say') }
          </p>
          <div className='btn-wrap'>
            <a
              href='/wallet'
              className='gradient-pink-btn gradient-pink-btn-3 c-md-btn c-inline-btn merchant-main-btn'
            >
              {t('merchant.get_started')}
            </a>
          </div>
        </div>
        <section className='merchant-6-new'>
          <MerchantTable />
        </section>
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(BecomeMerchant);
