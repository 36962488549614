import React, { Component } from 'react';

import Loading from 'react-fullscreen-loading';

class LoadingBG extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {

    const { t, toggle, modal, show} = this.props;

    return (
        <>
        {(show) &&
          <Loading loading background="rgba(97,97,194,0.57) none repeat scroll 0% 0%" loaderColor="black none repeat scroll 0% 0%" />
        }
        </>
    );
  }
}

export default LoadingBG;
