import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import SampleImg1 from '../assets/img/img-checkout-sample-img-1.png'
import SampleImg2 from '../assets/img/img-checkout-sample-img-2.png'
import ImgCoinbase from '../assets/img/img-coinbase.jpg'
import ImgLedger from '../assets/img/img-ledger.png'
import ImgMetamask from '../assets/img/img-metamask.png'
import ImgWalletConnect from '../assets/img/img-wallet-connect.png'
import ImgVisa from '../assets/img/img-checkout-visa.png'
import ImgAmericanExpress from '../assets/img/img-checkout-american-express.png'
import ImgMasterCard from '../assets/img/img-checkout-mastercard.png'
import ImgPaypal from '../assets/img/img-checkout-paypal.png'
import NFTsModalStyle from '../assets/css/NFTsCheckoutModal.css'
import { Service } from '../providers/Services';
import CheckoutForm from './CheckoutForm'
import CheckoutTimer from './checkout/CheckoutTimer';
import { toast } from 'react-toastify';

import '../assets/css/NFTsCheckoutModal.css'


class NFTsCheckoutModal2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nftId:"",
    };
  }

  componentDidMount() {
    const {item} = this.props
    this.setState({ nftId: item.id })
  }

  render() {
    const { t, setModal, item, timer } = this.props;
 
    
    return (
        
        <div className={`nft-checkout-modal-container ${this.props.show ? 'active' : ''}`}>
            <div className='nft-checkout-modal-backdrop'></div>
            <div className='nft-checkout-modal-row'>
                <form className='nft-checkout-form'>
                    {/* HEADER */}
                    <div className='nft-checkout-modal-header'>
                        <h2 className='nft-checkout-modal-header-title'>Checkout</h2>
                        <p className='nft-checkout-modal-header-close'><a href='#' onClick={(e) => setModal(e, false)}><span class="material-symbols-outlined">close</span></a></p>
                    </div>
                    {/* ITEMS */}
                    <div className='nft-checkout-modal-items-row nft-checkout-modal-items-row-2'>
                        <h3 className='nft-checkout-modal-items-title'>Item</h3>
                        { item && (
                            <ul className='nft-checkout-modal-items'>
                                <li>
                                    <div className='nft-checkout-modal-items-img'>
                                        {/* <img src={SampleImg1} alt='Sample Image 1' /> */}
                                        <div>
                                            <img src={require(`../assets/img/nft-images/${item.nft_name}.png`).default } />
                                        </div>

                                        <div>
                                            <p className='nft-checkout-modal-items-desc-title'>NFT #:{ item.nft_number }</p>
                                            <p className='mobile nft-checkout-modal-items-desc-price'>Price ${item.price}</p>
                                        </div>
                                    </div>
                                    
                                    <div className='nft-checkout-modal-items-desc desktop'>
                                        <p className='nft-checkout-modal-items-desc-price'>Price ${item.price}</p>
                                    </div>
                                </li>
                            </ul>
                        ) }
                    </div>
                    
                    <Row className='v2-pt-2 v2-pl-2 v2-pr-2'>
                        <Col>
                            <h2 className='poppins v2-font-15 v2-m-font-14 bold text-center blue-text text-uppercase ls-1'>select payment method</h2>
                        </Col>
                    </Row>

                    <Row className='v2-pl-3 v2-pr-3 v2-checkout-payment-2'>
                        <Col>
                            {/* WALLETS */}
                            <div className='nft-checkout-payment-row nft-checkout-payment-row-2'>
                                <h3 className='nft-checkout-wallets-heading'>Crypto</h3>
                                <ul className='nft-checkout-payment-lists nft-checkout-payment-lists-crypto'>
                                    <li>
                                        {/* <a href='#'> */}
                                            <img src={ImgCoinbase} style={{width:'115px'}} alt='Coinbase' />
                                        {/* </a> */}
                                    </li>
                                    <li>
                                        {/* <a href='#'> */}
                                            <img src={ImgLedger} style={{width:'115px'}} alt='Ledger' />
                                        {/* </a> */}
                                    </li>
                                    <li>
                                        {/* <a href='#'> */}
                                            <img src={ImgMetamask} style={{width:'115px'}} alt='Metamask' />
                                        {/* </a> */}
                                    </li>
                                    <li>
                                        {/* <a href='#'> */}
                                            <img src={ImgWalletConnect} style={{width:'115px'}} alt='Wallet Connect' />
                                        {/* </a> */}
                                    </li>
                                </ul>

                                <div className='nft-checkout-button-row'>
                                    <button className='nft-checkout-button' onClick={(e) => this.props.onCheckoutCryptoConfirmation(e, true)}>Pay Now</button>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            {/* PAYMENTS */}
                            <div className='nft-checkout-payment-row nft-checkout-payment-row-2'>
                                <h3 className='nft-checkout-wallets-heading'>Cash</h3>
                                <ul className='nft-checkout-payment-lists'>
                                    <li>
                                        <img src={ImgVisa} alt="Visa" />
                                    </li>
                                    <li>
                                        <img src={ImgMasterCard} alt="Master Card" />
                                    </li>
                                    <li>
                                        <img src={ImgAmericanExpress} alt="American Express" />
                                    </li>
                                    <li>
                                        <img src={ImgPaypal} alt="Paypal" />
                                    </li>
                                </ul>

                                <div className='nft-checkout-button-row'>
                                    <button className='nft-checkout-button' onClick={this.props.onCheckout}>Pay Now</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </form>
                
            </div>
        </div>
    );
  }
}
export default withTranslation()(NFTsCheckoutModal2);