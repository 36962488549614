import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Images
import imgQR from '../../../assets/img/tools/img-qr.jpg'

class ToolsLink extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   modal: false
    // };
    // this.toggle = this.toggle.bind(this);
  }

//   toggle() {
//     this.setState(prevState => ({
//       modal: !prevState.modal
//     }));
//   }

  render() {

    const { t, title, url, clicks, wallets} = this.props;

    return (
        <div className='tools-link-row'>

            <div className='tools-link-col tools-qr'>
                <img src={imgQR} />
            </div>

            <div className='tools-link-col tools-link'>
                <div className='tools-link-title'>{title}</div>
                <div className='v2-mt-0-5 tools-link-url'>
                    <a href='#'>{url}</a>
                </div>
            </div>

            <div className='tools-link-col tools-right flex align-center'>
                <div className='tools-right-text tools-clicks'>
                    Number of Clicks: {clicks}
                </div>
                <div className='tools-right-text tools-opened'>
                    Wallets opened: {wallets}
                </div>
            </div>

        </div>
    );
  }
}

export default ToolsLink;
