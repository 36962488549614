import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import yellowPaper from '../assets/img/OVATO_YELLOWPAPER.pdf';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { slice } from 'lodash'
import { withTranslation } from 'react-i18next';
import ImgFoundersClub01 from '../assets/img/founders-club/img-01-divyae.jpg'
import ImgLeftSpark from '../assets/img/img-left-spark-founders-club-2.jpg'
import foundersClubData from '../data/foundersClub';
class FoundersClubTop50NFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false,
      post: [],
      isCompleted: false,
      index: 9,
      perPage: 9
    };
  }
  getData = () => {
    this.setState({post: foundersClubData})
  }
  loadMore = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
        index: prevState.index + this.state.perPage
    }));
    if (this.state.index >= (this.state.post.length - this.state.perPage)) {
      this.setState({ isCompleted: true })
    } else {
      this.setState({ isCompleted: false })
    }
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  componentDidMount() {
    this.getData()
  }
  render() {
    const { t } = this.props;
    const initialPosts = slice(this.state.post, 0, this.state.index)
    return (
        <>
      <div className='founders-club-main'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER SECTION */}
        <div className='club-main-banner'>
            <Container>
                <Row>
                    <Col>
                        <h1 className='poppins font-55 bold white-text text-center club-main-banner-heading'>{t('foundersClubT50.founders')}</h1>
                        <h2 className='poppins font-37 bold text-uppercase ls-3 white-text text-center club-main-banner-subheading'>{t('foundersClubT50.theTop')}</h2>
                    </Col>
                </Row>
                <Row className='club-main-banner-2'>
                    <Col className='desktop'>
                        <div className='club-box-wrap'>
                            { foundersClubData.map((item, index) => (
                                <div className={(item.sold) ? ' club-box-col sold ' : 'club-box-col'}>
                                    <div className='club-box-col-inner'>
                                        <div className='club-box-img'>
                                            <img src={require('../assets/img/founders-club/' + item.img)} />
                                        </div>
                                        <div className='club-box-footer'>
                                            <div className='club-box-footer-left'>
                                                <span className='club-box-footer-num'>{ index + 1}</span>
                                                <h3 className='club-box-footer-title'>{ item.name }</h3>
                                            </div>
                                            <div className='club-box-footer-right'>
                                                <a href='#' className='club-box-btn'>{t('foundersClubT50.reserve')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </Col>
                    <Col className='mobile'>
                        <div className='club-box-wrap'>
                            { initialPosts.map((item, index) => (
                                <div className={(item.sold) ? ' club-box-col sold ' : 'club-box-col'}>
                                    <div className='club-box-col-inner'>
                                        <div className='club-box-img'>
                                            <img src={require('../assets/img/founders-club/' + item.img)} />
                                        </div>
                                        <div className='club-box-footer'>
                                            <div className='club-box-footer-left'>
                                                <span className='club-box-footer-num'>{ index + 1}</span>
                                                <h3 className='club-box-footer-title'>{ item.name }</h3>
                                            </div>
                                            <div className='club-box-footer-right'>
                                                <a href='#' className='club-box-btn'>{t('foundersClubT50.reserve')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                        
                        { !this.state.isCompleted && 
                            <div className='club-box-load-more-wrap'>
                                <a href='#' className='club-box-load-more-btn' onClick={(e) => this.loadMore(e)}>{t('foundersClubT50.load')}</a>
                            </div>
                        }
                        
                    </Col>
                        
                </Row>
            </Container>
        </div>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
      </>
    );
  }
}
export default withTranslation()(FoundersClubTop50NFT);