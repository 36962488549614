import React, { Component } from 'react'
import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink, Collapse, NavbarToggler, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import i18next from '../i18n';
import { withTranslation } from 'react-i18next';
import { WALLET_URL, EXPLORER_URL } from '../constants/defaultValues'
import ReactGA from 'react-ga';
import {GA_ID} from '../constants/defaultValues'
ReactGA.initialize(GA_ID);
class Topnav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            dropdown: false,
            langcode: localStorage.getItem('i18nextLng'),
            sidebar: false,
            logout: true,
            reloadpath: '',
        }
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleDropdown = () => {
        this.setState({ dropdown: true })
    }
    handleLanguage = (e) => {
        const langcode = localStorage.getItem('i18nextLng')
        const reloadpath = "/" + langcode + window.location.pathname.substring(3, window.location.pathname.length)
        this.setState({ langcode, reloadpath, dropdown: false })
        window.history.pushState(this.state, "", reloadpath)
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    toggleSidebar = () => {
        this.setState({ sidebar: !this.state.sidebar })
    }
    changeLanguage(lang) {
        i18next.changeLanguage(lang)
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className='top_main'>
                    <Container className='top_main_container'>
                        <div className='top_slogan'>{t('topbar.top_slogan')}<span style={{ color: '#D12476' }}> {t('topbar.learn_more')}</span></div>
                        <div className='top-social'>
                            <a rel='noopener noreferrer' href="https://www.youtube.com/watch?v=QvFtAtVnSik" target='_blank'><i className="fab fa-youtube fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true" target='_blank'><i className="fab fa-linkedin fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.facebook.com/OvatoCoin" target='_blank'><i className="fab fa-facebook fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://twitter.com/OvatoCoin" target='_blank'><i className="fab fa-twitter fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.instagram.com/ovatocoin/" target='_blank'><i className="fab fa-instagram fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://t.me/Ovato_Coin" target='_blank'><i className="fab fa-telegram fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://bitcointalk.org/index.php?topic=3012780.msg30971176#msg30971176" target='_blank'><i className="fab fa-bitcoin fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://www.reddit.com/user/ovatotoken/" target='_blank'><i className="fab fa-reddit fa-lg top_icons"></i></a>
                            <a rel='noopener noreferrer' href="https://github.com/Ovato-Pte-Ltd" target='_blank'><i className="fab fa-github fa-lg top_icons"></i></a>
                        </div>
                    </Container>
                </div>
                <div className='top_secondary top_secondary_web'>
                    <Container className='pt-3 pb-2'>
                        <Navbar light expand='md'>
                            <NavbarBrand href="/"><img alt="Ovato Logo" src={require("../assets/img/img-ovato-logo-new-header.png")} className="top_logo" /></NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className='top_navbar_toggler' />
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav navbar className='top_nav_list'>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href="/wallet">{t('topbar.get_started')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={WALLET_URL}>{t('topbar.web_wallet')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href="/support">{t('topbar.support')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href="/press">{t('topbar.press')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className='top_nav_list_items' href={EXPLORER_URL}>{t('topbar.explorer')}</NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                            <Dropdown isOpen={this.state.dropdown} size="sm" toggle={this.handleDropdown}>
                                {this.state.logout ?
                                    <><DropdownToggle tag='a' className="nav-link top_language_code" caret>
                                        {this.state.langcode === 'ko' ? <><Flag name='kr' /><span className='top_language_code'>KO</span></> :
                                            this.state.langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                                                this.state.langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                                                    this.state.langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                                                        <><Flag name='us' className='top_language_code' /><span>EN</span></>}
                                    </DropdownToggle>
                                        <DropdownMenu onClick={this.handleLanguage} className='top_language_drpdwn'>
                                            <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                                            <DropdownItem value='english' onClick={() => this.changeLanguage('en')}><Flag name='us' />{t('topbar.english')}</DropdownItem>
                                            <DropdownItem value='chinese' onClick={() => this.changeLanguage('zh')}><Flag name='cn' />{t('topbar.chinese')}</DropdownItem>
                                            <DropdownItem value='german' onClick={() => this.changeLanguage('de')}><Flag name='de' />{t('topbar.german')}</DropdownItem>
                                            <DropdownItem value='korean' onClick={() => this.changeLanguage('ko')}><Flag name='kr' />{t('topbar.korean')}</DropdownItem>
                                            <DropdownItem value='japanese' onClick=
                                                {() => this.changeLanguage('ja')}><Flag name='jp' />{t('topbar.japanese')}</DropdownItem>
                                        </DropdownMenu></> :
                                    <><DropdownToggle tag='a' className="nav-link" caret>
                                        <i className="far fa-user-circle top_user_icon" /><span className='top_myacc'>{t('topbar.account')}</span>
                                    </DropdownToggle>
                                        <DropdownMenu className='top_user_drpdwn'>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.profile')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/wordbackup'>{t('topbar.backup')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.wallet')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.balance')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/stackcoins'>{t('topbar.stack')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.graph')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.logout')}</DropdownItem>
                                        </DropdownMenu></>
                                }
                            </Dropdown>
                        </Navbar>
                    </Container>
                </div>
                <div className='top_secondary top_secondary_phone'>
                    <Container>
                        <Navbar light expand='md' className='pt-2'>
                            <div className='navbar'>
                                <Link to='#' className='menu-bars'>
                                    <FaBars onClick={this.toggleSidebar} />
                                </Link>
                            </div>
                            <NavbarBrand href="/"><img alt="Ovato Logo" src={require("../assets/img/img-ovato-logo-new-header.png")} className="top_logo ml-3" /></NavbarBrand>
                            <Dropdown isOpen={this.state.dropdown} size="sm" toggle={this.handleDropdown}>
                                {this.state.logout ?
                                    <><DropdownToggle tag='a' className="nav-link top_language_code" caret>
                                        {this.state.langcode === 'en' ? <><Flag name='us' /><span className='top_language_code'>EN</span></> :
                                            this.state.langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                                                this.state.langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                                                    this.state.langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                                                        <><Flag name='kr' className='top_language_code' /><span>KO</span></>}
                                    </DropdownToggle>
                                        <DropdownMenu onClick={this.handleLanguage} className='top_language_drpdwn'>
                                            <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                                            <DropdownItem value='english' onClick={() => this.changeLanguage('en')}><Flag name='us' />{t('topbar.english')}</DropdownItem>
                                            <DropdownItem value='chinese' onClick={() => this.changeLanguage('zh')}><Flag name='cn' />{t('topbar.chinese')}</DropdownItem>
                                            <DropdownItem value='german' onClick={() => this.changeLanguage('de')}><Flag name='de' />{t('topbar.german')}</DropdownItem>
                                            <DropdownItem value='korean' onClick={() => this.changeLanguage('ko')}><Flag name='kr' />{t('topbar.korean')}</DropdownItem>
                                            <DropdownItem value='japanese' onClick={() => this.changeLanguage('ja')}><Flag name='jp' />{t('topbar.japanese')}</DropdownItem>
                                        </DropdownMenu></> :
                                    <><DropdownToggle tag='a' className="nav-link" caret>
                                        <i className="far fa-user-circle top_user_icon" />
                                    </DropdownToggle>
                                        <DropdownMenu className='top_user_drpdwn'>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.profile')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/wordbackup'>{t('topbar.backup')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.wallet')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.balance')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list' href='/stackcoins'>{t('topbar.stack')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.graph')}</DropdownItem>
                                            <DropdownItem className='top_user_drpdwn_list'>{t('topbar.logout')}</DropdownItem>
                                        </DropdownMenu></>
                                }
                            </Dropdown>
                        </Navbar>
                    </Container>
                    <nav className={this.state.sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <div className='top_slogan'>{t('topbar.top_slogan')}<span style={{ color: '#D12476' }}><u>{t('topbar.learn_more')}</u></span></div>
                        <ul className='nav-menu-items'>
                            <li className='navbar-toggle'>
                                <Link to='#' className='menu-bars_cross text-white'>
                                    <AiOutlineClose onClick={this.toggleSidebar} />
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <Link to='/wallet'>
                                    <span>{t('topbar.get_started')}</span>
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <a href={WALLET_URL}>
                                    <span>{t('topbar.web_wallet')}</span>
                                </a>
                            </li>
                            <li className='nav-text'>
                                <Link to='/support'>
                                    <span>{t('topbar.support')}</span>
                                </Link>
                            </li>
                            <li className='nav-text'>
                                <a href='/press'>
                                    <span>{t('topbar.press')}</span>
                                </a>
                            </li>
                            <li className='nav-text'>
                                <a href={EXPLORER_URL}>
                                    <span>{t('topbar.explorer')}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="pink-border"></div>
            </div>
        )
    }
}
export default withTranslation()(Topnav)
