import './assets/css/App.css';
import './assets/css/mediaquery.css';
import React, { Suspense } from 'react';
import Route from './routes';
import { ToastContainer } from 'react-toastify';
const App = () => {  
  return (
    <Suspense fallback='Loading...'>
      <ToastContainer />
      <Route />
    </Suspense>
  );
}
export default App;
