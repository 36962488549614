import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import 'react-multi-carousel/lib/styles.css';
import { withTranslation } from 'react-i18next';
import imgProbit from '../assets/img/img-pro-bit-6.png';
import imgP2P from '../assets/img/img-p2p-b2b-6.png';
import imgWhiteBit from '../assets/img/img-white-page.png';
import imgEarnMore from '../assets/img/img-earn-more-6.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import imgFastAvow from '../assets/img/img-fast-avow-6.jpg';
import imgChineseNews from '../assets/img/img-chinese-news-6.jpg';
import imgCryptoGlobe from '../assets/img/img-crypto-globe-6.jpg';
import imgFirstCryptoNews from '../assets/img/img-first-crypto-news-6.jpg';
import imgLbank from '../assets/img/img-lbank-6.png';
class Section6 extends Component {
  render() {
    const { t } = this.props;
    const expertSlider = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 4,
      slidesToScroll: 4,
    };
    const expertSliderMobile = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '60px',
    };
    const sliders = [
      { title: 'Fastavow', img: imgFastAvow },
      { title: 'Crypto Globe', img: imgCryptoGlobe },
      {
        title: 'China News',
        img: imgChineseNews,
      },
      {
        title: 'First Crypto News',
        img: imgFirstCryptoNews,
      },
      { title: 'Crypto Globe', img: imgCryptoGlobe },
    ];
    return (
      <>
        <section className='section-6-new pt-120 desktop'>
          <Container>
            <Row>
              <Col>
                <h2 className='text-center white-text extra-bold font-87 max-width-800 lh-1 m-auto'>
                  {t('section6.three_ways')}
                </h2>
              </Col>
            </Row>
            <Row className='align-center mt-80 section-6-new-row-2'>
              <Col md={5} className='flex align-center'>
                <div className='pink-gradient-num-wrap'>1</div>
                <h3 className='font-57 bold white-text lh-1-1 mx-51 mt-0'>
                  {t('section6.invest_and_trade')}
                </h3>
              </Col>
              <Col md={7}>
                <p className='font-20 white-text medium'>
                  {t('section6.detail1')}
                </p>
              </Col>
            </Row>
            <Row className='mt-160'>
              <Col>
                <h3 className='text-center white-text extra-bold font-57 lh-1-1'>
                  {t('section6.buy')} <br /> {t('section6.on')}
                </h3>
              </Col>
            </Row>
            {/* PROBIT ROW */}
            <Row className='mt-350'>
              <Col md={4}>
                <div className='img-wrap white-box-6'>
                  <img src={imgProbit} alt='Probit' width='264' />
                </div>
              </Col>
              <Col md={8}>
                <p className='mt-40 font-21 medium white-text lh-1-3'>
                  {t('section6.level')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width c-lg-btn gradient-pink-btn-3'
                        href='http://probit.com'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                  <Col>
                    <div className='c-country-box-wrap'>
                      <div className='country-box'>
                        <div className='country-box-inner'>
                          {t('section6.usa')}
                        </div>
                      </div>
                      <div className='country-box'>
                        <div className='country-box-inner'>
                          {t('section6.asia')}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END PROBIT ROW */}
            {/* P2P ROW */}
            <Row className='mt-60'>
              <Col md={4}>
                <div className='img-wrap white-box-6'>
                  <img src={imgP2P} alt='P2P B2B' width='264' />
                </div>
              </Col>
              <Col md={8}>
                <p className='mt-40 font-21 medium white-text lh-1-3'>
                  {t('section6.cryptocurrency')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width c-lg-btn gradient-pink-btn-3'
                        href='http://p2pb2b.io'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                  <Col>
                    <div className='c-country-box-wrap'>
                      <div className='country-box'>
                        <div className='country-box-inner'>Europe</div>
                      </div>
                      <div className='country-box'>
                        <div className='country-box-inner'>
                          {t('section6.usa')}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END P2P ROW */}
            {/* WHITEBIT ROW */}
            <Row className='mt-60'>
              <Col md={4}>
                <div className='img-wrap white-box-6'>
                  <img src={imgWhiteBit} alt='Whitebit' width='264' />
                </div>
              </Col>
              <Col md={8}>
                <p className='mt-40 font-21 medium white-text lh-1-3'>
                  {t('section6.based')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width c-lg-btn gradient-pink-btn-3'
                        href='http://WhiteBit.com'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                  <Col>
                    <div className='c-country-box-wrap'>
                      <div className='country-box'>
                        <div className='country-box-inner'>Europe</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END WHITEBIT ROW */}
            {/* LBANK ROW */}
            <Row className='mt-60'>
              <Col md={4}>
                <div className='img-wrap white-box-6'>
                  <img src={imgLbank} alt='Lbank' width='264' />
                </div>
              </Col>
              <Col md={8}>
                <p className='mt-40 font-21 medium white-text lh-1-3'>
                  {t('section6.lbank')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width c-lg-btn gradient-pink-btn-3'
                        href='http://lbank.info'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                  <Col>
                    <div className='c-country-box-wrap'>
                      <div className='country-box'>
                        <div className='country-box-inner'>
                          {t('section6.usa')}
                        </div>
                      </div>
                      <div className='country-box'>
                        <div className='country-box-inner'>
                          {t('section6.asia')}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END LBANK ROW */}
            <Row className='mt-60'>
              <Col>
                <p className='font-25 bold text-center blue-text lh-1-2'>
                  {t('section6.ovo1')}
                  <br />
                  {t('section6.ovo2')}
                </p>
              </Col>
            </Row>
            <Row className='row-6-stacking align-center p-60 mt-60'>
              <Col>
                <h3 className='font-33 bold blue-text text-uppercase'>
                  {t('section6.earn_more')}
                </h3>
                <p className='font-20 medium'>
                  {t('section6.detail2')}
                  {t('section6.detail3')}
                </p>
                <p className='font-26 bold pink-text mb-0'>
                  {t('section6.earn_additional_ovato')}
                </p>
                <p className='font-25 bold mb-0 mt-10'>
                  1 - {t('section6.month_package')} - 3%
                </p>
                <p className='font-25 bold mb-0 mt-5'>
                  3 - {t('section6.month_package')} - 8%
                </p>
                <p className='font-25 bold mb-0 mt-5'>
                  12 - {t('section6.month_package')} - 30%
                </p>
                <div className='btn-wrap mt-30'>
                  <a className='pink-btn-2' href='/'>
                    Find Out More
                  </a>
                </div>
              </Col>
              <Col>
                <div className='img-wrap text-center'>
                  <img width='409' src={imgEarnMore} alt='Earn More' />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-6-new-mobile mobile pt-60'>
          <Container>
            <Row>
              <Col>
                <h2 className='font-37 extra-bold white-text text-center lh-1-2'>
                  {t('section6.three_ways')}
                </h2>
              </Col>
            </Row>
            <Row className='align-center mt-20'>
              <Col xs={5} className='text-right'>
                <div className='pink-gradient-num-wrap'>1</div>
              </Col>
              <Col xs={7} className='text-left pl-10'>
                <h3 className='font-27 bold white-text lh-1-2'>
                  {t('section6.invest_and_trade')}
                </h3>
              </Col>
            </Row>
            <Row className='mt-20'>
              <Col>
                <p className='font-18 medium text-center white-text'>
                  {t('section6.detail1')}
                </p>
              </Col>
            </Row>
            <Row className='mt-60'>
              <Col>
                <h3 className='font-27 extra-bold white-text text-center lh-1-1'>
                  {t('section6.buy')} <br /> {t('section6.on')}
                </h3>
              </Col>
            </Row>
            {/* PROBIT ROW */}
            <Row className='mt-40'>
              <Col md={12}>
                <div className='c-country-box-wrap'>
                  <div className='country-box'>
                    <div className='country-box-inner'>{t('section6.usa')}</div>
                  </div>
                  <div className='country-box'>
                    <div className='country-box-inner'>
                      {t('section6.asia')}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <div className='img-wrap white-box-6'>
                  <img src={imgProbit} alt='Probit' width='225' />
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <p className='mt-40 font-18 medium white-text lh-1-5 text-center'>
                  {t('section6.level')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width gradient-pink-btn-3'
                        href='http://probit.com'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END PROBIT ROW */}
            {/* P2P ROW */}
            <Row className='mt-45'>
              <Col md={12}>
                <div className='c-country-box-wrap'>
                  <div className='country-box'>
                    <div className='country-box-inner'>Europe</div>
                  </div>
                  <div className='country-box'>
                    <div className='country-box-inner'>{t('section6.usa')}</div>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <div className='img-wrap white-box-6'>
                  <img src={imgP2P} alt='Probit' width='225' />
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <p className='mt-40 font-18 medium white-text lh-1-5 text-center'>
                  {t('section6.cryptocurrency')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width gradient-pink-btn-3'
                        href='http://p2pb2b.io'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END P2P ROW */}
            {/* WHITEBIT ROW */}
            <Row className='mt-45'>
              <Col md={12}>
                <div className='c-country-box-wrap'>
                  <div className='country-box'>
                    <div className='country-box-inner'>Europe</div>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <div className='img-wrap white-box-6'>
                  <img src={imgWhiteBit} alt='Probit' width='225' />
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <p className='mt-40 font-18 medium white-text lh-1-5 text-center'>
                  {t('section6.based')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width gradient-pink-btn-3'
                        href='http://WhiteBit.com'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END WHITEBIT ROW */}
            {/* LBANK ROW */}
            <Row className='mt-45'>
              <Col md={12}>
                <div className='c-country-box-wrap'>
                  <div className='country-box'>
                    <div className='country-box-inner'>{t('section6.usa')}</div>
                  </div>
                  <div className='country-box'>
                    <div className='country-box-inner'>
                      {t('section6.asia')}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <div className='img-wrap white-box-6'>
                  <img src={imgLbank} alt='Lbank' width='225' />
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <p className='mt-40 font-18 medium white-text lh-1-5 text-center'>
                {t('section6.lbank')}
                </p>
                <Row className='align-center mt-30'>
                  <Col>
                    <div className='btn-wrap'>
                      <a
                        className='gradient-pink-btn full-width gradient-pink-btn-3'
                        href='http://lbank.info'
                        target='_blank'
                      >
                        {t('section6.buy_now')}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* END LBANK ROW */}
            <Row className='mt-40'>
              <Col>
                <p className='font-23 bold text-center blue-text lh-1-2'>
                  {t('section6.ovo1')} {t('section6.ovo2')}
                </p>
              </Col>
            </Row>
            <Row className='row-6-stacking align-center py-40 mt-40 mx-auto full-width'>
              <Col md={12}>
                <h3 className='font-23 bold blue-text text-uppercase text-center'>
                  {t('section6.earn_more')}
                </h3>
                <p className='font-18 medium text-center'>
                  {t('section6.detail2')}
                  {t('section6.detail3')}
                </p>
                <p className='font-26 bold pink-text mb-0 text-center  lh-1-2'>
                  {t('section6.earn_additional_ovato')}
                </p>
                <p className='font-24 bold mb-0 mt-15 text-center lh-1-2'>
                  3 - {t('section6.month_package')} - 6%
                </p>
                <p className='font-24 bold mb-0 mt-15 text-center lh-1-2'>
                  6 - {t('section6.month_package')} - 12.5%
                </p>
                <p className='font-24 bold mb-0 mt-15 text-center lh-1-2'>
                  12 - {t('section6.month_package')} - 30%
                </p>
                <div className='btn-wrap mt-30 text-center'>
                  <a className='pink-btn-2' href='/'>
                    Find Out More
                  </a>
                </div>
              </Col>
              <Col md={12}>
                <div className='img-wrap text-center mt-30'>
                  <img width='300' src={imgEarnMore} alt='Earn More' />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-6-slider pt-80 pb-80 desktop'>
          <Container>
            <Row>
              <Col>
                <h3 className='font-25 bold text-center gray-text'>
                  {t('section6.discover')}
                </h3>
                <Slider
                  {...expertSlider}
                  className='expert-slider pink-arrows pink-dot mt-40'
                >
                  {sliders.map((slider) => (
                    <div className='slider-col'>
                      <div className='slider-inner'>
                        <img src={slider.img} alt={slider.title} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-6-slider pt-10 pb-80 mobile'>
          <Container className='p-0'>
            <Row>
              <Col>
                <Slider
                  {...expertSliderMobile}
                  className='expert-slider pink-arrows pink-dot mt-40'
                >
                  {sliders.map((slider) => (
                    <div className='slider-col'>
                      <div className='slider-inner'>
                        <img src={slider.img} alt={slider.title} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section6);
