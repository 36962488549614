import React from 'react'
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import YouTubePlayer from '../../components/YoutubePlayer';
import '../../assets/css/OvatoNation.css'
import '../../assets/css/OvatoNation3.css'
import ImgOvato from '../../assets/img/logo-ovato-nation.png'
import ImgMembershipsAndWallets from '../../assets/img/img-o-n-memberships-and-wallets.png'
import ImgNFTs from '../../assets/img/img-o-n-nfts.png'
import ImgBuildingWealth from '../../assets/img/img-o-n-building-wealth.png'
import GovatoTools from '../../assets/img/govato-tools-sign-up.png'
import ImgIndustryDevelopment from '../../assets/img/img-industry-development-and-updates.png'
import ImgCoinMarketCap from '../../assets/img/img-coinmarketcap.png'
import ImgWeNeedYou from '../../assets/img/img-we-need-you.png'
import ImgFooter from '../../assets/img/img-o-n-i1-v3-footer.png'
import ImgFooterMobile from '../../assets/img/img-v3-footer-mobile.png'
import ImgNFT from '../../assets/img/img-nft-v3.png'
import ImgLaunching from '../../assets/img/img-v3-launching.png'
import ImgLaunchingMobile1 from '../../assets/img/img-v3-launch-mobile-1.png'
import ImgLaunchingMobile2 from '../../assets/img/img-v3-launch-mobile-2.png'
import ImgMap from '../../assets/img/img-v3-map.png'
import imgVideoLeft from '../../assets/img/img-video-left.png'
import imgVideoRight from '../../assets/img/img-video-right.png'
const OvatoNation3 = () => {
  return (
    <div className='ovato-nation-main'>
      
      {/* SECTION 1 */}
      <section className='o-n-1'>
        <Container>
          <Row>
            <Col sm={9} className='o-n-1-left'>
              <div className='o-n-1-logo'>
                <a href='/'><img src={ImgOvato} alt='Ovato' /></a>
                <p className='white-text text-center poppins font-18 regular text-uppercase mt--1'>symbol: OVO</p>
              </div>
              <div className='o-n-1-left-2'>
                <h1 className='poppins mb-2 lh-1 n-font-45 semi-bold white-text'>Ovato Nation!</h1>
                <p className='poppins lh-1 n-font-30 semi-bold white-text'>Let's Go!</p>
              </div>
            </Col>
            <Col sm={3} className='o-n-1-right-col'>
              <p className='poppins n-font-18 regular white-text o-n-1-right-text'>Volume 1, Issue 3</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 2 */}
      <section className='o-n-2 o-n-2-new'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-2-left-col o-n-2-left-col-new mt--80 t-mt--60 m-mt-0'>
                <h2 className='poppins n-font-42 bold dark-text-7 m-n-font-28'>Ovato update</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>The second quarter of this year has been very interesting for our industry, to say the least. We believe that the strategy we have chosen, our commitment to real retail utility and mass adoption, is the correct way to navigate this extremely dynamic and fluid industry. </p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>In this issue we aim to show how our original mind map will be coming more and more into focus as we encourage <strong>community participation.</strong> Ovato will now emerge as a truly decentralized currency with a sharply branded look and feel, with the greatest ease of use and fastest speed of transactions.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7'>We believe that this is the time when the chasm between the ‘believers’ and the ‘non-believers’ in this industry will become drastically obvious.
</p>
            </Col>
            <Col sm={4}>
              <div className='o-n-2-box o-n-2-box-new'>
                <h2 className='poppins n-font-30 bold dark-text-5 mb-4'>Helpful Hints</h2>
                <ul className='o-n-2-ul'>
                  <li>Always update your App</li>
                  <li>If you're not trading you should be stacking</li>
                  <li>Use chat support and don't rely on 3rd party info</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 3 */}
      <section className='o-n-3'>
        <Container>
          <Row className='align-center'>
            <Col sm={4}>
              <div className='o-n-3-img-wrap'>
                <img src={ImgIndustryDevelopment}  alt='Industry Development And Updates' />
              </div>
            </Col>
            <Col sm={8} className='o-n-3-left o-n-3-left-new'>
                <h2 class="poppins n-font-38 tp-n-font-32 m-n-font-28 bold dark-text-7">Industry Development <br /> And Updates</h2>
                <p class="poppins n-font-16 light dark-text-3 lh-1-7">The world just experienced the biggest attack on cryptocurrencies to date. This attack was led by the most powerful regulatory body in the world, the SEC, which announced suit to the two largest global exchanges, Binance and Coinbase; rattling the industry globally. 
</p>
                <p class="poppins n-font-16 light dark-text-3 lh-1-7">While prices on the exchanges plummeted, the community has responded by rallying behind top tier pe rformers, which resulted in Bitcoin rising to new interim highs at $30k.</p>
                <p class="poppins n-font-16 light dark-text-3 lh-1-7">We believe that our value proposition is already the best in the industry because Ovato’s strength lies in the coupling of lightning speed transactions with an amazing store of value program. In short, our technology.</p>
            </Col>
          </Row>
        </Container>
      </section>
    {/* v3 5 */}
      <section className='o-n-v3-5'>
        <Container>
          <Row className='align-center'>
            <Col sm={6} className='tp-order-2'>
                <h2 className='poppins n-font-42 bold dark-text-7 tp-mt-30 m-n-font-28'>CoinMarketCap </h2>
                <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>CMC has begun coverage of the Ovato blockchain. While the cap table is currently displayed in terms of the amounts of circulating coins, the ranking is still delayed and subject to the audit of our Blockchain being completed. We anticipate this will be accomplished and finalized in the next couple of weeks. In the meantime, Ovato has been already ranked in the top 350 on some competing platforms, which puts us in the top 1% of all cryptocurrencies ! We are looking forward to this being done shortly and showcasing the platform heavily at such time.</p>
            </Col>
            <Col sm={6} className='tp-order-1'>
                <div className='pl-60 img-wrap tp-pl-0 m-img-150'>
                    <img src={ImgCoinMarketCap} width='200' alt='CoinMarketCap '/>
                </div>
            </Col>
          </Row>
          <Row className='align-center mt-60'>
            <Col sm={6}>
                <div className='pl-60 img-wrap tp-pl-0 m-img-250'>
                    <img src={ImgWeNeedYou} width='375' alt='We Need You '/>
                </div>
            </Col>
            <Col sm={6}>
                <h2 className='poppins n-font-42 bold dark-text-7 tp-mt-30 m-n-font-28'>We Need You !</h2>
                <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Looking to make some more Ovato ? We need contributors of all kinds from online chat management-Community participation for questions and answers. </p>
                <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Let's help grow the community together in messages online. It's a great way to help grow the community and earn some more Ovato along the way !</p>
            </Col>
          </Row>
          <Row>
            <Col>
                <div className='o-n-v3-5-box'>
                    <h3 className='poppins n-font-24 tp-n-font-20 bold white-text'>And this is where you come into the picture.</h3>
                    <p className='poppins n-font-17 tp-n-font-16 medium white-text m-mt-30'>
                        Now is the time to get involved. Our proprietary platform, combined with our very unique pathway to mass adoption makes it the perfect time to get involved and help sound the war drums.
                    </p>
                    <p className='poppins n-font-16 light white-text lh-1-7 mt-20'>As we begin to focus on promotion, <strong>you</strong> are the lever we need to not only spread the word but also participate in. We want you to use the platforms we have created and engage with us to grow our new community online and via all media channels. </p>
                    <h3 className='poppins n-font-24 tp-n-font-20 bold white-text mt-40'>We will need your participation and engagement! </h3>
                    <p className='poppins n-font-16 light white-text lh-1-7 mt-20'>You can help Ovato to succeed not just by participating in social media but also by helping to answer questions and manage online chat forums. We will also welcome your help in translating our content to facilitate the expansion of our ecosystem around the globe.</p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* v3 6 */}
      <section className='o-n-v3-6'>
        <Container>
          <Row className='align-center'>
            <Col sm={6}>
                <h2 className='n-font-92 tp-n-font-72 m-n-font-62 poppins bold dark-text-7 lh-1 mb-0'>NFT</h2>
                <p className='poppins n-font-18 semi-bold dark-text-3 mt-20'>Each NFT includes:</p>
                <ul className='o-n-ul'>
                  <li>Over 100% face value of Ovato (OVO)</li>
                  <li>Each NFT earns 10% Merchant Transaction exclusively within their territory forever</li>
                  <li>Each NFT is loaded with rights and privileges and true intellectual property represented by unique individual art and memorialized on the blockchain</li>
                  <li>Ability to earn additional income with one year free membership to GOVATO ( $600 value)</li>
                </ul>
            </Col>
            <Col sm={6}>
                <div className='pl-60 m-pl-0 img-wrap o-n-v3-6-img-wrap tp-mt-20 m-img-250'>
                    <img width={320} src={ImgNFT} alt='NFT Carlos'/>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* v3 7 */}
      <section className='o-n-v3-7'>
        <Container>
          <Row className='align-center'>
            <Col sm={12}>
               <div className='video-wrap nft-collections-video-wrap nft-collections-video-wrap-2'>
                    <div className='img-wrap nft-coll-main-img-wrap-1'>
                        <img src={imgVideoLeft} />
                    </div>
                    
                    <div class='nft-coll-main-video-wrap'>
                        <YouTubePlayer videoId={'f_w6CzDuadE'} />
                    </div>
                    <div className='img-wrap nft-coll-main-img-wrap-2'>
                        <img src={imgVideoRight} />
                    </div>
                </div>
            </Col>
          </Row>
          <Row className='o-n-v3-7-row-2'>
            <Col>
              <h2 className='poppins n-font-45 tp-n-font-35 m-n-font-28 bold white-text text-center mb-0 mt-80 m-mt-20'>Launching in July 11</h2>
              <p className='poppins n-font-29 tp-n-font-24 m-n-font-20 medium white-text text-center lh-1-2 mt-10'>Our NFT collection offers a great store of value </p>
              <div className='img-wrap text-center mt-40'>
                <img src={ImgMap} alt='Map' width='680' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* v3 8 */}
      <section className='o-n-v3-8'>
        <Container>
          <Row className='align-center'>
            <Col sm={12}>
              <div className='o-n-v3-8-box'>
                <h2 className='poppins n-font-34 tp-n-font-29 m-n-font-24 bold dark-text-5 text-center'>Would you like to earn more Ovato?</h2>
                <p className='poppins n-font-16 light dark-text-3 text-center'>All of these activities will be incentivized through Ovato earnings: You now have the opportunity to grow your own holdings through the new hub we created just for this purpose, Govato.com.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION V3 4 */}
      <section className='o-n-v3-4'>
        <Container>
          <Row>
            <Col sm={8} className='o-n-4-left o-n-4-left-new'>
              <h2 className='poppins n-font-37 bold dark-text-5 tp-n-font-32'>Govato is now LIVE!</h2>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Govato is the platform for the build-out of our economy via everyone in our community. It is a forum that provides a fun and easy way for users to share links and information while making money every step of the way.</p>
              <p className='poppins n-font-16 light dark-text-3 lh-1-7 mt-20'>Visit <a className='text-underline dark-text-3' target='_blank' href='https://govato.io/c/b5246e'>govato.io</a> right now to get your tools to bring on and <strong>sign up</strong> new users and merchants, such as affiliate links and printable materials. </p>
               <p className='poppins n-font-16 semi-bold dark-text-3 lh-1-7 mt-20'>Use the link below and a free Ovato T-shirt when you sign up <br /> <a className='pink-text-4' href='https://govato.io/c/b5246e' target='_blank'>https://govato.io/c/b5246e</a></p>
              <div className='mt-40 o-n-4-img-wrap-2'>
                <img src={GovatoTools} alt='Govato Tools' />
              </div>
            </Col>
            <Col sm={4}>
              <div className='o-n-box o-n-box-new'>
                <h3 className='poppins n-font-30 bold text-center dark-text-5'>3 ways to Earn</h3>
                <div className='o-n-box-img'>
                  <img src={ImgMembershipsAndWallets} alt='Memberships and Wallets' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Memberships and Wallets</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgNFTs} alt='NFTs' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>NFT's</h4>
                </div>
                <div className='o-n-box-img'>
                  <img src={ImgBuildingWealth} alt='Building Wealth' />
                  <h4 className='poppins n-font-16 semi-bold dark-text-5 o-n-box-title'>Building Wealth</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 6 */}
      <section className='o-n-v1-i3-footer'>
        <Container>
          <Row>
            <Col className='m-order-2'>
              <h2 className='poppins n-font-25 semi-bold white-text m-mt-0'>Sign up free and <br /> get a free T-shirt <br /> this month</h2>
              <div className='mt-30 o-n-btn-wrap'>
                <a href='https://govato.io/c/b5246e' target='_blank' className='o-n-btn'>Discover Now</a>
              </div>
            </Col>
            <Col className='m-order-1'>
                <div className='img-wrap desktop'>
                    <img src={ImgFooter} />
                </div>
                <div className='img-wrap mobile m-img-250'>
                    <img src={ImgFooterMobile} />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default OvatoNation3