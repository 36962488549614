import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import yellowPaper from '../assets/img/OVATO_YELLOWPAPER.pdf';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Images
import ImgYellowPaper from '../assets/img/img-yellow-paper-banner.png'
class YellowPaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false,
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  render() {
    const { t } = this.props;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return (
      <div className='blue-nav support_main'>
        {/* HEADER */}
        <TopNav />
         {/* BANNER */}
        <div className='new-first-row brand-main-new yellow-paper-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={12}>
                <h1 className='Poppins font-43 bold blue-text text-uppercase text-center yellow-paper-banner-new-heading'>{t('newYellowPaper.yellowPaper')}</h1>
                <div className='btn-wrap text-center'>
                  <a className='gradient-pink-btn gradient-pink-btn-3 c-md-btn inline-btn white-paper-dl-btn mt-5' href={require('../assets/img/OVATO_YELLOWPAPER.pdf')} download='OVATO_YELLOWPAPER.pdf'>{t('newYellowPaper.download')}</a>
                </div>
              </Col>
              <Col md={12} className='yellow-paper-banner-new-col-2'>
                <div className='img-wrap desktop yellow-paper-banner-new-img-wrap'>
                  <img src={ImgYellowPaper} />
                </div>
                <div className='img-wrap mobile yellow-paper-banner-new-img-wrap'>
                  <img src={ImgYellowPaper} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <section className='yellow-paper-2 white-top-curve-4'>
          <Container className='text-center white-section-2'>
            <center>
              <Document
                file={yellowPaper}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                {Array.from(new Array(this.state.numPages), (el, index) => (
                  <Page
                    className='page'
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    size='A4'
                    wrap
                  >
                    <p
                      className='pageNumbers '
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                      }
                      fixed
                    />
                  </Page>
                ))}
              </Document>
            </center>
          </Container>  
        </section>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(YellowPaper);