import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container, Row, Col} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { deviceDetect } from '../constants/defaultValues';
import { Service } from '../providers/Services';
import { toast } from 'react-toastify';
import imgBaf from '../assets/img/img-baf.png'
import imgBaf3 from '../assets/img/img-baf-03.png'
import imgBaf4 from '../assets/img/img-baf-04.png'
const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class BecomeAnAffiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            country: ''
        };
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
        handleSend = (e) => {
        e.preventDefault()
        if (this.state.email === '') {
            toast.error("Email can't be empty !", toaststyle);
        } else {
        const payload = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            country: this.state.country,
            type: 'affiliate',
            os: deviceDetect.os,
            browser: deviceDetect.browsername,
            device: deviceDetect.devicetype,
            plateform: deviceDetect.plateform,
        };
        Service.affiliationForm(payload)
            .then((res) => {
            toast.success(
                'Thank you for signing up!',
                toaststyle
            );
            })
            .catch((error) => {
            if (error && error.status === 400) {
                toast.error(
                'Email is invalid, please enter a valid email!',
                toaststyle
                );
            } else {
                toast.error(
                error && error.status==429
                    ? error.data
                    : 'Some error occurred!',
                toaststyle
                );
            }
            });
        }
    };
    render() {
        const { t } = this.props;
        return (
            <div className='baf-main'>  
                {/* HEADER */}
                <TopNav />
                {/* SECTION 1 */}
                <section className='baf-1-new'>
                    <Container>
                        <Row>
                            <Col md={12} className='baf-1-new-col-1'>
                                <h1 className='font-55 Poppins bold mb-0 baf-heading-1 dark-text-5'>{t('affiliate.become')}</h1>
                                <h2 className='font-21 Poppins medium mt-0 baf-heading-2 blue-text'>{t('affiliate.sign_up_merchants')}</h2>
                            </Col>
                        </Row>
                        <Row className='mt-40 baf-1-new-row-2'>
                            <Col md='7' className='baf-1-new-row-2-left'>
                                <ul className='blue-check-ribbon-list blue-check-ribbon-list-small blue-pink-ribbon font-18 baf-ul'>
                                    <li>{t('affiliate.earn_ovato')}</li>
                                    <li>{t('affiliate.earn_residual')}</li>
                                    <li>{t('affiliate.help_merchants')}</li>
                                    <li> {t('affiliate.offer_merchants')}</li>
                                    <li> {t('affiliate.ovato_offers')}</li>
                                    <li> {t('affiliate.turnkey')} </li>
                                    <li>{t('affiliate.quick')}</li>
                                    <li> {t('affiliate.startup')}</li>
                                </ul>
                                <div className='btn-wrap mt-30 baf-1-new-btn-wrap'>
                                    <a href='#' className='custom-new-btn custom-new-btn-medium'>{ t('newAffiliate.join') }</a>
                                </div>
                            </Col>
                            <Col md='5'>
                                <div className='baf-form-wrap'>
                                    <form className='baf-form'>
                                        <h3 className='font-30 text-center Poppins bold mb-30 dark-text-5'>{t('affiliate.fill_out')}</h3>
                                        <div className='form-field mt-20'>
                                            <input 
                                                type='text' 
                                                name='name' 
                                                placeholder={t('affiliate.name')} 
                                                className='form-control' 
                                                value={this.state.name}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        <div className='form-field'>
                                            <input 
                                                type='phone' 
                                                name='phone' 
                                                placeholder={t('affiliate.phone')} 
                                                className='form-control' 
                                                value={this.state.phone} 
                                                onChange={this.handleInput} 
                                            />
                                        </div>
                                        <div className='form-field'>
                                            <input 
                                                type='email' 
                                                name='email' 
                                                placeholder={t('affiliate.email')} 
                                                className='form-control' 
                                                value={this.state.email}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        <div className='form-field'>
                                            <input 
                                                type='text' 
                                                name='country' 
                                                placeholder={t('affiliate.country')} 
                                                className='form-control' 
                                                value={this.state.country}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        <div className='form-submit'>
                                            <button 
                                                type='submit' 
                                                className='gradient-pink-btn gradient-pink-btn-3' 
                                                onClick={this.handleSend} 
                                            >{t('affiliate.submit')}</button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='baf-2-new white-top-curve-2'>
                    <Container>
                        <Row className='baf-row-3'>
                            <Col md={12} className='baf-row-3-col'>
                                <div className='img-wrap img-wrap-1'>
                                    <img src={imgBaf3} alt='Ovato Girl' />
                                </div>
                                <div className='img-wrap img-wrap-2'>
                                    <img src={imgBaf4} alt='Ovato Mobile' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* FOOTER */}
                <BottomNav />
            </div>
        )
    }
}
export default withTranslation()(BecomeAnAffiliate)
