import React, { Component } from 'react';
import TopNav from '../containers/TopNav';
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import BottomNav2 from '../containers/BottomNav2';
import { withTranslation } from 'react-i18next';
class StackCoins extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <TopNav />
        <div className='stackcoins_head stackcoins_forweb'>
          <Row>
            <Col className='stackcoins_headtext' sm='12' md='8' lg='6' xl='6'>
              {t('stackcoins.stackcoins')}
            </Col>
            <Col sm='12' md='4' lg='6' xl='6' className='stackcoins_coin'>
              <img
                alt='Ovato Coins'
                src={require('../assets/img/Ovato-Coins.png')}
                className='stackcoins_plans_img'
              />
            </Col>
          </Row>
        </div>
        <div className='stackcoins_head stackcoins_formobile'>
          <Row>
            <Col className='stackcoins_headtext' sm='12' md='8' lg='6' xl='6'>
              {t('stackcoins.stack')}
              <br />
              {t('stackcoins.coins')}
            </Col>
            <Col sm='12' md='4' lg='6' xl='6' className='stackcoins_coin'>
              <img
                alt='Stack Coins'
                src={require('../assets/img/Ovato-Coins.png')}
                className='stackcoins_plans_img'
              />
            </Col>
          </Row>
        </div>
        <Container>
          <Row className='text-center stackcoins_three_lines'>
            <Col sm='12' md='4' lg='4' xl='4'>
              {t('stackcoins.bonus')}
            </Col>
            <Col sm='12' md='4' lg='4' xl='4'>
              {t('stackcoins.keep')}
            </Col>
            <Col sm='12' md='4' lg='4' xl='4'>
              {t('stackcoins.cancel')}
            </Col>
          </Row>
          <div className='stackcoins_main '>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} className='stackcoins_forweb'>
                <img
                  alt='Stack Coins Girl'
                  src={require('../assets/img/Group 743.png')}
                  className='stackcoins_girl_img'
                />
              </Col>
              <Col xl={6} lg={6} ms={12} sm={12} className='stackcoins_form'>
                <Form>
                  <FormGroup row>
                    <Col xl={7} lg={7} md={6} sm={12}>
                      <Label className='stackcoins_headlabel'>
                        {t('stackcoins.coinsnumber')}
                      </Label>
                    </Col>
                    <Col
                      xl={5}
                      lg={5}
                      md={6}
                      sm={12}
                      className='stackcoins_inputbox_col'
                    >
                      <Input type='text' className='stackcoins_inputbox' />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xl={7} lg={7} md={6} sm={12}>
                      <Label className='stackcoins_headlabel'>
                        {t('stackcoins.promocode')}
                      </Label>
                    </Col>
                    <Col
                      xl={5}
                      lg={5}
                      md={6}
                      sm={12}
                      className='stackcoins_inputbox_col'
                    >
                      <Input type='text' className='stackcoins_inputbox' />
                    </Col>
                  </FormGroup>
                  <Row>
                    <Col sm={7}>
                      <FormGroup row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <Label className='stackcoins_headlabel'>
                            {t('stackcoins.time')}
                          </Label>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <FormGroup check>
                            <Label check className='stackcoins_sublabel pt-3'>
                              <Input type='radio' name='radio2' />
                              &emsp;1 {t('stackcoins.month')} (3 %)
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check className='stackcoins_sublabel pt-4'>
                              <Input type='radio' name='radio2' />
                              &emsp;3 {t('stackcoins.month')} (8 %)
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check className='stackcoins_sublabel pt-4'>
                              <Input type='radio' name='radio2' />
                              &emsp;1 {t('stackcoins.year')} (30 %)
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={5}>
                      <FormGroup>
                        <Label className='stackcoins_sublabel'>
                          {t('stackcoins.bonus2')}
                        </Label>
                        <div className='stackcoins_inputbox_col'>
                          <Input type='text' className='stackcoins_inputbox' />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className='stackcoins_sublabel'>
                          {t('stackcoins.total')}
                        </Label>
                        <div className='stackcoins_inputbox_col'>
                          <Input type='text' className='stackcoins_inputbox' />
                        </div>
                      </FormGroup>
                      <Button
                        className='stackcoins_submit'
                        style={{ borderRadius: 60 }}
                      >
                        {t('stackcoins.submit')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Label className='stackcoins_extra_label stackcoins_forweb'>
                  {t('stackcoins.note')}
                </Label>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className='stackcoins_formobile text-center'
              >
                <Label className='stackcoins_extra_label2 pt-4 pb-3'>
                  {t('stackcoins.note')}
                </Label>
                <img
                  alt='Stack Coins 3'
                  src={require('../assets/img/Group 743.png')}
                  className='stackcoins_girl_img2'
                />
              </Col>
            </Row>
          </div>
        </Container>
        <BottomNav2 />
      </div>
    );
  }
}
export default withTranslation()(StackCoins);
