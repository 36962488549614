import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class LeaderboardFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
           activeFilter: 'day'
        };
    }
    handleFilterClick = (filter) => {
      this.setState({ activeFilter: filter },()=>{
        this.props.leaderboardFilterFunc(this.state.activeFilter);
      });
    }
  render() {
    const { t, data } = this.props;
    const { activeFilter } = this.state;

    const filters = [
      { value: 'day', label: 'Day' },
      { value: 'week', label: 'Week' },
      { value: 'month', label: 'Month' },
      { value: 'total', label: 'Total' }
    ];
    return (
        <ul className='leaderboard-filter'>
          {filters.map(filter => (
          <li key={filter.value}>
            <a
              href='#'
              className={activeFilter === filter.value ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                this.handleFilterClick(filter.value);
              }}
            >
              {filter.label}
            </a>
          </li>
        ))}
        </ul>
    );
  }
}
export default withTranslation()(LeaderboardFilter);