import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgHome3 from '../../assets/img/img-new-home-3.png'
class NewHome3 extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className='new-home-3 white-top-curve-2'>
        <Container>
          <Row className='align-center'>
            <Col md={6} className='new-home-3-col-right'>
                <div className='new-home-3-col-inner'>
                    <h2 className='Poppins font-46 bold dark-text-5'>{t('newhome3.for')}</h2>
                    <p className='Poppins font-18 light dark-text-5 mt-30'>{t('newhome3.join')} </p>
                    <div className='btn-wrap mt-30 new-home-3-btn-wrap'>
                        <a href='/merchant-wallet-app' className='custom-new-btn'>{t('newhome3.learn')}</a>
                    </div>
                </div>
            </Col>
            <Col md={6}>
              <div className='img-wrap new-home-3-img-wrap'>
                <img src={imgHome3} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation()(NewHome3);
