import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgGooglePlay from '../../assets/img/img-google-play-home.png'
import imgAppStore from '../../assets/img/img-apple-store-home.png'
import imgOvatoLogo from '../../assets/img/img-new-home-1-new.png'
import imgOvatoLogoMobile from '../../assets/img/img-new-home-1-mobile.png'

import RelaunchingPopup from '../popup/RelaunchingPopup';

class NewHome1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }
  
  render() {
    const { t, modal } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <>
      <section className='new-home-1 new-first-row desktop'>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className='Poppins font-55 bold dark-text-5 new-home-1-heading'>{ t('newhome1.digital') }</h1>
              <p className='Poppins font-55 bold dark-text-5 text-capitalize new-home-1-text-1'>{ t('newhome1.for') }</p>
              <p className='Poppins font-30 semi-bold dark-text-5 new-home-1-text-2'>{ t('newhome1.super') }</p>
              <div className='btn-wrap new-home-1-btn-wrap'>
                <a href='/wallet' className='custom-new-btn'>{ t('newhome1.get') }</a>
              </div>
              <div className='img-wrap new-home-1-img-wrap-1'>
                <a
                  rel='noopener noreferrer'
                  // href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                  href='#'
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgGooglePlay} 
                    width={100}
                  />
                </a>
                <a
                  rel='noopener noreferrer'
                  // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                  href='#' 
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgAppStore}
                    width={100}
                  />
                </a>
              </div>
              <div className='img-wrap new-home-1-img-wrap-2'>
                <img src={imgOvatoLogo} alt='Ovato' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Mobile */}
      <section className='new-home-1 new-first-row mobile'>
        <Container>
          <Row>
            <Col md={6} className='new-home-1-col'>
              <h1 className='Poppins font-55 bold dark-text-5 new-home-1-heading'>{ t('newhome1.digital') }</h1>
              <p className='Poppins font-55 bold dark-text-5 text-capitalize new-home-1-text-1'>{ t('newhome1.for') }</p>
              <p className='Poppins font-30 semi-bold dark-text-5 new-home-1-text-2'>{ t('newhome1.super') }</p>
              <div className='btn-wrap'>
                <a href='/wallet' className='custom-new-btn'>{ t('newhome1.get') }</a>
              </div>
              <div className='img-wrap new-home-1-img-wrap-1'>
                <a
                  rel='noopener noreferrer'
                  // href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgGooglePlay} 
                    width={100}
                  />
                </a>
                <a
                  rel='noopener noreferrer'
                  // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                  target='_blank' 
                  onClick={(e) => toggleModal(e)}
                >
                  <img
                    alt='timer'
                    src={imgAppStore}
                    width={100}
                  />
                </a>
              </div>
              <div className='img-wrap new-home-1-img-wrap-2'>
                <img src={imgOvatoLogoMobile} alt='Ovato' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Modals */}
      <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </>
    );
  }
}
export default withTranslation()(NewHome1);
