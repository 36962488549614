import React, { Component } from 'react'
import DaoComingSoonModal from '../containers/DaoComingSoonModal';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import NewHome1 from './new-home/NewHome1';
import NewHome2 from './new-home/NewHome2';
import NewHome3 from './new-home/NewHome3';
import NewHome4 from './new-home/NewHome4';
import NewHome5 from './new-home/NewHome5';
export default class NewHome extends Component {
  render() {
    return (
      <div className='new-home-main'>
        <TopNav />
        <NewHome1 />
        <NewHome2 />
        <NewHome3 />
        <NewHome4 />
        <NewHome5 />
        <BottomNav />
      </div>
    )
  }
}