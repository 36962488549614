export default [
  {
    "name": "USA",
    "people": "337M",
    "gnp": "$20B"
  },
  {
    "name": "UK",
    "people": "68.9M",
    "gnp": "$3B"
  },
  {
    "name": "JAPAN",
    "people": "123M",
    "gnp": "$5T"
  },
  {
    "name": "CHINA",
    "people": "1.4B",
    "gnp": "$14T"
  },
  {
    "name": "CANADA",
    "people": "38.7M",
    "gnp": "$1.8B"
  },
  {
    "name": "FRANCE",
    "people": "67M",
    "gnp": "$3B"
  },
  {
    "name": "GERMANY",
    "people": "83M",
    "gnp": "$4B"
  },
  {
    "name": "INDIA",
    "people": "1.4B",
    "gnp": "$3B"
  },
  {
    "name": "ITALY",
    "people": "58.8M",
    "gnp": "$2B"
  },
  {
    "name": "SPAIN",
    "people": "47.5M",
    "gnp": "$1.4B"
  },
  {
    "name": "IRELAND",
    "people": "5M",
    "gnp": "$380B"
  },
  {
    "name": "COLUMBIA",
    "people": "52M",
    "gnp": "$318B"
  },
  {
    "name": "MEXICO",
    "people": "128M",
    "gnp": "$1.2B"
  },
  {
    "name": "NETHERLANDS",
    "people": "17.6M",
    "gnp": "$988B"
  },
  {
    "name": "AUSTRALIA",
    "people": "26.4M",
    "gnp": "$1.4B"
  },
  {
    "name": "AFGHANISTAN"
  },
  {
    "name": "SAUDI ARABIA"
  },
  {
    "name": "MALTA",
    "people": "535K",
    "gnp": "$16B"
  },
  {
    "name": "ZAMBIA"
  },
  {
    "name": "BRAZIL",
    "people": "216M",
    "gnp": "$1.8T"
  },
  {
    "name": "MADAGASCAR"
  },
  {
    "name": "BOTSWANA"
  },
  {
    "name": "UKRAINE",
    "people": "36.7M",
    "gnp": "$170B"
  },
  {
    "name": "CENTRAL AFRICAN"
  },
  {
    "name": "OVATO NATION"
  },
  {
    "name": "SWITZERLAND",
    "people": "8.7M",
    "gnp": "$788B"
  },
  {
    "name": "SERBIA"
  },
  {
    "name": "AUSTRIA",
    "people": "8.9M",
    "gnp": "$472B"
  },
  {
    "name": "BELARUS",
    "people": "9.4M",
    "gnp": "$65.5B"
  },
  {
    "name": "HUNGARY",
    "people": "10.1M",
    "gnp": "$172B"
  },
  {
    "name": "LUXEMBOURG",
    "people": "654K",
    "gnp": "$56B"
  },
  {
    "name": "MONTENEGRO",
    "people": "626K",
    "gnp": "$6B"
  },
  {
    "name": "BULGARIA",
    "people": "6.6M",
    "gnp": "$82B"
  },
  {
    "name": "ANDORRA"
  },
  {
    "name": "HOLY SEE",
    "people": "800-1k"
  },
  {
    "name": "CROATIA",
    "people": "4M",
    "gnp": "$68.7B"
  },
  {
    "name": "NORWAY",
    "people": "5.4M",
    "gnp": "$453B"
  },
  {
    "name": "SLOVAKIA",
    "people": "5.8M",
    "gnp": "$112B"
  },
  {
    "name": "DENMARK",
    "people": "5.9M",
    "gnp": "$400B"
  },
  {
    "name": "FINLAND",
    "people": "5.5M",
    "gnp": "$296B"
  },
  {
    "name": "ICELAND",
    "people": "375K",
    "gnp": "$23.6B"
  },
  {
    "name": "POLAND",
    "people": "41M",
    "gnp": "$636B"
  },
  {
    "name": "LIECHTENSTEIN",
    "people": "39.5K",
    "gnp": "$116M"
  },
  {
    "name": "MONACO",
    "people": "36K",
  },
  {
    "name": "MOLDOVA",
    "people": "3.4M",
    "gnp": "$14B"
  },
  {
    "name": "NORTH MACEDONIA",
    "people": "2M",
    "gnp": "$11.8B"
  },
  {
    "name": "LITHUANIA",
    "people": "2.7M",
    "gnp": "$60.8B"
  },
  {
    "name": "ALBANIA"
  },
  {
    "name": "BOSNIA"
  },
  {
    "name": "SAN MARINO"
  },
  {
    "name": "BELGIUM"
  },
  {
    "name": "ESTONIA"
  },
  {
    "name": "LATVIA"
  },
  {
    "name": "ROMANIA"
  },
  {
    "name": "SLOVENIA"
  },
  {
    "name": "SWEDEN"
  },
  {
    "name": "PORTUGAL"
  },
  {
    "name": "GREECE"
  },
  {
    "name": "CZECH REPUBLIC"
  },
]
