import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MerchantTable from './MerchantTable';
import { Service } from '../providers/Services';
class Top100Holders extends Component {
  // Set constructor
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isInvalid: '',
      data: [],
      allHolders: [],
      totally_outstanding: 0,
      currently_outstanding: 0,
      holders: [],
      holdersMobile: [],
      pageSize: 12,
      pageNumber: 1,
      paginationGroup: [],
    };
  }
  // Add comma separator in numbers
  separator = (numb) => {
    let str = numb.toString().split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str.join('.');
  };
  // Paginate record
  paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice(
      (page_number - 1) * page_size,
      page_number * page_size,
      true
    );
  }
  // Paginate Mobile record
  paginateMobile(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    if (page_number == 1) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size,
        true
      );
    } else {
      let oldRecord = this.state.holdersMobile;
      let newRecord = array.slice(
        (page_number - 1) * page_size,
        page_number * page_size,
        true
      );
      let finalRecord = [...oldRecord, ...newRecord];
      return finalRecord;
    }
  }
  // Paginate to next page
  goToNextPage() {
    if (this.state.pageNumber < 9) {
      let currentPageNumber = this.state.pageNumber;
      let newPageNumber = currentPageNumber + 1;
      this.setState({ pageNumber: newPageNumber });
      this.setState({
        holders: this.paginate(
          this.state.allHolders,
          this.state.pageSize,
          newPageNumber
        ),
      });
    }
  }
  // Paginate to previous page
  goToPreviousPage() {
    if (this.state.pageNumber > 1) {
      let currentPageNumber = this.state.pageNumber;
      let newPageNumber = currentPageNumber - 1;
      this.setState({ pageNumber: newPageNumber });
      this.setState({
        holders: this.paginate(
          this.state.allHolders,
          this.state.pageSize,
          newPageNumber
        ),
      });
    }
  }
  // Change Page
  changePage = (num) => {
    this.setState({ pageNumber: num });
    this.setState({
      holders: this.paginate(this.state.allHolders, this.state.pageSize, num),
    });
  };
  // Get Pagination Group
  getPaginationGroup = (total) => {
    const totalPages = Math.ceil(100 / this.state.pageSize);
    let start =
      Math.floor((this.state.pageNumber - 1) / totalPages) * totalPages;
    return new Array(totalPages).fill().map((_, idx) => start + idx + 1);
  };
  // View More Mobile
  viewMore = (e) => {
    e.preventDefault();
    if (this.state.pageNumber < 9) {
      let currentPageNumber = this.state.pageNumber;
      let newPageNumber = currentPageNumber + 1;
      this.setState({ pageNumber: newPageNumber });
      this.setState({
        holdersMobile: this.paginateMobile(
          this.state.allHolders,
          this.state.pageSize,
          newPageNumber
        ),
      });
    }
  };
  // Mount data
  componentDidMount() {
    Service.getHolders().then((res) => {
      this.setState({ allHolders: res.top_holders });
      this.setState({
        totally_outstanding: this.separator(res.totally_outstanding),
      });
      this.setState({
        currently_outstanding: this.separator(res.currently_outstanding),
      });
      this.state.allHolders.forEach((object, index) => {
        object.rank = index + 1;
      });
      // Get only 12 records of all holders
      this.setState({
        holders: this.paginate(
          this.state.allHolders,
          this.state.pageSize,
          this.state.pageNumber
        ),
      });
      // Get only 12 records of all holders mobile
      this.setState({
        holdersMobile: this.paginateMobile(
          this.state.allHolders,
          this.state.pageSize,
          this.state.pageNumber
        ),
      });
      // Set the pagination group
      this.setState({
        paginationGroup: this.getPaginationGroup(this.state.allHolders),
      });
    });
  }
  render() {
    const { t } = this.props;
    const record = [
      {
        rank: '01',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '02',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '03',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '04',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '05',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '06',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '07',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '08',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '09',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '10',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '11',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
      {
        rank: '12',
        address: '0xb5egsew3874y65whjt987y98w3475639475639',
        quantity: '400,000,000',
        percentage: '24.34545%',
        value: '$43,733,403.40',
      },
    ];
    return (
      <div className='top-100-main'>
        {/* Header Section */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new top-100-banner-new'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <h1 className='Poppins text-uppercase font-43 bold blue-text brand-main-new-heading-1'>
                  {t('top100.top')}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <section className='top-100-2 white-top-curve custom-gradient-1'>
          <Container>
            <Row>
              <Col>
                <div className='top-100-tbl-top-wrap'>
                  <ul className='top-100-tbl-top-ul'>
                    <li>
                      <span>{t('top100.total')}</span>
                    </li>
                    <li>
                      <label>Probit</label>
                      <input type='text' value={0} />
                    </li>
                    <li>
                      <label>Lbank</label>
                      <input type='text' value={0} />
                    </li>
                    <li>
                      <label>Whitebit</label>
                      <input type='text' value={0} />
                    </li>
                    <li>
                      <label>P2p B2B</label>
                      <input type='text' value={0} />
                    </li>
                  </ul>
                </div>
                <div className='top-100-tbl-wrap'>
                  {/* TABLE WRAP HEADER */}
                  <div className='top-100-tbl-wrap-header desktop'>
                    <div className='top-100-tbl-wrap-header-col top-100-tbl-wrap-header-left'>
                      <p>
                        {t('top100.totally')} {this.state.totally_outstanding}
                      </p>
                    </div>
                    <div className='top-100-tbl-wrap-header-col top-100-tbl-wrap-header-right'>
                      <p>
                        {t('top100.currently')}{' '}
                        {this.state.currently_outstanding}{' '}
                      </p>
                    </div>
                  </div>
                  {/* TABLE WRAP HEADER MOBILE */}
                  <div className='top-100-tbl-wrap-header mobile'>
                    <div className='top-100-tbl-wrap-header-col top-100-tbl-wrap-header-left'>
                      <p>
                        {t('top100.totally')} <br />{' '}
                        {this.state.totally_outstanding}{' '}
                      </p>
                    </div>
                    <div className='top-100-tbl-wrap-header-col top-100-tbl-wrap-header-right'>
                      <p>
                        {t('top100.currently')} <br />{' '}
                        {this.state.currently_outstanding}{' '}
                      </p>
                    </div>
                  </div>
                  {/* Table Record */}
                  <div className='top-100-tbl-main-wrap desktop'>
                    <table className='top-100-tbl-main'>
                      <thead className='top-100-tbl-main-header'>
                        <tr>
                          <th>{t('top100.rank')}</th>
                          <th className='top-100-address'>
                            {t('top100.address')}
                          </th>
                          <th>{t('top100.quantity')}</th>
                          <th>{t('top100.percentage')}</th>
                          <th>{t('top100.value')}</th>
                        </tr>
                      </thead>
                      <tbody className='top-100-tbl-main-body'>
                        {this.state.holders &&
                          this.state.holders.map((val, key) => (
                            <tr>
                              <td>{val.rank}</td>
                              <td className='top-100-address'>{val.address}</td>
                              <td>{val.balance}</td>
                              <td>{val.percentage}%</td>
                              <td>{val.value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className='top-100-ribbon top-100-ribbon-left'></div>
                    <div className='top-100-ribbon top-100-ribbon-right'></div>
                    {this.state.holders && (
                      <ul className='top-100-pagination'>
                        <li>
                          <a onClick={() => this.goToPreviousPage()} href='#'>
                            {'<'}
                          </a>
                        </li>
                        {this.getPaginationGroup() &&
                          this.getPaginationGroup().map((item, index) => (
                            <li key={item}>
                              <a
                                href='#'
                                onClick={(e) => this.changePage(item)}
                                className={
                                  this.state.pageNumber === item ? 'active' : ''
                                }
                              >
                                {item}
                              </a>
                            </li>
                          ))}
                        <li>
                          <a onClick={() => this.goToNextPage()} href='#'>
                            {'>'}
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* TABLE RECORD MOBILE */}
                  <div className='top-100-tbl-main-wrap mobile'>
                    <div className='top-100-tbl-record-wrap-mobile'>
                      {this.state.holdersMobile &&
                        this.state.holdersMobile.map((val, key) => (
                          <div className='top-100-tbl-record'>
                            <div className='top-100-tbl-record-details'>
                              <div class='top-100-tbl-record-left'>
                                {t('top100.rank')}
                              </div>
                              <div class='top-100-tbl-record-right'>
                                {val.rank}
                              </div>
                            </div>
                            <div className='top-100-tbl-record-details'>
                              <div class='top-100-tbl-record-left'>
                                {t('top100.address')}
                              </div>
                              <div class='top-100-tbl-record-right'>
                                {val.address}
                              </div>
                            </div>
                            <div className='top-100-tbl-record-details'>
                              <div class='top-100-tbl-record-left'>
                                {t('top100.quantity')}
                              </div>
                              <div class='top-100-tbl-record-right'>
                                {val.balance}
                              </div>
                            </div>
                            <div className='top-100-tbl-record-details'>
                              <div class='top-100-tbl-record-left'>
                                {t('top100.percentage')}
                              </div>
                              <div class='top-100-tbl-record-right'>
                                {val.percentage}
                              </div>
                            </div>
                            <div className='top-100-tbl-record-details'>
                              <div class='top-100-tbl-record-left'>
                                {t('top100.value')}
                              </div>
                              <div class='top-100-tbl-record-right'>
                                {val.value}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {this.state.pageNumber < 9 && (
                      <div className='view-more-btn-wrap'>
                        <a onClick={this.viewMore} href='#'>
                          {t('top100.view')} <i class='fas fa-chevron-down'></i>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Footer Section */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Top100Holders);
