import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgOwardsRewards from '../assets/img/img-owards-rewards-5.png';
import imgBitovation from '../assets/img/img-bitovation-5.png';
class Section5 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='section-5-new pt-80 pb-100 desktop'>
          <Container className='section-5-new-container p-60'>
            <Row>
              <Col>
                <h2 className='font-57 extra-bold blue-text text-center lh-1-2'>
                  {' '}
                  {t('section5.how_it_works')}
                </h2>
              </Col>
            </Row>
            <Row className='mt-30 align-center'>
              <Col md='5'>
                <div className='img-wrap text-right pr-60'>
                  <img
                    src={imgOwardsRewards}
                    alt='Owards Rewards'
                    width='250'
                  />
                  <p className='text-right font-17 medium pink-text mt-10'>
                    {t('section5.owards')}
                  </p>
                </div>
              </Col>
              <Col md='7'>
                <p className='font-20 medium'>{t('section5.detail1')}</p>
              </Col>
            </Row>
            <Row className='mt-30 align-center'>
              <Col md='5'>
                <div className='img-wrap img-wrap text-right pr-60'>
                  <img src={imgBitovation} alt='Bitovation' width='245' />
                  <p className='text-right font-17 medium pink-text mt-10'>
                    {t('section5.bitovation')}
                  </p>
                </div>
              </Col>
              <Col className='7'>
                <p className='font-20 medium'>{t('section5.detail2')}</p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-5-new-mobile pt-40 pb-40 mobile px-10'>
          <Container className='section-5-new-container px-20 py-60'>
            <Row>
              <Col>
                <h2 className='font-37 extra-bold blue-text text-center'>
                  {' '}
                  {t('section5.how_it_works')}
                </h2>
              </Col>
            </Row>
            <Row className='mt-30 align-center'>
              <Col md='5'>
                <div
                  className='img-wrap text-center'
                  style={{ maxWidth: '250px', margin: 'auto' }}
                >
                  <img
                    src={imgOwardsRewards}
                    alt='Owards Rewards'
                    width='250'
                  />
                  <p className='text-right font-17 medium pink-text'>
                    {t('section5.owards')}
                  </p>
                </div>
              </Col>
              <Col md='7'>
                <p className='font-18 text-center medium mt-15'>
                  {t('section5.detail1')}
                </p>
              </Col>
            </Row>
            <Row className='mt-40 align-center'>
              <Col md='5'>
                <div
                  className='img-wrap img-wrap text-center'
                  style={{ maxWidth: '250px', margin: 'auto' }}
                >
                  <img src={imgBitovation} alt='Bitovation' width='250' />
                  <p className='text-right font-17 medium pink-text'>
                    {t('section5.bitovation')}
                  </p>
                </div>
              </Col>
              <Col className='7'>
                <p className='font-18 text-center medium mt-15'>
                  {t('section5.detail2')}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section5);
