import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import '../assets/css/Announcement.css';

import imgGermanFlag from '../assets/img/img-a-german-flag.jpg'
import imgBackground from '../assets/img/img-a-background.png'
import imgAMean from '../assets/img/img-a-mean.png'

class Announcement extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='announcement-main'>     

        {/* A 1 */}
        <section className='a-1'>
            <Container>
                <Row>
                    <Col>
                        <h1 className='poppins n-font-44  m-n-font-26 semi-bold white-text lh-1-2 mb-0'>Announcement</h1>
                        <p className='poppins n-font-23 m-n-font-19 regular m-semi-bold light-blue-2 lh-1-2'>Immediate Action Required</p>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* A 2 */}
        <section className='a-2'>
            <Container>
                <Row>
                    <Col>
                        <p className='poppins n-font-21 m-n-font-18 medium text-center dark-text-8 mw-629 mx-auto'>To better align with the changing environment and pending new rules and regulations with exchanges, Ovato would like to announce the policy changes effective immediately with a trailing effect for ALL stakeholders.</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='a-box'>
                            <h2 className='poppins n-font-29 m-n-font-23 semi-bold text-center dark-text-9 lh-1-2 a-2-heading'><span className='a-2-heading-text'>Effective Immediately:</span></h2>

                            <p className='poppins n-font-18 m-n-font-16 semi-bold text-center dark-text-3 mt-30 m-mt-20'>All Storage of Value Products must be activated in utility to participate in the Ovato Wallet ecosystem administered through Govato.</p>

                            <ul className='mt-40 m-mt-30 a-ul'>
                                <li><span>1</span> <strong>Registration Required:</strong> Ovato (Wallets: OVO) "Storage of Value" Stakeholders: Registration is required prior to August 30, 2023, in Govato (govato.io) for stakeholders who own above $1000 USD value held inside the Ovato Wallets.</li>
                                <li><span>2</span> All <strong>EARNED</strong> will also be done via the Govato utility engine, and stacked earned coins are now to be earned in the Ovato NFTs administered by Govato. Notice: Ovato Customer Support will continue to activate Govato members. Technical support will continue through Ovato.com.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='a-box-bg'>
                            <div className='img-wrap text-center a-box-img-wrap'>
                                <img src={imgGermanFlag} width={110} className='a-box-img' />
                            </div>

                            <h2 className='poppins n-font-27 m-n-font-26 semi-bold text-center white-text mt-30 m-mt-30'>German Stakeholders</h2>

                            <p className='poppins n-font-16 light text-center white-text mt-20'>Additionally, all German nationals will be required to undergo <strong className='bold'>"Basic KYC".</strong> </p>

                            <p className='poppins n-font-16 regular light-blue-3 text-center mt-20'>To schedule a call in German or in English</p>

                            <div className='btn-wrap text-center mt-30'>
                                <a href='https://calendly.com/ovatodev' target='_blank' className='custom-new-btn custom-new-btn-2'>Schedule a call</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* A 3 */}
        <section className='a-3'>
            <Container>
                <Row className='align-center mw-1000 mx-auto'>
                    <Col md={6}>
                        <div className='img-wrap text-center'>
                            <img src={imgBackground} width={304} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <h2 className='poppins n-font-27 m-n-font-23 semi-bold dark-text-9 m-mt-20'>Background</h2>
                        <p className='poppins n-font-15 light dark-text-6'>As you may already know, the secondary markets (Exchanges) have been experiencing major fundamental challenges. Because of this, there is the potential to have the utility of your holdings unnecessarily challenged because you were onboard in the "Real World". And while Ovato itself is undeniably a Utility coin, the easiest approach for stakeholders is to "shore up" and participate in the Govato ecosystem. Furthermore, by participating in Govato, you're not just earning and protecting your holdings but helping advance our collective community.</p>
                    </Col>
                </Row>

                <Row className='align-center mw-1000 mx-auto mt-60'>
                    <Col md={6} className='tt-order-2'>
                        <h2 className='poppins n-font-27 semi-bold dark-text-9 mw-300 tt-mw-full m-n-font-23'>What does this mean to you and Ovato?</h2>
                        <p className='poppins n-font-15 light dark-text-6'>By taking these steps, your Ovato holdings are strengthened. You can still earn through the stacking protocol but also earn in additional ways through the Govato ecosystem. Most importantly, you have the ability to transact your holdings through the NFT universe, such as Opensea and the like. You have the ability to strip your NFT at any time.</p>
                    </Col>

                    <Col md={6} className='tt-order-1'>
                        <div className='img-wrap text-center m-mt-20'>
                            <img src={imgAMean} width={372} />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='btn-wrap text-center mt-30 tt-mt-40'>
                            <a href='https://calendly.com/ovatodev' target='_blank' className='custom-new-btn custom-new-btn-2'>Schedule a call</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* A 4 */}
        <section className='a-4'>
            <Container>
                <Row className='a-4-row'>
                    <Col>
                        <div className='a-4-step'>step</div>
                        <div className='a-4-num'>1</div>
                        <p className='a-4-text'>
                            Register <br/>at Govato.
                        </p>
                    </Col>
                    <Col>
                        <div className='a-4-step'>step</div>
                        <div className='a-4-num'>2</div>
                        <p className='a-4-text'>
                            Follow Govato basic <br/> terms of service
                        </p>
                    </Col>
                    <Col>
                        <div className='a-4-step'>step</div>
                        <div className='a-4-num'>3</div>
                        <p className='a-4-text'>
                            Become <br/>  Activated
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* A 5 */}
        <section className='a-5'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins n-font-27 semi-bold white-text text-center'>Questions and Support</h2>

                        <div className='btn-wrap text-center mt-30 m-mt-30'>
                            <a href='https://calendly.com/ovatodev' target='_blank' className='custom-new-btn custom-new-btn-2'>Schedule a call</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

      </div>
    );
  }
}
export default withTranslation()(Announcement);
