import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Containers
import TopNavPremium from '../../containers/TopNavPremium';

// Components
import ProfileMain from '../../components/premium/profile/ProfileMain';
import RecentTransactionsMain from '../../components/premium/recent-transactions/RecentTransactionsMain';

import BottomNav from '../../containers/NewBottomNav';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <>
            {/* Header */}
            <TopNavPremium />

            <section className='top-section pw-1'>
                <div className='container'>
                    <ProfileMain />
                    <RecentTransactionsMain />
                </div>
            </section>

            {/* Footer */}
            <BottomNav />
        </>
    );
  }
}
export default withTranslation()(Profile);