import React from 'react';
import YouTube from 'react-youtube';
class YouTubePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.state = {
      isVisible: false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Check initial visibility
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    const { top, bottom } = this.playerRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (top < windowHeight && bottom >= 0 && !this.state.isVisible) {
      this.setState({ isVisible: true });
    }
  };
  handlePlayerReady = event => {
    if (this.state.isVisible) {
      event.target.playVideo();
    }
  };
  render() {
    const { videoId } = this.props;
    const { isVisible } = this.state;
    return (
      <div>
        <div ref={this.playerRef}>
          {isVisible && (
            <YouTube
              videoId={videoId}
              onReady={this.handlePlayerReady}
            />
          )}
        </div>
      </div>
    );
  }
}
export default YouTubePlayer;