import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgGetAWallet from '../../assets/img/img-5-get-a-wallet.png'
import imgLearn from '../../assets/img/img-5-new-learn.png'
import imgPurchaseOvato from '../../assets/img/img-5-new-purchase-ovato.png'
import imgOvatoNFTS from '../../assets/img/img-ovato-nfts-new.png'
import imgOvatoDAO from '../../assets/img/img-5-new-ovato-dao.png'
import DaoComingSoonModal from '../../containers/DaoComingSoonModal';
class NewHome5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDaoModal: false
        }
    }
    toggleDaoModal = (e, value) => {
        e.preventDefault();
        this.setState({ showDaoModal: value })
    }
  render() {
    const { t } = this.props;
    return (
        <>
      <section className='new-home-5 white-top-curve-7'>
        <Container>
            <Row className='new-home-5-row-1'>
                <Col>
                    <h2 className='Poppins font-46 bold dark-text-5 text-center new-home-5-row-1-heading'>{t('newhome5.begin')}</h2>
                </Col>
            </Row>
            <div className='new-home-5-inner'>
                {/* GET A WALLET */}
                <Row className='new-home-5-row new-home-5-inner-row-1'>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgGetAWallet} />
                        </div>
                    </Col>
                    <Col className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            1
                        </div>
                    </Col>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>{t('newhome5.get')}</h3>
                        <p className='new-home-5-col-text-content'>
                            {t('newhome5.pick')}
                        </p>
                        <div className='btn-wrap new-home-5-col-text-btn'>
                            <a href='/wallet' className='custom-new-btn'>{t('newhome5.wallets')}</a>
                        </div>
                    </Col>
                </Row>
                {/* LEARN */}
                <Row className='new-home-5-row new-home-5-inner-row-2'>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>{t('newhome5.learn')}</h3>
                        <p className='new-home-5-col-text-content'>
                            {t('newhome5.start')}
                        </p>
                        <div className='btn-wrap new-home-5-col-text-btn'>
                            <a href='/landing' className='custom-new-btn'>{t('newhome5.theBasics')}</a>
                        </div>
                    </Col>
                    <Col className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            2
                        </div>
                    </Col>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgLearn} />
                        </div>
                    </Col>
                </Row>
                {/* PURCHASE OVATO */}
                <Row className='new-home-5-row new-home-5-inner-row-3'>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgPurchaseOvato} />
                        </div>
                    </Col>
                    <Col className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            3
                        </div>
                    </Col>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>{t('newhome5.purchase')}</h3>
                        <p className='new-home-5-col-text-content'>
                            {t('newhome5.theQuickest')}
                        </p>
                        <div className='btn-wrap new-home-5-col-text-btn'>
                            <a href='/exchange' className='custom-new-btn'>{t('newhome5.discover')}</a>
                        </div>
                    </Col>
                </Row>
                {/* OVATO NFTS */}
                <Row className='new-home-5-row new-home-5-inner-row-4'>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>{t('newhome5.ovatoNFTS')}</h3>
                        <p className='new-home-5-col-text-content'>
                            {t('newhome5.join')}
                        </p>
                        <div className='btn-wrap new-home-5-col-text-btn'>
                            <a href='/nfts' className='custom-new-btn'>{t('newhome5.discover2')}</a>
                        </div>
                    </Col>
                    <Col className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            4
                        </div>
                    </Col>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgOvatoNFTS} />
                        </div>
                    </Col>
                </Row>
                {/* OVATO DAO */}
                <Row className='new-home-5-row new-home-5-inner-row-5'>
                    <Col className='new-home-5-col-img'>
                        <div className='img-wrap'>
                            <img src={imgOvatoDAO} />
                        </div>
                    </Col>
                    <Col className='new-home-5-col-2'>
                        <div className='new-home-5-num'>
                            5
                        </div>
                    </Col>
                    <Col className='new-home-5-col-text'>
                        <h3 className='new-home-5-col-text-title'>{t('newhome5.ovatoDAO')}</h3>
                        <p className='new-home-5-col-text-content'>
                            {t('newhome5.discover3')}
                        </p>
                        <div className='btn-wrap new-home-5-col-text-btn'>
                            <a href='#' onClick={(event) => this.toggleDaoModal(event, true)} className='custom-new-btn'>{t('newhome5.discover4')}</a>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
      </section>
      {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}
      </>
    );
  }
}
export default withTranslation()(NewHome5);
