import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../helpers/helpers';

// Components
import RelaunchingPopup from '../components/popup/RelaunchingPopup';

// CSS
import '../assets/css/Dispensory.css'
import '../assets/css/Escorts.css'
import '../assets/css/Unbanked.css'

// Images
import imgUnbanked1 from '../assets/img/unbanked/img-1.png'
import imgMobileBanner from '../assets/img/unbanked/img-mobile-banner.png'
import imgBenefits from '../assets/img/unbanked/img-benefits.png'
import imgStartHere from '../assets/img/unbanked/img-start-here.png'
import imgQRMobile from '../assets/img/unbanked/img-mobile-qr.png'

// QR Unbanked
import QRUnbanked from '../components/qrcode/QRUnbanked';

class Unbanked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletmodal: false,
      fade: false,
      merchantmodal: false,
      modal: false
    };
  }
  toggleWallet = () => {
    this.setState({ walletmodal: !this.state.walletmodal });
  };
  toggleMerchant = () => {
    this.setState({ merchantmodal: !this.state.merchantmodal });
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    const boxData = [
        { id: 1, title: t('dispensory.title1'), content: t('dispensory.content1'), },
        { id: 2, title: t('dispensory.title2'), content: t('dispensory.content2'), },
        { id: 3, title: t('dispensory.title3'), content: t('dispensory.content3') },
        { id: 4, title: t('dispensory.title4'), content: t('dispensory.content4') },
        { id: 5, title: t('dispensory.title5'), content: t('dispensory.content5') },
        { id: 6, title: t('dispensory.title6'), content: t('dispensory.content6') }
    ]

    const boxData2 = [
        { 
            id: 1, 
            title: t('escorts.title1'), 
            content: [
                { value: t('escorts.content1') },
                { value: t('escorts.content11') }
            ],
        },
        { 
            id: 2, 
            title: t('escorts.title1'), 
            content: [
                { value: t('escorts.content2') },
                { value: t('escorts.content22') }
            ],
        },
        { 
            id: 3, 
            title: t('escorts.title3'), 
            content: [
                { value: t('escorts.content3') },
            ],
        },
        { 
            id: 4, 
            title: t('escorts.title4'), 
            content: [
                { value: t('escorts.content4') },
            ],
        },
        { 
            id: 5, 
            title: t('escorts.title5'), 
            content: [
                { value: t('escorts.content5') },
            ],
        },
        { 
            id: 6, 
            title: t('escorts.title6'), 
            content: [
                { value: t('escorts.content6') },
            ],
        },
    ]

    return (
      <div className='blue-nav v3-main dispen-main'>
        {/* Header */}
        <TopNav />

        {/* Section 1 */}
        <section className='new-first-row v2-pb-12 v2-tl-pb-0 v2-m-pb-3 v2-mb--28 v2-tl-mb-0 dispen-1 ub-1'>
            <Container>
                <Row className='align-center'>
                    <Col className='v2-tp-col-12' sm={7}>
                        <h1 className='v2-font-41 v2-tl-font-32 poppins v2 v2-tp-font-32 v2-m-font-24 v2-tp-text-center bold dark-text-5 v2-mb-0 v2-mt-2 v2-m-mt-0 v2-pr-6 v2-tp-pr-0'>{t('unbanked.ovato')}</h1>

                        <p className='poppins v2-font-16 v2-m-font-15 regular dark-text-5 v2-mt-1 v2-mb-1 v2-tp-text-center lh-1-6'>{t('unbanked.welcome')}</p>

                        <div className='v2-hidden v2-tp-show text-center v2-mx-auto img-wrap escort-1-img-wrap v2-mt-2 v2-tl-mw-60 v2-m-mw-90'>
                            <img src={imgMobileBanner} />
                        </div>
                    </Col>
                </Row>
                <div className='img-wrap dispen-1-img-wrap v2-tp-hidden unbanked-1-img-wrap'>
                    <img src={imgUnbanked1} />
                </div>

                <Row className='v2-mt-2 v2-m-mt-0'>
                    <Col sm={6} className='v2-mt-6 v2-tp-mt-6 v2-tp-col-12 v2-m-col-12'>
                        <div className='dispen-box'>
                            <div className='text-center dispen-box-num-wrap'>
                                <div className='dispen-box-num dispen-box-num-title'>{t('unbanked.section1')}</div>
                            </div>
                            <h3 className='v2-px-8 v2-tp-px-0 dispen-box-title'>{t('unbanked.section1Title')}</h3>
                            <p className='regular dispen-box-content'>{t('unbanked.section1Content')}</p>
                        </div>
                    </Col>

                    <Col sm={6} className='v2-mt-6 v2-tp-mt-6 v2-tp-col-12 v2-m-col-12'>
                        <div className='dispen-box'>
                            <div className='text-center dispen-box-num-wrap'>
                                <div className='dispen-box-num dispen-box-num-title'>{t('unbanked.section2')}</div>
                            </div>
                            <h3 className='v2-px-8 v2-tp-px-0 dispen-box-title'> {t('unbanked.section2Title')}</h3>
                            <p className='regular dispen-box-content'>{t('unbanked.section2Content')}</p>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-2 v2-m-mt-0'>
                    <Col sm={12} className='v2-mt-4 v2-tp-mt-4 v2-m-mt-6 v2-tp-col-12 v2-m-col-12'>
                        <div className='dispen-box'>
                            <div className='text-center dispen-box-num-wrap'>
                                <div className='v2-tp-text-center dispen-box-num dispen-box-num-title'>{t('unbanked.section3')}</div>
                            </div>
                            <Row className='v2-tp-column'>
                                <Col>
                                    <div className='img-wrap ub-benefits-img-wrap'>
                                        <img width={250} src={imgBenefits} />
                                    </div>
                                    <h3 className='text-left v2-tp-text-center v2-pr-6 v2-tp-pr-0 dispen-box-title'>{t('unbanked.section3Title')}</h3>
                                    <ul className='dispen-box-ul'>
                                        <li>{t('unbanked.section3Content1')}</li>
                                    </ul>
                                </Col>

                                <Col>
                                    <ul className='dispen-box-ul'>
                                        <li>{t('unbanked.section3Content2')}</li>
                                        <li>{t('unbanked.section3Content3')}</li>
                                    </ul>
                                </Col>

                                <Col>
                                    <ul className='dispen-box-ul'>
                                        <li>{t('unbanked.section3Content4')}</li>
                                        <li>{t('unbanked.section3Content5')}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-6'>
                    <Col sm={8} className='v2-tp-col-12'>
                        <div className='dispen-box'>
                            <div className='text-center dispen-box-num-wrap'>
                                <div className='dispen-box-num dispen-box-num-title'>{t('unbanked.section4')}</div>
                            </div>
                            <Row className='v2-tp-column v2-mt-2'>
                                <Col className=''>
                                    <h3 className='text-left v2-tp-text-center v2-pr-6 v2-tp-pr-0 dispen-box-title'>{t('unbanked.section4Title')}</h3>

                                    <div className='text-left regular dispen-box-content'>
                                        <p>{t('unbanked.section4Content1')}</p>
                                    </div>
                                    
                                    <ul className='dispen-box-ul'>
                                        <li>{t('unbanked.section4Content2')}</li>
                                        <li>{t('unbanked.section4Content3')}</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className='dispen-box-ul'>
                                        <li>{t('unbanked.section4Content4')}</li>
                                        <li>{t('unbanked.section4Content5')}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col sm={4} className='v2-tp-col-12 v2-tp-mt-6'>
                        <div className='dispen-box'>
                            <div className='text-center dispen-box-num-wrap'>
                                <div className='dispen-box-num dispen-box-num-title'>{t('unbanked.section5')}</div>
                            </div>
                            <h3 className='dispen-box-title'>{t('unbanked.section5Title')}</h3>
                            <p className='regular dispen-box-content'>{t('unbanked.section5Content1')}</p>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-5 v2-m-mt-4 relative z-index-3'>
                    <Col sm={12} className='v2-mx-auto relative'>
                        <div className='dispen-blue-box ub-blue-box'>
                            <Row className='align-center'>
                                <Col sm={9} className='v2-tp-col-12'>
                                    <h2 className='poppins v2-font-26 bold white-text v2-tp-font-24 v2-m-font-22 v2-tp-text-center'>{t('unbanked.ready')}</h2>
                                    <p className='poppins v2-font-15 light white-text v2-m-text-left'>{t('unbanked.ovatoCoin')}</p>
                                    <div className='v2-mt-2 btn-wrap v2-tp-text-center'>
                                        <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='site-btn dispen-btn'>{t('unbanked.get')}</a>
                                    </div>
                                </Col>
                                <Col sm={3} className='v2-tp-col-12'>
                                
                                    <div className=''>
                                        {/* <img src={imgStartHere} /> */}
                                        <QRUnbanked url={withAffCodeURL('https://wallet.ovato.com/')} size={600} />
                                    </div>

                                    {/* <div className='v2-hidden v2-tl-visible v2-tp-text-center v2-tp-mt-2'>
                                        <img src={imgQRMobile} />
                                    </div> */}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        {/* Footer */}
        <BottomNav/>

        {/* Modals */}
        <RelaunchingPopup modal={this.state.modal} toggle={toggleModal} />
      </div>
    );
  }
}
export default withTranslation()(Unbanked);