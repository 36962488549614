import React, { Component } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { withTranslation } from 'react-i18next';

// Images
import ImgBanner from '../assets/img/img-support-banner-new.png'
import ImgBannerMobile from '../assets/img/img-support-banner-new-mobile.png'
import ImgConversation from '../assets/img/conversation.png'
import ImgMaintenance from '../assets/img/web-maintenance.png'
import ImgMining from '../assets/img/mining.png'
import ImgBeetle from '../assets/img/beetle.png'
import ImgSurface1 from '../assets/img/surface1.png'
import ImgPlaybook from '../assets/img/img-the-new-playbook-2021.png'
import Img16 from '../assets/img/img-16-proven-ways.png'
import ImgRestaurant from '../assets/img/img-new-playbook-for-restaurants.png'
import ImgStudy from '../assets/img/img-merchant-study.png'
class Support extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav support-main-new-2'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER */}
        <div className='new-first-row brand-main-new support-banner-new custom-gradient-2'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <h1 className='Poppins font-45 bold blue-text support-banner-new-heading'>
                  {t('support.support')}
                </h1>
              </Col>
              <Col md={6} className='support-banner-new-col-2'>
                <div className='img-wrap desktop'>
                  <img
                    src={ImgBanner}
                  />
                </div>
                <div className='img-wrap mobile'>
                  <img
                    src={ImgBannerMobile}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Container className='support-main-container support-2-new'>
            <div>
              <Row className='support-2-new-row-1'>
                {/* Questions and Answers */}
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Conversation'
                            src={ImgConversation}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        <a target='_blank' href='/en/faqs'>
                        {t('support.question')}&nbsp;
                        <br />
                        {t('support.and_answers')}
                        </a>
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('newSupport.discover')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                {/* FAUCET */}
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Web Maintenance'
                            src={ImgMaintenance}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        <a target='_blank' href='/en/faqs'>{t('newSupport.get')}</a>
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('newSupport.discover2')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                {/* Github */}
                {/* <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Github'
                            src={require('../assets/img/github.png')}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        {t('support.github')}
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.view_the_source_code')}&nbsp;
                        <br />
                        {t('support.contribute_and_file')}&nbsp;
                        <br />
                        {t('support.bug_report_on_github')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* MINE OVATO */}
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Mining'
                            src={ImgMining}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        {t('support.mine_ovato')}
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('newSupport.learn')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className='support-2-new-row-2'>
                {/* REPORT A BUG */}
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Beetle'
                            src={ImgBeetle}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        <a target='_blank' href='/en/bug-bounty'>{t('support.report_bug')}</a>
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.report_bug1')}&nbsp;
                        <br />
                        {t('support.report_bug2')}&nbsp;
                        <br />
                        {t('support.report_bug3')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                {/* Privacy and Security */}
                <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Surface'
                            src={ImgSurface1}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        <a target='_blank' href='/en/privacy-policy'>{t('support.privacy_and_security')}</a>
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.information_and')}&nbsp;
                        <br />
                        {t('support.announcements_about_ovato')}&nbsp;
                        <br />
                        {t('support.digital_cash_security')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                {/* User Guide */}
                {/* <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='File'
                            src={require('../assets/img/file.png')}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        {t('support.user_guide')}
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.user_guide1')}&nbsp;
                        <br />
                        {t('support.user_guide2')}&nbsp;
                        <br />
                        {t('support.user_guide3')}&nbsp;
                        <br />
                        {t('support.user_guide4')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* Integration Guide */}
                {/* <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Intersection'
                            src={require('../assets/img/intersection.png')}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        {t('support.integration_guide')}
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.integration_guide1')}&nbsp;
                        <br />
                        {t('support.integration_guide2')}&nbsp;
                        <br />
                        {t('support.integration_guide3')}&nbsp;
                        <br />
                        {t('support.integration_guide4')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
              {/* <Row> */}
              {/* Report a bug */}
              {/* Public Keys */}
              {/* <Col sm='12' md='4' lg='4' xl='4' className='support_col'>
                  <Card
                    className='text-center support_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <center>
                        <div className='support_card_img_div text-center'>
                          <img
                            alt='Key'
                            src={require('../assets/img/key.png')}
                            className='support_card_img'
                          />
                        </div>
                      </center>
                      <CardTitle className='support_card_head'>
                        {t('support.public_keys')}
                      </CardTitle>
                      <CardText className='support_card_text'>
                        {t('support.public_keys_for_our')}&nbsp;
                        <br />
                        {t('support.software_release')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col> */}
              {/* </Row> */}
              {/* <Row>
                {/* FORUM */}
              {/* <Col className='support_col'>
                  <Card
                    className='support_forum_card'
                    style={{ borderRadius: 10 }}
                  >
                    <CardBody>
                      <Row>
                        <Col sm='12' md='2' lg='2' cl='2'>
                          <center>
                            <div className='support_forum_card_img_div text-center'>
                              <img
                                alt='Group'
                                src={require('../assets/img/group.png')}
                                className='support_forum_card_img'
                              />
                            </div>
                          </center>
                        </Col>
                        <Col sm='12' md='10' lg='10' cl='10'>
                          <CardTitle className='support_forum_card_head'>
                            {t('support.forum')}
                          </CardTitle>
                          <CardText className='support_forum_card_text'>
                            {t('support.forum1')}
                            {t('support.forum2')}
                          </CardText>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              {/* </Row> */}
            </div>
          </Container>
        </div>
        {/* Section 3 */}
        <section className='support-new-3 white-top-curve-6'>
          <Container>
            <Row>
              <Col>
                <h2 className='Poppins font-57 bold blue-text text-uppercase text-center'>
                  {t('newSupport.free')}
                </h2>
              </Col>
            </Row>
            <Row className='support-new-3-row-2'>
              <Col md={6} className='support-new-3-col'>
                <div className='img-wrap support-new-3-col-img'>
                  <img
                    src={ImgPlaybook}
                  />
                </div>
                <p className='support-new-3-col-text'>
                  {t('newSupport.theNew')}
                </p>
                <div className='btn-wrap support-new-3-col-btn-wrap'>
                  <a
                    target='_blank'
                    href={require('../assets/pdf/THE NEW PLAYBOOK - EBOOK.pdf')}
                    class='custom-new-btn'
                  >
                    {t('newSupport.download')}
                  </a>
                </div>
              </Col>
              <Col md={6} className='support-new-3-col'>
                <div className='img-wrap support-new-3-col-img'>
                  <img src={Img16} />
                </div>
                <p className='support-new-3-col-text'>
                  {t('newSupport.16Proven')}
                </p>
                <div className='btn-wrap support-new-3-col-btn-wrap'>
                  <a
                    target='_blank'
                    href={require('../assets/pdf/16 PROVEN WAYS - OVATO EBOOK.pdf')}
                    class='custom-new-btn'
                  >
                    {t('newSupport.download')}
                  </a>
                </div>
              </Col>
              <Col md={6} className='support-new-3-col'>
                <div className='img-wrap support-new-3-col-img'>
                  <img
                    src={ImgRestaurant}
                  />
                </div>
                <p className='support-new-3-col-text'>
                  {t('newSupport.newPlaybook')}
                </p>
                <div className='btn-wrap support-new-3-col-btn-wrap'>
                  <a
                    target='_blank'
                    href={require('../assets/pdf/NEW PLAYBOOK FOR RESTAURANT - EBOOK.pdf')}
                    class='custom-new-btn'
                  >
                    {t('newSupport.download')}
                  </a>
                </div>
              </Col>
              <Col md={6} className='support-new-3-col'>
                <div className='img-wrap support-new-3-col-img'>
                  <img src={ImgStudy} />
                </div>
                <p className='support-new-3-col-text'>
                  {t('newSupport.merchant')}
                </p>
                <div className='btn-wrap support-new-3-col-btn-wrap'>
                  <a href='/wallet' class='custom-new-btn'>
                    {t('newSupport.download')}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Support);
