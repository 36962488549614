import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Containers
import TopNavPremium from '../../containers/TopNavPremium';
import BottomNav from '../../containers/NewBottomNav';

// Components
import TotalBox from '../../components/premium/organization/TotalBox';
import HeatMapBox from '../../components/premium/organization/HeatMapBox';
import TopCountriesBox from '../../components/premium/organization/TopCountriesBox';
import OrgTable from '../../components/premium/organization/OrgTable';

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <>
            {/* Header */}
            <TopNavPremium />

            <section className='top-section v2-py-3 org-1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='poppins v2-font-30 v2-tp-font-28 v2-m-font-24 semi-bold blue-text text-center mb-0'>My Organization</h1>
                            <p className='poppins v2-font-16 v2-tp-font-14 regular text-uppercase text-center blue-text'>(USERS)</p>
                        </div>
                    </div>
                    
                    <div className='v2-mt-2 row small-col org-col-wrap'>
                        <div className='col'>
                            <TotalBox title={'Total Organization'} value={250} />

                            <div className='v2-mt-1'>
                                <HeatMapBox />
                            </div>
                        </div>
                        <div className='col v2-tp-mt-1'>
                            <TotalBox title={'Total Wallets'} value={800} />

                            <div className='v2-mt-1'>
                                <TopCountriesBox />
                            </div>
                        </div>
                        <div className='col v2-tp-mt-1'>
                            <TotalBox title={'Total Level 1'} value={100} />

                            <div className='v2-mt-1'>
                                <TopCountriesBox />
                            </div>
                        </div>
                        <div className='col v2-tp-mt-1'>
                            <TotalBox title={'Total Level 2'} value={150} />

                            <div className='v2-mt-1'>
                                <TopCountriesBox />
                            </div>
                        </div>
                    </div>

                    <div className='v2-mt-3 row'>
                        <div className='col'>
                            <ul className='org-level-wrap'>
                                <li>Level 1</li>
                                <li>Level 2</li>
                            </ul>
                        </div>
                    </div>

                    <div className='v2-mt-3 row'>
                        <OrgTable />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <BottomNav />
        </>
    );
  }
}
export default withTranslation()(Organization);