import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
class MassAdoption extends Component {
    constructor(props) {
        super(props);
        this.state = {
        modal: false,
        fade: false,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
        modal: !this.state.modal,
        });
    }
  render() {
    const { t } = this.props;
    return (
      <>
      <Modal
        isOpen={this.state.modal}
        fade={this.state.fade}
        toggle={this.toggle}
        size='lg'
        centered={true}
    >
        <ModalHeader toggle={this.toggle}></ModalHeader>
        <ModalBody>
        <iframe
            width='100%'
            height='400'
            src='https://www.youtube.com/embed/QvFtAtVnSik'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen='true'
        ></iframe>
        </ModalBody>
    </Modal>
      <section className='mass-adoption-sect'>
          <Container className='mass-adoption-container'>
              <Row className='mass-adoption-row'>
                  <Col className='mass-adoption-col'>
                    <div className="img-wrap mass-adoption-img-wrap">
                        <a href='/'><img width='120' src={require('../assets/img/img-ovato-logo-ma.png')} /></a>
                    </div>
                    <div className='text-center mask-img home-why-img-video mt-30'>
                        <img
                            alt='Why'
                            src={require('../assets/img/img-why.jpg')}
                            onClick={this.toggle}
                            className='mask-img-img'
                        />
                    </div>
                    <h1 className='Poppins bold font-48 text-center white-text'>The Answer To Mass <br /> Adoption Is Now Here !</h1>
                    <div className="btn-wrap text-center mt-30">
                        <a href='/landing' className='gradient-pink-btn gradient-pink-btn-3' onClick={this.scrollToSection} style={{ cursor: 'pointer' }}>
                            Discover Now
                        </a>
                    </div>
                  </Col>
              </Row>
          </Container>
      </section>
      </>
    );
  }
}
export default withTranslation()(MassAdoption);
