import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import ImgOvo from '../../../assets/img/premium-wallet/icon-premium-ovo.png'

class WhiteBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    return (
        <div className='v2-p-2 send-receive-box'>
            <div className='flex align-center content-center send-receive-box-val-wrap'>
                <img width={40} src={ImgOvo} /> <span className='inline-flex v2-font-36 v2-tl-font-32 v2-tp-font-28 poppins semi-bold blue-text v2-pl-0-5 send-receive-box-val-text'>{value}</span>
            </div>

            <div className='v2-mt-0-25 poppins v2-font-17 v2-tl-font-15 v2-tp-font-14 medium blue-text text-uppercase text-center ls-1 send-receive-box-text'>
                {title}
            </div>
        </div>
    );
  }
}
export default withTranslation()(WhiteBox);
