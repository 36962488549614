import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MerchantTable from './MerchantTable';
class MeetUpTextIcon extends Component {
  render() {
    const { t, title, image, content } = this.props;
    return (
      <>
      <div className='muti-wrap'>
          <div className='muti-icon'>
              <div className='img-wrap'>
                <img src={image} />
              </div>
          </div>
          <div className='muti-content'>
              <h2 className='muti-title'>{title}</h2>
              <h2 className='muti-text'>{content}</h2>
          </div>
      </div>
      </>
    );
  }
}
export default withTranslation()(MeetUpTextIcon);
