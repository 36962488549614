import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios'; // Import Axios

import { WALLET_URL } from '../constants/defaultValues';

import { Service } from '../providers/Services';

const stripePromise = loadStripe('pk_test_51PN5FUIyXXACTIES4DbZIul9uLjvD8Eb3VmPh8iwMbWi1RcSZOdNqnLWii2sPzVEYEwPWPgzAQ4iFfZXVN9kKeDT00cIsaOt2r'); // Replace with your publishable key

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clientSecret: '',
      amount: '',
    };
  }

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value }); //will be fetched from nft api
  };

  handleEnterClick = async () => {

    this.setState({ loading: true });

    const amount = this.state.amount * 100; // Convert dollars to cents

    try {
      const response = await axios.post(
            WALLET_URL + 'nfts/create_intent', 
            {
                // amount,
                // currency: 'usd','
                nft: '666917cf2975787a2d34989c',
                payment_mode: 0
            },
            {
                headers: {
                'Authorization': `Bearer ` + localStorage.getItem('x-access-token')
                }
            }
        );

      const { clientSecret } = response.data;

      this.setState({ clientSecret });

      // Simulate waiting for the payment to be processed
      setTimeout(async () => {
        const stripe = await stripePromise;
        const paymentIntent = await stripe.retrievePaymentIntent(this.state.clientSecret);
        console.log('ssS::: ')
        if (paymentIntent.status === 'succeeded') {
          console.log('Payment succeeded');
          this.setState({ loading: false });
        } else {
          console.log('Payment failed');
          this.setState({ loading: false });
        }
      }, 2000); // Simulate network delay
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, clientSecret, amount } = this.state;

    return (
      <div className="App">
        <button onClick={this.handleEnterClick}>Pay</button>
        {loading && <p>Loading...</p>}
      </div>
    );
  }
}

export default function PaymentCard() {
  return (
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  );
}