import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgOwardsRewards from '../assets/img/img-owards-rewards-7.png';
import imgEarn from '../assets/img/img-earned-new.png';
import imgEarnMobile from '../assets/img/img-7-earned-new-mobile.png';
import imgPartner from '../assets/img/img-7-earn-new.png';
import imgPartnerMobile from '../assets/img/img-partner-mobile-7-new.png';
import imgWaysPartner from '../assets/img/img-ways-partner-7.png';
import imgWaysPartnerMobile from '../assets/img/img-ways-partners-mobile.png';
class Section7 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='section-7-new pt-100 desktop'>
          <Container>
            <Row>
              <Col className='relative pl-135' md={6}>
                <div class='pink-gradient-num-wrap absolute left-0 top-0'>
                  2
                </div>
                <h3 className='font-57 bold blue-text m-0'>
                  {t('section7.earn')}
                </h3>
                <div className='img-wrap img-owards-rewards-7'>
                  <img
                    src={imgOwardsRewards}
                    alt='Owards Rewards'
                    width='250'
                  />
                </div>
                <p className='font-20 medium mb-0 mt-40'>
                  {t('section7.detail1')}
                </p>
                <div className='cta-wrap mt-5'>
                  <a href='/' className='light-blue-text font-17 bold'>
                    Visit Site
                  </a>
                </div>
                <p className='font-25 bold mt-20'>
                {t('section7.come_join')} <br /> {t('section7.try_reward')} <br /> {t('section7.reward_at')}{' '}
                  <a href='/' className='light-blue-text text-underline'>
                  {t('section7.owards_com')}
                  </a>
                </p>
              </Col>
              <Col md={6}>
                <div className='img-wrap img-7-new-earn'>
                  <img src={imgEarn} alt='Earn' />
                </div>
              </Col>
            </Row>
            <Row className='mt-45'>
              <Col className='relative pl-135' md={12}>
                <div class='pink-gradient-num-wrap absolute left-0 top-0'>
                  3
                </div>
                <h3 className='font-57 bold blue-text mt-10'>{t('section7.partner')}</h3>
                <p className='font-20 medium mb-0 mt-20'>
                {t('section7.detail2')}
                </p>
                <p className='font-20 medium mb-0 mt-20'>
                {t('section7.detail4')}
                </p>
              </Col>
            </Row>
            <Row className='img-partner-7-row'>
              <Col>
                <div className='img-wrap img-partner-7'>
                  <img src={imgPartner} alt='Partner' width='1127' />
                </div>
              </Col>
            </Row>
            <Row className='mt-90 ways-to-partner-row'>
              <Col md={7}>
                <h2 className='font-38 bold blue-text mb-0'>{t('section7.ways_to_partner')}</h2>
                <h3 className='font-25 bold pink-text mt-5'>
                {t('section7.become_a_merchant')}
                </h3>
                <ul className='font-20 medium pink-ul-list mt-30'>
                  <li>
                  {t('section7.list1')}
                  </li>
                  <li>
                  {t('section7.list2')}{' '}
                  </li>
                  <li>
                  {t('section7.list3')}
                  </li>
                  <li>
                  {t('section7.list4')}
                  </li>
                  <li>
                  {t('section7.list5')}
                  </li>
                  <li>
                  {t('section7.list6')}{' '}
                  </li>
                  <li>
                  {t('section7.list7')}
                  </li>
                </ul>
                <h3 className='font-26 bold pink-text mt-30'>
                {t('section7.agent')}
                </h3>
                <ul className='pink-ul-list font-20 medium mt-15'>
                  <li>
                  {t('section7.detail3')}{' '}
                  </li>
                  <li>{t('section7.excellent')}</li>
                </ul>
                <div className='btn-wrap mt-30'>
                  <a href='/' className='gradient-pink-btn gradient-pink-btn-3'>
                  {t('section7.visit_website')}
                  </a>
                </div>
              </Col>
              <Col md={5}>
                <div className='img-wrap img-ways-partner-7'>
                  <img src={imgWaysPartner} alt='Ways Partner' />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section-7-new-mobile pt-0 mobile'>
          <Container>
            <Row>
              <Col className='relative' md={6}>
                <div class='pink-gradient-num-wrap absolute left-15 top-0'>
                  2
                </div>
                <h3 className='font-30 bold blue-text m-0 pl-70 pt-5'>
                  {t('section7.earn')}
                </h3>
                <div className='img-wrap pl-70'>
                  <img
                    src={imgOwardsRewards}
                    alt='Owards Rewards'
                    width='225'
                  />
                </div>
                <p className='font-18 medium mb-0 mt-20 text-center'>
                {t('section7.detail1')}
                </p>
                <div className='cta-wrap mt-5 text-center'>
                  <a href='/' className='light-blue-text font-17 bold'>
                    Visit Site
                  </a>
                </div>
                <p className='font-22 bold mt-30 text-center lh-1-3'>
                {t('section7.come_join')} <br /> {t('section7.try_reward')} <br/> {t('section7.reward_at')}{' '}
                  <a href='/' className='light-blue-text text-underline'>
                  {t('section7.owards_com')}
                  </a>
                </p>
              </Col>
              <Col md={6} className='mt-20'>
                <div className='img-wrap'>
                  <img
                    src={imgEarnMobile}
                    alt='Earn Mobile'
                    width='300'
                    style={{ margin: 'auto', display: 'block' }}
                  />
                </div>
              </Col>
            </Row>
            <Row className='mt-60'>
              <Col className='relative' md={12}>
                <div class='pink-gradient-num-wrap absolute left-15 top-0'>
                  3
                </div>
                <h3 className='font-30 bold blue-text mt-10 pl-70'>{t('section7.partner')}</h3>
                <p className='font-18 medium mb-0 mt-30 text-center'>
                {t('section7.detail2')}
                </p>
                <p className='font-18 medium mb-0 mt-20 text-center'>
                {t('section7.detail4')}
                </p>
              </Col>
            </Row>
            <Row className='mt-30'>
              <Col>
                <div className='img-wrap'>
                  <img
                    src={imgPartnerMobile}
                    alt='Partner Mobile'
                    width='300'
                    style={{ margin: 'auto', display: 'block' }}
                  />
                </div>
              </Col>
            </Row>
            <Row className='mt-45'>
              <Col md={12}>
                <h2 className='font-38 bold blue-text mb-0 text-center'>
                {t('section7.ways_to_partner')}
                </h2>
                <h3 className='font-25 bold pink-text mt-5 text-center'>
                {t('section7.become_a_merchant')}
                </h3>
                <ul className='font-20 medium pink-ul-list mt-30'>
                  
                </ul>
                <h3 className='font-26 bold pink-text mt-30 text-center'>
                {t('section7.agent')}
                </h3>
                <ul className='pink-ul-list font-20 medium mt-15'>
                  <li>
                  {t('section7.detail3')}
                  </li>
                  <li>{t('section7.excellent')}</li>
                </ul>
                <div className='btn-wrap mt-30 text-center'>
                  <a href='/' className='gradient-pink-btn gradient-pink-btn-3'>
                  {t('section7.visit_website')}
                  </a>
                </div>
              </Col>
              <Col md={12} className='mt-30'>
                <div className='img-wrap text-center'>
                  <img
                    src={imgWaysPartnerMobile}
                    alt='Ways Partner Mobile'
                    width='300'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section7);
