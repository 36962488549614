import React, { Component } from 'react';
import BottomNav from '../containers/BottomNav';
import TopNav from '../containers/TopNav';
import {
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  Table,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgCustomizable from '../assets/img/img-customizable-home-4.png';
import imgFitsForAll from '../assets/img/img-fits-for-all-home-4.png';
import imgInstant from '../assets/img/img-instant-home-4.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
class MerchantTable extends Component {
  render() {
    const { t } = this.props;
    const mobileSlider = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <>
      <Container className='merchant_table text-center merchant-6'>
          <div className='merchant_table_div desktop'>
            <div className='merchant-table-left-ribbon'></div>
            <div className='merchant-table-right-ribbon'></div>
            <Table responsive>
              <thead className='merchant_table_primary_head'>
                <tr>
                  <th></th>
                  <th className='merchant_th'>
                    {t('merchant.speed')}
                    <br />
                    <span className='merchant_th_span'>
                      {t('merchant.per_transaction')}
                    </span>
                  </th>
                  <th className='merchant_th'>
                    {t('merchant.settlement')}
                    <br />
                    <span className='merchant_th_span'>
                      {t('merchant.per_transaction')}
                    </span>
                  </th>
                  <th className='merchant_th'>
                    {t('merchant.cost')}
                    <br />
                    <span className='merchant_th_span'>
                      {t('merchant.per_transaction')}
                    </span>
                  </th>
                </tr>
              </thead>
              <thead className='merchant_table_secondary_head'>
                <tr>
                  <th>
                    <img
                      alt='Merchant Ovo'
                      src={require('../assets/img/img-ovato-tbl.png')}
                      className='merchant_table_ovo_img'
                    />
                  </th>
                  <th className='merchant_secondary_th'>
                    1 {t('merchant.sec')}
                  </th>
                  <th className='merchant_secondary_th'>
                    10 {t('merchant.sec')}
                  </th>
                  <th className='merchant_secondary_th'>&lt;$0.01</th>
                </tr>
              </thead>
              <tbody>
                <tr className='merchant_tr'>
                  <th scope='row'>{t('merchant.bitcoin')}</th>
                  <td>10 {t('merchant.min')}</td>
                  <td>60 {t('merchant.min')}</td>
                  <td>$0.30</td>
                </tr>
                <tr className='merchant_tr'>
                  <th scope='row'>{t('merchant.paypal')}</th>
                  <td>5 {t('merchant.sec')}</td>
                  <td>8 {t('merchant.days')}</td>
                  <td>$3.45</td>
                </tr>
                <tr className='merchant_tr'>
                  <th scope='row'>{t('merchant.credit_card')}</th>
                  <td>3 {t('merchant.sec')}</td>
                  <td>3 {t('merchant.business_days')}</td>
                  <td>{t('merchant.upto')} $75</td>
                </tr>
                <tr className='merchant_tr'>
                  <th scope='row'>{t('merchant.wire_trans')}</th>
                  <td>1-5 {t('merchant.days')}</td>
                  <td>1-5 {t('merchant.days')}</td>
                  <td>$5-$65.00</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Slider {...mobileSlider} className='merchant-table-mobile-wrap mobile pink-arrows'>
            <div className='merchant-table-mobile'>
              <div className='merchant-table-mobile-heading'>
                <span className='mtm-text-1'>Speed</span>
                <span className='mtm-text-2'>Per Transaction</span>
              </div>
              <div className='merchant-table-mobile-body'>
                <div className='mtm-left'><img src={require('../assets/img/img-ovato-tbl.png')} alt='Ovato' width='107' /></div>
                <div className='mtm-right'>1 Sec</div>
              </div>
              <div className='merchant-table-mobile-footer-wrap'>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Bitcoin</div>
                  <div className='mtm-right'>10 Min</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Paypal</div>
                  <div className='mtm-right'>5 Sec</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Credit Cards</div>
                  <div className='mtm-right'>3 Sec</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Wire Transfer</div>
                  <div className='mtm-right'>1-5 Days</div>
                </div>
              </div>
            </div>
            <div className='merchant-table-mobile'>
              <div className='merchant-table-mobile-heading'>
                <span className='mtm-text-1'>Settlement</span>
                <span className='mtm-text-2'>Per Transaction</span>
              </div>
              <div className='merchant-table-mobile-body'>
                <div className='mtm-left'><img src={require('../assets/img/img-ovato-tbl.png')} alt='Ovato' width='107' /></div>
                <div className='mtm-right'>10 Sec</div>
              </div>
              <div className='merchant-table-mobile-footer-wrap'>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Bitcoin</div>
                  <div className='mtm-right'>60 Min</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Paypal</div>
                  <div className='mtm-right'>8 days</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Credit Cards</div>
                  <div className='mtm-right'>3 Business Days</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Wire Transfer</div>
                  <div className='mtm-right'>1-5 Days</div>
                </div>
              </div>
            </div>
            <div className='merchant-table-mobile'>
              <div className='merchant-table-mobile-heading'>
                <span className='mtm-text-1'>Cost</span>
                <span className='mtm-text-2'>Per Transaction</span>
              </div>
              <div className='merchant-table-mobile-body'>
                <div className='mtm-left'><img src={require('../assets/img/img-ovato-tbl.png')} alt='Ovato' width='107' /></div>
                <div className='mtm-right'>{ '<$0.01' }</div>
              </div>
              <div className='merchant-table-mobile-footer-wrap'>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Bitcoin</div>
                  <div className='mtm-right'>$0.30</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Paypal</div>
                  <div className='mtm-right'>$3.45</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Credit Cards</div>
                  <div className='mtm-right'>Up To $75</div>
                </div>
                <div className='merchant-table-mobile-footer'>
                  <div className='mtm-left'>Wire Transfer</div>
                  <div className='mtm-right'>$5-$65.00</div>
                </div>
              </div>
            </div>
          </Slider>
        </Container>
      </>
    );
  }
}
export default withTranslation()(MerchantTable);
