import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgHome4Video from '../../assets/img/img-new-home-4-video.jpg'
import imgInstant from '../../assets/img/img-new-instant.png'
import imgFastAndEasy from '../../assets/img/img-new-fast-and-easy.png'
import imgFullCircle from '../../assets/img/img-a-new-full-circle.png'
import Fancybox from '../../containers/FancyBox';
class NewHome4 extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className='new-home-4'>
        <Container>
            <Row>
                <Col>
                    <h2 className='Poppins font-46 bold dark-text-5 text-center new-home-4-heading'>{t('newhome4.why')}</h2>
                    <div className='video-wrap new-home-4-video-wrap mt-60'>
                        <Fancybox>
                            <a data-fancybox href='https://www.youtube.com/watch?v=QvFtAtVnSik'>
                                <img className='new-home-4-video' src={imgHome4Video} />
                            </a>
                        </Fancybox>
                    </div>
                </Col>
            </Row>
            <Row className='new-home-4-row-2'>
                <Col>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgInstant} />
                        </div>
                        <h3 className='new-home-4-box-title'>{t('newhome4.instant')}</h3>
                        <p className='new-home-4-box-text-1'>{t('newhome4.fast')}</p>
                        <p className='new-home-4-box-text-2'>{t('newhome4.ovato')}</p>
                    </div>
                </Col>
                <Col>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgFastAndEasy} />
                        </div>
                        <h3 className='new-home-4-box-title'>{t('newhome4.fast2')}</h3>
                        <p className='new-home-4-box-text-1'>{t('newhome4.merchant')}</p>
                        <p className='new-home-4-box-text-2'>{t('newhome4.no')}</p>
                    </div>
                </Col>
                <Col>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgFullCircle} />
                        </div>
                        <h3 className='new-home-4-box-title'>{t('newhome4.a')}</h3>
                        <p className='new-home-4-box-text-1'>{t('newhome4.merchant2')}</p>
                        <p className='new-home-4-box-text-2'>{t('newhome4.value')}</p>
                    </div>
                </Col>
            </Row>
            <Row className='align-center'>
                <Col>
                    <div className='btn-wrap new-home-4-btn-wrap text-center'>
                        <a href='/wallet' className='custom-new-btn'>{t('newhome4.get')}</a>
                    </div>
                </Col>
            </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation()(NewHome4);
