import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Components
import InputField from '../InputField';

class OrganizationBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <div className='pw-box pw-box-3'>

            <div className='v2-py-1-5 v2-px-2 flex align-center space-between pw-box-header-title'>
                Organization
            </div>
            
            <div className='v2-py-1-5 v2-px-2 pw-box-body'>

                <div className='row'>
                    <div className='col col-sm-4'>
                        <InputField label='Total' name='total' value={0} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-sm-7'>
                        <InputField label='Total Income Level 1' name='total_income_level_1_usd' value={0} currency={'USD'} />
                    </div>
                    <div className='col col-sm-5'>
                        <InputField label='' name='total_income_level_1_ovo' value={0} currency={'ovo'} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-sm-7'>
                        <InputField label='Total Income Level 2' name='total_income_level_2_usd' value={0} currency={'USD'}/>
                    </div>
                    <div className='col col-sm-5'>
                        <InputField label='' name='total_income_level_2_ovo' value={0} currency={'ovo'} />
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default withTranslation()(OrganizationBox);
