import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import {
  Container,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import BrandBoxContainer from './BrandBoxContainer';

import ImgBanner from '../assets/img/img-brand-assets-banner-new.png'
import ImgBannerMobile from '../assets/img/img-brand-assets-banner-new-mobile.png'

import Img1_1 from '../assets/img/img-ovato-coin-new.png'
import Img1_1_JPG from '../assets/img/img-ovato-coin-new.jpg'
import Img1_1_PDF from '../assets/img/img-ovato-coin-new.pdf'

import Img1_2 from '../assets/img/img-ovato-symbol-new.png'
import Img1_2_JPG from '../assets/img/img-ovato-symbol-new.jpg'
import Img1_2_PDF from '../assets/img/img-ovato-symbol-new.pdf'

import Img1_3 from '../assets/img/img-ovato-brand-new.png'
import Img1_3_JPG from '../assets/img/img-ovato-brand-new.jpg'
import Img1_3_PDF from '../assets/img/img-ovato-brand-new.pdf'

import Img1_4 from '../assets/img/img-ovato-mascot.png'
import Img1_4_JPG from '../assets/img/img-ovato-mascot.jpg'
import Img1_4_PDF from '../assets/img/img-ovato-mascot.pdf'

import Img1_5 from '../assets/img/img-ovato-mascot-who-hoo.png'
import Img1_5_JPG from '../assets/img/img-ovato-mascot-who-hoo.jpg'
import Img1_5_PDF from '../assets/img/img-ovato-mascot-who-hoo.pdf'

// Section 2 Images
import Img2_1 from '../assets/img/logo-3d-ovato-coin-front-new.png'
import Img2_1_JPG from '../assets/img/logo-3d-ovato-coin-front-new.jpg'
import Img2_1_PDF from '../assets/img/logo-3d-ovato-coin-front-new.pdf'

import Img2_2 from '../assets/img/logo-3d-ovato-coin-perspective-new.png'
import Img2_2_JPG from '../assets/img/logo-3d-ovato-coin-perspective-new.jpg'
import Img2_2_PDF from '../assets/img/logo-3d-ovato-coin-perspective-new.pdf'

import Img2_3 from '../assets/img/logo-3d-ovato-logo-new.png'
import Img2_3_JPG from '../assets/img/logo-3d-ovato-logo-new.jpg'
import Img2_3_PDF from '../assets/img/logo-3d-ovato-logo-new.pdf'

// Section 3 Images
import Img3_1 from '../assets/img/img-digital-cash-horizontal.png'
import Img3_1_JPG from '../assets/img/img-digital-cash-horizontal.jpg'
import Img3_1_PDF from '../assets/img/img-digital-cash-horizontal.pdf'

import Img3_2 from '../assets/img/img-digital-sticker-vertical.png'
import Img3_2_JPG from '../assets/img/img-digital-sticker-vertical.jpg'
import Img3_2_PDF from '../assets/img/img-digital-sticker-vertical.pdf'

// Section 4 Images
import Img4_1 from '../assets/img/img-consumer-wallet-new.png'
import Img4_1_JPG from '../assets/img/img-consumer-wallet-new.jpg'
import Img4_1_PDF from '../assets/img/img-consumer-wallet-new.pdf'

import Img4_2 from '../assets/img/img-merchant-wallet-new.png'
import Img4_2_JPG from '../assets/img/img-merchant-wallet-new.jpg'
import Img4_2_PDF from '../assets/img/img-merchant-wallet-new.pdf'

import Img4_3 from '../assets/img/img-multi-sig-wallet-new.png'
import Img4_3_JPG from '../assets/img/img-multi-sig-wallet-new.jpg'
import Img4_3_PDF from '../assets/img/img-multi-sig-wallet-new.pdf'

// Section 5 Images
import Img5_1 from '../assets/img/img-colored-logo-text.png'
import Img5_1_JPG from '../assets/img/img-colored-logo-text.jpg'
import Img5_1_PDF from '../assets/img/img-colored-logo-text.pdf'

import Img5_2 from '../assets/img/img-monochrome-full-logo.png'
import Img5_2_JPG from '../assets/img/img-monochrome-full-logo.jpg'
import Img5_2_PDF from '../assets/img/img-monochrome-full-logo.pdf'

import Img5_3 from '../assets/img/img-monochrome-logo-text.png'
import Img5_3_JPG from '../assets/img/img-monochrome-logo-text.jpg'
import Img5_3_PDF from '../assets/img/img-monochrome-logo-text.pdf'

// Section 6 Images
import Img6_1 from '../assets/img/img-ovato-coin-front-new.png'
import Img6_1_JPG from '../assets/img/img-ovato-coin-front-new.jpg'
import Img6_1_PDF from '../assets/img/img-ovato-coin-front-new.pdf'

import Img6_2 from '../assets/img/img-ovato-coin-perspective-new.png'
import Img6_2_JPG from '../assets/img/img-ovato-coin-perspective-new.jpg'
import Img6_2_PDF from '../assets/img/img-ovato-coin-perspective-new.pdf'

import Img6_3 from '../assets/img/img-ovato-coin-stack-new.png'
import Img6_3_JPG from '../assets/img/img-ovato-coin-stack-new.jpg'
import Img6_3_PDF from '../assets/img/img-ovato-coin-stack-new.pdf'

class BrandAssets extends Component {
  
  render() {
    const { t } = this.props;
    return (
      <div className='blue-nav support_main'>
        <TopNav />
        <div className='new-first-row brand-main-new'>
          <Container>
            <Row>
              <Col md={8}>
                <h1 className='Poppins font-43 extra-bold blue-text brand-main-new-heading-1'>{t('brandassets.brand')}</h1>
                <h2 className='Poppins regular font-18 dark-text-4 lh-1-5 m-0 brand-main-new-heading-2'>
                  {t('brandassets.brandline')}
                </h2>
              </Col>
              <Col md={4}>
                <div className='img-wrap brand-banner-img-wrap-new desktop'>
                  <img
                    alt='Brand 1'
                    src={ImgBanner}
                    className='brand_img'
                  />
                </div>
                <div className='img-wrap brand-banner-img-wrap-new mobile'>
                  <img
                    alt='Brand 1'
                    src={ImgBannerMobile}
                    className='brand_img'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section className='brand-asset-section-new brand-asset-section-new-2'>
          {/* Section 1 */}
          {/* OVATO LOGO / ICON PURPOSES */}
          <BrandBoxContainer 
            className='brand-purposes-section' 
            title={t('brand_asset_new.ovato_logo_icon_purposes')} 
            record={[
              { 
                image: Img1_1, 
                title: t('brand_asset_new.ovato_coin'), 
                subtitle: t('brand_asset_new.ovato_coin_used'), 
                downloads: {
                  png: Img1_1,
                  jpg: Img1_1_JPG,
                  pdf: Img1_1_PDF
                }
              },
              { 
                image: Img1_2, 
                title: t('brand_asset_new.ovato_symbol'), 
                subtitle: t('brand_asset_new.ovato_symbol_used'), 
                downloads: {
                  png: Img1_2,
                  jpg: Img1_2_JPG,
                  pdf: Img1_2_PDF
                } 
              },
              { 
                image: Img1_3, 
                title: t('brand_asset_new.ovato_brand'), 
                subtitle: t('brand_asset_new.ovato_brand_used'), 
                downloads: {
                  png: Img1_3,
                  jpg: Img1_3_JPG,
                  pdf: Img1_3_PDF
                } 
              },
              { 
                image: Img1_4, 
                title: t('brand_asset_new.ovato_mascot'), 
                subtitle: t('brand_asset_new.ovato_measurement'), 
                downloads: {
                  png: Img1_4,
                  jpg: Img1_4_JPG,
                  pdf: Img1_4_PDF
                } 
              },
              { 
                image: Img1_5, 
                title: t('brand_asset_new.ovato_mascot'), 
                subtitle: t('brand_asset_new.ovato_measurement'), 
                downloads: {
                  png: Img1_5,
                  jpg: Img1_5_JPG,
                  pdf: Img1_5_PDF
                } 
              }
            ]} 
          />
          {/* SECTION 2 */}
          {/* 3D Icons New */}
          <BrandBoxContainer 
            className='brand-box-5' 
            title={t('brand_asset_new.3d_icons')} 
            record={[
              { 
                image: Img2_1, 
                title: t('brand_asset_new.ovato_coin_front'), 
                downloads: {
                  png: Img2_1,
                  jpg: Img2_1_JPG,
                  pdf: Img2_1_PDF
                } 
              },
              { 
                image: Img2_2, 
                title: t('brand_asset_new.ovato_coin_perspective'), 
                downloads: {
                  png: Img2_2,
                  jpg: Img2_2_JPG,
                  pdf: Img2_2_PDF
                } 
              },
              { 
                image: Img2_3, 
                title: t('brand_asset_new.ovato_coin_stack'), 
                downloads: {
                  png: Img2_3,
                  jpg: Img2_3_JPG,
                  pdf: Img2_3_PDF
                } 
              }
            ]} 
          />
          {/* SECTION 3 */}
          {/* DIGITAL CASH STICKERS */}
          <BrandBoxContainer 
            className='brand-box-2' 
            title={t('brand_asset_new.digital_cash_stickers')} 
            record={[
              { 
                image: Img3_1, 
                title: t('brand_asset_new.horizontal_version'), 
                downloads: {
                  png: Img3_1,
                  jpg: Img3_1_JPG,
                  pdf: Img3_1_PDF
                }  
              },
              { 
                image: Img3_2,
                title: t('brand_asset_new.vertical_version'), 
                downloads: {
                  png: Img3_2,
                  jpg: Img3_2_JPG,
                  pdf: Img3_2_PDF
                } 
              }
            ]} 
          />
          {/* SECTION 4 */}
          {/* APP ICONS */}
          <BrandBoxContainer 
            className='brand-box-3' 
            title={t('brand_asset_new.app_icons')} 
            record={[
              { 
                image: Img4_1, 
                title: t('brand_asset_new.consumer_wallet'), 
                downloads: {
                  png: Img4_1,
                  jpg: Img4_1_JPG,
                  pdf: Img4_1_PDF
                } 
              },
              { 
                image: Img4_2, 
                title: t('brand_asset_new.merchant_wallet'), 
                downloads: {
                  png: Img4_2,
                  jpg: Img4_2_JPG,
                  pdf: Img4_2_PDF
                }  
              },
              { 
                image: Img4_3, 
                title: t('brand_asset_new.multi_sig_wallet'), 
                downloads: {
                  png: Img4_3,
                  jpg: Img4_3_JPG,
                  pdf: Img4_3_PDF
                } 
              }
            ]} 
          />
          {/* Section 5 */}
          {/* Logo Variations */}
          <BrandBoxContainer 
            className='brand-box-5' 
            title={t('brand_asset_new.logo_variations')} 
            record={[
              { 
                image: Img5_1, 
                title: t('brand_asset_new.colored_logo_text'), 
                downloads: {
                  png: Img5_1,
                  jpg: Img5_1_JPG,
                  pdf: Img5_1_PDF
                } 
              },
              { 
                image: Img5_2, 
                title: t('brand_asset_new.monochrome_logo_text'), 
                downloads: {
                  png: Img5_2,
                  jpg: Img5_2_JPG,
                  pdf: Img5_2_PDF
                } 
              },
              { 
                image: Img5_3, 
                title: t('brand_asset_new.monochrome_full_logo'), 
                downloads: {
                  png: Img5_3,
                  jpg: Img5_3_JPG,
                  pdf: Img5_3_PDF
                } 
              }
            ]} 
          />
          {/* SECTION 6 */}
          {/* 3D Icons */}
          <BrandBoxContainer 
            className='brand-box-4' 
            title={t('brand_asset_new.3d_icons')} 
            record={[
              { 
                image: Img6_1, 
                title: t('brand_asset_new.ovato_coin_front'), 
                downloads: {
                  png: Img6_1,
                  jpg: Img6_1_JPG,
                  pdf: Img6_1_PDF
                } 
              },
              { 
                image: Img6_2, 
                title: t('brand_asset_new.ovato_coin_perspective'), 
                downloads: {
                  png: Img6_2,
                  jpg: Img6_2_JPG,
                  pdf: Img6_2_PDF
                } 
              },
              { 
                image: Img6_3, 
                title: t('brand_asset_new.ovato_coin_stack'), 
                downloads: {
                  png: Img6_3,
                  jpg: Img6_3_JPG,
                  pdf: Img6_3_PDF
                } 
              }
            ]} 
          />
        </section>
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(BrandAssets);
