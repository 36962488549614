import React from 'react'
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import '../../assets/css/OvatoNation.css'
// Images
import ImgOvato from '../../assets/img/announcement/hf-logo-ovato.png'
import ImgHF2 from '../../assets/img/announcement/img-hf-2.png'
import ImgHF3 from '../../assets/img/announcement/img-hf-3.png'
import ImgHF32 from '../../assets/img/announcement/img-hf-3-2.png'
import ImgHFBonus from '../../assets/img/announcement/img-hf-bonus.png'

import ImgOvatoMobile from '../../assets/img/announcement/mobile-img-ovato-hf.png'

const HardFork = () => {
  return (
    <div className='hf-main'>
      {/* SECTION 1 */}
      <section className='hf-1 v2-pb-10'>
        <Row>
            <Col>
                <p className='poppins v2-font-20 v2-tl-font-16 v2-m-font-13 regular white-text text-right v2-p-1'>Volume 2, Issue 1</p>
            </Col>
        </Row>
        <Container>
          <Row>
            <Col>
                <div className='img-wrap text-center hf-1-logo'>
                    <img width={115} src={ImgOvato} />
                </div>

                <h1 className='poppins v2-font-48 v2-tl-font-40 v2-tp-font-36 v2-m-font-24 semi-bold white-text text-center mt-0'>Hard Fork Complete</h1>

                <p className='poppins v2-font-15 light white-text text-center v2-mw-50 v2-m-mw-100 mx-auto v2-mt-2 v2-m-mt-18'>We apologize for the delay; we had anticipated a faster process! The Ovato Fork has been completed as the Ovato Protocol transitions from Layer 1 to Layer 2. To be fair, we've introduced several pioneering features to the industry, such as mobile-based sending, username identification, and transaction rating akin to conventional centralized transactions, which have not yet been implemented in crypto.</p>

                <p className='poppins v2-font-15 light white-text text-center v2-mw-50 v2-m-mw-100 mx-auto v2-mt-2'>And while Bitcoin has proven itself to be the bellwether asset class for the store of value, the race to capture the larger "Real World Utility" is virtually wide open. And that's what Ovato has always been all about!</p>

                <div className='mobile v2-mt-2 v2-mb--14'>
                  <img src={ImgOvatoMobile} width={200} />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END SECTION 1 */}

      {/* SECTION 2 */}
      <section className='v2-pt-8 v2-tl-pt-4 v2-m-pt-6 v2-pb-4 v2-tl-pb-2 hf-2'>
        <Container>
          <Row className='align-center'>
            <Col>
                <h2 className='poppins lh-1-2 v2-font-39 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-3 v2-m-br-show'>
                    Great Upgrades, <br/> 
                    Elevated User <br/>
                    Experience!
                </h2>
            </Col>

            <Col sm={8}>
                <div className='img-wrap text-center v2-m-mt-2'>
                    <img src={ImgHF2} />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END SECTION 2 */}

      {/* SECTION 3 */}
      <section className='white-top-curve-10 v2-pt-16 v2-tp-pt-8 v2-m-pt-4 v2-pb-4 hf-3'>
        <Container className='relative z-index-2'>
          <Row className='align-center'>
            <Col sm={6}>
                <div className='img-wrap text-center'>
                    <img src={ImgHF3} />
                </div>
            </Col>
            <Col className='v2-pl-4 v2-m-pl-1'>
                <h2 className='poppins v2-m-mt-2 lh-1-2 v2-font-39 v2-tl-font-36 v2-tp-font-32 v2-m-font-24 bold dark-text-3 v2-m-br-show'>
                For Basic Wallet <br />Holders Only
                </h2>

                <p className='poppins v2-font-15 light dark-text-3 v2-mt-2'>The Basic upgrade is now complete, and we will begin migrating coins for all Basic wallet holders from April 2nd to April 5th. Your coins will automatically be deposited into your Wallet. If you haven't received them by April 5th or encounter difficulty logging into your wallet, don't worry, we've got you covered. You can collect these coins via chat support at ovato.com from April 5th to the 15th, between 10 am and 6 pm (EST) (2 pm to 10 pm GMT). (FYI: This could occur due to various reasons, ranging from unauthenticated accounts to integration issues with certain country codes and SMS).</p>

                <p className='poppins v2-font-15 light dark-text-3 v2-mt-1'>Collect unclaimed coins by April 15 Via chat support</p>

                <div className='v2-mt-2 btn-wrap'>
                    <a href='https://ovato.com' target='_blank' className='hf-btn'>Chat Support</a>
                    <a href='https://calendly.com/ovato' target='_blank' className='hf-btn hf-btn-2 v2-tp-mt-1'>Book a call</a>
                </div>
            </Col>
          </Row>

          <Row className='v2-mt-8 v2-m-mt-4 align-center'>
            <Col className='v2-tp-pl-1'>
                    <h2 className='poppins lh-1-2 v2-font-39 v2-tl-font-36 v2-tp-font-32  v2-m-font-24 bold dark-text-3 v2-m-br-show'>
                    Premium Wallet <br /> (NFT) Holders
                    </h2>

                    <p className='poppins v2-font-15 light dark-text-3 v2-mt-2'>For all premium (NFT) holders your upgraded wallets are also now completed and you will be able to access the via your Basic Wallet at the Govato launch and NFT marketplace launch April 15.</p>

                    <p className='poppins v2-font-15 light dark-text-3 v2-mt-1'>Govato meetings and Influencer marketing commencing and Govato is awarding key reward positions entitled the “ The Ambassadors” (10) that act as a liaison between the affiliate field and Ovato Core. These positions are awarded to long time supporters; Check with Ovato Support to see your eligibility.</p>
                </Col>
            <Col sm={7}>
                <div className='img-wrap text-center v2-m-mt-2'>
                    <img src={ImgHF32} />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END SECTION 3 */}

      {/* SECTION 4 */}
      <section className='white-top-curve-11 v2-pt-14 v2-tp-pt-8 v2-m-pt-6 v2-pb-20 v2-m-pb-22 hf-4'>
        <Container className='relative z-index-2'>
          <Row className='align-center'>
            <Col>
                <h2 className='poppins lh-1-2 v2-font-39 v2-tl-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-3 text-center'>The Rewards</h2>
                <p className='poppins v2-font-15 light dark-text-3 text-center v2-mw-50 v2-m-mw-100 mx-auto v2-mt-2 lh-1-8'>The rewards are still available for Ovato stakeholders wishing to upgrade to Premium. The rewards are phenomenal for users wanting to solidify their positions value while have the ability to earn additional rewards</p>
                <div className='img-wrap text-center v2-mt-2'>
                    <img width={336} src={ImgHFBonus} className='v2-m-mw-70' />
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END SECTION 4 */}

      {/* SECTION 5 */}
      <section className='v2-pb-8 v2-m-pb-4 v2-tl-pb-6 hf-5'>
        <Container className='relative z-index-2'>
          <Row className='align-center'>
            <Col>
                <div className='img-wrap text-center v2-m-mt--4'>
                    <img width={150} src={ImgOvato} />
                </div>
            </Col>
          </Row>

          <Row>
            <Col>
                <h2 className='poppins lh-1-2 v2-font-39 v2-tl-font-36 v2-tp-font-32 v2-m-font-24 bold white-text text-center v2-mt-2 v2-m-mt-1'>Financial Highlights</h2>

                <Row className='v2-mw-70 v2-tp-mw-100 mx-auto v2-mt-3'>
                    <Col>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Online</span> <span className='hf-5-text-2'>ovato.com</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Symbol</span> <span className='hf-5-text-2'>OVO</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Maximum Outstanding</span> <span className='hf-5-text-2'>950 million</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Current Outstanding</span> <span className='hf-5-text-2'>344.4 million</span></p>
                    </Col>

                    <Col className='v2-m-mt-1'>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Public float</span> <span className='hf-5-text-2'>15 million</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Stack/Locking</span> <span className='hf-5-text-2'>75 million</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>Public float</span> <span className='hf-5-text-2'>3 million</span></p>
                        <p className='hf-5-text-wrap'><span className='hf-5-text-1'>General lock</span> <span className='hf-5-text-2'>200 million</span></p>
                    </Col>
                </Row>

                <div className='v2-mt-4 v2-m-mt-3 text-center btn-wrap'>
                    <a href='#' className='hf-btn'>View Financial Overview</a>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END SECTION 5 */}
    </div>
  )
}
export default HardFork  