import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Service } from '../providers/Services';
import imgGooglePlay from '../assets/img/img-google-play.png';
import imgAppleStore from '../assets/img/img-apple-store.png';
import imgOvato from '../assets/img/ovato-logo-new.png';
import imgOvatoHorse from '../assets/img/img-ovato-horse.png';
import imgBloomberg from '../assets/img/img-bloomberg.png';
import imgUsaToday from '../assets/img/img-usa-today.png';
import imgTheWallStreetJournal from '../assets/img/img-the-wall-street-journal.png';
import imgInvestorsBusinessDaily from '../assets/img/img-investors-business-daily.png';
import imgTheDeal from '../assets/img/img-the-deal.png';
import imgTheStreet from '../assets/img/img-the-street.png';
import imgMobileHorse from '../assets/img/img-mobile-horse.png';
import { scroller } from "react-scroll";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
class Section1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      exch: '',
      exchloaded: false
    };
  }
  componentDidMount() {
    Service.getExch()
      .then((res) => {
        this.setState({ exch: res.usd, exchloaded: true });
      })
      .catch((err) => {
      });
  }
  scrollToSection = () => {
    scroller.scrollTo("section-6-new", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  scrollToSection2 = () => {
    scroller.scrollTo("row-6-stacking", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  scrollToSection3 = () => {
    scroller.scrollTo("landing-footer-new", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  render() {
    const { t } = this.props;
    const sliders = [
      { title: 'Bloomberg', img: imgBloomberg },
      { title: 'USA Today', img: imgUsaToday },
      {
        title: 'The Wall Street Journal',
        img: imgTheWallStreetJournal,
      },
      {
        title: 'Investors Business Daily',
        img: imgInvestorsBusinessDaily,
      },
      { title: 'CNBC', img: imgBloomberg },
      { title: 'The Deal', img: imgTheDeal },
      { title: 'The Street', img: imgTheStreet },
    ];
    const mobileSlider = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 2,
      slidesToScroll: 2,
    };
    return (
      <>
        <section className='section-1 desktop'>
          <Container>
            <Row className='section-1-header'>
              <Col sm={4} className='section-1-col-1'>
                <a
                  href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    rel='noopener noreferrer'
                    width='138'
                    className='dl-img mr-15'
                    alt='timer'
                    src={imgGooglePlay}
                  ></img>
                </a>
                <a href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'>
                  <img
                    width='138'
                    className='dl-img'
                    alt='timer'
                    src={imgAppleStore}
                  ></img>
                </a>
              </Col>
              <Col sm={5} className='text-center section-1-col-2'>
                <a href='/'>
                  <img alt='timer' src={imgOvato}></img>
                </a>
                <p className='white-text source-sans-pro font-20 text-center bold italic lh-1-1 mt-30'>
                  {t('landingtop.headline')}
                </p>
              </Col>
              <Col sm={3} className='text-right section-1-col-3'>
                <p className='mb-1 font-20 medium symbol white-text'>
                  {t('landingtop.trading_symbol')}:&nbsp;
                  <span className='bold'>OVO</span>
                </p>
                {this.state.exchloaded && <p className='mb-1 font-20 medium symbol white-text'>
                  {t('landingtop.current_price')}:&nbsp;
                  <span className='bold'>${this.state.exch}</span>
                </p>
                }
              </Col>
            </Row>
            <Row className='section-1-body align-center mt-40'>
              <Col sm={8}>
                <h1 className='font-73 bold lh-1-1 white-text'>
                  {t('section1.an_untapped')}
                  <br />
                  {t('section1.unicorn_opportunity')}
                </h1>
                <p className='font-28 medium white-text mt-20'>
                  {t('section1.head')}
                </p>
                <div class='btn-wrap mt-40'>
                  <a className='gradient-pink-btn gradient-pink-btn-3' onClick={this.scrollToSection} style={{ cursor: 'pointer' }}>
                    Invest
                  </a>
                   <span class='btn-divider'>|</span> 
                  <a className='gradient-pink-btn gradient-pink-btn-3' onClick={this.scrollToSection2} style={{ cursor: 'pointer' }}>
                    Earn Ovato
                  </a>
                   <span class='btn-divider'>|</span> 
                  <a className='gradient-pink-btn gradient-pink-btn-3' onClick={this.scrollToSection3} style={{ cursor: 'pointer' }}>
                    Partner
                  </a>
                </div>
              </Col>
              <Col sm={4}>
                <div className='img-wrap img-ovato-horse'>
                  <img src={imgOvatoHorse} alt='Ovato Horse' />
                </div>
              </Col>
            </Row>
            {sliders && (
              <Row className='section-1-footer mt-40'>
                <Col>
                  <div className='section-1-slider-wrap flex space-between'>
                    {sliders.map((slider) => (
                      <div className='section-1-slider'>
                        <div className='section-1-slider-inner'>
                          <img src={slider.img} alt={slider.title} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </section>
        <section className='mobile section-1-mobile pt-40 pb-20'>
          <Container>
            <Row>
              <Col>
                <div className='img-wrap text-center'>
                  <a href='/'>
                    <img alt='timer' width='120' src={imgOvato}></img>
                  </a>
                </div>
                <p className='white-text source-sans-pro font-20 text-center bold italic lh-1-1 mt-30'>
                  {t('landingtop.headline')}
                </p>
              </Col>
            </Row>
            <Row className='mt-15'>
              <Col>
                <p className='mb-1 font-16 medium symbol white-text'>
                  {t('landingtop.trading_symbol')}:&nbsp;
                  <span className='bold'>OVO</span>
                </p>
              </Col>
              <Col>
                {this.state.exchloaded && <p className='mb-1 font-16 medium symbol white-text text-right'>
                  {t('landingtop.current_price')}:&nbsp;
                  <span className='bold'>${this.state.exch}</span>
                </p>
                }
              </Col>
            </Row>
            <Row className='mt-30'>
              <Col>
                <h1 className='font-52 bold lh-1-1 white-text text-center'>
                  {t('section1.an_untapped')}
                  <br />
                  {t('section1.unicorn_opportunity')}
                </h1>
                <p className='font-20 text-center medium white-text mt-20'>
                  {t('section1.head')}
                </p>
                <div class='btn-wrap mt-30'>
                  <a className='gradient-pink-btn full-width gradient-pink-btn-3' href='#section1'>
                    Invest
                  </a>
                  <a
                    className='gradient-pink-btn full-width mt-8 gradient-pink-btn-3'
                    href='#section1'
                  >
                    Earn Ovato
                  </a>
                  <a
                    className='gradient-pink-btn full-width mt-8 gradient-pink-btn-3'
                    href='#section1'
                  >
                    Partner
                  </a>
                </div>
                <div className='img-wrap img-ovato-horse-mobile mt-30 text-center'>
                  <img src={imgMobileHorse} alt='Ovato Horse' />
                </div>
                <Slider
                  {...mobileSlider}
                  className='section-1-mobile-slider mt-20'
                >
                  {sliders.map((slider) => (
                    <div className='slider-col'>
                      <div className='slider-inner'>
                        <img src={slider.img} alt={slider.title} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section1);
