import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import yellowPaper from '../assets/img/OVATO_YELLOWPAPER.pdf';
import BottomNav from '../containers/NewBottomNav';
import TopNav from '../containers/NewTopNav';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Fancybox from '../containers/FancyBox';
import ImgOwlPeople from '../assets/img/img-owl-people-welcome-founders-club.png'
import ImgCollections from '../assets/img/img-founders-club-welcome-collections.png'
import ImgCollectionsMobile from '../assets/img/img-founders-collection-mobile.png' 
import ImgVideoPoster from '../assets/img/video-welcome-founders-club.jpg'
class FoundersClub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  render() {
    const { t } = this.props;
    return (
        <>
      <div className='founders-club-welcome'>
        {/* HEADER */}
        <TopNav />
        {/* BANNER SECTION */}
        <div className='new-first-row fcw-banner'>
            <Container>
                <Row className='align-center'>
                    <Col md={4} className='fcw-banner-left'>
                        <div className='img-wrap fcw-banner-img'>
                            <img src={ImgOwlPeople} />
                        </div>
                    </Col>
                    <Col md={8} className='fcw-banner-right'>
                        <h1 className='poppins font-47 bold dark-text-5 fcw-banner-heading'>{t('foundersClub.welcome')}</h1>
                        <h2 className='poppins font-27 bold dark-text-5 fcw-banner-subheading'>{t('foundersClub.anExclusive')}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
        {/* SECTION 2 */}
        <div className='fcw-2'>
            <Container>
                <Row>
                    <Col md={4} className='fcw-2-left'>
                        <p className='poppins font-16 light dark-text-3'>{t('foundersClub.theFounders')}</p>
                        <div className='btn-wrap fcw-2-btn-wrap'>
                            <a href='/founders-club-top-50-nft' className='custom-site-btn'>{t('foundersClub.discover')}</a>
                        </div>
                    </Col>
                    <Col md={8} className='fcw-2-right'>
                        <div className='desktop img-wrap fcw-2-right-img'>
                            <img src={ImgCollections} />
                        </div>
                        <div className='mobile img-wrap fcw-2-right-img'>
                            <img src={ImgCollectionsMobile} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        {/* SECTION 3 */}
        <div className='fcw-3'>
            <Container>
                <Row className='align-center'>
                    <Col md={7} className='fcw-3-left'>
                        <Fancybox>
                            <a data-fancybox href='https://www.youtube.com/watch?v=f_w6CzDuadE'>
                                <img className='new-home-4-video' src={ImgVideoPoster} />
                            </a>
                        </Fancybox>
                    </Col>
                    <Col md={5} className='fcw-3-right'>
                        <h2 className='poppins font-24 bold dark-text-5'>{t('foundersClub.each')}</h2>
                        <ul className='custom-checklist fcw-3-right-checklist'>
                            <li>{t('foundersClub.very')}</li>
                            <li>{t('foundersClub.ovo')}</li>
                            <li>{t('foundersClub.ability')}</li>
                            <li>{t('foundersClub.earn')}</li>
                            <li>{t('foundersClub.have')}</li>
                            <li>{t('foundersClub.individual')}</li>
                        </ul>
                        <div className='btn-wrap fcw-3-btn-wrap'>
                            <a href='/founders-club-top-50-nft' className='custom-site-btn'>{t('foundersClub.discover')}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
      </>
    );
  }
}
export default withTranslation()(FoundersClub);