import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';

// OTP Input
import OtpInput from 'react-otp-input';

// Crypto
import Crypto from 'crypto-js'

// Default Values
import { customConsole, secret } from "../../constants/defaultValues"

// Providers
import { UserAuth } from '../../providers/UserAuth';
import { User } from '../../providers/User';

// CSS
import '../../assets/css/LoginModal.css'
import '../../assets/css/OTPModal.css'
import 'react-toastify/dist/ReactToastify.css';
// import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/style.css'

// Components
import PhoneInput from 'react-phone-input-2'
import { ToastContainer, toast } from 'react-toastify';

// Images
import ImgOTP from '../../assets/img/login-modal/img-otp-mobile.png'

function LoginModal(props) {

  const { showModal, handleModalClose, showOTP, handleOTPModal, handleOTPModalClose, redirectURL } = props;

  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState(null)
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(false)
  const [otp, setOtp] = useState('');
  const [forgotPhone, setForgotPhone] = useState(null);
  const [forgotToken, setForgotToken] = useState(null);
  const [forgotUser, setForgotUser] = useState(null);

  const [otpTimer, setOtpTimer] = useState(true) 
  const [otpSeconds, setOtpSeconds] = useState(0)

  const navigate = useHistory();

  // Methods
  const handleClose = () => setShow(false);

  const onPhoneChange = (value, country) => {
    const country_code = "+" + country.dialCode;
    const phone = value.toString().replace(country.dialCode, "")
    setPhone(country_code + "-" + phone)
  }

  // Set Login Payload
  const setloginPayload = () => {
        let payload = {}

        payload = {
            detail: phone,
            password: password,
            rememberMe: remember
        }

        login(payload)
    }

    // Login function
    const login = (payload) => {

        payload.password = Crypto.HmacSHA512(payload.password, secret).toString(Crypto.enc.Hex);

        UserAuth.login(payload)
            .then(res => {
                User.setUser(res)
                localStorage.setItem("signupmobileverified", "true")
                localStorage.setItem('x-access-token', res.token)
                toast.success('Logging in. Please wait...')
                setIsLoading(true)
                setTimeout(() => {
                    if (redirectURL && redirectURL != '') {
                      window.location.href = redirectURL
                    } else {
                      window.location.href = '/nfts'
                    }
                }, 3000);
            })
            .catch(err => {
                toast.error(err && err.response ? err.response.data.msg : "Something went wrong");
                setIsLoading(false)
            })
    }

  // Handle Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    // Check phone or password is not empty
    if (!phone || !password) {
        toast.error("All fields are mandatory!", {theme: "colored"});
        setIsLoading(false)
        return
    } else {
        // Login request
        setloginPayload()
    }
  };

  // Forgot Password
  const forgotPassword = () => {
    if (!phone) {
      toast.error("Please enter your phone to proceed forgot password");
    } else {
      let payload = {}

      payload = { detail: phone }

      UserAuth.forgotPassword(payload).then(res => {
        
        // this.setState({forgot_phone:res.contact,forgot_token:res.token,forgot_user:res.user})

        setForgotPhone(res.contact)
        setForgotToken(res.token)
        setForgotUser(res.user)

        toast.success(`Please enter the OTP sent to your mobile number`);

        // Hide
        handleModalClose()

        // Show OTP
        handleOTPModal(true)
      })
        .catch(err => {
          if (err.status == 429)
            toast.error("Please try again in an hour, You have exceeded your limit");
          else
            toast.error(err && err.response ? err.response.data.msg : "Something went wrong");
        })
        .finally(() => {
          // this.toggleWallet()
        })
    }
  }

  // Verify Forgot OTP 
  const verifyForgotOTP = (e) => {
    e.preventDefault();

    let payload = {
      mobile: forgotPhone,
      otp: otp,
      type: 4,
      otp_type: 1,
    }

    customConsole.log('verify forgot payload', payload)

    UserAuth.validateForgotOtp(payload)
      .then(res => {
        // customConsole.log('success verify', res)
        toast.success('Redirecting...');
        // customConsole.log(res);
        // his.setState({errmsg:''})
        let link="/auth/password-reset/" + forgotPhone + "/" + forgotToken
        // this.props.history.push(link)
        window.location.href = link
      }).catch(err => {
        // toast.error(err && err.response ? err.response.data.msg : "error in verifying OTP");
        toast.error("Error in Verifying OTP");
        customConsole.log('error verify forgot otp', err)
        localStorage.setItem("signupmobileverified", "false")
      })
  }

  // Resend Forgot OTP
  const resendForgotOTP = async () => {
    // this.setState({ otp_timer: true, seconds: 60 })
    setOtpTimer(true)
    setOtpSeconds(60)

    let payload={user: forgotUser, type: 4, otp_type: 1 }

    // customConsole.log(payload);

    UserAuth.resendTwoFA(payload)
      .then(res => {
        // customConsole.log('reerererreer', res)
        toast.success("OTP sent successfully");
      })
      .catch((err) => {
        // customConsole.log('erer', err)
        toast.error(err && err.response ? err.response.data.msg : "error sending OTP");
      })

    //   this.myInterval = setInterval(() => {
    //     const { seconds } = this.state
    //     if (seconds > 0) {
    //         this.setState(({ seconds }) => ({
    //             seconds: seconds - 1
    //         }))
    //     }
    //     else if (seconds === 0) {
    //         this.setState({ otp_timer: false, seconds: 0 })
    //         clearInterval(this.myInterval)
    //     }
    // }, 1000)
  }

  return (
    <>
      <Modal className='login-modal' show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form id="login-form" onSubmit={handleSubmit}>
                <div className='form-row phone-row'>
                    <PhoneInput
                        country={'us'}
                        value={phone}
                        onChange={(value, country) => onPhoneChange(value, country)}
                        enableSearch={true}
                        placeholder={'Enter your mobile no.'}
                    />
                </div>

                <div className='form-row password-row'>
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Enter your password" 
                        onChange={(e) => setPassword(e.target.value)}
                        required />
                </div>

                <div className='form-row'>
                    <button disabled={isLoading} type="submit">
                        { isLoading ? 'Loading...' : 'Login' }
                    </button>
                </div>

                <div className='v2-mt-1-5 flex align-center justify-content-space-between'>
                  <div className='remember-me'><label><input type="checkbox" name="remember_me" /> Remember Me</label></div>

                  <a href='#' className='blue-text forgot-text v2-font-14 v2-m-font-12 medium poppins' onClick={forgotPassword}>Forgot Password?</a>
                </div>

            </form>
        </Modal.Body>
      </Modal>

      {/* OTP Forgot Modal */}
      <Modal className='login-modal otp-forgot-modal' show={showOTP} onHide={handleOTPModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='text-center img-wrap'>
              <img src={ImgOTP} width={75} />
            </div>

            <div className='poppins lh-1-2 dark-text-2 bold v2-font-28 v2-m-font-24 text-center text-uppercase v2-mt-1-5'>Verify mobile number</div>

            <p className='poppins lh-1-4 dark-text-11 medium v2-font-16 v2-m-font-15 text-center v2-mt-0-25'>To reset your password, please enter OTP sent to your registered mobile number.</p>

            <form id="otp-forgot-form">
              <div className='form-row'>
                <div className='otp-wrap'>
                  <div className='otp-input'>
                    <OtpInput 
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={''}
                        renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <button type="submit" onClick={(e) => verifyForgotOTP(e)}>Verify</button>
              </div>

              <div className='text-center justify-content-center form-row'>
                <a href="#" class="blue-text forgot-text v2-font-14 v2-m-font-15 medium poppins" onClick={resendForgotOTP}>Resend</a>
              </div>
            </form>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
}

export default LoginModal;