import React, { Component, useState } from 'react';
import { Row, Col, Container, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgClose from '../assets/img/icon-mindmap-modal-close.png';
class DaoComingSoonModal extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
        show: ''
    }
  }
  componentDidMount() {
    const {show} = this.props;
    this.setState({show})
  }
  render() {
    const { t } = this.props;
    return (
      <>
        {/* MODAL */}
        <Modal 
            className={'mindmap-modal dao-coming-soon-modal'}
            isOpen={this.state.show} 
        >
            <ModalHeader>
                <a className='mindmap-modal-close' onClick={(event) => this.props.toggleDaoModal(event, false)}>
                    <img src={imgClose} alt='Close' />
                </a>
            </ModalHeader>
            <ModalBody>
                <h2 className='poppins font-55 bold white-text dao-coming-soon-modal-title'>{t('dao.launching')}</h2>
                <p className='poppins font-18 regular white-text dao-coming-soon-modal-text'>{t('dao.welcome')}</p>
            </ModalBody>
        </Modal>
      </>
    );
  }
}
export default withTranslation()(DaoComingSoonModal);
