import React from 'react';
const nftOverview = (country) => {
    switch (country) {
        case 'USA':
            return (
                <p>The <strong>United States of America</strong>, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, nine Minor Outlying Islands, and 326 Indian reservations. It has a total population of 336,905,879 as of 2023 and a GNP of $20,382.26B.</p>
            )
            break;
        case 'UK':
            return (
                <p>The <strong>United Kingdom of Great Britain and Northern Ireland</strong>, is a country in Northwestern Europe, off the north-western coast of the continental mainland. It comprises England, Scotland, Wales, and Northern Ireland. It has a total population of 68,968,721 as of 2023 and a GNP of $2,994.73B.</p>
            )
            break;
        case 'JAPAN':
            return (
                <p><strong>Japan</strong> is an island country in East Asia. It is situated in the northwest Pacific Ocean and is bordered on the west by the Sea of Japan, extending from the Sea of Okhotsk in the north toward the East China Sea, Philippine Sea, and Taiwan in the south. It has a total population of 123,294,513 as of 2023 and a GNP of $5.16T.</p>
            )
            break;
        case 'CHINA':
            return (
                <p><strong>China</strong> is a country in East Asia. It is the world's second-most populous country, with a population exceeding 1.4 billion. China spans the equivalent of five time zones and borders fourteen countries by land. This country has a GNP of $14.62T.
</p>
            )
            break;
        case 'CANADA':
            return (
                <p><strong>Canada</strong> is a country in North America. Its ten provinces and three territories extend from the Atlantic Ocean to the Pacific Ocean and northward into the Arctic Ocean, making it the world's second-largest country by total area, with the world's longest coastline.It has a total population of 38,781,292 as of 2023 and a GNP of $14.62T.
</p>
            )
            break;
        case 'FRANCE':
            return (
                <p><strong>France</strong> is a country located primarily in Western Europe. It also includes overseas regions and territories in the Americas and the Atlantic, Pacific and Indian Oceans, giving it one of the largest discontiguous exclusive economic zones in the world. It has a total population of 67,075,000 as of 2023 and a GNP of $2,991.55B.
</p>
            )
            break;
        case 'GERMANY':
            return (
                <p><strong>Germany</strong>, officially the Federal Republic of Germany, is a country in Central Europe. It is the second-most populous country in Europe after Russia, and the most populous member state of the European Union. This country has 16 constituent states and has a total population of 83,294,633 as of 2023 and a GNP of $4,298.33B.
</p>
            )
            break;
        case 'INDIA':
            return (
                <p><strong>India</strong> is a country in South Asia. It is the seventh-largest country by area; the most populous country as of June 1, 2023; and from the time of its independence in 1947, the world's most populous democracy. It has a total population of 1,428,627,663 as of 2023 and a GNP of $3,023.42B.
</p>
            )
            break;
        case 'ITALY':
            return (
                <p><strong>Italy</strong> is a country in Southern and Western Europe. Located in the middle of the Mediterranean Sea, it consists of a peninsula delimited by the Alps and surrounded by several islands; its territory largely coincides with the homonymous geographical region. It has a total population of 58,870,763 as of 2023 and a GNp of $2,127.12B.
</p>
            )
            break;
        case 'SPAIN':
            return (
                <p><strong>Spain</strong> is a country primarily located in Southwestern Europe, with parts of its territory in the Atlantic Ocean and across the Mediterranean Sea. The largest part of Spain is situated on the Iberian Peninsula. It has a total population of 47,519,628 as of 2023 and a GNP of $1,407.94B.</p>
            )
            break;
        case 'IRELAND':
            return (
                <p><strong>Ireland</strong> is an island in the North Atlantic Ocean, in north-western Europe. It is separated from Great Britain to its east by the North Channel, the Irish Sea, and St George's Channel. Ireland is the second-largest island of the British Isles, the third-largest in Europe, and the twentieth-largest in the world. It has a total population of 5,056,935 as of 2023 and has a GNP of $383.08B.</p>
            )
            break;
        case 'COLUMBIA':
            return (
                <p><strong>Columbia</strong> is a country mostly in South America with insular regions in North America. The Columbian mainland is bordered by the Caribbean Sea to the north, Venezuela to the east and northeast, Brazil to the southeast, Ecuador and Peru to the south and southwest, the Pacific Ocean to the west, and Panama to the northwest. It has a population of 52,085,168 as of 2023 and a GNp of $318.93B. </p>
            )
            break;
        case 'MEXICO':
            return (
                <p><strong>Mexico</strong> is a country in the southern portion of North America. It is bordered to the north by the United States; to the south and west by the Pacific Ocean; to the southeast by Guatemala, Belize, and the Caribbean Sea; and to the east by the Gulf of Mexico. It has a total population of 128,455,567 as of 2023 and a GNP of $1,239.325B.</p>
            )
            break;
        case 'NETHERLANDS':
            return (
                <p>The <strong>Netherlands</strong> is a country located in northwestern Europe with overseas territories in the Caribbean. It is the largest of four constituent countries of the Kingdom of the Netherlands. The Netherlands consists of twelve provinces. It has a total population of 17,618,299 as of 2023 and a GNP of $988.28B.</p>
            )
            break;
        case 'AUSTRALIA':
            return (
                <p><strong>Australia</strong>, officially the Commonwealth of Australia, is a sovereign country comprising the mainland of the Australian continent, the island of Tasmania, and numerous smaller islands.  Australia is the largest country by area in Oceania. It has a total population of 26,439,112 as of 2023 and a GNP of $1,468.49B. </p>
            )
            break;
        case 'MALTA':
            return (
                <p><strong>Malta</strong> is an island country in Southern Europe, located in the Mediterranean Sea. It consists of an archipelago, between Italy and Libya. It lies 80 km (50 mi) south of Sicily (Italy), 284 km (176 mi) east of Tunisia, and 333 km (207 mi) north of Libya. It has a total population if 535,065 as of 2023 and a GNP of $15.95B.
</p>
            )
            break;
        case 'BRAZIL':
            return (
                <p><strong>Brazil</strong> is the largest country in South America and in Latin America. Brazil is the world's fifth-largest country by area and the seventh most populous. Its capital is Brasília, and its most populous city is São Paulo. It has a total population of 216,422,446 as of 2023 and a GNP of $1.87T.
</p>
            )
            break;
        case 'UKRAINE':
            return (
                <p><strong>Ukraine</strong> is a country in Eastern Europe. It is the second-largest European country after Russia, which it borders to the east and northeast. It is also bordered by Belarus to the north; by Poland, Slovakia, and Hungary to the west; and by Romania and Moldova to the southwest. It has a total population of 36,744,634 as of 2023 and a GNP of $170.65B.
</p>
            )
            break;
        case 'OVATO NATION':
            return (
                <p><strong>Ovato</strong> is built on the same blockchain protocol as Bitcoin. Ovato is an extraordinarily efficient method of exchanging value in peer-to-peer transactions. Ovato is taking the unique business approach of specifically forging strategic partnerships for the mass adoption of its coin.
<strong>Ovato</strong> is specifically designed and programmed to be the default digital currency for shopping for both merchants and shoppers. The protocol is designed to be extremely fast, making it ideal for peer to peer and small transactions.</p>
            )
            break;
        case 'SWITZERLAND':
            return (
                <p><strong>Switzerland</strong>, officially the Swiss Confederation, is a landlocked country located at the confluence of Western, Central and Southern Europe. It is bordered by Italy to the south, France to the west, Germany to the north and Austria and Liechtenstein to the east. It has a total population of 8,796,669 as of 2023 and a GNP of $788.51B.</p>
            )
            break;
        case 'AUSTRIA':
            return (
                <p><strong>Austria</strong>, formally the <strong>Republic of Austria</strong>, is a landlocked country in Central Europe, lying in the Eastern Alps.  It is a federation of nine provinces, one of which is the capital, Vienna, the most populous city and province. It has a total population of 8,958,961 as of 2023 and a GNP of $472.47B.</p>
            )
            break;
        case 'BELARUS':
            return (
                <p><strong>Belarus</strong>,  officially the <strong>Republic of Belarus</strong>, is a landlocked country in Eastern Europe. It is bordered by Russia to the east and northeast, Ukraine to the south, Poland to the west, and Lithuania and Latvia to the northwest. It has a total population of 9,498,238 as of 2023 and a GNP of $65.567B. </p>
            )
            break;
        case 'HUNGARY':
            return (
                <p><strong>Hungary</strong>  is a landlocked country in Central Europe.  Spanning 93,030 square kilometres of the Carpathian Basin, it is bordered by Slovakia to the north, Ukraine to the northeast, Romania to the east and southeast, Serbia to the south, Croatia and Slovenia to the southwest, and Austria to the west. It has a population of 10,156,239 and a GNP of $172.21B. 
</p>
            )
            break;
        case 'LUXEMBOURG':
            return (
                <p><strong>Luxembourg</strong> is a small landlocked country in Western Europe. It borders Belgium to the west and north, Germany to the east, and France to the south. Its capital and most populous city, Luxembourg, is one of the four institutional seats of the European Union. It has a total population of 654,768 and a GNP of $56.45B. </p>
            )
            break;
        case 'MONTENEGRO':
            return (
                <p><strong>Montenegro</strong> is a country in Southeastern Europe.  It is a part of the Balkans and is bordered by Bosnia and Herzegovina to the northwest, Serbia to the northeast and Kosovo to the east, Albania to the southeast, Croatia to the southwest, and the Adriatic Sea to the south. It has a total population of 626,485 and a GNP of $5.984B.
</p>
            )
            break;
        case 'BULGARIA':
            return (
                <p><strong>Bulgaria</strong> is a country in Southeast Europe. It is situated on the eastern flank of the Balkans, and is bordered by Romania to the north, Serbia and North Macedonia to the west, Greece and Turkey to the south, and the Black Sea to the east. It has a total population of 6,687,717 as of 2023 and a GNP of $81.866B.
</p>
            )
            break;
        case 'HOLY SEE':
            return (
                <p>The <strong>Holy See</strong> is the jurisdiction of the Pope in his role as the bishop of Rome. It includes the apostolic episcopal see of the Diocese of Rome, which has ecclesiastical jurisdiction over the Catholic Church and sovereignty over the city-state known as Vatican City. It has a total population of 800-1000 people.
</p>
            )
            break;
        case 'CROATIA':
            return (
                <p><strong>Croatia</strong> is a country at the crossroads of Central and Southeast Europe. Its capital is Zagreb which is also the largest city. The country spans 56,594 square kilometres (21,851 square miles), and has a population of 4,008,617 as of 2023 and a GNP of $68.72B.
</p>
            )
            break;
        case 'NORWAY':
            return (
                <p><strong>Norway</strong> is a Nordic country in Northern Europe, the mainland territory of which comprises the western and northernmost portion of the Scandinavian Peninsula. The capital and largest city in Norway is Oslo. It has a total population of 5,474,360 as of 2023 and a GNP of $453.63B. 
</p>
            )
            break;
        case 'SLOVAKIA':
            return (
                <p><strong>Slovakia</strong> is a landlocked country in Central Europe. It is bordered by Poland to the north, Ukraine to the east, Hungary to the south, Austria to the southwest, and the Czech Republic to the northwest. It has a total population of 5,795,199 as of 2023 and a GNP of $112.42B. 
</p>
            )
            break;
        case 'DENMARK':
            return (
                <p><strong>Denmark</strong> is a Nordic country in Northern Europe. It is the metropolitan part of and most populous constituent of the Kingdom of Denmark, a constitutionally unitary state that includes the autonomous territories of the Faroe Islands and Greenland in the North Atlantic Ocean.  It has a total population of 5,910,913 as of 2023 and a GNP of $400.04B. 
</p>
            )
            break;
        case 'FINLAND':
            return (
                <p><strong>Finland</strong> is a Nordic country in Northern Europe. It borders Sweden to the northwest, Norway to the north, and Russia to the east, with the Gulf of Bothnia to the west and the Gulf of Finland to the south, across from Estonia. It has a total population of 5,545,475 as of 2023 and a GNP of $296.47B. 
</p>
            )
            break;
        case 'ICELAND':
            return (
                <p><strong>Iceland</strong>  is a Nordic island country in the North Atlantic Ocean and in the Arctic Ocean. Iceland is the most sparsely populated country in Europe.  Iceland's capital and largest city is Reykjavík. It has a total population of 375,319 as of 2023 and a GNP of $23.64B.
</p>
            )
            break;
        case 'POLAND':
            return (
                <p><strong>Poland</strong> is a country in Central Europe. It is divided into 16 administrative provinces called voivodeships. Poland has a population of 41,026,068 as of 2023 and is the fifth-most populous member state of the European Union. It has a GNP of $636.06B.
</p>
            )
            break;
        case 'LIECHTENSTEIN':
            return (
                <p><strong>Liechtenstein</strong> is a <u>German-speaking</u> and doubly landlocked country and microstate located in the Alps between Austria and Switzerland.  It is the sixth smallest nation worldwide. It has a total population of 39,585 as of 2023 and a GNP of $116,600. 
</p>
            )
            break;
        case 'MONACO':
            return (
                <p><strong>Monaco</strong> is a sovereign city-state and microstate on the French Riviera a few kilometres west of the Italian region of Liguria, in Western Europe, on the Mediterranean Sea. It is bordered by France to the north, east and west. It has a total population of 36,298 as of 2023. </p>
            )
            break;
        case 'MOLDOVA':
            return (
                <p><strong>Moldova</strong> is a landlocked country in Eastern Europe.  It is bordered by Romania to the west and Ukraine to the north, east, and south. The unrecognised state of Transnistria lies across the Dniester river on the country's eastern border with Ukraine. It has a total population of 3,435,931 as of 2023 and a GNP of 14.056B. </p>
            )
            break;
        case 'NORTH MACEDONIA':
            return (
                <p><strong>North Macedonia</strong> is a landlocked country in Southeast Europe. It shares land borders with Kosovo  to the northwest, Serbia to the north, Bulgaria to the east, Greece to the south, and Albania to the west. It has a total population of 2,085,679 as of 2023 and a GNP of 11.87B. </p>
            )
            break;
        case 'LITHUANIA':
            return (
                <p><strong>Lithuania</strong> is a country in the Baltic region of Europe. It is one of three Baltic states and lies on the eastern shore of the Baltic Sea. Lithuania covers an area of 65,300 km2 (25,200 sq mi), with a population of 2,718,352 as of 2023 and a GNP of $60.88B. </p>
            )
            break;
    
        default:
            return (
                <p>--</p>
            )
            break;
    }
}
export default nftOverview