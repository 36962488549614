import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Containers
import TopNavPremium from '../../containers/TopNavPremium';
import BottomNav from '../../containers/NewBottomNav';

// Components
import EarningWrap from '../../components/premium/earnings/EarningWrap';
import EarningBox from '../../components/premium/earnings/EarningBox';

// CSS
import '../../assets/css/Earnings.css'

class Earnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    return (
        <div className='earning-main'>
            {/* Header */}
            <TopNavPremium />

            <section className='top-section v2-py-3 earning-1 v2-mb--20 v2-m-mb-0'>
                <div className='v2-mw-1400 container'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='poppins v2-font-30 v2-tp-font-28 v2-m-font-24 semi-bold blue-text text-center mb-0'>Earnings</h1>
                        </div>
                    </div>

                    <div className='v2-mt-2 row'>
                        <div className='col'>
                            <EarningWrap />
                        </div>
                    </div>

                    <div className='row v2-mt-4 v2-m-mt-3'>
                        <div className='col flex justify-center content-center align-center v2-m-flex v2-m-column'>
                            <h2 className='poppins v2-font-21 v2-tl-font-20 v2-m-font-22 semi-bold blue-text v2-mr-2 v2-m-mr-0'>Sources</h2>
                            <ul className='v2-m-mt-0-5 earning-level'>
                                <li><a href='#' className='active'>Level 1</a></li>
                                <li><a href='#' className=''>Level 2</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className='relative z-index-2 row earning-box-row'>
                        <div className='v2-mt-2 v2-m-mt-1 col'>
                            <EarningBox title={'Wallets'} value={9999} />
                        </div>

                        <div className='v2-mt-2 col'>
                            <EarningBox title={'NFTs'} value={9999} />
                        </div>

                        <div className='v2-mt-2 col'>
                            <EarningBox title={'Royalties'} value={9999} />
                        </div>

                        <div className='v2-mt-2 col'>
                            <EarningBox title={'Merchants'} value={9999} />
                        </div>

                        <div className='v2-mt-2 col'>
                            <EarningBox title={'Memberships'} value={9999}/>
                        </div>
                    </div>

                </div>
            </section>

            {/* Footer */}
            <BottomNav />
        </div>
    );
  }
}
export default withTranslation()(Earnings);