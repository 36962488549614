import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Images
import ImgUSA from '../../assets/img/leaderboard/img-usa.jpg'

class LeaderboardTableMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    formatEarnings = (number) => {
        const formattedEarnings = new Intl.NumberFormat('en-US').format(number);
        return formattedEarnings;
    }

  render() {
    const { t, data } = this.props;

    return (
        <div className='leaderboard-tbl-mobile'>
            { data.map((item, key) => (
                <div className='leaderboard-tbl-mobile-row'>

                    <div className='d-flex justify-content-space-between align-items-center leaderboard-tbl-mobile-header'>
                        <div className='poppins v2-font-16 medium blue-text leaderboard-tbl-mobile-header-col' title={item.username}>
                            { item.username }
                        </div>

                        <div className='leaderboard-tbl-mobile-header-col'>
                            <img src={ImgUSA} />
                        </div>
                    </div>

                    <div className='v2-mt-0-25 leaderboard-tbl-mobile-body'>
                        <div className='poppins v2-font-14 medium gray-text-6 text-left'>Wallet Earnings: { this.formatEarnings(item.wallet_earnings) } USD</div>
                    </div>

                    <div className='leaderboard-tbl-mobile-footer'>
                        {/* <div className='leaderboard-tbl-mobile-footer-col'>
                            <div className='v2-font-11 poppins medium blue-text lh-1-2 leaderboard-tbl-mobile-footer-col-text-1'>No. of Wallets Opened</div>
                            <div className='v2-font-24 v2-m-font-16 poppins bold blue-text leaderboard-tbl-mobile-footer-col-text-2'>{ this.formatEarnings(item.wallet_earnings) }</div>
                        </div> */}

                        <div className='leaderboard-tbl-mobile-footer-col'>
                            <div className='v2-font-11 poppins medium blue-text lh-1-2 leaderboard-tbl-mobile-footer-col-text-1'>NFT Earnings</div>
                            <div className='v2-font-24 v2-m-font-16 poppins bold blue-text leaderboard-tbl-mobile-footer-col-text-2'>{ this.formatEarnings(item.nft_earnings) }</div>
                        </div>

                        <div className='leaderboard-tbl-mobile-footer-col'>
                            <div className='v2-font-11 poppins medium blue-text lh-1-2 leaderboard-tbl-mobile-footer-col-text-1'>Total Earnings</div>
                            <div className='v2-font-24 v2-m-font-16 poppins bold blue-text leaderboard-tbl-mobile-footer-col-text-2'>{ this.formatEarnings(item.total_earnings) }</div>
                        </div>

                        {/* <div className='leaderboard-tbl-mobile-footer-col'>
                            <div className='v2-font-11 poppins medium blue-text lh-1-2 leaderboard-tbl-mobile-footer-col-text-1'>Total number in organization</div>
                            <div className='v2-font-24 v2-m-font-16 poppins bold blue-text leaderboard-tbl-mobile-footer-col-text-2'>{ this.formatEarnings(item.total_earnings) }</div>
                        </div> */}
                    </div>
                </div>
            )) }
        </div>
    );
  }
}
export default withTranslation()(LeaderboardTableMobile);