import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgCycle from '../assets/img/img-cycle-4.png';
import imgMerchantWallets from '../assets/img/img-merchant-wallets-4.png';
import imgUserWallets from '../assets/img/img-user-wallets-4.png';
import imgCycleNew from '../assets/img/img-full-circle-economy-new.png';
import imgSection4MobileNew from '../assets/img/img-section-4-mobile-new.jpg';
import imgCycleMobile from '../assets/img/img-cycle-mobile.png';
class Section4 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='section-4-new desktop'>
          <Row>
            <Col>
              <div className='img-wrap section-4-img'></div>
            </Col>
          </Row>
          <Container className='section-4-new-container'>
            <Row className='section-4-new-row-1'>
              <Col>
                <div className='img-wrap section-4-img-wrap-1'>
                  <img src={imgCycleNew} />
                </div>
              </Col>
            </Row>
            <Row className='section-4-new-row-2'>
              <Col>
                <p className='bold font-18 blue-text text-center'>
                  User Wallets
                </p>
              </Col>
              <Col>
                <p className='bold font-18 blue-text mt--75 text-center'>
                  Merchant Wallets
                </p>
              </Col>
            </Row>
            {/* <Row className='mt-45 section-4-new-row-3'>
              <Col>
                <h2 className='text-center blue-text font-57 extra-bold'>
                  {t('section4.full_circle_ecosystem')}
                </h2>
                <p className='font-20 medium text-center mt-30'>
                  {t('section4.detail1')}
                </p>
                <p className='font-23 bold italic blue-text text-center lh-1-3 mt-30'>
                  {t('section4.detail2')}
                  <br />
                  {t('section4.detail3')}
                </p>
              </Col>
            </Row> */}
          </Container>
        </section>
        <section className='section-4-new-mobile mobile pb-20'>
          <Row>
            <Col>
              <div className='img-wrap text-center'>
                <img
                  alt='Section 4 Mobile'
                  className='full-width'
                  src={imgSection4MobileNew}
                />
              </div>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col>
                <div className='img-wrap text-center mt--45'>
                  <img src={imgCycleMobile} alt='Cycle' />
                </div>
              </Col>
            </Row>
            <Row className='flex-row'>
              <Col>
                <p className='bold font-17 blue-text text-center mt-15'>
                  User Wallets
                </p>
              </Col>
              <Col>
                <p className='bold font-17 blue-text mt-15 text-center'>
                  Merchant Wallets
                </p>
              </Col>
            </Row>
            {/* <Row className='mt-20'>
              <Col>
                <h2 className='text-center blue-text font-41 extra-bold lh-1-1'>
                  {t('section4.full_circle_ecosystem')}
                </h2>
                <p className='font-18 medium text-center mt-20'>
                  {t('section4.detail1')}
                </p>
                <p className='font-19 bold italic blue-text text-center lh-1-3 mt-20'>
                  {t('section4.detail2')} {t('section4.detail3')}
                </p>
              </Col>
            </Row> */}
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section4);
