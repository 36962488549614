import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NFTsModalStyle from '../../assets/css/NFTsCheckoutModal.css'


class CheckoutCryptoConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nftId:"",
    };
  }

  componentDidMount() {
    const {item} = this.props
    this.setState({ nftId: item.id })
  }

  render() {
    const { t, setModal, item, timer } = this.props;
    
    return (
        
        <div className={`nft-checkout-modal-container nft-checkout-modal-small ${this.props.show ? 'active' : ''}`}>
            <div className='nft-checkout-modal-backdrop' onClick={(e) => setModal(e, false)}></div>
            <div className='nft-checkout-modal-row'>
                <form className='nft-checkout-form'>
                    {/* HEADER */}
                    <div className='nft-checkout-modal-header'>
                        <h2 className='nft-checkout-modal-header-title'>Crypto Checkout Confirmation</h2>
                        <p className='nft-checkout-modal-header-close'><a href='#' onClick={(e) => setModal(e, false)}><span class="material-symbols-outlined">close</span></a></p>
                    </div>

                    <div className='nft-checkout-content'>
                        <p>This NFT has been reserved for 30 minutes. Execute payment immediately. Thank you!</p>
                    </div>

                    {/* SUBMIT BUTTON */}
                    <div className='nft-checkout-button-row'>
                        <button className='nft-checkout-button' onClick={this.props.onCheckout}>Proceed</button>
                    </div>
                </form>
            </div>
        </div>
    );
  }
}
export default withTranslation()(CheckoutCryptoConfirmationModal);