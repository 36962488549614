import React, { Component } from 'react';
import QRCode from 'qrcode.react';

// CSS
import '../../assets/css/QRCode.css'

class QRFinancialOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {

    const { t, url, size} = this.props;

    return (
        <div className='financial-qr-wrap'>
            <a href={url} target='_blank'><QRCode value={url} size={size} /></a>
        </div>
    );
  }
}

export default QRFinancialOverview;
