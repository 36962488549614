import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Components
import ProfileBox from './ProfileBox';
import DemographicsBox from './DemographicsBox';
import BalanceBox from './BalanceBox';
import IncomeBox from './IncomeBox';
import OrganizationBox from './OrganizationBox';
import HeatMapBox from './HeatMapBox';

class ProfileMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editmodal: false,
      deletetmodal: false,
      addmodal: false,
      fade: false,
      showSpinner: true,
      walletList: [],
      walletname: '',
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t } = this.props;

    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <div className='row pm-row'>
            <div className='col col-3'>
                <ProfileBox />

                <DemographicsBox />
            </div>
            <div className='col col-9'>
                
                <div className='row'>
                    <div class='col col-sm-6 v2 v2-tp-mt-2'>
                        <BalanceBox />
                    </div>

                    <div class='col col-sm-6 v2-tp-mt-2'>
                        <IncomeBox />
                    </div>
                </div>

                <div className='v2-mt-1-5 row'>
                    <div className='col'>
                        <OrganizationBox />
                    </div>
                </div>

                <div className='v2-mt-1-5 row'>
                    <div className='col'>
                        <HeatMapBox />
                    </div>
                </div>

            </div>
        </div>
    );
  }
}
export default withTranslation()(ProfileMain);
