import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class EarningBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  render() {
    const { t, title, value} = this.props;

    return (
        <div className='earning-box'>
            <div className='earning-box-header'>
                {title}
            </div>

            <div className='earning-box-body'>
                <div className='flex align-center space-between earning-box-total-wrap'>
                    <div className='poppins v2-font-17 v2-tl-font-16 v2-m-font-18 semi-bold blue-text earning-box-total'>Total</div>
                    <div className='poppins v2-font-18  v2-tl-font-16 v2-m-font-18 semi-bold blue-text earning-box-ovo'>{value} OVO</div>
                </div>

                <div className='v2-mt-1 justify-center earning-box-date-wrap'>
                    <ul className='earning-box-date'>
                        <li><a href='#'>7D</a></li>
                        <li><a href='#'>1m</a></li>
                        <li><a className='active' href='#'>3m</a></li>
                        <li><a href='#'>1y</a></li>
                        <li><a href='#'>Max</a></li>
                    </ul>

                    <div className='v2-mt-2 v2-font-18 v2-tl-font-16 v2-m-font-18 poppins medium blue-text earning-box-ovo-2 text-center'>
                        {value} OVO
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default withTranslation()(EarningBox);
