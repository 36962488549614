import React, { Component, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { User } from '../../providers/User';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [hasToken, setHasToken] = useState(null); // null means token check is in progress
  
    useEffect(() => {
      // Check token when the component mounts
      const checkToken = async () => {
        // const isValid = await User.hasToken();
        // setHasToken(isValid);

        User.getUser().then(data => {
          setHasToken(true)
        }).catch(error => {
          setHasToken(false)
        })
      };
      checkToken();
    }, []); // Empty array means this effect runs once (on mount)
  
    if (hasToken === null) {
      // While checking the token, render a loading spinner or placeholder
      return <div>Loading...</div>;
    }
  
    return (
      <Route
        {...rest}
        render={(props) =>
          hasToken ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  export default PrivateRoute